import { Platform } from "react-native";
import { ERentioFormField, ERentioFormFormat, } from "../../components/components/Form";
import { getValue, isValidVATNumber } from "../../redux/form/form.utils";
import { CONTACT_INITIAL_TYPE_READABLE, EContactInitialType, } from "../../types/contact.types";
import { formatAddress } from "../../utils/address.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getMappedLanguages, getMappedNationalities, } from "../../utils/location/location.utils";
import { EIdCardOptions } from "./schema.addContact.1.broker";
import { CONTACT_AGE_RANGE_MAX, CONTACT_AGE_RANGE_MIN, } from "./schema.addContact.constants";
import { EField } from "./schema.addContact.types";
import { getNavigationTitle } from "./schema.addContact.utils";
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [
                EField.VATNumber,
                EField.Company,
                EField.Email,
                EField.Phone,
                EField.Language,
            ],
            properties: (_a = {},
                _a[EField.Header] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function () { return Platform.OS !== "web"; },
                },
                _a[EField.InitialType] = {
                    default: getValue(EField.InitialType, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(EContactInitialType).map(function (initialType) { return ({
                        id: initialType,
                        value: getLocalizedText("contact.initial_type.".concat(CONTACT_INITIAL_TYPE_READABLE[initialType]).toLowerCase()),
                    }); }),
                    showIf: function () { return !extraData.contact && !extraData.contactId; },
                    // @ts-ignore
                    requiredIf: function () { return !extraData.contact && !extraData.contactId; },
                },
                _a[EField.VATNumber] = {
                    default: getValue(EField.VATNumber, initialValues, defaultValues),
                },
                _a[EField.Company] = {
                    default: getValue(EField.Company, initialValues, defaultValues),
                },
                _a[EField.PrefillAddress] = {
                    default: getValue(EField.PrefillAddress, initialValues, defaultValues),
                    // @ts-ignore
                    options: ["yes", "no"].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("contact.address_from_property.option.".concat(id.toLowerCase())),
                    }); }),
                    showIf: function () { return !!extraData.prefilledAddress; },
                },
                _a[EField.Address] = {
                    default: getValue(EField.Address, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) { return formData[EField.PrefillAddress] !== "yes"; },
                },
                _a[EField.Header2] = {
                    default: undefined,
                },
                _a[EField.Language] = {
                    default: getValue(EField.Language, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedLanguages(),
                },
                _a[EField.Firstname] = {
                    default: getValue(EField.Firstname, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: function () { var _a; return !((_a = extraData.optionalFields) === null || _a === void 0 ? void 0 : _a.includes(EField.Firstname)); },
                },
                _a[EField.Lastname] = {
                    default: getValue(EField.Lastname, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: function () { var _a; return !((_a = extraData.optionalFields) === null || _a === void 0 ? void 0 : _a.includes(EField.Lastname)); },
                },
                _a[EField.Email] = {
                    default: getValue(EField.Email, initialValues, defaultValues),
                },
                _a[EField.Phone] = {
                    default: getValue(EField.Phone, initialValues, defaultValues),
                },
                _a[EField.DateOfBirth] = {
                    default: getValue(EField.DateOfBirth, initialValues, defaultValues),
                },
                _a[EField.PlaceOfBirth] = {
                    default: getValue(EField.PlaceOfBirth, initialValues, defaultValues),
                },
                _a[EField.Nationality] = {
                    default: getValue(EField.Nationality, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedNationalities(),
                },
                _a[EField.IdType] = {
                    default: getValue(EField.IdType, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        EIdCardOptions.BelgianIdCardNumber,
                        EIdCardOptions.PassportNumber,
                    ].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("system.national_register_number.".concat(id)),
                    }); }),
                    showIf: function (formData) { return formData[EField.Nationality] !== "BE"; },
                },
                _a[EField.NationalRegisterNumber] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Nationality] === "BE" ||
                            (formData[EField.Nationality] !== "BE" &&
                                formData[EField.IdType] === EIdCardOptions.BelgianIdCardNumber);
                    },
                    format: ERentioFormFormat.NationalRegisterNumber,
                    default: getValue(EField.NationalRegisterNumber, initialValues, defaultValues),
                },
                _a[EField.ForeignIdentityNumber] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Nationality] !== "BE" &&
                            formData[EField.IdType] === EIdCardOptions.PassportNumber;
                    },
                    default: getValue(EField.ForeignIdentityNumber, initialValues, defaultValues),
                },
                _a[EField.SendInvite] = {
                    default: getValue(EField.SendInvite, initialValues, defaultValues),
                    // @ts-ignore
                    options: ["yes", "no"].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("system.".concat(id.toLowerCase())),
                    }); }),
                    // @ts-ignore
                    showIf: function (formData) { return extraData.showSendInvite; },
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    var _b, _c;
    return (_a = {
            "rentio:navigationTitle": getNavigationTitle(extraData),
            "rentio:trackingKey": "contact_company"
        },
        _a[EField.Header] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText(extraData.contactId ? "company.edit" : "company.add"),
        },
        _a[EField.VATNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("contact.company.vat_number.label"),
            placeholder: getLocalizedText("contact.company.vat_number.label"),
            info: getLocalizedText("contact.company.vat_number.info"),
            iMaskProps: {
                mask: "aa[".concat(Array(20).fill("*").join(""), "]"),
                lazy: false,
                unmask: true,
            },
        },
        _a[EField.Company] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("contact.company.name.label"),
            placeholder: getLocalizedText("contact.company.name.label"),
            autoFocus: true,
        },
        _a[EField.PrefillAddress] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("contact.address_from_property.label"),
            info: getLocalizedText("contact.address_from_property.info", {
                address: extraData.prefilledAddress
                    ? formatAddress(extraData.prefilledAddress)
                    : "-",
            }),
        },
        _a[EField.Address] = {
            "ui:field": ERentioFormField.Address,
            title: getLocalizedText("system.address"),
            placeholder: getLocalizedText("system.address"),
            optional: true,
        },
        _a[EField.Header2] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contact.add_company.section.contact_info.title"),
        },
        _a[EField.Firstname] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.firstname"),
            placeholder: getLocalizedText("system.firstname"),
            optional: (_b = extraData.optionalFields) === null || _b === void 0 ? void 0 : _b.includes(EField.Firstname),
        },
        _a[EField.Lastname] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.lastname"),
            placeholder: getLocalizedText("system.lastname"),
            optional: (_c = extraData.optionalFields) === null || _c === void 0 ? void 0 : _c.includes(EField.Lastname),
        },
        _a[EField.Email] = {
            "ui:field": ERentioFormField.Email,
            title: getLocalizedText("system.email"),
            placeholder: getLocalizedText("system.email"),
        },
        _a[EField.Phone] = {
            "ui:field": ERentioFormField.Phone,
            title: getLocalizedText("system.phone"),
            placeholder: getLocalizedText("system.phone"),
        },
        _a[EField.Language] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("system.language"),
            placeholder: getLocalizedText("system.language"),
        },
        _a[EField.InitialType] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("contact.initial_type.label"),
            info: getLocalizedText("contact.initial_type.info"),
            placeholder: getLocalizedText("contact.initial_type.placeholder"),
        },
        _a[EField.DateOfBirth] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("system.date_of_birth"),
            minDate: CONTACT_AGE_RANGE_MIN,
            maxDate: CONTACT_AGE_RANGE_MAX,
            formatSummary: function (formData) { return (formData ? formatDate(formData) : undefined); },
            optional: true,
        },
        _a[EField.PlaceOfBirth] = {
            "ui:field": ERentioFormField.Address,
            title: getLocalizedText("system.place_of_birth"),
            lookupTypes: ["(cities)"],
            optional: true,
        },
        _a[EField.Nationality] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("system.nationality"),
            optional: true,
        },
        _a[EField.IdType] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("system.identification"),
        },
        _a[EField.NationalRegisterNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.national_register_number"),
            iMaskProps: {
                mask: "00.00.00-000.00",
                lazy: false,
                unmask: true,
            },
            optional: true,
        },
        _a[EField.ForeignIdentityNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.national_register_number.foreigner"),
            optional: true,
        },
        _a[EField.SendInvite] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("contact.send_invite.label"),
            info: getLocalizedText("contact.send_invite.info"),
        },
        _a);
};
export var getValidate = function () { return function (formData, errors) {
    var vatNumber = formData[EField.VATNumber];
    if (vatNumber && !isValidVATNumber(vatNumber)) {
        errors[EField.VATNumber].addError(getLocalizedText("invalid.vat_number"));
    }
    return errors;
}; };
