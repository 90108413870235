import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import {
  getSchema as getSchema1,
  uiSchema as uiSchema1,
} from "./schema.createDocumentPackage.1";
import {
  getSchema as getSchema2,
  uiSchema as uiSchema2,
} from "./schema.createDocumentPackage.2";
import {
  getSchema as getSchema3,
  uiSchema as uiSchema3,
} from "./schema.createDocumentPackage.3";
import {
  getSchema as getSchema4,
  uiSchema as uiSchema4,
} from "./schema.createDocumentPackage.4";
import {
  getSchema as getSchema5,
  uiSchema as uiSchema5,
} from "./schema.createDocumentPackage.5";
import {
  getSchema as getSchema6,
  getValidate as getValidate6,
  uiSchema as uiSchema6,
} from "./schema.createDocumentPackage.6";
import { EField, IExtraData } from "./schema.createDocumentPackage.types";
import { getInitialValues } from "./schema.createDocumentPackage.utils";

// eslint-disable-next-line import/no-default-export
export default (extraData: IExtraData) => {
  const initialValues = getInitialValues(extraData);

  const documentPackageTypeStep = {
    schema: getSchema2(initialValues, extraData),
    uiSchema: uiSchema2(extraData),
    showIf: () => !initialValues[EField.DocumentPackageType],
  };

  const propertyStep = {
    schema: getSchema1(initialValues, extraData),
    uiSchema: uiSchema1(extraData),
    showIf: () => !initialValues[EField.Property],
  };

  const contractStep = {
    schema: getSchema5(initialValues, extraData),
    uiSchema: uiSchema5(extraData),
    showIf: (formData: any) =>
      !initialValues[EField.Contract] &&
      formData[EField.DocumentPackageType] === EDocumentPackageTypes.Contract,
  };

  const documentsStep = {
    schema: getSchema3(initialValues, extraData),
    uiSchema: uiSchema3(extraData),
    showIf: () => !initialValues[EField.Documents],
  };

  const signingTypeStep = {
    schema: getSchema4(initialValues),
    uiSchema: uiSchema4(extraData),
  };

  const infoStep = {
    schema: getSchema6(initialValues, extraData),
    uiSchema: uiSchema6(initialValues, extraData),
    validate: getValidate6(extraData),
  };

  return [
    documentPackageTypeStep,
    propertyStep,
    contractStep,
    signingTypeStep,
    documentsStep,
    infoStep,
  ];
};
