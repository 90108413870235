var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import { getPagedDefaultState, registerPagedActions, } from "../generic/paged.reducer";
import * as actions from "./registration.actions";
import CONFIG from "./registration.config";
export var REDUCER_KEY_REGISTRATION = CONFIG.REDUCER_KEY;
var getDefaultState = function () {
    return merge({}, {
        registrationsById: {},
        isCreating: undefined,
        createError: undefined,
        createdRegistration: undefined,
    }, getPagedDefaultState());
};
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(actions.getRegistration.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[id] = {
                isFetching: true,
                fetchError: undefined,
            },
            _b),
    });
});
reducer.on(actions.getRegistration.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var id = _a.id, registration = _a.registration;
    return merge({}, state, {
        data: (_b = {},
            _b[id] = registration,
            _b),
        registrationsById: (_c = {},
            _c[id] = {
                isFetching: false,
                fetchError: undefined,
            },
            _c),
    });
});
reducer.on(actions.getRegistration.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[id] = {
                isFetching: false,
                fetchError: error,
            },
            _b),
    });
});
reducer.on(actions.createRegistration.types.START, function (state) {
    return merge({}, state, {
        isCreating: true,
        createError: undefined,
    });
});
reducer.on(actions.createRegistration.types.SUCCESS, function (state, _a) {
    var _b;
    var registration = _a.registration;
    return merge({}, state, {
        data: (_b = {},
            _b[registration.id] = registration,
            _b),
        isCreating: false,
        createError: undefined,
        createdRegistration: registration,
    });
});
reducer.on(actions.createRegistration.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return merge({}, state, {
        isCreating: false,
        createError: error,
    });
});
reducer.on(actions.registrationAddDocuments.types.START, function (state, _a) {
    var _b;
    var registrationId = _a.registrationId;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[registrationId] = __assign(__assign({}, state.registrationsById[registrationId]), { isUpdating: true, updateError: undefined }),
            _b),
    });
});
reducer.on(actions.registrationAddDocuments.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var registrationId = _a.registrationId, registration = _a.registration;
    return merge({}, state, {
        data: (_b = {},
            _b[registrationId] = registration,
            _b),
        registrationsById: (_c = {},
            _c[registrationId] = __assign(__assign({}, state.registrationsById[registrationId]), { isUpdating: false, updateError: undefined }),
            _c),
    });
});
reducer.on(actions.registrationAddDocuments.types.FAILURE, function (state, _a) {
    var _b;
    var registrationId = _a.registrationId, error = _a.error;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[registrationId] = __assign(__assign({}, state.registrationsById[registrationId]), { isUpdating: false, updateError: error }),
            _b),
    });
});
reducer.on(actions.registrationUpdateDocument.types.START, function (state, _a) {
    var _b;
    var registrationId = _a.registrationId;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[registrationId] = __assign(__assign({}, state.registrationsById[registrationId]), { isUpdating: true, updateError: undefined }),
            _b),
    });
});
reducer.on(actions.registrationUpdateDocument.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var registrationId = _a.registrationId, registration = _a.registration;
    return merge({}, state, {
        data: (_b = {},
            _b[registrationId] = registration,
            _b),
        registrationsById: (_c = {},
            _c[registrationId] = __assign(__assign({}, state.registrationsById[registrationId]), { isUpdating: false, updateError: undefined }),
            _c),
    });
});
reducer.on(actions.registrationUpdateDocument.types.FAILURE, function (state, _a) {
    var _b;
    var registrationId = _a.registrationId, error = _a.error;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[registrationId] = __assign(__assign({}, state.registrationsById[registrationId]), { isUpdating: false, updateError: error }),
            _b),
    });
});
reducer.on(actions.updateRegistration.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[id] = {
                isUpdating: true,
                updateError: undefined,
            },
            _b),
    });
});
reducer.on(actions.updateRegistration.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var id = _a.id, registration = _a.registration;
    return merge({}, state, {
        data: (_b = {},
            _b[id] = registration,
            _b),
        registrationsById: (_c = {},
            _c[id] = {
                isUpdating: false,
                updateError: undefined,
            },
            _c),
    });
});
reducer.on(actions.updateRegistration.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[id] = {
                isUpdating: false,
                updateError: error,
            },
            _b),
    });
});
reducer.on(actions.deleteRegistration.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[id] = {
                isDeleting: true,
                deleteError: undefined,
            },
            _b),
    });
});
reducer.on(actions.deleteRegistration.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var id = _a.id;
    return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[id] = undefined, _b)), registrationsById: (_c = {},
            _c[id] = __assign(__assign({}, state.registrationsById[id]), { isDeleting: false, deleteError: undefined }),
            _c) }));
});
reducer.on(actions.deleteRegistration.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[id] = {
                isDeleting: false,
                deleteError: error,
            },
            _b),
    });
});
reducer.on(actions.brusselsRegistrationRetry.types.START, function (state, _a) {
    var _b;
    var id = _a.registrationId;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[id] = __assign(__assign({}, state.registrationsById[id]), { isRetryingBrussels: true, brusselsRetryError: undefined }),
            _b),
    });
});
reducer.on(actions.brusselsRegistrationRetry.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var registration = _a.registration, id = _a.registrationId;
    return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[id] = registration, _b)), registrationsById: (_c = {},
            _c[id] = __assign(__assign({}, state.registrationsById[id]), { isRetryingBrussels: false, brusselsRetryError: undefined }),
            _c) }));
});
reducer.on(actions.brusselsRegistrationRetry.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.registrationId, error = _a.error;
    return merge({}, state, {
        registrationsById: (_b = {},
            _b[id] = __assign(__assign({}, state.registrationsById[id]), { isRetryingBrussels: false, brusselsRetryError: error }),
            _b),
    });
});
registerPagedActions({
    entityName: "registration",
    reducer: reducer,
});
// eslint-disable-next-line import/no-default-export
export default reducer;
