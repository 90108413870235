var _a;
import api from "../../utils/api/api.utils";
import { generateApi } from "../generators/generator.api";
import { GENERATOR_PARAMS } from "./chat.constants";
export var getPaged = (_a = generateApi(GENERATOR_PARAMS), _a.getPaged), getDetail = _a.getDetail, getCount = _a.getCount, getInfiniteLoad = _a.getInfiniteLoad, update = _a.update, create = _a.create, remove = _a.remove;
export var markChatUnread = function (id) {
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(id, "/unread"), {
        isV2: true,
    });
};
