import { EPaymentOrderType } from "@rentiohq/shared-frontend/dist/types/payment.types";

export const RECURRING_PAYMENT_TYPES = [
  EPaymentOrderType.Rent,
  EPaymentOrderType.CommonCosts,
  EPaymentOrderType.NonCommonCosts,
  EPaymentOrderType.ManagementCosts,
  EPaymentOrderType.Rentio,
  EPaymentOrderType.OtherRecurring,
  EPaymentOrderType.RentDiscount,
];

export const ONE_TIME_PAYMENT_TYPES = [
  EPaymentOrderType.RentDeposit,
  EPaymentOrderType.RentDepositPayout,
  EPaymentOrderType.LocationDescription,
  EPaymentOrderType.BrokerFee,
  EPaymentOrderType.OtherOneoff,
];
