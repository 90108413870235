import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import {
  ITask,
  ETenantTaskFilter,
  ETaskMemberType,
  ERecurrenceType,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { SHOW_CONFIRM_MODAL } from "@rentiohq/web-shared-next/dist/redux/actions";
import { useTheme, useMediaQuery } from "@rentiohq/web-shared-next/dist/themes";
import {
  Box,
  DataList,
  Divider,
  MainCard,
  SpinningLoader,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { enqueueSnackbar } from "@rentiohq/web-shared-next/dist/utils/snackbar";
import { EButtonVariants } from "@rentiohq/web-shared-next/dist/utils/types/button";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NumberParam } from "serialize-query-params";
import { Activities } from "../../../../components/Activities";
import { TaskDrawer } from "../TaskDrawer";
import { Archived } from "./components/Archived";
import { Comments } from "./components/Comments";
import { Description } from "./components/Description";
import { Documents } from "./components/Documents";
import { TaskHeader } from "./components/TaskHeader";
import { TaskMembers } from "./components/TaskMembers";
import { TaskStyles } from "./Task.styles";

export interface IProps {
  tasks: ITask[];
  taskFilter: ETenantTaskFilter;
  isLoading: boolean;
  refresh: () => void;
  handleLoadMore?: () => void;
  handleTaskTypeChange: (type: ETenantTaskFilter) => void;
}

export const TaskApp = ({
  tasks,
  refresh,
  taskFilter,
  isLoading,
  handleLoadMore,
  handleTaskTypeChange,
}: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [queryParams] = useQueryParams({
    taskId: NumberParam,
  });

  const sx = TaskStyles();
  const theme = useTheme();

  const downMD = useMediaQuery(theme.breakpoints.down("md"));

  const [openTaskDrawer, setOpenTaskDrawer] = React.useState(true);

  const {
    detail: task,
    fetchError: detailFetchError,
    isFetching,
  } = taskModule.hooks.useDetail({
    shouldRefetch: false,
    id: queryParams.taskId,
  });

  const {
    update,
    error: updateError,
    isUpdating,
  } = taskModule.hooks.useUpdate({
    id: queryParams.taskId,
  });

  const {
    remove,
    error: removeError,
    isRemoving,
  } = taskModule.hooks.useRemove({
    id: queryParams.taskId,
  });

  const {
    archive,
    error: archiveError,
    isArchiving,
  } = taskModule.hooks.useArchive({
    id: queryParams.taskId,
  });

  const {
    unarchive,
    error: unarchiveError,
    isUnArchiving,
  } = taskModule.hooks.useUnarchive({
    id: queryParams.taskId,
  });

  const error =
    detailFetchError ||
    updateError ||
    removeError ||
    archiveError ||
    unarchiveError;

  React.useEffect(() => {
    if (error) {
      enqueueSnackbar({
        message: error.message,
        variant: "error",
      });
    }
  }, [error]);

  const navigateToOverview = () => navigate("/tasks");

  const handleDrawerOpen = () => setOpenTaskDrawer(prevState => !prevState);

  const handleDelete = () => {
    remove({
      onSuccess: navigateToOverview,
    });
    handleCloseConfirmModal();
  };

  const handleDeleteAndTerminate = () => {
    update({
      data: {
        recurrenceType: ERecurrenceType.Once,
      },
      onSuccess: () =>
        remove({
          onSuccess: navigateToOverview,
        }),
    });
    handleCloseConfirmModal();
  };

  const handleArchive = () => {
    archive({
      onSuccess: () => {
        navigateToOverview();
        enqueueSnackbar({
          message: getLocalizedText("system.archive.success", {
            type: getLocalizedText("system.task"),
          }),
          variant: "success",
        });
        refresh();
      },
    });
    handleCloseConfirmModal();
  };

  const handleUnArchive = () => {
    unarchive({
      onSuccess: () => {
        navigateToOverview();
        enqueueSnackbar({
          message: getLocalizedText("system.unarchive.success", {
            type: getLocalizedText("system.task"),
          }),
          variant: "success",
        });
        refresh();
      },
    });
  };

  const handleFinish = () => {
    update({
      data: {
        finishedAt: new Date(),
      },
      onSuccess: () => {
        navigateToOverview();
        enqueueSnackbar({
          message: getLocalizedText("task.completed"),
          variant: "success",
        });
        refresh();
      },
      customSuccessMessage: getLocalizedText("task.completed"),
    });
    handleCloseConfirmModal();
  };

  const handleActivateTask = () => {
    update({
      data: {
        finishedAt: null,
      },
      onSuccess: () => {
        navigateToOverview();
        enqueueSnackbar({
          message: getLocalizedText("task.activated"),
          variant: "success",
        });
        refresh();
      },
      customSuccessMessage: getLocalizedText("task.activated"),
    });
    refresh();
  };

  const handleCloseConfirmModal = () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: { showConfirmModal: false },
    });
  };

  const handleShowConfirmModalForDelete = () => {
    const isRecurringTask = task?.recurrenceType !== ERecurrenceType.Once;
    const title = isRecurringTask
      ? getLocalizedText("task.remove.repeating.title")
      : getLocalizedText("system.delete.confirm", {
          type: "task",
          typePrefix: "",
        });

    const actions = [
      {
        label: getLocalizedText("system.cancel"),
        onClick: handleCloseConfirmModal,
        variant: EButtonVariants.Outlined,
      },
      {
        label: getLocalizedText("system.remove"),
        onClick: handleDelete,
      },
    ];

    if (isRecurringTask) {
      actions.push({
        label: getLocalizedText("task.remove.repeating.title"),
        onClick: handleDeleteAndTerminate,
      });
    }

    const payload = {
      title,
      showConfirmModal: true,
      actions,
    };

    if (isRecurringTask) {
      // @ts-ignore
      payload.description = getLocalizedText("task.remove.repeating.info");
    }

    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload,
    });
  };

  const handleShowConfirmModalForArchive = () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        title: getLocalizedText("system.archive.confirm", {
          type: "task",
          typePrefix: "",
        }),
        showConfirmModal: true,
        actions: [
          {
            label: getLocalizedText("system.cancel"),
            onClick: handleCloseConfirmModal,
            variant: EButtonVariants.Outlined,
          },
          {
            label: getLocalizedText("system.archive"),
            onClick: handleArchive,
          },
        ],
      },
    });
  };

  const handleShowConfirmModalForFinish = () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        title: getLocalizedText("task.finish.confirm.title"),
        description: getLocalizedText("task.finish.confirm.body"),
        showConfirmModal: true,
        actions: [
          {
            label: getLocalizedText("system.cancel"),
            onClick: handleCloseConfirmModal,
            variant: EButtonVariants.Outlined,
          },
          {
            label: getLocalizedText("system.ok"),
            onClick: handleFinish,
          },
        ],
      },
    });
  };

  const showSidebar = downMD ? !queryParams.taskId : true;
  const showMessages = downMD ? !!queryParams.taskId : true;
  const isLoadingDetails =
    isRemoving || isArchiving || isUnArchiving || isFetching || isUpdating;

  return (
    <Box sx={sx.wrapper}>
      {showSidebar && (
        <TaskDrawer
          taskId={queryParams.taskId}
          tasks={tasks}
          taskFilter={taskFilter}
          isLoading={isLoading}
          openTaskDrawer={openTaskDrawer}
          handleDrawerOpen={handleDrawerOpen}
          handleLoadMore={handleLoadMore}
          handleTaskTypeChange={handleTaskTypeChange}
        />
      )}
      {showMessages && (
        <MainCard sx={sx.content.card}>
          {isLoadingDetails && (
            <Box p={2}>
              <SpinningLoader />
            </Box>
          )}
          {!isLoadingDetails && task?.id && (
            <Box>
              <TaskHeader
                task={task}
                handleDelete={handleShowConfirmModalForDelete}
                handleArchive={handleShowConfirmModalForArchive}
                handleUnArchive={handleUnArchive}
                handleFinishTask={handleShowConfirmModalForFinish}
                handleActivateTask={handleActivateTask}
              />
              <Divider sx={sx.content.divider} />
              {task.archivedAt ? (
                <Archived handleActivateTask={handleActivateTask} />
              ) : (
                <Box p={2}>
                  <DataList>
                    <Description task={task} />
                    <Comments task={task} />
                    <Documents task={task} />
                    <TaskMembers
                      task={task}
                      placeholder={getLocalizedText("tasks.add.executors")}
                      title={getLocalizedText("task.executor.label")}
                      role={ETaskMemberType.Executor}
                    />
                    <TaskMembers
                      task={task}
                      placeholder={getLocalizedText("tasks.add.followers")}
                      title={getLocalizedText("task.follower.label")}
                      role={ETaskMemberType.Follower}
                    />
                    <Activities
                      identifier={`task-${queryParams.taskId}`}
                      filter={{
                        where: {
                          taskId: queryParams.taskId,
                        },
                      }}
                      hasFetchMore={true}
                    />
                  </DataList>
                </Box>
              )}
            </Box>
          )}
        </MainCard>
      )}
    </Box>
  );
};
