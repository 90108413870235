import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMyTenantProfileUrl, getRentioPayUrl, } from "@rentiohq/shared-frontend/dist/utils/url.utils";
import { EAssets } from "../assets";
import { EMenuItems } from "./types";
var menuItems = [
    {
        id: EMenuItems.Welcome,
        title: getLocalizedText("dashboard.welcome"),
        icon: EAssets.CalendarIcon,
        url: "",
        matchPaths: ["/"],
        breadcrumbs: true,
    },
    {
        id: EMenuItems.Property,
        title: getLocalizedText("system.properties"),
        icon: EAssets.HouseIcon,
        url: "/properties",
        matchPaths: [
            "/properties",
            "/properties/:propertyId/",
            "/properties/:propertyId/contracts",
            "/properties/:propertyId/documents",
            "properties/:propertyId/history",
        ],
        breadcrumbs: true,
    },
    {
        id: EMenuItems.Contacts,
        title: getLocalizedText("system.contacts"),
        icon: EAssets.UserOutlinedIcon,
        url: "/contacts",
        matchPaths: ["/contacts"],
        breadcrumbs: true,
    },
    {
        id: EMenuItems.Applications,
        title: getLocalizedText("system.entity.applications"),
        icon: EAssets.ApplicationIcon,
        url: getMyTenantProfileUrl({}),
        matchPaths: ["applications"],
        breadcrumbs: true,
        target: "_blank",
        openModal: true,
        message: getLocalizedText("tenant_dashboard.see_applications.modal.message"),
    },
    {
        id: EMenuItems.Payments,
        title: getLocalizedText("payments_overview.heading"),
        icon: EAssets.EuroIcon,
        url: getRentioPayUrl({}),
        // url: "/payments",
        matchPaths: ["payments"],
        breadcrumbs: true,
        target: "_blank",
        openModal: true,
        message: getLocalizedText("tenant_dashboard.see_payments.modal.message"),
    },
    {
        id: EMenuItems.Tasks,
        title: getLocalizedText("system.entity.tasks"),
        icon: EAssets.TasksIcon,
        url: "/tasks",
        matchPaths: ["tasks"],
        breadcrumbs: true,
    },
    {
        id: EMenuItems.Chats,
        title: getLocalizedText("system.entity.chats"),
        icon: EAssets.ChatsIcon,
        url: "/chats",
        matchPaths: ["chats"],
        breadcrumbs: true,
    },
];
// eslint-disable-next-line import/no-default-export
export default menuItems;
