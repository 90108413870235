var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { flatten, uniq } from "lodash";
import * as propertyActions from "../../reduxV2/property/property.actions";
import { EPropertyMemberFromString, EPropertyMemberTypes, } from "../../types/property.types";
import { EField as EEpcField } from "../epc/schema.epcInfo.types";
import { EField } from "./schema.createProperty.types";
export var isPerformingSubmitActionSelector = function (action, propertyId) {
    return function (state) {
        if (propertyId && action === "update") {
            var updateState = state.PROPERTY_V2.update[propertyId];
            if (updateState) {
                return updateState.pending || false;
            }
            return false;
        }
        return !!state.PROPERTY_V2.create.pending;
    };
};
export var performingSubmitActionResultSelector = function (action, propertyId) {
    return function (state) {
        if (propertyId && action === "update") {
            return state.PROPERTY_V2.update[propertyId];
        }
        return state.PROPERTY_V2.create.id;
    };
};
export var performingSubmitActionErrorSelector = function (action, propertyId) {
    return function (state) {
        if (propertyId && action === "update") {
            var updateState = state.PROPERTY_V2.update[propertyId];
            if (updateState) {
                return updateState.error;
            }
            return;
        }
        return state.PROPERTY_V2.create.error;
    };
};
export var submitActionCreator = function (extraData, propertyId) { return function (formData) {
    var address = __assign({}, formData[EField.Address]);
    address.countryId = address.country;
    delete address.country;
    var epcData = {
        epcLabel: formData[EEpcField.Label],
        epcExpirationDate: formData[EEpcField.ExpirationDate],
        epcValue: formData[EEpcField.Value],
    };
    if (propertyId) {
        var property = __assign(__assign(__assign({}, address), { name: formData[EField.Name], typeId: formData[EField.PropertyType], parentId: formData[EField.Group] || null, roles: formData[EField.Roles] }), epcData);
        return propertyActions.updateStart.getAction({
            id: propertyId,
            data: property,
        });
    }
    else {
        var formDataOwners = formData[EField.Owners] && Array.isArray(formData[EField.Owners])
            ? formData[EField.Owners]
            : [];
        var formDataManagers = formData[EField.Roles] && Array.isArray(formData[EField.Roles])
            ? formData[EField.Roles]
            : [];
        var membersRaw_1 = __spreadArray(__spreadArray([], formDataOwners.map(function (ownerId) { return ({
            accountId: ownerId,
            roles: [EPropertyMemberTypes.Owner],
        }); }), true), formDataManagers.map(function (idWithRole) {
            var _a = idWithRole.split("-"), id = _a[0], roleAsString = _a[1];
            var role = EPropertyMemberFromString.get(roleAsString);
            return {
                accountId: Number(id),
                roles: role ? [role] : [],
            };
        }), true);
        var members = uniq(membersRaw_1.map(function (m) { return m.accountId; })).map(function (accountId) {
            var roles = flatten(membersRaw_1.filter(function (m) { return m.accountId === accountId; }).map(function (m) { return m.roles; }));
            return {
                accountId: accountId,
                roles: roles,
            };
        });
        var property = __assign(__assign(__assign({}, address), epcData), { name: formData[EField.Name], members: members, typeId: formData[EField.PropertyType], parentId: formData[EField.Group] });
        return propertyActions.createStart.getAction({ data: property });
    }
}; };
