import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import * as documentPackageSelectors from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.selectors";
import * as rentDepositActions from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.actions";
import * as rentDepositSelectors from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.selectors";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import {
  EContractType,
  EInsuranceExternalStatus,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EDocumentPackageStatus,
  EDocumentPackageTypes,
} from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { ERentDepositStatus } from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import { formatMediumDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import { SHOW_LOADER } from "@rentiohq/web-shared-next/dist/redux/actions";
import { alpha, useTheme } from "@rentiohq/web-shared-next/dist/themes";
import {
  Box,
  DataList,
  DataListItem,
  Divider,
  EIconAvatarVariant,
  Icon,
  IconAvatar,
  Link,
  Stack,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { NumberParam, StringParam } from "serialize-query-params";
import { ts } from "../../../services";

interface IProps {
  contract: IContract;
  showDetails?: boolean;
}

export const ContractCard = ({ contract, showDetails = true }: IProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [_, setQueryParamValue] = useQueryParams({
    documentId: StringParam,
    rentDepositId: NumberParam,
    registrationId: NumberParam,
    insuranceContractId: StringParam,
    requestInsuranceContractId: StringParam,
    templateDocumentId: StringParam,
    documentPackageId: StringParam,
  });

  const { startDate, stopDate, id: contractId } = contract;
  const renewalInfo = contract && contractUtils.getRenewalInfo(contract);

  const rentDepositsIdentifier = `rent-deposits-contract-${contract.id}`;
  const documentPackagesIdentifier = `document-packages-contract-${contract.id}`;

  const rentDeposits =
    useSelector((state: IRootStore) =>
      rentDepositSelectors.getRentDeposits(state, rentDepositsIdentifier),
    ) || [];

  const documentPackages =
    useSelector((state: IRootStore) =>
      documentPackageSelectors.getDocumentPackages(
        state,
        documentPackagesIdentifier,
      ),
    ) || [];

  const { detail: property, isFetching: isFetchingProperty } =
    propertyHooks.useDetail({
      shouldRefetch: false,
      id: contract?.propertyId,
    });

  const isFetching = isFetchingProperty;

  React.useEffect(() => {
    dispatch({ type: SHOW_LOADER, payload: isFetching });
  }, [isFetching]);

  React.useEffect(() => {
    if (!showDetails) {
      return;
    }

    dispatch(
      rentDepositActions.getRentDeposits.actions.start({
        identifier: rentDepositsIdentifier,
        refetch: true,
        extraFilterData: {
          where: {
            basicContractId: contract.id,
            status: { neq: ERentDepositStatus.Cancelled },
          },
          order: "createdAt DESC",
        },
      }),
    );

    dispatch(
      documentPackageActions.getDocumentPackages.actions.start({
        identifier: documentPackagesIdentifier,
        refetch: true,
        filterData: {
          where: {
            contractId,
            type: EDocumentPackageTypes.Contract,
            status: {
              nin: [
                EDocumentPackageStatus.Revoked,
                EDocumentPackageStatus.Expired,
                EDocumentPackageStatus.Failed,
                EDocumentPackageStatus.Rejected,
                EDocumentPackageStatus.WorkerFailed,
              ],
            },
          },
          order: "createdAt DESC",
        },
        limit: 1,
      }),
    );
  }, [showDetails]);

  const onClickDetail = (key: string, value: string | number) => {
    setQueryParamValue({
      [key]: value,
    });
  };

  const getDocumentPackageMessage = () => {
    if (
      !contract.contractType ||
      contract.contractType !== EContractType.Basic
    ) {
      return;
    }

    const documentPackage = documentPackages?.[0];

    if (documentPackage) {
      const content = (
        <Stack flexDirection="row" gap={2} alignItems="center">
          <IconAvatar
            icon={EAssets.NotesIcon}
            variant={EIconAvatarVariant.Info}
          />
          <Box>
            <Typography variant="h5">
              {getLocalizedText("contract.document.title")}
            </Typography>
            <Typography variant="caption">
              {getLocalizedText("contract.document.description")}
            </Typography>
          </Box>
        </Stack>
      );

      const onClick = () =>
        onClickDetail("documentPackageId", documentPackage.id);

      const action = <Link label={getLocalizedText("summary.view.document")} />;

      return { content, action, onClick };
    }
  };

  const getRentDepositMessage = () => {
    const { contractType } = contract;
    if (contractType !== EContractType.Basic) {
      return;
    }

    const rentDeposit = rentDeposits[0];

    if (rentDeposit) {
      const subTitle =
        rentDeposit.status === ERentDepositStatus.Established
          ? getLocalizedText(
              "property.dashboard.checklist.rent_deposit.content.established",
              {
                value: formatCurrency(rentDeposit.amount),
              },
            )
          : getLocalizedText(
              "property.dashboard.checklist.rent_deposit.content",
              {
                value: formatCurrency(rentDeposit.amount),
              },
            );

      const content = (
        <Stack flexDirection="row" gap={2} alignItems="center">
          <IconAvatar
            icon={EAssets.SafeIcon}
            variant={EIconAvatarVariant.Info}
          />
          <Box>
            <Typography variant="h5">
              {ts.propertyDashboardChecklistRentDepositHeading()}
            </Typography>
            <Typography variant="caption">{subTitle}</Typography>
          </Box>
        </Stack>
      );

      const onClick = () => onClickDetail("rentDepositId", rentDeposit?.id);

      const action = <Link label={getLocalizedText("contract.view_deposit")} />;

      return { content, action, onClick };
    }
  };

  const getInsuranceMessage = () => {
    const { insuranceExternalStatus } = contract;

    let onClick: any = undefined;

    switch (insuranceExternalStatus) {
      case EInsuranceExternalStatus.Created:
      case EInsuranceExternalStatus.Pending:
      case EInsuranceExternalStatus.Failed: {
        onClick = onClickDetail;
        break;
      }

      case EInsuranceExternalStatus.Completed:
        onClick = onClickDetail;
        break;

      default:
        break;
    }

    const content = (
      <Stack flexDirection="row" gap={2} alignItems="center">
        <IconAvatar
          icon={EAssets.InsuranceIcon}
          variant={EIconAvatarVariant.Info}
        />
        <Box>
          <Typography variant="h5">
            {getLocalizedText("task.category.fire_insurance_tenant")}
          </Typography>
          <Typography variant="caption">
            {getLocalizedText(
              "task.category.fire_insurance_tenant.description",
            )}
          </Typography>
        </Box>
      </Stack>
    );

    onClick = () => onClickDetail("insuranceContractId", contract?.id);

    const action = <Link label={getLocalizedText("system.view_contract")} />;

    return { content, action, onClick };
  };

  const {
    content: docPackageContent,
    action: docPackageAction,
    onClick: onClickViewDoc,
  } = getDocumentPackageMessage() || {};

  const {
    content: rentDepositContent,
    action: rentDepositAction,
    onClick: onClickViewDeposit,
  } = getRentDepositMessage() || {};

  const {
    content: insuranceContent,
    action: insuranceAction,
    onClick: onClickSeeContract,
  } = getInsuranceMessage() || {};

  const availableActions = [
    {
      content: docPackageContent,
      action: docPackageAction,
      onClick: onClickViewDoc,
    },
    {
      content: rentDepositContent,
      action: rentDepositAction,
      onClick: onClickViewDeposit,
    },
    {
      content: insuranceContent,
      action: insuranceAction,
      onClick: onClickSeeContract,
      hidden: true,
    },
  ].filter(({ content, hidden }) => !!content && !hidden);

  return (
    <Stack direction="column" gap={0.5} width={"100%"} pb={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Icon
          path={EAssets.CalendarIcon}
          width="16px"
          height="16px"
          realColor={alpha(theme.palette.text.primary, 0.5)}
        />
        <Typography variant="body2">
          {formatMediumDate(startDate)} -{" "}
          {stopDate ? formatMediumDate(stopDate) : "N/A"}
        </Typography>
        {!showDetails && (
          <Link
            link={`/properties/${property?.id}/contracts`}
            label={getLocalizedText("system.view_detail")}
          />
        )}
      </Stack>
      {renewalInfo && (
        <Stack direction="row" alignItems="center" gap={1}>
          <Icon
            path={EAssets.SynchronizeLightIcon}
            width="16px"
            height="16px"
            realColor={alpha(theme.palette.text.primary, 0.5)}
          />
          <Typography variant="body2">{renewalInfo}</Typography>
        </Stack>
      )}
      {showDetails && (
        <>
          {availableActions?.length > 0 && (
            <Divider orientation="horizontal" sx={{ my: 1 }} />
          )}
          <DataList>
            {availableActions.map(({ content, action, onClick }) => (
              <DataListItem
                mainContent={content}
                configuration={{
                  action: <Box ml={{ xs: 5, lg: 0 }}>{action}</Box>,
                  onClick,
                }}
              />
            ))}
          </DataList>
        </>
      )}
    </Stack>
  );
};
