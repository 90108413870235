import * as documentSelectors from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.selectors";
import { getStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import { EDefaultVariableId } from "@rentiohq/web-shared/dist/scenes/TemplateEditor/components/Editor/Editor.config";
import utils from "@rentiohq/web-shared/dist/utils";
import { EField, IExtraData } from "./schema.createDocumentPackage.types";

export const getDocumentFromStore = (params: {
  formData: { [key: string]: any };
  extraData: IExtraData;
}) => {
  const { formData } = params;

  const document = formData[EField.Documents]?.[0];
  if (!document || typeof document !== "string") {
    return;
  }

  const state = getStore()?.getState();
  if (!state) {
    return;
  }

  return documentSelectors.getDetail(state, document);
};

export const getFilename = (params: {
  formData: { [key: string]: any };
  extraData: IExtraData;
}) => {
  const { formData } = params;

  const documentFromStore = getDocumentFromStore(params);

  if (documentFromStore) {
    return utils.file.removeExtension(documentFromStore.filename);
  }

  const document = formData[EField.Documents]?.[0];
  if (document && typeof document !== "string" && document.name) {
    return utils.file.removeExtension(document.name);
  }
};

export const getDefaultSignerInfo = (params: {
  formData: { [key: string]: any };
  extraData: IExtraData;
}) => {
  const { extraData } = params;
  const { templateDocumentDetail, isBroker, contactMeMaster } = extraData;

  const documentFromStore = getDocumentFromStore(params);

  const signerFields =
    documentFromStore?.signerFields || extraData.signerFields;

  const hasDefaultSigners =
    signerFields && Object.keys(signerFields).length > 0;

  let hasDefaultBrokerSignAsOwnerField = false;
  if (
    templateDocumentDetail?.fields &&
    typeof templateDocumentDetail.fields !== "string"
  ) {
    const defaultBrokerSignAsOwnerField =
      templateDocumentDetail.fields.variables.find(
        x => x.id === EDefaultVariableId.BrokerIsSignerOnBehalfOfOwners,
      );

    hasDefaultBrokerSignAsOwnerField =
      defaultBrokerSignAsOwnerField !== undefined;
  }

  const defaultSigners: number[] = [];
  const defaultSignersAsBroker: number[] = [];

  if (signerFields) {
    const signerFieldsMapped = Object.keys(signerFields).map(id => +id);
    if (hasDefaultBrokerSignAsOwnerField) {
      // Get broker from signer fields and add to defaultSignersAsBroker
      signerFieldsMapped.forEach(id => {
        if (isBroker && contactMeMaster?.accountIds.includes(id)) {
          defaultSignersAsBroker.push(id);
        } else {
          defaultSigners.push(id);
        }
      });
    } else {
      // Add all to defaultSigners as broker cannot sign on behalf of the owner
      defaultSigners.push(...signerFieldsMapped);
    }
  }

  return {
    hasDefaultSigners,

    hasDefaultBrokerSignAsOwnerField,

    defaultSigners,
    defaultSignersAsBroker,
  };
};
