import { ERegistrationBrusselsStatus, ERegistrationDocumentStatus, } from "../../types/registration.types";
// Contracts
export var DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT = [
    ERegistrationDocumentStatus.Registering,
    ERegistrationDocumentStatus.Incomplete,
];
export var DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT = [
    ERegistrationDocumentStatus.NeedPayment,
    ERegistrationDocumentStatus.Rejected,
    ERegistrationDocumentStatus.Failed,
];
export var DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT = [
    ERegistrationDocumentStatus.Registered,
    ERegistrationDocumentStatus.RegisteredManually,
    ERegistrationDocumentStatus.NotApplicable,
];
// Addenda
export var DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_ADDENDA = [
    ERegistrationDocumentStatus.Registering,
    ERegistrationDocumentStatus.Incomplete,
];
export var DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_ADDENDA = [
    ERegistrationDocumentStatus.NeedPayment,
    ERegistrationDocumentStatus.Rejected,
    ERegistrationDocumentStatus.Failed,
];
export var DEFAULT_STATUSES_REGISTRATION_REGISTERED_ADDENDA = [
    ERegistrationDocumentStatus.Registered,
    ERegistrationDocumentStatus.RegisteredManually,
    ERegistrationDocumentStatus.NotApplicable,
];
// Location description
export var DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_LOCATION_DESCRIPTION = [
    ERegistrationDocumentStatus.Registering,
    ERegistrationDocumentStatus.Incomplete,
];
export var DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_LOCATION_DESCRIPTION = [
    ERegistrationDocumentStatus.None,
    ERegistrationDocumentStatus.NeedPayment,
    ERegistrationDocumentStatus.Rejected,
    ERegistrationDocumentStatus.Failed,
];
export var DEFAULT_STATUSES_REGISTRATION_REGISTERED_LOCATION_DESCRIPTION = [
    ERegistrationDocumentStatus.Registered,
    ERegistrationDocumentStatus.RegisteredManually,
    ERegistrationDocumentStatus.NotApplicable,
];
// Brussels
export var DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_BRUSSELS = [
    ERegistrationBrusselsStatus.Registering,
];
export var DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_BRUSSELS = [
    ERegistrationBrusselsStatus.None,
    ERegistrationBrusselsStatus.Failed,
];
export var DEFAULT_STATUSES_REGISTRATION_REGISTERED_BRUSSELS = [
    ERegistrationBrusselsStatus.Registered,
    ERegistrationBrusselsStatus.RegisteredManually,
    // ERegistrationBrusselsStatus.NotApplicable,
];
