import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import {
  uiSchema as epcUiSchema,
  getSchema as getEpcSchema,
} from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import {
  getValue,
  mapFormFieldBooleanToSummary,
} from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EElectricityMeterType,
  EElectricityType,
  EHeatingType,
  ENaturalGasAvailable,
  ENaturalGasMeterType,
  EPropertyAdjoined,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  propertyTypeCanHaveAdjoinedType,
  propertyTypeCanHaveFloors,
} from "forms/editPropertyExtraInfo/schema.editPropertyExtraInfo.utils";
import {
  EAN_MASK,
  EAN_PREFIX,
} from "forms/requestMoveService/schema.requestMoveService.config";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.registration.types";

const booleanOptions = [
  {
    value: getLocalizedText("system.no"),
    id: "no",
  },
  {
    value: getLocalizedText("system.yes"),
    id: "yes",
  },
];

export const getSchema =
  (
    initialValues: {
      [key: string]: unknown;
    },
    extraData: IExtraData,
  ) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    const epcSchema = getEpcSchema(
      { ...extraData, epcIsRequired: false },
      initialValues,
    )(defaultValues);
    const epcRequiredFields = epcSchema.required ?? [];
    const epcProperties = epcSchema.properties ?? {};

    const canHaveAdjoinedType = propertyTypeCanHaveAdjoinedType(extraData);
    const canHaveFloors = propertyTypeCanHaveFloors(extraData);

    return {
      required: [
        // General
        EField.ConstructionYear,

        // EPC
        ...epcRequiredFields,

        // Electricity
        EField.ElectricityType,
        EField.ElectricityEan,
        EField.ElectricityMeterNumberBoth,
        EField.ElectricityMeterType,

        // Natural Gas
        EField.NaturalGasAvailable,
        // EField.NaturalGasEan,
        // EField.NaturalGasMeterNumber,
        // EField.NaturalGasMeterType,

        // Water
        // EField.WaterMeterNumber,

        // Exterior
        // EField.BuildingAdjoined,
        EField.BuildingSurfaceArea,
        EField.BuildingGardenSurfaceArea,
        EField.BuildingTerraceSurfaceArea,

        // Interior
        // EField.BuildingFloor,
        // EField.BuildingFloorCount,
        EField.RoomCountBedroom,
        EField.RoomCountBathroom,
        EField.RoomCountGarage,
        EField.BuildingHasStorage,
        EField.IsFurnished,

        // Amenities
        EField.HasOven,
        EField.HasFridge,
        EField.HasDishWasher,
        EField.HasRangeHood,
        EField.HasLift,

        // Energy
        EField.HasDoubleGlazing,
        EField.HasCentralHeating,
        EField.HeatingType,
      ],
      properties: {
        // General
        [EField.Header + "7"]: {
          default: undefined,
        },
        [EField.ConstructionYear]: {
          default: getValue(
            EField.ConstructionYear,
            initialValues,
            defaultValues,
          ),
        },

        // EPC
        [EField.Header + "8"]: {
          default: undefined,
        },
        ...epcProperties,

        // Electricity
        [EField.Header + "9"]: {
          default: undefined,
        },
        [EField.ElectricityType]: {
          default: getValue(
            EField.ElectricityType,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [
            EElectricityType.Single,
            EElectricityType.Double,
            // EElectricityType.Unknown,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `move.option.electricity_type.${id}`.toLowerCase(),
            ),
          })),
        },

        [EField.ElectricityEan]: {
          default: getValue(
            EField.ElectricityEan,
            initialValues,
            defaultValues,
          ),
          // // @ts-ignore
          // showIf: (formData: any) =>
          //   formData[EField.ElectricityType] &&
          //   formData[EField.ElectricityType] !== EElectricityType.Unknown,
        },

        [EField.ElectricityMeterNumberBoth]: {
          default: getValue(
            EField.ElectricityMeterNumberBoth,
            initialValues,
            defaultValues,
          ),
          // // @ts-ignore
          // showIf: (formData: any) =>
          //   formData[EField.ElectricityType] &&
          //   formData[EField.ElectricityType] !== EElectricityType.Unknown,
        },
        [EField.ElectricityMeterType]: {
          default: getValue(
            EField.ElectricityMeterType,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [
            EElectricityMeterType.Standard,
            EElectricityMeterType.Opening,
            EElectricityMeterType.Budget,
            EElectricityMeterType.Empty,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `move.option.electricity_meter_type.${id}`.toLowerCase(),
            ),
          })),
          // showIf: (formData: any) =>
          //   formData[EField.ElectricityType] &&
          //   formData[EField.ElectricityType] !== EElectricityType.Unknown,
          // requiredIf: (formData: any) =>
          //   formData[EField.ElectricityType] &&
          //   formData[EField.ElectricityType] !== EElectricityType.Unknown,
        },

        // Natural Gas
        [EField.Header + "10"]: {
          default: undefined,
        },
        [EField.NaturalGasAvailable]: {
          default: getValue(
            EField.NaturalGasAvailable,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [
            ENaturalGasAvailable.Available,
            ENaturalGasAvailable.Unavailable,
            // ENaturalGasAvailable.Unknown,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `move.option.natural_gas_type.${id}`.toLowerCase(),
            ),
          })),
        },
        [EField.NaturalGasEan]: {
          default: getValue(EField.NaturalGasEan, initialValues, defaultValues),
          // @ts-ignore
          showIf: (formData: any) =>
            formData[EField.NaturalGasAvailable] ===
            ENaturalGasAvailable.Available,
          requiredIf: (formData: any) =>
            formData[EField.NaturalGasAvailable] ===
            ENaturalGasAvailable.Available,
        },
        [EField.NaturalGasMeterNumber]: {
          default: getValue(
            EField.NaturalGasMeterNumber,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: (formData: any) =>
            formData[EField.NaturalGasAvailable] ===
            ENaturalGasAvailable.Available,
          requiredIf: (formData: any) =>
            formData[EField.NaturalGasAvailable] ===
            ENaturalGasAvailable.Available,
        },
        [EField.NaturalGasMeterType]: {
          default: getValue(
            EField.NaturalGasMeterType,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [
            ENaturalGasMeterType.Standard,
            ENaturalGasMeterType.Opening,
            ENaturalGasMeterType.Budget,
            ENaturalGasMeterType.Empty,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `move.option.natural_gas_meter_type.${id}`.toLowerCase(),
            ),
          })),
          showIf: (formData: any) =>
            formData[EField.NaturalGasAvailable] ===
            ENaturalGasAvailable.Available,
          requiredIf: (formData: any) =>
            formData[EField.NaturalGasAvailable] ===
            ENaturalGasAvailable.Available,
        },

        // Water
        [EField.Header + "11"]: {
          default: undefined,
        },
        [EField.WaterMeterNumber]: {
          default: getValue(
            EField.WaterMeterNumber,
            initialValues,
            defaultValues,
          ),
        },

        // Exterior
        [EField.Header + "12"]: {
          default: undefined,
        },
        [EField.BuildingAdjoined]: {
          default: getValue(
            EField.BuildingAdjoined,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: () => canHaveAdjoinedType,
          requiredIf: () => canHaveAdjoinedType,
          options: [
            EPropertyAdjoined.Freestanding,
            EPropertyAdjoined.OneSide,
            EPropertyAdjoined.BothSides,
            EPropertyAdjoined.Unknown,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `property.adjoined.option.${id}.label`.toLowerCase(),
            ),
          })),
        },
        [EField.BuildingSurfaceArea]: {
          default: getValue(
            EField.BuildingSurfaceArea,
            initialValues,
            defaultValues,
          ),
        },
        [EField.BuildingGardenSurfaceArea]: {
          default: getValue(
            EField.BuildingGardenSurfaceArea,
            initialValues,
            defaultValues,
          ),
        },
        [EField.BuildingTerraceSurfaceArea]: {
          default: getValue(
            EField.BuildingTerraceSurfaceArea,
            initialValues,
            defaultValues,
          ),
        },

        // Interior
        [EField.Header + "13"]: {
          default: undefined,
        },
        [EField.BuildingFloor]: {
          default: getValue(EField.BuildingFloor, initialValues, defaultValues),
          // @ts-ignore
          showIf: () => canHaveFloors,
          requiredIf: () => canHaveFloors,
        },
        [EField.BuildingFloorCount]: {
          default: getValue(
            EField.BuildingFloorCount,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: () => canHaveFloors,
          requiredIf: () => canHaveFloors,
        },
        [EField.RoomCountBedroom]: {
          default: getValue(
            EField.RoomCountBedroom,
            initialValues,
            defaultValues,
          ),
        },
        [EField.RoomCountBathroom]: {
          default: getValue(
            EField.RoomCountBathroom,
            initialValues,
            defaultValues,
          ),
        },
        [EField.RoomCountGarage]: {
          default: getValue(
            EField.RoomCountGarage,
            initialValues,
            defaultValues,
          ),
        },
        [EField.BuildingHasStorage]: {
          default: getValue(
            EField.BuildingHasStorage,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: booleanOptions,
        },
        [EField.IsFurnished]: {
          default: getValue(EField.IsFurnished, initialValues, defaultValues),
          // @ts-ignore
          options: booleanOptions,
        },

        // Amenities
        [EField.Header + "14"]: {
          default: undefined,
        },
        [EField.HasOven]: {
          default: getValue(EField.HasOven, initialValues, defaultValues),
          // @ts-ignore
          options: booleanOptions,
        },
        [EField.HasFridge]: {
          default: getValue(EField.HasFridge, initialValues, defaultValues),
          // @ts-ignore
          options: booleanOptions,
        },
        [EField.HasDishWasher]: {
          default: getValue(EField.HasDishWasher, initialValues, defaultValues),
          // @ts-ignore
          options: booleanOptions,
        },
        [EField.HasRangeHood]: {
          default: getValue(EField.HasRangeHood, initialValues, defaultValues),
          // @ts-ignore
          options: booleanOptions,
        },
        [EField.HasLift]: {
          default: getValue(EField.HasLift, initialValues, defaultValues),
          // @ts-ignore
          options: booleanOptions,
        },

        // Energy
        [EField.Header + "15"]: {
          default: undefined,
        },
        [EField.HasDoubleGlazing]: {
          default: getValue(
            EField.HasDoubleGlazing,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: booleanOptions,
        },
        [EField.HasCentralHeating]: {
          default: getValue(
            EField.HasCentralHeating,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: booleanOptions,
        },
        [EField.HeatingType]: {
          default: getValue(EField.HeatingType, initialValues, defaultValues),
          // @ts-ignore
          options: [
            EHeatingType.NaturalGas,
            EHeatingType.Electricity,
            EHeatingType.HeatPump,
            EHeatingType.FuelOil,
            EHeatingType.Coal,
            EHeatingType.Other,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `property.extra.option.energy.${id}`.toLowerCase(),
            ),
          })),
        },
      },
    };
  };

export const uiSchema = (
  initialValues: {
    [key: string]: unknown;
  },
  extraData: IExtraData,
): IUiSchema<EField | EField> => ({
  "rentio:title": getLocalizedText(
    "registration.create.form.step.property_additional_information",
  ),

  // General
  [EField.Header + "7"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("property.extra.general.title"),
  },
  [EField.ConstructionYear]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("property.extra.general.item.construction_year"),
  },

  // EPC
  [EField.Header + "8"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("property.extra.epc.title"),
  },
  ...epcUiSchema({ epcIsRequired: false }, initialValues),

  // Electricity
  [EField.Header + "9"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("property.extra.electricity.title"),
  },
  [EField.ElectricityType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.electricity_type.title"),
    formatSummary: value =>
      getLocalizedText(`move.option.electricity_type.${value}`.toLowerCase()),
  },
  [EField.ElectricityEan]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.electricity_ean.title"),
    // optional: true,
    iMaskProps: {
      mask: EAN_MASK,
      lazy: false,
      unmask: true,
      placeholderChar: ". ",
    },
    formatSummary: value => (value ? `${EAN_PREFIX}${value}` : undefined),
  },
  [EField.ElectricityMeterNumberBoth]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.electricity_meter_number_both.title"),
  },
  [EField.ElectricityMeterType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.electricity_meter_type.title"),
    formatSummary: value =>
      getLocalizedText(
        `move.option.electricity_meter_type.${value}`.toLowerCase(),
      ),
  },

  // Natural Gas
  [EField.Header + "10"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("property.extra.natural_gas.title"),
  },
  [EField.NaturalGasAvailable]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.natural_gas_type.title"),
    formatSummary: value =>
      getLocalizedText(`move.option.natural_gas_type.${value}`.toLowerCase()),
  },
  [EField.NaturalGasEan]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.natural_gas_ean.title"),
    // optional: true,
    iMaskProps: {
      mask: EAN_MASK,
      lazy: false,
      unmask: true,
      placeholderChar: ". ",
    },
    formatSummary: value => (value ? `${EAN_PREFIX}${value}` : undefined),
  },
  [EField.NaturalGasMeterNumber]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.natural_gas_meter_number.title"),
    // optional: true,
  },
  [EField.NaturalGasMeterType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.natural_gas_meter_type.title"),
    formatSummary: value =>
      getLocalizedText(
        `move.option.natural_gas_meter_type.${value}`.toLowerCase(),
      ),
  },

  // Water
  [EField.Header + "11"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("property.extra.water.title"),
  },
  [EField.WaterMeterNumber]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.water_meter_number.title"),
    optional: true,
  },

  // Exterior
  [EField.Header + "12"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("property.extra.exterior.title"),
  },
  [EField.BuildingAdjoined]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: getLocalizedText("property.extra.exterior.field.building_adjoined"),
    formatSummary: value =>
      getLocalizedText(`property.adjoined.option.${value}.label`.toLowerCase()),
    // optional: true,
  },
  [EField.BuildingSurfaceArea]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.exterior.field.building_surface_area",
    ),
    suffixText: "m²",
    isFloat: true,
    // optional: true,
  },
  [EField.BuildingGardenSurfaceArea]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.exterior.field.building_garden_surface_area",
    ),
    suffixText: "m²",
    isFloat: true,
    // optional: true,
  },
  [EField.BuildingTerraceSurfaceArea]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.exterior.field.building_terrace_surface_area",
    ),
    suffixText: "m²",
    isFloat: true,
    // optional: true,
  },

  // Interior
  [EField.Header + "13"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("property.extra.interior.title"),
  },
  [EField.BuildingFloor]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("property.extra.interior.field.building_floor"),
    // optional: true,
  },
  [EField.BuildingFloorCount]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("property.extra.interior.field.building_layers"),
    // optional: true,
  },
  [EField.RoomCountBedroom]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("property.extra.interior.field.room_count_bedroom"),
    // optional: true,
  },
  [EField.RoomCountBathroom]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.interior.field.room_count_bathroom",
    ),
    // optional: true,
  },
  [EField.RoomCountGarage]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("property.extra.interior.field.room_count_garage"),
    // optional: true,
  },
  [EField.BuildingHasStorage]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText(
      "property.extra.interior.field.building_has_storage",
    ),
    formatSummary: (value?: string) => mapFormFieldBooleanToSummary(value),
    // optional: true,
  },
  [EField.IsFurnished]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.interior.field.is_furnished"),
    formatSummary: (value?: string) => mapFormFieldBooleanToSummary(value),
    // optional: true,
  },

  // Amenities
  [EField.Header + "14"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("property.extra.amenities.title"),
  },
  [EField.HasOven]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.amenities.item.has_oven"),
    formatSummary: (value?: string) => mapFormFieldBooleanToSummary(value),
    // optional: true,
  },
  [EField.HasFridge]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.amenities.item.has_fridge"),
    formatSummary: (value?: string) => mapFormFieldBooleanToSummary(value),
    // optional: true,
  },
  [EField.HasDishWasher]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.amenities.item.has_dish_washer"),
    formatSummary: (value?: string) => mapFormFieldBooleanToSummary(value),
    // optional: true,
  },
  [EField.HasRangeHood]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.amenities.item.has_range_hood"),
    formatSummary: (value?: string) => mapFormFieldBooleanToSummary(value),
    // optional: true,
  },
  [EField.HasLift]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.amenities.item.has_lift"),
    formatSummary: (value?: string) => mapFormFieldBooleanToSummary(value),
    // optional: true,
  },

  // Energy
  [EField.Header + "15"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("property.extra.energy.title"),
  },
  [EField.HasDoubleGlazing]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.energy.item.has_double_glazing"),
    formatSummary: (value?: string) => mapFormFieldBooleanToSummary(value),
    // optional: true,
  },
  [EField.HasCentralHeating]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.energy.item.has_central_heating"),
    formatSummary: (value?: string) => mapFormFieldBooleanToSummary(value),
    // optional: true,
  },
  [EField.HeatingType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("property.extra.energy.item.has_heating_type"),
    formatSummary: value =>
      getLocalizedText(`property.extra.option.energy.${value}`.toLowerCase()),
    // optional: true,
  },
});
