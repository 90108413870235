import { useTheme } from "@mui/material/styles";
export var DataListItemStyles = function (clickable) {
    var theme = useTheme();
    return {
        wrapper: {
            position: "relative",
            cursor: clickable ? "pointer" : "default",
        },
        content: {
            link: {
                position: "absolute",
                right: "8px",
                top: "8px",
                svg: {
                    color: theme.palette.text.secondary,
                },
            },
        },
    };
};
