// import { useDispatch } from "react-redux";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as cardActions from "@rentiohq/shared-frontend/dist/redux/card/card.actions";
import {
  ECardButtonActionType,
  ECardType,
  ICard,
  ICardButtonAction,
} from "@rentiohq/shared-frontend/dist/redux/card/card.types";
import { formatDateWithCustomClosebyFormat } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import { EFontWeights } from "@rentiohq/web-shared-next/dist/themes/types";
import {
  Grid,
  Icon,
  Link,
  Stack,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StringParam } from "serialize-query-params";
import { openDeeplink } from "../../../components/HeaderNotificationMenu/components/deeplink.utils";

interface IProps {
  notification: ICard;
}

export const NotificationCard = ({ notification }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, title, message, createdAt, buttons, autoclose, type } =
    notification;

  const [, setQueryParamValue] = useQueryParams({ documentId: StringParam });

  const handleClickButton = (action: ICardButtonAction, index: number) => {
    switch (action.type) {
      case ECardButtonActionType.Callback:
        dispatch(
          cardActions.cardActionCallback.actions.start({
            cardId: id,
            actionIndex: index,
            action,
          }),
        );
        break;

      case ECardButtonActionType.Deeplink:
        if (action.url) {
          openDeeplink({
            url: action.url,
            navigate,
            setQueryParamValue,
          });
        }
        break;

      case ECardButtonActionType.External:
        if (action.url) {
          window.open(action.url, "_blank");
        }
        break;

      case ECardButtonActionType.Handler:
      case ECardButtonActionType.Ignore:
        break;
    }

    if (autoclose && action.type !== ECardButtonActionType.Callback) {
      dispatch(cardActions.closeCard.actions.start({ cardId: id }));
    }
  };

  return (
    <Grid container alignItems="center" gap={-0.5}>
      <Grid item xs={12} md={12} lg={8}>
        <Stack direction="row" gap={0.5} alignItems="center">
          <Icon
            path={
              ECardType.Payment === type ? EAssets.EuroIcon : EAssets.TasksIcon
            }
            width="14px"
            height="14px"
          />
          <Typography variant="caption" fontWeight={EFontWeights.Regular}>
            {formatDateWithCustomClosebyFormat(createdAt)}
          </Typography>
        </Stack>
        <Typography variant="subtitle2">{title}</Typography>
        {message && (
          <Typography
            mb={1}
            variant="caption"
            fontWeight={EFontWeights.Regular}
          >
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </Typography>
        )}
        <Stack direction="row" gap={1}>
          {buttons?.map((button, index) => {
            const { action, label } = button;
            return (
              <Link
                label={`${label} >`}
                underlined={false}
                onClick={() => handleClickButton(action, index)}
              />
            );
          })}
        </Stack>
      </Grid>
    </Grid>
  );
};
