import { Route } from "react-router-dom";
import { TaskForm } from "./TaskForm";
import Tasks from "./";

export const getTasksScene = () => {
  return (
    <Route path="tasks">
      <Route index element={<Tasks />} />
      <Route path="add" element={<TaskForm />} />

      <Route path=":taskId">
        <Route path="edit" element={<TaskForm />} />
        <Route index element={<Tasks />} />
      </Route>
    </Route>
  );
};
