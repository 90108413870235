import { useTheme } from "@rentiohq/web-shared-next/dist/themes";

export const CommentStyles = () => {
  const theme = useTheme();
  return {
    content: {
      comment: {
        position: "relative",
      },
      closeIcon: {
        position: "absolute",
        top: "24px",
        right: 0,
        color: theme.palette.error.dark,
        cursor: "pointer",
      },
    },
  };
};
