import {
  ERecurrenceType,
  ETaskReminder,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import {
  addYears,
  isBefore,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ERentioFormField,
  IFormikProps,
  IFormikValues,
  IUiSchema,
} from "@rentiohq/web-shared-next/dist/utils/types/form";
import { EField, IExtraData } from "../addForm/schema.addTask.types";
import {
  isReminderOptionDisabled,
  reminderOptionChange,
  prefillDateOnChange,
  REMINDER_OPTIONS,
} from "./schema.addTask.utils";

const NOW = new Date();

export const uiSchema = (extraData: IExtraData): IUiSchema => {
  return {
    stepTitle: getLocalizedText("task.date.section_header"),
    heading: getLocalizedText("task.date.section_header"),
    fields: [
      {
        fieldKey: EField.ExecuteDate,
        uiComponent: ERentioFormField.Date,
        formatSummary: input =>
          input
            ? formatDate(input)
            : getLocalizedText("task.date.no_selection"),
        getTitle: (formData: IFormikValues) =>
          formData[EField.RecurrenceSelection] === ERecurrenceType.Once
            ? getLocalizedText("task.execute_date")
            : getLocalizedText("task.recurrent.execute_date"),
        optional: true,
        onChange: (value, formikProps: IFormikProps<IFormikValues>) => {
          formikProps.setFieldValue(EField.ExecuteDate, value);
          formikProps.setFieldValue(EField.ReminderType, ETaskReminder.None);
          formikProps.setFieldValue(EField.ReminderOption, undefined);
        },
        componentProps: {
          minDate: NOW,
          maxDate: addYears(NOW, 40),
        },
      },
      {
        fieldKey: EField.DueDate,
        uiComponent: ERentioFormField.Date,
        formatSummary: input =>
          input
            ? formatDate(input)
            : getLocalizedText("task.date.no_selection"),
        getTitle: (formData: IFormikValues) =>
          formData[EField.RecurrenceSelection] === ERecurrenceType.Once
            ? getLocalizedText("task.due_date")
            : getLocalizedText("task.recurrent.due_date"),
        optional: true,
        onChange: (value, formikProps: IFormikProps<IFormikValues>) => {
          formikProps.setFieldValue(EField.DueDate, value);
          formikProps.setFieldValue(EField.ReminderType, ETaskReminder.None);
          formikProps.setFieldValue(EField.ReminderOption, undefined);
        },
        additionalValidation: {
          name: "dueDate_before_executeDate",
          message: getLocalizedText("task.execute_date.after.due_date.error"),
          test: (value: any, formData: IFormikValues) =>
            !isBefore(value, formData[EField.ExecuteDate]),
        },
        componentProps: {
          minDate: NOW,
          maxDate: addYears(NOW, 40),
        },
      },
      {
        fieldKey: EField.ReminderType,
        uiComponent: ERentioFormField.SegmentedControl,
        title: getLocalizedText("task.reminder.title"),
        formatSummary: formData => {
          if (formData === ETaskReminder.None) {
            return getLocalizedText("task.reminder_type.no");
          }
          return getLocalizedText("task.reminder_type.date");
        },
        componentProps: {
          options: [
            {
              id: ETaskReminder.None,
              value: getLocalizedText("task.reminder_type.no"),
            },
            {
              id: ETaskReminder.HasDate,
              value: getLocalizedText(
                "intake.profile.history.info.date.header",
              ),
            },
          ],
        },
      },
      {
        fieldKey: EField.ReminderOption,
        uiComponent: ERentioFormField.InlineSelect,
        getTitle: (formData: IFormikValues) =>
          formData[EField.RecurrenceSelection] === ERecurrenceType.Once
            ? getLocalizedText("task.reminder.select.title")
            : getLocalizedText("task.reminder.recurrent.select.title"),
        shouldShowOnSummary: () => false,
        showIf: formData =>
          formData[EField.ReminderType] === ETaskReminder.HasDate,
        onChange: (value, formikProps) => {
          prefillDateOnChange(
            value as ETaskReminder,
            EField.NextReminderDate,
            formikProps,
          );
          formikProps.setFieldValue(EField.ReminderOption, value);
        },
        componentProps: {
          customIsFetchingSelector: () => false,
          options: REMINDER_OPTIONS.map(id => ({
            id,
            value: getLocalizedText(`reminder.type.${id.toLowerCase()}`),
            isDisabled: (formData: IFormikValues) =>
              isReminderOptionDisabled(
                id,
                formData[EField.ExecuteDate],
                formData[EField.DueDate],
              ),
          })),
        },
      },
      {
        fieldKey: EField.NextReminderDate,
        title: getLocalizedText("task.reminder.date"),
        uiComponent: ERentioFormField.Date,
        formatSummary: value =>
          value
            ? formatDate(value, "dd/MM/yyyy HH:mm")
            : getLocalizedText("task.date.no_selection"),
        onChange: (value, formikProps) => {
          reminderOptionChange(
            value as Date,
            EField.ReminderOption,
            formikProps,
          );
          formikProps.setFieldValue(EField.NextReminderDate, value);
        },
        showIf: formData => {
          return (
            formData[EField.ReminderType] !== ETaskReminder.None &&
            formData[EField.ReminderOption] &&
            formData[EField.ReminderOption] !== ETaskReminder.None
          );
        },
        componentProps: {
          minDate: NOW,
          maxDate: addYears(NOW, 40),
          dateFormat: "dd/MM/yyyy HH:mm",
          showTimePicker: true,
        },
      },
    ],
  };
};
