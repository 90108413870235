import * as activityActions from "@rentiohq/shared-frontend/dist/redux/activity/activity.actions";
import * as activitySelectors from "@rentiohq/shared-frontend/dist/redux/activity/activity.selectors";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countTypes from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { ACTIVITY_TYPES } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { startOfDay } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  IActivityFilter,
  IPartialState,
} from "@rentiohq/web-shared/dist/components/Activities/Activities.types";
import {
  Box,
  Button,
  Stack,
  SpinningLoader,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import {
  EButtonColors,
  EButtonVariants,
} from "@rentiohq/web-shared-next/dist/utils/types/button";
import { isEmpty } from "lodash";
import groupBy from "lodash/groupBy";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActivityGroup } from "../../components/ActivityGroup";

interface IProps {
  identifier: string;
  limit?: number;
  filter?: { [key: string]: any };
  heading?: React.ReactNode;
  hasFetchMore?: boolean;
  onMoreClick?: () => void;
  moreLink?: string;
}

const getFilterQuery = (values: IActivityFilter, defaultFilter: any) => {
  const { activityTypes, activityDateTime, activityQuery } = values;

  const types = activityTypes?.length
    ? activityTypes.map(type => ACTIVITY_TYPES[type])
    : undefined;
  const dateTimeFilter = !isEmpty(activityDateTime)
    ? activityDateTime
    : undefined;

  let newFilter: any = {
    skip: defaultFilter.filter.skip,
    order: defaultFilter.filter.order,
    limit: defaultFilter.filter.limit,
  };

  if (types || dateTimeFilter || defaultFilter.filter.where) {
    newFilter.filter = { where: { and: [] } };

    if (types) {
      newFilter.filter.where.and.push({ type: { inq: types } });
    }

    if (dateTimeFilter) {
      newFilter.filter.where.and.push(dateTimeFilter);
    }

    if (defaultFilter.filter.where) {
      newFilter.filter.where.and.push(defaultFilter.filter.where);
    }
  }

  if (activityQuery) {
    newFilter.filter = newFilter.filter || {};
    newFilter.filter.search = activityQuery;
  }

  return newFilter;
};

export const Activities = ({
  identifier,
  limit = 20,
  filter,
  onMoreClick,
  moreLink,
}: IProps) => {
  const dispatch = useDispatch();

  const defaultFilter = React.useMemo(
    () => ({
      filter: {
        limit: !!moreLink || !!onMoreClick ? limit + 1 : limit,
        skip: 0,
        order: "datetime DESC",
        ...filter,
      },
    }),
    [filter],
  );

  const [skip, setSkip] = React.useState(0);

  const activities =
    useSelector((state: IPartialState) =>
      activitySelectors.getActivities(state, identifier),
    ) || [];

  const activitiesCount =
    useSelector((state: IPartialRootState) =>
      countSelectors.getCount(state, `activities-${identifier}`),
    ) || 0;

  const activitiesFetchError = useSelector((state: IPartialState) =>
    activitySelectors.getFetchActivitiesError(state, identifier),
  );

  const isFetchingActivities = useSelector((state: IPartialState) =>
    activitySelectors.getIsFetchingActivities(state, identifier),
  );

  React.useEffect(() => {
    fetchCount();
  }, []);

  React.useEffect(() => {
    if (isFetchingActivities) {
      return;
    }

    fetchActivities(!skip);
  }, [skip]);

  const fetchActivities = (refetch: boolean) => {
    const {
      filter: { where },
    } = getFilterQuery({}, defaultFilter);

    dispatch(
      activityActions.getActivities.actions.start({
        activityIdentifier: identifier,
        refetch,
        limit,
        extraFilterData: where,
      }),
    );
  };

  const fetchCount = () => {
    const {
      filter: { where },
    } = getFilterQuery({}, defaultFilter);

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: `activities-${identifier}`,
        countBase: append(
          countTypes.COUNT_BASE[countTypes.ECountIdentifier.Activities],
          { where },
        ),
      }),
    );
  };

  if (activitiesFetchError) {
    return <p>{getLocalizedText("fetch.error")}</p>;
  }

  const fetchMore = () => setSkip(skip + 20);

  const renderActivities = () => {
    const groupedActivities = groupBy(activities, (activity: any) =>
      startOfDay(new Date(activity.datetime)),
    );

    const sortedActivitiesKeys = Object.keys(groupedActivities).sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime(),
    );

    return (
      <Box>
        {sortedActivitiesKeys.map((groupedDate: any) => {
          return (
            <ActivityGroup
              groupedDate={groupedDate}
              groupedActivities={groupedActivities[groupedDate]}
            />
          );
        })}
      </Box>
    );
  };

  const renderLoadMore = () => {
    if (activitiesCount <= activities.length) {
      return null;
    }

    if (isFetchingActivities) {
      return null;
    }

    return (
      <Button
        onClick={fetchMore}
        variant={EButtonVariants.Text}
        color={EButtonColors.Success}
      >
        {getLocalizedText("system.load_more")}
      </Button>
    );
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="h5" mb={2}>
          {getLocalizedText("system.history")}
        </Typography>
        {isFetchingActivities && <SpinningLoader />}
      </Stack>
      <Box>
        {renderActivities()}
        {renderLoadMore()}
      </Box>
    </Box>
  );
};
