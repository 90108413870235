import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import * as documentApi from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.api";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import { EDocumentCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.types";
import * as documentUtils from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import { ITask } from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { check } from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import {
  SHOW_CONFIRM_MODAL,
  SHOW_LOADER,
} from "@rentiohq/web-shared-next/dist/redux/actions";
import {
  Box,
  DataListItem,
  Menu,
  SpinningLoader,
  Stack,
  Typography,
  UploadField,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { enqueueSnackbar } from "@rentiohq/web-shared-next/dist/utils/snackbar";
import { EButtonVariants } from "@rentiohq/web-shared-next/dist/utils/types/button";
import uniqBy from "lodash/uniqBy";
import React from "react";
import { useDispatch } from "react-redux";
import { StringParam } from "serialize-query-params";
import { CreateDocumentModal } from "../../../../../../components/Modals/CreateDocumentModal";
import { TaskDocument } from "../../../../../../components/TaskDocument";

interface IProps {
  task: ITask;
}

const multiple = false;
const asDropzone = true;

export const Documents = ({ task }: IProps) => {
  const dispatch = useDispatch();

  const [files, setFiles] = React.useState<File[]>([]);
  const [showCreateDocModal, setShowCreateDocModal] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState<boolean>();

  const [, setQueryParamValue] = useQueryParams({
    documentPackageId: StringParam,
  });

  const {
    items: documents = [],
    isFetching,
    fetchError,
    refetch,
  } = documentHooks.usePaged(
    documentUtils.getDocumentPagedQuery({
      extraFilterData: { taskId: { eq: task.id } },
      customFilters: [
        EDocumentCustomFilter.Active,
        EDocumentCustomFilter.ExcludeInternalDocuments,
      ],
    }),
  );

  React.useEffect(() => {
    if (fetchError?.message) {
      enqueueSnackbar(fetchError.message, { variant: "error" });
    }
  }, [fetchError]);

  React.useEffect(() => {
    dispatch({ type: SHOW_LOADER, payload: isFetching || isUploading });
  }, [isFetching, isUploading]);

  const handleDrop = (acceptedFiles: any[]) => {
    if (acceptedFiles.length === 0) {
      return;
    }

    setFiles(acceptedFiles);
    toggleCreateDocModal();
  };

  const toggleCreateDocModal = () =>
    setShowCreateDocModal(prevState => !prevState);

  const handleClose = () => {
    toggleCreateDocModal();
    setFiles([]);
  };

  const handleAdd = async (values: any) => {
    try {
      setIsUploading(true);

      const uploads = (files || []).map(async (file: any) => {
        const meta = { taskId: task.id, ...values };

        const preSignedUrl = await documentApi.uploadDocumentToS3(file);

        return documentApi.uploadDocument(preSignedUrl, meta);
      });

      const uploadedDocuments = await Promise.all(uploads);

      setFiles([]);

      if (uploadedDocuments) {
        enqueueSnackbar("Document uploaded successfully", {
          variant: "success",
        });
      }
      // setQueryParamValue({ documentId: uploadedDocuments[0].id });

      refetch();
    } catch (unknownError) {
      const error = unknownError as any;
      // @ts-ignore
      enqueueSnackbar(new Error(error as any), { variant: "error" });
    }

    setIsUploading(false);
  };

  const handleDelete = (document: IDocument) => {
    dispatch(documentActions.removeStart.getAction({ id: document.id }));
    handleCloseConfirmModal();
  };

  const handleCloseConfirmModal = () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: { showConfirmModal: false },
    });
  };

  const handleShowConfirmModal = (document: IDocument) => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        title: getLocalizedText("document.alert.delete_confirm.message"),
        description: document?.filename,
        showConfirmModal: true,
        actions: [
          {
            label: getLocalizedText("system.cancel"),
            onClick: handleCloseConfirmModal,
            variant: EButtonVariants.Outlined,
          },
          {
            label: getLocalizedText("system.remove"),
            onClick: () => handleDelete(document),
          },
        ],
      },
    });
  };

  const getActions = (document: IDocument) => {
    const { url, downloadUrl, documentPackageId, roles } = document;
    const canDeleteDocument = check(roles, "document:delete");

    const actions = [];

    if (url) {
      actions.push({
        label: "document.view",
        onClick: () => utils.document.viewDocument(document),
      });
    }

    if (downloadUrl) {
      actions.push({
        label: "document.download",
        onClick: () => (window.location.href = downloadUrl),
      });
    }

    if (Boolean(documentPackageId)) {
      actions.push({
        label: "document_package.view.action",
        onClick: () =>
          setQueryParamValue({
            documentPackageId,
          }),
      });
    }

    if (canDeleteDocument) {
      actions.push({
        label: "document.delete",
        onClick: () => handleShowConfirmModal(document),
      });
    }

    return actions;
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="h5" mb={2}>
          {getLocalizedText("system.documents")}
        </Typography>
        {(isFetching || isUploading) && <SpinningLoader />}
      </Stack>
      <Stack gap={1}>
        {!isFetching && documents?.length === 0 && (
          <Typography textAlign="center" mb={1}>
            {getLocalizedText("document.empty.info")}
          </Typography>
        )}
        {documents.map(document => {
          return (
            <DataListItem
              key={task?.id}
              configuration={{
                action: <Menu actions={getActions(document)} />,
              }}
              mainContent={<TaskDocument document={document} />}
            />
          );
        })}
        <UploadField
          id="doc-files"
          value={files || []}
          asDropzone={asDropzone}
          multiple={multiple}
          disabled={isUploading}
          onChange={(newFiles: any[]) => {
            if (multiple) {
              handleDrop(
                uniqBy([...(files || []), ...newFiles], x => {
                  if (typeof x === "object") {
                    return x.name;
                  }
                  return x;
                }),
              );
              return;
            }

            handleDrop(newFiles);
          }}
        />
      </Stack>
      {files?.length > 0 && (
        <CreateDocumentModal
          openDialog={showCreateDocModal}
          handleClose={handleClose}
          handleAdd={handleAdd}
          file={files[0]}
        />
      )}
    </Box>
  );
};
