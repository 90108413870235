import { Flex } from "@rebass/grid";
import ChatsLists from "@rentiohq/shared-frontend/dist/components/components/Chats";
import ListItemSeparator from "@rentiohq/shared-frontend/dist/components/components/ListItemSeparator";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as ChatActions from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.actions";
import * as chatHooks from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.hooks";
import * as chatSelectors from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import * as chatUtils from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IChat } from "@rentiohq/shared-frontend/dist/types/chat.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  DisplayText,
  DropdownMenu,
  EmptyState,
  Filters,
  Icon,
  Loading,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as t from "../../services/translationService";
import * as S from "./Chats.styled";
import { ChatConversation } from "./components/ChatConversation";

interface IStateProps {}

export const Chats: React.FC<IStateProps> = React.memo(props => {
  // External hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { employees: employeeAccounts } = brokerHooks.useGetBrokerEmployees();

  const params = useParams<{ chatId: string }>();
  const chatId = params.chatId ? +params.chatId : undefined;

  // State hooks
  const [queryInput, setQueryInput] = React.useState<string>("");
  const [showArchivedChats, setShowArchivedChats] =
    React.useState<boolean>(false);
  const [employeeIds, setEmployeeIds] = React.useState<number[]>([]);

  const [queryDebounced] = useDebounce(queryInput);

  const chat = useSelector((state: IRootStore) =>
    chatSelectors.getDetail(state, chatId),
  );

  const hasMessagesForNotFetchedChats = useSelector((state: IRootStore) =>
    chatSelectors.hasMessagesForNotFetchedChats(state),
  );

  // Reducer hooks
  const { archive } = chatHooks.useArchive({ id: chatId });

  const getQuery = () => {
    const payload: any = {
      archived: showArchivedChats,
    };

    if (queryDebounced) {
      payload.search = queryDebounced;
    }

    if (employeeIds?.length > 0) {
      payload.employeeAccountIds = employeeIds;
      payload.unassignedToEmployee = false;
    }

    return payload;
  };

  const {
    items: chats = [],
    isFetching = false,
    refresh,
    loadMore,
    isExhausted,
  } = chatHooks.useInfiniteLoad({
    query: chatUtils.getChatsQuery(getQuery()),
  });

  // Lifecycle hooks
  React.useEffect(() => {
    if (chat) {
      document.title = `Rentio | ${chatUtils.getTitle({ chat })}`;
    } else {
      document.title = `Rentio | ${getLocalizedText("system.entity.chats")}`;
    }
  }, [chat]);

  React.useEffect(() => {
    if (hasMessagesForNotFetchedChats) {
      refresh();
    }
  }, [hasMessagesForNotFetchedChats]);

  // Event handlers
  const handlePressChat = ({ id }: IChat) => {
    navigate(`/chats/${id}`);
  };

  const handleSearchChatChange = (newQuery: string) => {
    setQueryInput(newQuery);
  };

  const handleSearchChatRemove = () => {
    setQueryInput("");
  };

  const handleToggleShowArchivedChats = () => {
    setShowArchivedChats(!showArchivedChats);
  };

  const markAsUnread = (chatId: number) => {
    if (!chatId) {
      return;
    }

    dispatch(
      ChatActions.markUnreadStart.getAction({
        id: chatId,
        onSuccess: () => refresh(),
      }),
    );
  };

  const archiveChat = (chatId?: number) => {
    if (!chatId) {
      return;
    }

    confirm({
      title: t.archiveConfirm("chat"),
      type: "warning",
      primaryActions: [
        {
          title: t.system("archive"),
          onPress: () => archive({}),
        },
      ],
    });
  };

  const onEmployeeChange = (employee: IAccount) => {
    const newId = employee.id;
    if (employeeIds.includes(newId)) {
      setEmployeeIds(employeeIds.filter(id => id !== newId));
    } else {
      setEmployeeIds([...employeeIds, newId]);
    }
  };

  const getDropdownActions = () => {
    return employeeAccounts
      .filter(employee => !employeeIds.includes(employee.id))
      .map(employee => ({
        content: getName(employee),
        onClick: () => onEmployeeChange(employee),
      }));
  };

  // Render
  const renderChats = (chats: IChat[]) => {
    return (
      <ChatsLists
        chats={chats.sort((a, b) => b.unreadCounter - a.unreadCounter)}
        activeChatId={chatId}
        onPressChat={handlePressChat}
      />
    );
  };

  const renderChatList = () => {
    const hasChats = chats.length > 0;
    const emptyChats = chats.length === 0;

    if (hasChats) {
      return (
        <>
          {renderChats(chats)}
          {isFetching && <Loading />}
          <ListItemSeparator />
        </>
      );
    }

    if (isFetching) {
      return <Loading />;
    }

    if (emptyChats) {
      const action: IAction | undefined =
        !queryDebounced && !employeeIds.length
          ? {
              content: t.chatsListEmptyAction(),
              appearance: "link",
              url: "/chats/add",
            }
          : undefined;

      return (
        <EmptyState isShownInline={true} action={action}>
          {t.chatsListEmptyText()}
        </EmptyState>
      );
    }

    return null;
  };

  const renderViewArchivedButton = () => {
    if (showArchivedChats) {
      return null;
    }

    return (
      <S.ArchivedButtonWrap>
        <Button appearance="link" onClick={handleToggleShowArchivedChats}>
          {t.chatsViewArchived()}
        </Button>
      </S.ArchivedButtonWrap>
    );
  };

  const renderShowAllButton = () => {
    if (isExhausted) {
      return;
    }

    return (
      <S.ShowAllButtonWrap>
        <Button appearance="link" onClick={loadMore}>
          {t.chatsListShowMore()}
        </Button>
      </S.ShowAllButtonWrap>
    );
  };

  const renderContent = () => {
    if (!chatId) {
      return;
    }

    const actions: IAction[] = [
      {
        content: (
          <TextStyle variation={"negative"}>
            {t.chatsArchiveActionTitle()}
          </TextStyle>
        ),
        onClick: () => archiveChat(chatId),
      },
    ];

    actions.unshift({
      content: (
        <TextStyle variation={"default"}>
          {t.chatsMarkAsUnreadActionTitle()}
        </TextStyle>
      ),
      onClick: () => markAsUnread(chatId),
    });

    return <ChatConversation chatId={chatId} actions={actions} />;
  };

  return (
    <S.Wrapper>
      <S.Sidebar>
        <S.SidebarHeader>
          <Flex justifyContent="space-between" alignItems="center" mb={3}>
            {showArchivedChats ? (
              <>
                <Button
                  appearance="link"
                  onClick={handleToggleShowArchivedChats}
                >
                  {`‹ ${t.chatsTitle()}`}
                </Button>
              </>
            ) : (
              <>
                <DisplayText>{t.chatsTitle()}</DisplayText>
                <Button
                  appearance="primary"
                  size="small"
                  title={t.chatsAddActionTitle()}
                  url="/chats/add"
                >
                  <Icon source="messagesBubbleAdd" />
                </Button>
              </>
            )}
          </Flex>
          {!showArchivedChats && (
            <Filters
              appliedFilters={employeeAccounts
                .filter(employee => employeeIds.includes(employee.id))
                .map(employee => ({
                  key: "employee",
                  label: getName(employee),
                  onRemove: () => onEmployeeChange(employee),
                }))}
              queryValue={queryInput}
              queryPlaceholder={t.chatsSearchPlaceholder()}
              onQueryChange={handleSearchChatChange}
              onQueryClear={handleSearchChatRemove}
              filters={[
                {
                  key: "employees",
                  label: getLocalizedText("properties.filter.employees"),
                  asDropdown: false,
                  content: (
                    <DropdownMenu
                      passDownClickEvents={false}
                      children={
                        <span>
                          <Icon source="profile" size="large" />
                        </span>
                      }
                      actions={getDropdownActions()}
                    />
                  ),
                },
              ]}
            />
          )}
        </S.SidebarHeader>

        {renderChatList()}

        {!isFetching && (
          <S.SidebarFooter>
            {renderViewArchivedButton()}
            {renderShowAllButton()}
          </S.SidebarFooter>
        )}
      </S.Sidebar>
      <S.Content>{renderContent()}</S.Content>
    </S.Wrapper>
  );
});
