import { useTheme } from "@rentiohq/web-shared-next/dist/themes";

export const TaskStyles = (open?: boolean) => {
  const theme = useTheme();

  return {
    wrapper: {
      display: "flex",
      columnGap: "8px",
    },
    content: {
      card: {
        bgcolor: theme.palette.background.paper,
        width: "100%",
        minHeight: "100%",
        overflow: "auto",
        maxHeight: "calc(100vh - 138px)",

        ".MuiCardContent-root": {
          p: 0,
        },
      },
      divider: {
        display: { xs: "none", md: "block" },
      },
    },
  };
};
