import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentActionsV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import { useDetail } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EPaymentOrderType,
  EPaymentRepetitionType,
  EPayoutType,
  IPaymentOrder,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  EPaymentRequestStatus,
  IPaymentRequest,
} from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  canMarkPaid,
  canMarkUnPaid,
  getPaymentOrders,
} from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import {
  Button,
  Grid,
  Icon,
  Page,
  RentioInternalRenderer,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { IAction } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import { ManuallyMarkPaidModal } from "components/OpenPaymentRequests/components/ManuallyMarkPaidModal";
import React from "react";
import { useDispatch } from "react-redux";
import { ExternalInvoiceDetailModal } from "scenes/Revenues/components/ExternalInvoiceDetailModal";
import { ts } from "../../../../services";
import {
  createTitle,
  getPeriod,
  isFromRent,
  isRentDiscount,
} from "../../../../utils/payment";
import { PaymentRequestHistory } from "./components/PaymentRequestHistory";
import { PaymentRequestInfo } from "./components/PaymentRequestInfo";
import {
  EPaymentRequestTabs,
  IPaymentRequestProps,
} from "./PaymentRequest.types";

export const PaymentRequest = (props: IPaymentRequestProps) => {
  const { paymentRequest } = props;

  const { user } = authHooks.useSelf();
  const { internalModeEnabled } = useInternalMode();
  const dispatch = useDispatch();

  const [tab, setTab] = React.useState<EPaymentRequestTabs>(
    EPaymentRequestTabs.Info,
  );
  const [externalInvoiceDetailModalId, setExternalInvoiceDetailModalId] =
    React.useState<string>();

  const [showManuallyMarkPaidModal, setShowManuallyMarkPaidModal] =
    React.useState(false);

  const propertyId = paymentRequest.requestPropertyId;

  const { detail: property } = useDetail({
    id: propertyId,
    shouldRefetch: false,
  });

  const period = getPeriod(paymentRequest);
  const isCharge = paymentRequest.payoutType === EPayoutType.Charge;
  const isEditable = utils.paymentRequest.isEditable(paymentRequest as any);

  const hasBookkeeping = brokerHooks.useBrokerFeature(
    EBrokerFeature.Bookkeeping,
  );

  const hasPaymentV2ForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.PaymentV2,
  );

  const recurringPaymentOrders = paymentRequest.paymentRequestItems
    .filter(
      item => item.paymentOrder.repetitionType !== EPaymentRepetitionType.Once,
    )
    .map(item => item.paymentOrder);

  const firstPaymentOrder = paymentRequest.paymentRequestItems[0]
    ?.paymentOrder as IPaymentOrder | undefined;

  const { propertyAddress } = paymentRequest;

  const metadata = (
    <div>
      {property && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Stack.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon
                  source={utils.properties.getIcon(property.typeId || 0)}
                  size="small"
                />
              </Grid.Item>
              <Grid.Item>
                <Button
                  appearance="link"
                  url={`/properties/${paymentRequest.requestPropertyId}`}
                >
                  {propertyAddress ? propertyAddress.full : ""}
                  {!propertyAddress && property ? formatAddress(property) : ""}
                </Button>
              </Grid.Item>
            </Grid>
          </Stack.Item>
        </>
      )}

      <Spacer weight={ESpacerWeight.W08} />
      <Stack.Item>
        <Grid spacing="extraTight" alignItems="center">
          <Grid.Item>
            <Icon source="billStack" size="small" />
          </Grid.Item>
          <Grid.Item>
            {getLocalizedText(
              `payment_request.payout_type.${paymentRequest.payoutType}`.toLowerCase(),
            )}
          </Grid.Item>
        </Grid>
      </Stack.Item>

      {paymentRequest.paymentMethod &&
        paymentRequest.status !== EPaymentRequestStatus.New && (
          <>
            <Spacer weight={ESpacerWeight.W08} />
            <Stack.Item hasFill={true}>
              <Grid spacing="extraTight" alignItems="center">
                <Grid.Item>
                  <Icon source="billStack" size="small" />
                </Grid.Item>
                <Grid.Item>
                  {paymentRequest.paymentInterface
                    ? getLocalizedText("payment_request.info.paid_through", {
                        paymentInterface: getLocalizedText(
                          `payment_request.interface.${paymentRequest.paymentInterface}`,
                        ),
                        paymentMethod: getLocalizedText(
                          `payment_request.method.${paymentRequest.paymentMethod}`,
                        ),
                      })
                    : getLocalizedText(
                        `payment_request.method.${paymentRequest.paymentMethod}`,
                      )}
                </Grid.Item>
              </Grid>
            </Stack.Item>
          </>
        )}

      {firstPaymentOrder?.type === EPaymentOrderType.RentDeposit &&
        firstPaymentOrder.wireReference && (
          <>
            <Spacer weight={ESpacerWeight.W08} />
            <Stack.Item>
              <Grid spacing="extraTight" alignItems="center">
                <Grid.Item>
                  <Icon source="billStack" size="small" />
                </Grid.Item>
                <Grid.Item>
                  {getLocalizedText("payment_order.wire_reference.info", {
                    value: firstPaymentOrder.wireReference,
                  })}
                </Grid.Item>
              </Grid>
            </Stack.Item>
          </>
        )}

      {paymentRequest.payoutDescription && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Stack.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="billStack" size="small" />
              </Grid.Item>
              <Grid.Item>
                {getLocalizedText("payment_request.payout_description.info", {
                  value: paymentRequest.payoutDescription,
                })}
              </Grid.Item>
            </Grid>
          </Stack.Item>
        </>
      )}

      {!paymentRequest.payoutDescription &&
        (firstPaymentOrder?.payoutReference ||
          firstPaymentOrder?.payoutReferenceCustom) && (
          <>
            <Spacer weight={ESpacerWeight.W08} />
            <Stack.Item>
              <Grid spacing="extraTight" alignItems="center">
                <Grid.Item>
                  <Icon source="billStack" size="small" />
                </Grid.Item>
                <Grid.Item>
                  {getLocalizedText("payment_order.reference.info", {
                    value:
                      firstPaymentOrder.payoutReference ||
                      firstPaymentOrder.payoutReferenceCustom ||
                      "",
                  })}
                </Grid.Item>
              </Grid>
            </Stack.Item>
          </>
        )}

      {period.includes("-") && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Stack.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="calendar" size="small" />
              </Grid.Item>
              <Grid.Item>
                {ts.system("period")}: {period}
              </Grid.Item>
            </Grid>
          </Stack.Item>
        </>
      )}

      {isCharge && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Stack.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="calendar" size="small" />
              </Grid.Item>
              <Grid.Item>
                {(() => {
                  if (paymentRequest.status === EPaymentRequestStatus.Paid) {
                    if (paymentRequest.paidAt) {
                      return ts.paymentRequestDetailPaidAt({
                        values: {
                          date: utils.date.format(paymentRequest.paidAt),
                        },
                      });
                    }

                    if (paymentRequest.paidManuallyAt) {
                      return `${ts.paymentRequestPaidManuallyLabel()} (${formatDate(
                        paymentRequest.paidManuallyAt,
                      )})`;
                    }
                  }

                  return `${ts.paymentRequestDetailDueDateMessage()}: ${utils.date.format(
                    paymentRequest.dueDateAt,
                  )}`;
                })()}
              </Grid.Item>
            </Grid>
          </Stack.Item>
        </>
      )}

      {recurringPaymentOrders.length > 0 &&
        recurringPaymentOrders[0].nextInvitationAt &&
        recurringPaymentOrders[0].nextContractualPayDate && (
          <>
            <Spacer weight={ESpacerWeight.W08} />
            <Stack.Item>
              <Grid spacing="extraTight" alignItems="center">
                <Grid.Item>
                  <Icon source="calendar" size="small" />
                </Grid.Item>
                <Grid.Item>
                  {getLocalizedText(
                    "payment_order.next_date_info.contractual_date",
                    {
                      nextContractualPayDate: formatDate(
                        recurringPaymentOrders[0].nextContractualPayDate,
                        "d MMMM yyyy",
                      ),
                    },
                  )}
                </Grid.Item>
              </Grid>
            </Stack.Item>

            <Spacer weight={ESpacerWeight.W08} />
            <Stack.Item>
              <Grid spacing="extraTight" alignItems="center">
                <Grid.Item>
                  <Icon source="calendar" size="small" />
                </Grid.Item>
                <Grid.Item>
                  {getLocalizedText(
                    "payment_order.next_date_info.invitation_date",
                    {
                      nextInvitationAt: formatDate(
                        recurringPaymentOrders[0].nextInvitationAt,
                        "d MMMM yyyy",
                      ),
                    },
                  )}
                </Grid.Item>
              </Grid>
            </Stack.Item>
          </>
        )}

      {hasBookkeeping && !!paymentRequest.externalBookkeepingId && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Stack.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="layout" size="small" />
              </Grid.Item>
              <Grid.Item>
                {getLocalizedText("payment_request.booked_in_bookkeeping")}
                {firstPaymentOrder?.bookkeepingGeneralLedgerAccount
                  ? ` (${firstPaymentOrder.bookkeepingGeneralLedgerAccount})`
                  : ""}
              </Grid.Item>
            </Grid>
          </Stack.Item>
        </>
      )}
    </div>
  );

  const handleInfoTabClick = () => {
    setTab(EPaymentRequestTabs.Info);
  };

  const handleHistoryTabClick = () => {
    setTab(EPaymentRequestTabs.History);
  };

  // Events
  const handleRemoveWithConfirm = () => {
    const handleRemove = () => {
      if (internalModeEnabled && hasPaymentV2ForBroker) {
        dispatch(
          paymentActionsV2.removeStart.getAction({ id: paymentRequest.id }),
        );

        return;
      }

      dispatch(
        paymentActions.deletePaymentRequest.actions.start({
          id: paymentRequest.id,
        }),
      );
    };

    confirm({
      title: getLocalizedText("system.delete.confirm", {
        typePrefix: getLocalizedText("system.model.payment_request.prefix"),
        type: getLocalizedText("system.model.payment_request").toLowerCase(),
      }),
      primaryActions: [
        {
          title: getLocalizedText("system.remove"),
          onPress: handleRemove,
        },
      ],
    });
  };

  const handleMarkPaidClick = () => {
    setShowManuallyMarkPaidModal(true);
  };

  const handleMarkUnPaidClick = () => {
    confirm({
      type: "warning",
      modalProps: {
        hasDismiss: true,
        shouldCloseOnOverlayClick: true,
      },
      title: getLocalizedText("payment_request.mark_unpaid.action.alert"),
      primaryActions: [
        {
          title: getLocalizedText("system.no"),
        },
      ],
      secondaryAction: {
        title: getLocalizedText("system.yes"),
        onPress: () => {
          dispatch(
            paymentActionsV2.markUnPaidStart.getAction({
              id: paymentRequest.parent || paymentRequest.id,
            }),
          );
        },
      },
    });
  };

  const handleManuallyMarkPaidModalClose = () => {
    setShowManuallyMarkPaidModal(false);
  };

  const handleInvitationClick = (item: IPaymentRequest) => () => {
    if (internalModeEnabled && hasPaymentV2ForBroker) {
      dispatch(
        paymentActionsV2.sendPaymentRequestInvitationStart.getAction({
          id: item.id,
        }),
      );

      return;
    }

    dispatch(
      paymentActions.sendPaymentRequestInvitation.actions.start({
        paymentRequestId: item.id,
      }),
    );
  };

  let tabs = [
    {
      name: "dashboard",
      permission: "propertyDashboard:visit",
      content: ts.paymentRequestDetailTabsInfo(),
      onClick: handleInfoTabClick,
      isActive: tab === EPaymentRequestTabs.Info,
    },
    {
      name: "payments",
      permission: "propertyPayments:visit",
      content: ts.paymentRequestDetailTabsHistory(),
      onClick: handleHistoryTabClick,
      isActive: tab === EPaymentRequestTabs.History,
    },
  ];

  const dropdown = [];

  const paymentOrders = getPaymentOrders(paymentRequest);
  const hasFromRent = isFromRent(paymentOrders);
  const hasRentDiscount = isRentDiscount(paymentOrders);

  if (!hasFromRent && !hasRentDiscount) {
    dropdown.push({
      content: ts.paymentRequestResendInvitationAction(),
      onClick: handleInvitationClick(paymentRequest),
    });
  }

  if (user && property && canMarkPaid({ paymentRequest, property, user })) {
    dropdown.push({
      content: <TextStyle>{ts.paymentRequestMarkPaidAction()}</TextStyle>,
      onClick: handleMarkPaidClick,
    });
  } else if (internalModeEnabled) {
    dropdown.push({
      content: (
        <RentioInternalRenderer>
          {ts.paymentRequestMarkPaidAction()}
        </RentioInternalRenderer>
      ),
      onClick: handleMarkPaidClick,
    });
  }

  if (user && property && canMarkUnPaid({ paymentRequest, property, user })) {
    dropdown.push({
      content: <TextStyle>{ts.paymentRequestMarkUnPaidAction()}</TextStyle>,
      onClick: handleMarkUnPaidClick,
    });
  } else if (internalModeEnabled) {
    dropdown.push({
      content: (
        <RentioInternalRenderer>
          {ts.paymentRequestMarkUnPaidAction()}
        </RentioInternalRenderer>
      ),
      onClick: handleMarkUnPaidClick,
    });
  }

  if (
    isEditable &&
    [
      EPaymentRequestStatus.New,
      EPaymentRequestStatus.Partial,
      EPaymentRequestStatus.FailedPaidIn,
    ].includes(paymentRequest.status as EPaymentRequestStatus)
  ) {
    dropdown.push({
      content: <TextStyle variation="negative">{ts.remove()}</TextStyle>,
      onClick: handleRemoveWithConfirm,
    });
  }

  if (paymentRequest.externalInvoiceId) {
    dropdown.push({
      content: getLocalizedText("revenues.view_invoice"),
      onClick: () => {
        if (!paymentRequest.externalInvoiceId) {
          return;
        }

        setExternalInvoiceDetailModalId(paymentRequest.externalInvoiceId);
      },
    });
  }

  const renderInfo = () => (
    <PaymentRequestInfo paymentRequest={paymentRequest} setTab={setTab} />
  );
  const renderHistory = () => (
    <PaymentRequestHistory paymentRequest={paymentRequest} setTab={setTab} />
  );

  return (
    <>
      <Page
        title={createTitle({ paymentOrders: getPaymentOrders(paymentRequest) })}
        metadata={metadata}
        tabs={tabs}
        dropdown={dropdown.length > 0 ? (dropdown as IAction[]) : undefined}
        setDocumentTitle={false}
      >
        {tab === EPaymentRequestTabs.Info && renderInfo()}
        {tab === EPaymentRequestTabs.History && renderHistory()}
      </Page>

      {externalInvoiceDetailModalId && (
        <ExternalInvoiceDetailModal
          externalInvoiceId={externalInvoiceDetailModalId}
          onClose={() => {
            setExternalInvoiceDetailModalId(undefined);
          }}
        />
      )}

      {showManuallyMarkPaidModal && (
        <ManuallyMarkPaidModal
          showFromRentRefreshPageCheck={false}
          paymentRequest={paymentRequest}
          onClose={handleManuallyMarkPaidModalClose}
          onSuccess={handleManuallyMarkPaidModalClose}
        />
      )}
    </>
  );
};
