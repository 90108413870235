var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import MuiAutocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
export var AsyncAutoComplete = function (_a) {
    var isLoading = _a.isLoading, customInputValue = _a.customInputValue, placeholder = _a.placeholder, onChange = _a.onChange, loadOptions = _a.loadOptions, _b = _a.options, options = _b === void 0 ? [] : _b, renderOption = _a.renderOption, autoFocus = _a.autoFocus;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = React.useState([]), loadedOptions = _d[0], setLoadedOptions = _d[1];
    var _e = React.useState(null), timeoutId = _e[0], setTimeoutId = _e[1];
    var _f = React.useState(""), inputValue = _f[0], setInputValue = _f[1];
    React.useEffect(function () {
        if (loadOptions && inputValue) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            var id = setTimeout(function () {
                loadOptions(inputValue, setLoadedOptions);
            }, 500);
            setTimeoutId(id);
        }
    }, [inputValue]);
    React.useEffect(function () {
        if (customInputValue) {
            setInputValue(customInputValue);
        }
    }, [customInputValue]);
    var handleInputChange = function (event, value, reason) {
        if (reason === "clear") {
            setLoadedOptions([]);
        }
        setInputValue(value);
    };
    var handleOptionSelect = function (event, value) {
        if (value) {
            onChange(value);
            setLoadedOptions([]);
            setInputValue("");
        }
    };
    return (_jsx(MuiAutocomplete, { freeSolo: true, open: open, onOpen: function () { return setOpen(true); }, onClose: function () { return setOpen(false); }, options: (options === null || options === void 0 ? void 0 : options.length) > 0 ? options : loadedOptions, loading: isLoading, inputValue: inputValue, value: inputValue, onInputChange: handleInputChange, size: "small", fullWidth: true, onChange: handleOptionSelect, renderOption: function (optionProps, option) { return (_jsx(Box, __assign({}, optionProps, { component: "li", children: renderOption ? renderOption(option) : option === null || option === void 0 ? void 0 : option.label }), option === null || option === void 0 ? void 0 : option.value)); }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { variant: "outlined", autoFocus: autoFocus, placeholder: placeholder, InputProps: __assign(__assign({}, params.InputProps), { endAdornment: (_jsxs(_Fragment, { children: [isLoading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))); } }));
};
