import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import {
  EContractIbanisationStatus,
  EContractPeriodTypes,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EPaymentMethod,
  EPaymentOrderOwnerPaymentMethod,
  EPaymentOrderType,
  EPaymentRepetitionType,
  IPaymentOrder,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { endOfDay as endOfDayBase } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import {
  formatDate,
  formatDateAndTime,
  getTerm,
} from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { getTitle } from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import {
  AddressCell,
  Card,
  DataTable,
  EFilterType,
  EmptyState,
  Error,
  Filters,
  Icon,
  Pagination,
  TColumnContentType,
  TextStyle,
  Tooltip,
  TruncateTooltip,
  TSortDirection,
} from "@rentiohq/web-shared/dist/components";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import hash from "object-hash";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { getAmounts } from "scenes/Properties/scenes/Payments/scenes/PaymentsOverview";
import usePreference from "scenes/Settings/hooks/usePreference";
import { NumberParam } from "serialize-query-params";
import * as S from "./PaymentOrders.styles";

interface IProps {}

const columnContentTypes: TColumnContentType[] = [
  "text",
  "text",
  "text",
  "numeric",
  "numeric",
  "icon",
  "text",
  "text",
  "icon",
  "text",
  "text",
];

const orderMap = [
  "createdAt",
  undefined,
  undefined,
  "amount",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
];

const sortable = orderMap.map(x => !!x);

const PREFERENCE_KEY_PAYMENT_ORDERS_QUERY = "PAYMENT_ORDERS_QUERY";
const PREFERENCE_KEY_PAYMENT_ORDERS_TYPES_RECURRING =
  "PAYMENT_ORDERS_TYPES_RECURRING";
const PREFERENCE_KEY_PAYMENT_ORDERS_TYPES_ONE_TIME =
  "PAYMENT_ORDERS_TYPES_ONE_TIME";
const PREFERENCE_KEY_PAYMENT_ORDERS_TYPES_ENDED = "PAYMENT_ORDERS_TYPES_ENDED";
const PREFERENCE_KEY_PAYMENT_ORDERS_PAYMENT_METHODS =
  "PAYMENT_ORDERS_PAYMENT_METHODS";
const PREFERENCE_KEY_PAYMENT_ORDERS_SEPA_MANDATE =
  "PAYMENT_ORDERS_SEPA_MANDATE";
const PREFERENCE_KEY_PAYMENT_ORDERS_FROM_RENT = "PAYMENT_ORDERS_FROM_RENT";
const PREFERENCE_KEY_PAYMENT_ORDERS_CONTRACT_PERIOD =
  "PAYMENT_ORDERS_CONTRACT_PERIOD";

enum EExtraPaymentOptions {
  CombinedSepa = "combined_sepa",
  CombinedOnline = "combined_online",
  FromRent = "from_rent",
}

enum ESepaMandateOptions {
  NonRecurring = "non_recurring",
  Recurring = "recurring",
}

const SEPA_PAYMENT_METHODS = [
  EPaymentMethod.Sepa,
  EPaymentMethod.SepaOnce,
  EPaymentMethod.SepaRecurring,
];

const ONLINE_PAYMENT_METHODS = [
  EPaymentMethod.Bancontact,
  EPaymentMethod.Payconiq,
  EPaymentMethod.Ideal,
];

const RECURRING_PAYMENT_TYPES = [
  EPaymentOrderType.Rent,
  EPaymentOrderType.CommonCosts,
  EPaymentOrderType.NonCommonCosts,
  EPaymentOrderType.ManagementCosts,
  EPaymentOrderType.Rentio,
  EPaymentOrderType.OtherRecurring,
];

const ONE_TIME_PAYMENT_TYPES = [
  EPaymentOrderType.RentDeposit,
  EPaymentOrderType.LocationDescription,
  EPaymentOrderType.BrokerFee,
  EPaymentOrderType.OtherOneoff,
];

const ENDED_PAYMENT_TYPES = [
  EPaymentOrderType.RentDepositPayout,
  EPaymentOrderType.RentDiscount,
];

const PaymentOrdersBase = (props: IProps) => {
  const dispatch = useDispatch();

  const [, setQueryParamValue] = useQueryParams({
    paymentOrderId: NumberParam,
  });

  const [order, setOrder] = React.useState<{
    field: string;
    method: TSortDirection;
  }>({
    field: "createdAt",
    method: "DESC",
  });
  const [page, setPage] = useState<number>(1);

  const [query = "", setQuery] = usePreference<string>({
    preferenceKey: PREFERENCE_KEY_PAYMENT_ORDERS_QUERY,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [paymentTypesRecurring = [], setPaymentTypesRecurring] = usePreference<
    EPaymentOrderType[]
  >({
    preferenceKey: PREFERENCE_KEY_PAYMENT_ORDERS_TYPES_RECURRING,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [paymentTypesOneTime = [], setPaymentTypesOneTime] = usePreference<
    EPaymentOrderType[]
  >({
    preferenceKey: PREFERENCE_KEY_PAYMENT_ORDERS_TYPES_ONE_TIME,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [paymentTypesEnded = [], setPaymentTypesEnded] = usePreference<
    EPaymentOrderType[]
  >({
    preferenceKey: PREFERENCE_KEY_PAYMENT_ORDERS_TYPES_ENDED,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [paymentMethods = [], setPaymentMethods] = usePreference<
    (EPaymentMethod | EExtraPaymentOptions)[]
  >({
    preferenceKey: PREFERENCE_KEY_PAYMENT_ORDERS_PAYMENT_METHODS,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [sepaMandate = [], setSepaMandate] = usePreference<
    ESepaMandateOptions[]
  >({
    preferenceKey: PREFERENCE_KEY_PAYMENT_ORDERS_SEPA_MANDATE,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [fromRent = [], setFromRent] = usePreference<
    EPaymentOrderOwnerPaymentMethod[]
  >({
    preferenceKey: PREFERENCE_KEY_PAYMENT_ORDERS_FROM_RENT,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [contractPeriod = [], setContractPeriod] = usePreference<string[]>({
    preferenceKey: PREFERENCE_KEY_PAYMENT_ORDERS_CONTRACT_PERIOD,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const filter = (() => {
    const endOfDay = endOfDayBase(new Date());

    const result: { [key: string]: any } = {
      order: `${order.field} ${order.method}`,
      where: {
        completedAt: null,
      },
    };

    if (paymentMethods && paymentMethods.length > 0) {
      let methods = [...paymentMethods];

      const combinedSepaIndex = methods.findIndex(
        m => m === EExtraPaymentOptions.CombinedSepa,
      );

      if (combinedSepaIndex !== -1) {
        methods.splice(combinedSepaIndex, 1);
        methods = [...methods, ...SEPA_PAYMENT_METHODS];
      }

      const combinedOnlineIndex = methods.findIndex(
        m => m === EExtraPaymentOptions.CombinedOnline,
      );

      if (combinedOnlineIndex !== -1) {
        methods.splice(combinedOnlineIndex, 1);
        methods = [...methods, ...ONLINE_PAYMENT_METHODS];
      }

      const ibanisationIndex = methods.findIndex(
        m => m === EPaymentMethod.Ibanisation,
      );

      if (ibanisationIndex !== -1) {
        methods.splice(ibanisationIndex, 1);
        result.where = {
          ...result.where,
          ibanisation: true,
        };
      }

      if (methods && methods.length > 0) {
        result.where = {
          ...result.where,
          paymentMethods: { inq: methods },
        };
      }
    }

    if (fromRent && fromRent.length > 0) {
      result.where = {
        ...result.where,
        ownerPaymentMethod:
          fromRent[0] === EPaymentOrderOwnerPaymentMethod.FromRent
            ? EPaymentOrderOwnerPaymentMethod.FromRent
            : EPaymentOrderOwnerPaymentMethod.Direct,
      };
    }

    if (contractPeriod && contractPeriod.length > 0) {
      const period = contractPeriod[0];

      let periodFilter = {};
      if (period === EContractPeriodTypes.Current) {
        periodFilter = {
          contractStopDate: { gte: endOfDay },
          contractStartDate: { lte: endOfDay },
        };
      } else if (period === EContractPeriodTypes.Past) {
        periodFilter = {
          contractStopDate: { lt: endOfDay },
        };
      } else if (period === EContractPeriodTypes.Future) {
        periodFilter = {
          contractStartDate: { gt: endOfDay },
        };
      }

      result.where = {
        ...result.where,
        ...periodFilter,
      };
    }

    if (sepaMandate && sepaMandate.length > 0) {
      let mandate: boolean | undefined = undefined;
      let sepaFilterSelection = sepaMandate[0];
      if (sepaFilterSelection === ESepaMandateOptions.NonRecurring) {
        mandate = false;
      } else if (sepaFilterSelection === ESepaMandateOptions.Recurring) {
        mandate = true;
      }

      if (mandate !== undefined) {
        result.where = {
          ...result.where,
          mandate,
        };
      }
    }

    let selectedPaymentTypes: EPaymentOrderType[] = [];
    if (paymentTypesRecurring && paymentTypesRecurring.length > 0) {
      selectedPaymentTypes = [
        ...selectedPaymentTypes,
        ...paymentTypesRecurring,
      ];
    }
    if (paymentTypesOneTime && paymentTypesOneTime.length > 0) {
      selectedPaymentTypes = [...selectedPaymentTypes, ...paymentTypesOneTime];
    }
    if (paymentTypesEnded && paymentTypesEnded.length > 0) {
      selectedPaymentTypes = [...selectedPaymentTypes, ...paymentTypesEnded];
    }

    if (selectedPaymentTypes.length > 0) {
      result.where = {
        ...result.where,
        type: { inq: selectedPaymentTypes },
      };
    }

    return result;
  })();

  const [debouncedQuery] = useDebounce(query);

  const identifier = `payment-orders-${hash({ filter, debouncedQuery })}`;

  const countBase = append("/payment-orders/count", {
    where: filter.where,
    search: debouncedQuery,
  });

  const { count: paymentOrdersCount } = useCount({
    shouldRefetch: true,
    countBase,
  });

  const pageCount = paymentOrdersCount
    ? Math.ceil(paymentOrdersCount / CONFIG.DEFAULT_FETCH_LIMIT)
    : 0;

  const isFetching = useSelector((state: IRootStore) =>
    paymentSelectors.isFetchingPaymentOrdersForPage(state, identifier, page),
  );

  const fetchError = useSelector((state: IRootStore) =>
    paymentSelectors.paymentOrdersForPageFetchError(state, identifier, page),
  );

  const paymentOrders = useSelector((state: IRootStore) =>
    paymentSelectors.getPaymentOrdersForPage(state, identifier, page),
  );

  useEffect(() => {
    if (isFetching) {
      return;
    }

    dispatch(
      paymentActions.getPaymentOrdersPaged.actions.start({
        limit: CONFIG.DEFAULT_FETCH_LIMIT,
        page,
        identifier,
        filterData: { ...filter, search: debouncedQuery },
      }),
    );
  }, [identifier, page]);

  const handleSort = (headingIndex: number, direction: TSortDirection) => {
    const sortItem = orderMap[headingIndex];
    if (!sortItem) {
      return;
    }

    setOrder({ field: sortItem, method: direction });
  };

  const getPaymentMethod = ({
    ownerPaymentMethod,
    paymentGroup,
    contract,
  }: IPaymentOrder) => {
    let allMethods: string[] = [];

    if (ownerPaymentMethod === EPaymentOrderOwnerPaymentMethod.FromRent) {
      allMethods.push(
        getLocalizedText("payment_order.payment_method.from_rent.short"),
      );
    } else {
      if (
        contract &&
        (contract.ibanisationStatus === EContractIbanisationStatus.Enabled ||
          contract.ibanisationStatus === EContractIbanisationStatus.Enabling)
      ) {
        allMethods.push(
          getLocalizedText("payment_order.payment_method.ibanisation"),
        );
      }
      if (paymentGroup?.preferredPaymentMethods) {
        const preferredMethods = [
          ...new Set(
            // eslint-disable-next-line array-callback-return
            paymentGroup?.preferredPaymentMethods.sort().map(paymentMethod => {
              if (
                [
                  EPaymentMethod.Bancontact,
                  EPaymentMethod.Ideal,
                  EPaymentMethod.Payconiq,
                ].includes(paymentMethod)
              ) {
                return "online_payment";
              }

              if (
                [EPaymentMethod.Sepa, EPaymentMethod.SepaOnce].includes(
                  paymentMethod,
                )
              ) {
                return "one_time_sepa";
              }

              if (EPaymentMethod.SepaRecurring === paymentMethod) {
                return "continuous_sepa";
              }

              if (EPaymentMethod.BankWire === paymentMethod) {
                return "bank_transfer";
              }
            }),
          ),
        ];
        allMethods = [
          ...allMethods,
          ...preferredMethods.map(p =>
            getLocalizedText(`payment_order.payment_method.${p}`),
          ),
        ];
      }
    }
    return allMethods.join(" / ");
  };

  const handleQueryChange = (newQuery: string) => {
    setPage(1);
    setQuery(newQuery);
  };

  const handleQueryClear = () => {
    setQuery("");
  };

  const renderFilter = () => (
    <Filters
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryClear}
      queryValue={query}
      queryPlaceholder={getLocalizedText(
        "payment_orders.filters.query.placeholder",
      )}
      filterConfigs={[
        {
          label: getLocalizedText("payment.payment_order.type.step"),
          groupKey: "type",
          filters: [
            {
              label: getLocalizedText(
                "payment.payment_order.repetition_type.recurring",
              ),
              filterKey: "recurring_payments",
              type: EFilterType.MultiSelect,
              values: paymentTypesRecurring,
              options: RECURRING_PAYMENT_TYPES,
              onChange: recurringPaymentTypes => {
                setPage(1);
                setPaymentTypesRecurring([...recurringPaymentTypes]);
              },
              onRemove: (key: string) =>
                setPaymentTypesRecurring(
                  (paymentTypesRecurring || []).filter(v => v !== key),
                ),
              translate: (value: any) => {
                return getLocalizedText(
                  `payment.payment_order.type.${value.toLowerCase()}`,
                );
              },
            },
            {
              label: getLocalizedText(
                "payment.payment_order.repetition_type.once",
              ),
              filterKey: "one_time_payments",
              type: EFilterType.MultiSelect,
              values: paymentTypesOneTime,
              options: ONE_TIME_PAYMENT_TYPES,
              onChange: oneTimePaymentTypes => {
                setPage(1);
                setPaymentTypesOneTime([...oneTimePaymentTypes]);
              },
              onRemove: (key: string) =>
                setPaymentTypesOneTime(
                  (paymentTypesOneTime || []).filter(v => v !== key),
                ),
              translate: (value: any) => {
                return getLocalizedText(
                  `payment.payment_order.type.${value.toLowerCase()}`,
                );
              },
            },
            {
              label: getLocalizedText("payments.overview_inactive.heading"),
              filterKey: "ended_payments",
              type: EFilterType.MultiSelect,
              values: paymentTypesEnded,
              options: ENDED_PAYMENT_TYPES,
              onChange: endedPaymentTypes => {
                setPage(1);
                setPaymentTypesEnded([...endedPaymentTypes]);
              },
              onRemove: (key: string) =>
                setPaymentTypesEnded(
                  (paymentTypesEnded || []).filter(v => v !== key),
                ),
              translate: (value: any) => {
                return getLocalizedText(
                  `payment.payment_order.type.${value.toLowerCase()}`,
                );
              },
            },
          ],
        },
        {
          label: getLocalizedText(
            "payment_request.follow_up.filter.features.label",
          ),
          groupKey: "payment_via",
          filters: [
            {
              label: getLocalizedText("system.payment_methods"),
              filterKey: "payment_via",
              type: EFilterType.MultiSelect,
              values: paymentMethods,
              options: [
                EExtraPaymentOptions.CombinedSepa,
                EExtraPaymentOptions.CombinedOnline,
                EPaymentMethod.Ibanisation,
              ],
              onChange: selectedPaymentMethods => {
                setPage(1);
                setPaymentMethods([...selectedPaymentMethods]);
              },
              onRemove: (key: string) =>
                setPaymentMethods(
                  (paymentMethods || []).filter(v => v !== key),
                ),
              translate: (value: any) =>
                getLocalizedText(
                  `payment_order.payment_method.${value.toLowerCase()}`,
                ),
            },
            {
              label: getLocalizedText("payment_orders.filter.withhold"),
              // groupKey: "from_rent",
              filterKey: "from_rent",
              type: EFilterType.SingleSelect,
              sort: false,
              options: [
                EPaymentOrderOwnerPaymentMethod.FromRent,
                EPaymentOrderOwnerPaymentMethod.Direct,
              ],
              values: fromRent,
              onChange: (value: any) => {
                setFromRent([...value]);
              },
              onRemove: () => {
                setFromRent([]);
              },
              translate: (value: any) => {
                return getLocalizedText(
                  `payment.payment_order.owner_payment_method.${value.toLowerCase()}`,
                );
              },
            },
          ],
        },
        {
          label: getLocalizedText("payment_orders.contract_period"),
          groupKey: "contract_period",
          filters: [
            {
              filterKey: "quick_filters",
              type: EFilterType.SingleSelect,
              options: [
                EContractPeriodTypes.Past,
                EContractPeriodTypes.Current,
                EContractPeriodTypes.Future,
              ],
              values: contractPeriod,
              onRemove: () => {
                setContractPeriod([]);
              },
              onChange: (values: any) => {
                setPage(1);
                setContractPeriod([...values]);
              },
              translate: (value: any) => {
                const contract = value as string;
                return getLocalizedText(
                  `contracts.overview.period.${contract.toLowerCase()}`,
                );
              },
            },
            /* {
              label: getLocalizedText("filter.month_range.starting_months"),
              filterKey: "staring_months",
              type: EFilterType.MonthRange,
              translate: (value: any) =>
                translateDateRange(value, "filter.month_range.starting_months"),
              onRemove: () => {
                setStartDateRange(undefined);
              },
              onChange: range => setStartDateRange(range),
              values: startDateRange,
            },
            {
              label: getLocalizedText("filter.month_range.ending_months"),
              filterKey: "ending_months",
              type: EFilterType.MonthRange,
              translate: (value: any) =>
                translateDateRange(value, "filter.month_range.ending_months"),
              onRemove: () => {
                setEndDateRange(undefined);
              },
              onChange: range => setEndDateRange(range),
              values: endDateRange,
            }, */
          ],
        },
        {
          label: getLocalizedText("payment_method.visual.title.sepa"),
          groupKey: "sepa_mandate",
          filters: [
            {
              filterKey: "sepa_mandate",
              type: EFilterType.SingleSelect,
              sort: false,
              options: [
                ESepaMandateOptions.NonRecurring,
                ESepaMandateOptions.Recurring,
              ],
              values: sepaMandate,
              onRemove: () => {
                setSepaMandate([]);
              },
              onChange: (values: any) => {
                setPage(1);
                setSepaMandate([...values]);
              },
              translate: (value: any) => {
                const contract = value as string;
                return getLocalizedText(
                  `payment_orders.sepa_mandate.${contract}`,
                );
              },
            },
          ],
        },
      ]}
    />
  );

  return (
    <Card
      heading={getLocalizedText("payments.follow_up.payment_orders.heading")}
    >
      {renderFilter()}

      <Spacer weight={ESpacerWeight.W16} />

      {fetchError && !isFetching && !paymentOrders ? (
        <Error errors={[fetchError]} />
      ) : (
        <DataTable
          isLoadingData={isFetching}
          horizontalSpacing="extraTight"
          emptyState={
            <EmptyState
              backgroundColor="gray"
              heading={getLocalizedText("payment_orders.overview.empty")}
            />
          }
          columnContentTypes={columnContentTypes}
          headings={[
            getLocalizedText("system.created"),
            getLocalizedText("contact.details.address"),
            getLocalizedText("system.pay_in"),
            getLocalizedText("payment_orders.amount"),
            getLocalizedText("system.vat.exclusive"),
            getLocalizedText("contract.length_type.option.label.short"),
            getLocalizedText("payment_orders.contract_period"),
            getLocalizedText(
              "contract_info.add.payment_orders.payer.heading.summary",
            ),
            <Icon source="mandateGiven" />,
            getLocalizedText(
              "contract_info.add.payment_orders.payee.heading.summary",
            ),
            "",
          ]}
          sortColumnIndex={orderMap.indexOf(order.field)}
          sortDirection={order.method}
          sortable={sortable}
          onSort={handleSort}
          onRowClick={rowIndex => {
            setQueryParamValue({
              paymentOrderId: paymentOrders?.[rowIndex].id,
            });
          }}
          rows={
            paymentOrders?.map(paymentOrder => {
              const {
                createdAt,
                repetitionType,
                payer,
                payee,
                propertyAddress: address,
                propertyId,
                mandate,
              } = paymentOrder;

              const isRecurring =
                repetitionType !== EPaymentRepetitionType.Once;

              const fullAmount = getAmounts(paymentOrder).amountWithVat;
              const amountWithoutVat = getAmounts(paymentOrder).amount;

              return {
                id: paymentOrder.id,
                content: [
                  formatDateAndTime(createdAt),

                  <AddressCell propertyId={propertyId} address={address} />,

                  <TruncateTooltip width={130}>
                    {getTitle(paymentOrder)}
                  </TruncateTooltip>,

                  <S.FixedCell>{formatCurrency(fullAmount)}</S.FixedCell>,

                  <S.FixedCell>
                    {amountWithoutVat ? formatCurrency(amountWithoutVat) : "-"}
                  </S.FixedCell>,

                  <Tooltip
                    tooltipContent={getLocalizedText(
                      `payment.payment_order.repetition_type.${
                        isRecurring ? "recurring" : "once"
                      }`,
                    )}
                  >
                    <Icon
                      source={isRecurring ? "synchronizeArrows" : "calendar"}
                      color="neutral70"
                    />
                  </Tooltip>,

                  <div>
                    {paymentOrder.contract
                      ? getTerm(paymentOrder.contract, {
                          dateFormatter: formatDate,
                        })
                      : "-"}
                  </div>,

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <TruncateTooltip width={120}>
                      {payer ? getName(payer) : "-"}
                    </TruncateTooltip>
                    <TextStyle variation="subdued">
                      {getPaymentMethod(paymentOrder)}
                    </TextStyle>
                  </div>,

                  <div>
                    {mandate ? (
                      <Tooltip
                        tooltipContent={getLocalizedText(
                          "bank_account.mandate.approved_at",
                          { date: formatDate(mandate.approvedAt) },
                        )}
                      >
                        <Icon source="mandateGiven" color="primary" />
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </div>,

                  <TruncateTooltip width={120}>
                    {payee ? getName(payee) : "-"}
                  </TruncateTooltip>,
                ],
                actions: [
                  {
                    content: getLocalizedText(
                      "payment_order.view_detail.action",
                    ),
                    onClick: () => {
                      setQueryParamValue({
                        paymentOrderId: paymentOrder.id,
                      });
                    },
                  },
                ],
              };
            }) || []
          }
        />
      )}

      {paymentOrdersCount && paymentOrdersCount > CONFIG.DEFAULT_FETCH_LIMIT ? (
        <Pagination
          initialPage={page ? page - 1 : 0}
          pageCount={pageCount}
          onPageChange={({ selected }) => setPage(selected + 1)}
        />
      ) : null}
    </Card>
  );
};

export const PaymentOrders = memo(PaymentOrdersBase);
