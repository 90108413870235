var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { sum } from "lodash";
import { getPagedId as getPagedIdBase, } from "../../redux/generic/paged.utils";
import { EContractLengthType, EContractPaymentRepetitionType, EContractType, } from "../../types/contract.types";
import { isEndedContract } from "../../utils/contract.utils";
import { addDays, addMonths, addQuarters, endOfMonth, addYears, endOfDay, isSameDay, startOfMonth, startOfQuarter, startOfYear, subDays, subMonths, } from "../../utils/date-fns.utils";
import { formatDateWithCustomClosebyFormat } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { formatCurrency, getVat } from "../../utils/number.utils";
import { EContractFetchType } from "./contract.types";
export var getRenewalDeadline = function (contract) {
    var stopDate = contract.stopDate, contractLengthType = contract.contractLengthType;
    if (!stopDate) {
        return;
    }
    switch (contractLengthType) {
        case EContractLengthType.Short:
            return subDays(subMonths(addDays(stopDate, 1), 3), 1);
        case EContractLengthType.Long:
            return subDays(subMonths(addDays(stopDate, 1), 6), 1);
        default:
            return;
    }
};
export var getNewDeadlineAfterTacitRenewal = function (contract) {
    var startDate = contract.startDate, stopDate = contract.stopDate, tacitRenewal = contract.tacitRenewal;
    if (!stopDate || !tacitRenewal) {
        return;
    }
    if (isSameDay(addYears(startDate, 1), addDays(stopDate, 1))) {
        return addYears(stopDate, 9 - 1);
    }
    if (isSameDay(addYears(startDate, 2), addDays(stopDate, 1))) {
        return addYears(stopDate, 9 - 2);
    }
    if (isSameDay(addYears(startDate, 3), addDays(stopDate, 1))) {
        return addYears(stopDate, 9 - 3);
    }
    for (var index = 0; index <= 10; index++) {
        if (isSameDay(addYears(startDate, 9 + index * 3), addDays(stopDate, 1))) {
            return addYears(stopDate, 3);
        }
    }
};
export var getRenewalInfo = function (contract) {
    if (isEndedContract(contract)) {
        return;
    }
    var renewalDeadline = getRenewalDeadline(contract);
    var newDeadlineAfterTacitRenewal = getNewDeadlineAfterTacitRenewal(contract);
    if (!renewalDeadline || !newDeadlineAfterTacitRenewal) {
        return;
    }
    return getLocalizedText("contract.tacit_renewal.new_dates_info", {
        renewalDate: formatDateWithCustomClosebyFormat(addDays(renewalDeadline, 1), "d MMM yyyy"),
        newEndDate: formatDateWithCustomClosebyFormat(newDeadlineAfterTacitRenewal, "d MMM yyyy"),
    });
};
export var isValidStopDateForTacitRenewal = function (params) {
    var startDate = params.startDate, stopDate = params.stopDate;
    if (!startDate || !stopDate) {
        return undefined;
    }
    return !!__spreadArray([
        addYears(startDate, 1),
        addYears(startDate, 2),
        addYears(startDate, 3),
        addYears(startDate, 9)
    ], Array(30)
        .fill(0)
        .map(function (_, index) { return addYears(startDate, 9 + (index + 1) * 3); }), true).find(function (x) { return isSameDay(addDays(stopDate, 1), x); });
};
export var getProRataBaseEndDate = function (startDate, contract, includingExtraDay) {
    if (includingExtraDay === void 0) { includingExtraDay = false; }
    if (!contract) {
        return startDate;
    }
    // Get followup
    var followUp = contract.repetitionType || EContractPaymentRepetitionType.Monthly;
    var result = startDate;
    if (followUp === EContractPaymentRepetitionType.Monthly) {
        result = addMonths(startOfMonth(startDate), 1);
    }
    else if (followUp === EContractPaymentRepetitionType.Quarterly) {
        result = addQuarters(startOfQuarter(startDate), 1);
    }
    else if (followUp === EContractPaymentRepetitionType.Yearly) {
        result = addYears(startOfYear(startDate), 1);
    }
    if (includingExtraDay) {
        return result;
    }
    // After adding a month the final day is in the next period.
    // Subtract 1 day to get the last day of the current pro-rata period.
    return subDays(result, 1);
};
export var getPagedId = function (params) {
    return getPagedIdBase(__assign(__assign({}, params), { entityName: "contract" }));
};
export var contractIndexGetIncrease = function (params) {
    var _a;
    var contract = params.contract, indexInfo = params.indexInfo, customNewPrice = params.customNewPrice;
    var currentPrice = contract.currentPrice;
    if (customNewPrice) {
        var base = 100;
        return (customNewPrice * base - currentPrice * base) / base;
    }
    return ((_a = getIndexInfoAvailable(indexInfo)) === null || _a === void 0 ? void 0 : _a.increase) || 0;
};
export var contractIndexGetNewPrice = function (params) {
    var _a;
    var contract = params.contract, indexInfo = params.indexInfo;
    var currentPrice = contract.currentPrice;
    return currentPrice + (((_a = getIndexInfoAvailable(indexInfo)) === null || _a === void 0 ? void 0 : _a.increase) || 0);
};
export var contractIndexGetMinNewPrice = function (contract) {
    var currentPrice = contract.currentPrice;
    return currentPrice;
};
export var getProRataExtraMonthEndDate = function (startDate, contract) {
    // Get followup
    var followUp = EContractPaymentRepetitionType.Monthly;
    if (contract === null || contract === void 0 ? void 0 : contract.repetitionType) {
        followUp = contract.repetitionType;
    }
    var base = getProRataBaseEndDate(startDate, contract, false);
    if (followUp === EContractPaymentRepetitionType.Quarterly) {
        return endOfMonth(addQuarters(base, 1));
    }
    if (followUp === EContractPaymentRepetitionType.Yearly) {
        return endOfMonth(addYears(base, 1));
    }
    return endOfMonth(addMonths(base, 1));
};
export var getIdentifierForProperty = function (propertyId, type) { return "property-".concat(type, "-").concat(propertyId).toLowerCase(); };
export var getFilterForProperty = function (propertyId, type) {
    switch (type) {
        case EContractFetchType.Active:
            return {
                and: [
                    {
                        contractType: { eq: EContractType.Basic },
                    },
                    {
                        propertyId: { eq: propertyId },
                    },
                    {
                        stopDate: {
                            gt: endOfDay(new Date()),
                        },
                    },
                ],
            };
        case EContractFetchType.Ended:
            return {
                and: [
                    {
                        contractType: { eq: EContractType.Basic },
                    },
                    {
                        propertyId: { eq: propertyId },
                    },
                    {
                        stopDate: {
                            lte: endOfDay(new Date()),
                        },
                    },
                ],
            };
        default:
            return {
                propertyId: propertyId,
            };
    }
};
export var getIndexInfoAvailable = function (indexInfo) {
    if (!indexInfo || (indexInfo === null || indexInfo === void 0 ? void 0 : indexInfo.error)) {
        return;
    }
    return indexInfo;
};
export var getContractCost = function (contract) {
    var _a = contract.currentPrice, currentPrice = _a === void 0 ? 0 : _a, _b = contract.priceVat, priceVat = _b === void 0 ? 0 : _b, _c = contract.currentCostsCommon, currentCostsCommon = _c === void 0 ? 0 : _c, _d = contract.costsCommonVat, costsCommonVat = _d === void 0 ? 0 : _d, _e = contract.currentCostsNonCommon, currentCostsNonCommon = _e === void 0 ? 0 : _e, _f = contract.costsNonCommonVat, costsNonCommonVat = _f === void 0 ? 0 : _f, _g = contract.amount, amount = _g === void 0 ? 0 : _g, contractType = contract.contractType, repetitionType = contract.repetitionType;
    switch (contractType) {
        case EContractType.RentDeposit:
            return formatCurrency(amount !== null && amount !== void 0 ? amount : 0);
        case EContractType.Basic:
        default: {
            var totalVatAmount = sum([
                getVat(currentPrice, priceVat / 100),
                getVat(currentCostsCommon, costsCommonVat / 100),
                getVat(currentCostsNonCommon, costsNonCommonVat / 100),
            ]);
            var amountWithoutVat = formatCurrency(sum([currentPrice, currentCostsCommon, currentCostsNonCommon]));
            var total = totalVatAmount > 0
                ? getLocalizedText("system.contract.amount_with_vat", {
                    amount: amountWithoutVat,
                    vat: formatCurrency(totalVatAmount),
                })
                : amountWithoutVat;
            var result = [
                getLocalizedText("system.contract.rent", {
                    amount: formatCurrency(currentPrice),
                }),
            ];
            if (currentCostsCommon) {
                result.push(getLocalizedText("system.contract.common_costs", {
                    cost: formatCurrency(currentCostsCommon),
                }));
            }
            if (currentCostsNonCommon) {
                result.push(getLocalizedText("system.contract.non_common_costs", {
                    cost: formatCurrency(currentCostsNonCommon),
                }));
            }
            var frequency = (repetitionType || EContractPaymentRepetitionType.Monthly).toLowerCase();
            return result.length > 1
                ? "".concat(result.join(" + "), " (").concat(getLocalizedText("system.total"), " = ").concat(total, ") ").concat(frequency)
                : "".concat(total, " ").concat(frequency);
        }
    }
};
