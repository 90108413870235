import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { EContactType, } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SpinningLoader } from "../SpinningLoader";
import { ContactFetchCheckListItemStyles } from "./ContactFetchCheckListItem.styles";
export var ContactFetchCheckListItem = function (_a) {
    var _b = _a.checked, checked = _b === void 0 ? true : _b, contactId = _a.contactId, getSubtitle = _a.getSubtitle, handleSelectContact = _a.handleSelectContact;
    var sx = ContactFetchCheckListItemStyles(checked);
    var dispatch = useDispatch();
    var isFetching = useSelector(function (state) {
        return contactSelectors.getContactIsFetching(state, undefined, contactId);
    });
    var contact = useSelector(function (state) {
        return contactSelectors.getContact(state, undefined, contactId);
    });
    React.useEffect(function () {
        if (contactId && !contact && !isFetching) {
            dispatch(contactActions.getContactsByAccountIds.actions.start({
                accountIds: [contactId],
            }));
        }
    }, [contactId, contact, isFetching]);
    if (!contact) {
        return null;
    }
    var _c = ((getSubtitle === null || getSubtitle === void 0 ? void 0 : getSubtitle(contact)) || {}).subTitle, subTitle = _c === void 0 ? "" : _c;
    if (isFetching) {
        return _jsx(SpinningLoader, {});
    }
    return (_jsxs(Stack, { direction: "row", gap: 1, sx: sx.wrapper, onClick: function () { return handleSelectContact(contactId); }, children: [_jsx(Checkbox, { checked: checked }), _jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle1", lineHeight: "16px", sx: sx.content.title, children: getName(contact) }), _jsx(Stack, { direction: "row", gap: 1, alignItems: "center", children: _jsx(Typography, { variant: "caption", children: subTitle && contact.type === EContactType.Personal
                                ? getLocalizedText("system.yourself")
                                : getLocalizedText("system.contact") }) })] })] }));
};
