var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { FontWeights, spacing } from "@rentiohq/web-theme";
import { rem, transparentize } from "polished";
export var StylesQuickActionCard = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  ", ";\n  padding: ", ";\n  cursor: ", ";\n  width: 100%;\n  height: 100%;\n  flex-grow: 1;\n"], ["\n  background: ", ";\n  ", ";\n  padding: ", ";\n  cursor: ", ";\n  width: 100%;\n  height: 100%;\n  flex-grow: 1;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
}, function (_a) {
    var theme = _a.theme;
    return "box-shadow: 0 2px 8px ".concat(transparentize(0.8, theme.colors.neutral80), ";");
}, spacing("loose"), function (_a) {
    var onClick = _a.onClick;
    return (onClick ? "pointer" : "auto");
});
export var QuickActionCardHeaderContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n"])));
export var QuickActionCardHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  font-size: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem("18px", theme.baseFontSize);
});
export var QuickActionCardSubheader = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary[800];
}, function (_a) {
    var theme = _a.theme;
    return rem("14px", theme.baseFontSize);
});
export var QuickActionCount = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem("40px", theme.baseFontSize);
}, FontWeights.Bold);
export var QuickActionCountDescription = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem("12px", theme.baseFontSize);
}, FontWeights.Bold, function (_a) {
    var theme = _a.theme;
    return theme.colors.gray[400];
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
