import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import { mapFormFieldToNullableBooleanValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IUpdateProperty } from "@rentiohq/shared-frontend/dist/reduxV2/property";
import * as propertyActions from "@rentiohq/shared-frontend/dist/reduxV2/property/property.actions";
import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { EAN_PREFIX } from "forms/requestMoveService/schema.requestMoveService.config";
import {
  EExtraInfoGroup,
  EField,
  IExtraData,
} from "./schema.editPropertyExtraInfo.types";

export const isPerformingSubmitActionSelector =
  (propertyId: number) => (state: IPartialRootState) =>
    propertySelectors.isUpdating(state, propertyId);

export const performingSubmitActionErrorSelector =
  (propertyId: number) => (state: IPartialRootState) =>
    propertySelectors.updateError(state, propertyId);

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { property } = extraData;

    const updateProperty: IUpdateProperty = {};

    switch (extraData.extraInfoGroup) {
      case EExtraInfoGroup.General:
        updateProperty.constructionYear =
          formData[EField.ConstructionYear] ?? null;
        break;

      case EExtraInfoGroup.Epc:
        updateProperty.epcLabel = formData[EEpcField.Label] ?? null;
        updateProperty.epcValue = formData[EEpcField.Value] ?? null;
        updateProperty.epcExpirationDate =
          formData[EEpcField.ExpirationDate] ?? null;
        updateProperty.epcCertificateId =
          formData[EEpcField.CertificateId] ?? null;
        break;

      case EExtraInfoGroup.Bookkeeping:
        updateProperty.costUnit = formData[EField.CostUnit] ?? null;
        updateProperty.bookkeepingPaidOutStartDate =
          formData[EField.BookkeepingPaidOutStartDate] ?? null;
        break;

      case EExtraInfoGroup.BookkeepingGroup:
        updateProperty.costCenter = formData[EField.CostCenter] ?? null;
        break;

      case EExtraInfoGroup.Electricity:
        updateProperty.electricityType =
          formData[EField.ElectricityType] ?? null;

        const electricityEan = formData[EField.ElectricityEan];
        let parsedElectricityEan = null;
        if (electricityEan) {
          parsedElectricityEan = `${EAN_PREFIX}${electricityEan}`;
        }
        updateProperty.electricityEan = parsedElectricityEan;

        updateProperty.electricityMeterNumberBoth =
          formData[EField.ElectricityMeterNumberBoth] ?? null;
        updateProperty.electricityMeterType =
          formData[EField.ElectricityMeterType] ?? null;
        break;

      case EExtraInfoGroup.NaturalGas:
        updateProperty.naturalGasAvailable =
          formData[EField.NaturalGasAvailable] ?? null;

        const naturalGasEan = formData[EField.NaturalGasEan];
        let parsedNaturalGasEan = null;
        if (naturalGasEan) {
          parsedNaturalGasEan = `${EAN_PREFIX}${naturalGasEan}`;
        }
        updateProperty.naturalGasEan = parsedNaturalGasEan;

        updateProperty.naturalGasMeterNumber =
          formData[EField.NaturalGasMeterNumber] ?? null;
        updateProperty.naturalGasMeterType =
          formData[EField.NaturalGasMeterType] ?? null;
        break;

      case EExtraInfoGroup.Water:
        updateProperty.waterMeterNumber =
          formData[EField.WaterMeterNumber] ?? null;
        break;

      case EExtraInfoGroup.Exterior:
        updateProperty.buildingAdjoined =
          formData[EField.BuildingAdjoined] ?? null;
        updateProperty.buildingSurfaceArea =
          formData[EField.BuildingSurfaceArea] ?? null;
        updateProperty.buildingGardenSurfaceArea =
          formData[EField.BuildingGardenSurfaceArea] ?? null;
        updateProperty.buildingTerraceSurfaceArea =
          formData[EField.BuildingTerraceSurfaceArea] ?? null;
        break;

      case EExtraInfoGroup.Interior:
        updateProperty.buildingFloor = formData[EField.BuildingFloor] ?? null;
        updateProperty.buildingFloorCount =
          formData[EField.BuildingFloorCount] ?? null;
        updateProperty.roomCountBedroom =
          formData[EField.RoomCountBedroom] ?? null;
        updateProperty.roomCountBathroom =
          formData[EField.RoomCountBathroom] ?? null;
        updateProperty.roomCountGarage =
          formData[EField.RoomCountGarage] ?? null;
        updateProperty.buildingHasStorage = mapFormFieldToNullableBooleanValue(
          formData[EField.BuildingHasStorage],
        );
        updateProperty.isFurnished = mapFormFieldToNullableBooleanValue(
          formData[EField.IsFurnished],
        );
        break;

      case EExtraInfoGroup.Amenities:
        updateProperty.hasOven = mapFormFieldToNullableBooleanValue(
          formData[EField.HasOven],
        );
        updateProperty.hasFridge = mapFormFieldToNullableBooleanValue(
          formData[EField.HasFridge],
        );
        updateProperty.hasDishwasher = mapFormFieldToNullableBooleanValue(
          formData[EField.HasDishWasher],
        );
        updateProperty.hasRangeHood = mapFormFieldToNullableBooleanValue(
          formData[EField.HasRangeHood],
        );
        updateProperty.hasLift = mapFormFieldToNullableBooleanValue(
          formData[EField.HasLift],
        );
        break;

      case EExtraInfoGroup.Energy:
        updateProperty.hasDoubleGlazing = mapFormFieldToNullableBooleanValue(
          formData[EField.HasDoubleGlazing],
        );
        updateProperty.hasCentralHeating = mapFormFieldToNullableBooleanValue(
          formData[EField.HasCentralHeating],
        );
        updateProperty.heatingType = formData[EField.HeatingType] ?? null;
        break;

      default:
        break;
    }

    return propertyActions.updateStart.getAction({
      id: property.id,
      data: updateProperty,
    });
  };
