import { useDispatch, useSelector } from "react-redux";
import { REDUCER_KEY_BROKER } from "../broker/broker.reducer";
import * as brokerSelectors2 from "../broker/broker.selectors";
import { REDUCER_KEY_CONTACT } from "../contact/contact.reducer";
import { getContactMe, getContactMeMaster } from "../contact/contact.selectors";
import * as authActions from "./auth.actions";
import { REDUCER_KEY_AUTH } from "./auth.reducer";
import * as authSelectors from "./auth.selectors";
export var useSelf = function () {
    var user = useSelector(authSelectors.getUser);
    var contactMe = useSelector(function (state) { return getContactMe(state); });
    var contactMeMaster = useSelector(function (state) {
        return getContactMeMaster(state);
    });
    var broker = useSelector(function (state) {
        return brokerSelectors2.detail.detail(state, user === null || user === void 0 ? void 0 : user.brokerId);
    });
    var isBroker = !!(user === null || user === void 0 ? void 0 : user.brokerId);
    return {
        user: user,
        contactMe: contactMe,
        contactMeMaster: contactMeMaster,
        broker: broker,
        isBroker: isBroker,
    };
};
export var useDashboardType = function () {
    // Redux
    var dispatch = useDispatch();
    var selectedDashboardType = useSelector(authSelectors.getSelectedDashboardType);
    // Actions
    var setSelectedDashboardType = function (dashboardType) {
        dispatch(authActions.setSelectedDashboardType({ dashboardType: dashboardType }));
    };
    return {
        selectedDashboardType: selectedDashboardType,
        setSelectedDashboardType: setSelectedDashboardType,
    };
};
