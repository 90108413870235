import { EContractMemberTypes } from "../../types/contract.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { ECancellationReasons, EField, } from "./schema.cancelContract.types";
export var getOptionsForReasonOfCancellation = function (field) {
    if (field === EField.ReasonForCancellationTenant) {
        return [
            {
                id: ECancellationReasons.NoMotiveNoticePeriod,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.NoMotiveNoticePeriod)),
            },
            {
                id: ECancellationReasons.NoMotiveCancellationFee,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.NoMotiveCancellationFee)),
            },
            {
                id: ECancellationReasons.Other,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.Other)),
            },
        ];
    }
    if (field === EField.ReasonForCancellationOwner) {
        return [
            {
                id: ECancellationReasons.NoMotiveWithoutCancellationFee,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.NoMotiveWithoutCancellationFee)),
            },
            {
                id: ECancellationReasons.NoMotiveCancellationFee,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.NoMotiveCancellationFee)),
            },
            {
                id: ECancellationReasons.OwnUse,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.OwnUse)),
            },
            {
                id: ECancellationReasons.FamilyUse,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.FamilyUse)),
            },
            {
                id: ECancellationReasons.Renovation,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.Renovation)),
            },
            {
                id: ECancellationReasons.Other,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.Other)),
            },
        ];
    }
};
export var getInitialValues = function (extraData) {
    var _a;
    var contract = extraData.contract, members = extraData.members;
    var dateOfCancellation = contract.dateOfCancellation, stopDate = contract.stopDate, reasonForCancellation = contract.reasonForCancellation, cancelledForAccountId = contract.cancelledForAccountId;
    var initialValues = (_a = {},
        _a[EField.MemberThatCancels] = cancelledForAccountId || undefined,
        _a[EField.CancellationDate] = dateOfCancellation || undefined,
        _a[EField.StopDate] = dateOfCancellation ? stopDate : undefined,
        _a);
    if (cancelledForAccountId) {
        [
            {
                field: EField.ReasonForCancellationTenant,
                role: EContractMemberTypes.Tenant,
            },
            {
                field: EField.ReasonForCancellationOwner,
                role: EContractMemberTypes.Owner,
            },
        ].forEach(function (_a) {
            var field = _a.field, role = _a.role;
            if (selectedMemberHasRole(cancelledForAccountId, role, members)) {
                initialValues[field] = reasonForCancellation || undefined;
            }
        });
    }
    return initialValues;
};
export var selectedMemberHasRole = function (selectedMemberId, role, members) {
    var selectedMember = members.find(function (member) { return member.account.id === selectedMemberId; });
    return (selectedMember === null || selectedMember === void 0 ? void 0 : selectedMember.roles.includes(role)) || false;
};
export var shouldShowDropdownForRole = function (role, formData, members) {
    if (Array.isArray(formData[EField.MemberThatCancels])) {
        return false;
    }
    var selectedMemberId = formData[EField.MemberThatCancels];
    if (!selectedMemberId) {
        return false;
    }
    return selectedMemberHasRole(selectedMemberId, role, members);
};
