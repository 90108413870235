import queryString from "query-string";
import { CONFIG } from "../config/app.config";
import { EEnvironment } from "../config/app.config.types";
import { ELocale } from "../types/i18n.types";
import { getLocale } from "./i18n/i18n.utils";
import { removeUndefinedValuesFromObject } from "./object.utils";
export var appendQueryParams = function (params) {
    var path = params.path, queryParams = params.queryParams;
    var queryParamsParsed = removeUndefinedValuesFromObject(queryParams);
    var result = queryString.parseUrl(path);
    Object.keys(queryParamsParsed).forEach(function (queryParamKey) {
        var value = queryParamsParsed[queryParamKey];
        if (value !== undefined) {
            result.query[queryParamKey] = "".concat(queryParamsParsed[queryParamKey]);
        }
    });
    return queryString.stringifyUrl(result);
};
export var getDashboardUrl = function (params) {
    var _a = params.path, path = _a === void 0 ? "" : _a, queryParams = params.queryParams;
    var environmentSubdomain = "";
    switch (CONFIG.ENVIRONMENT) {
        case EEnvironment.Dev:
            environmentSubdomain = ".dev";
            break;
        case EEnvironment.Stag:
            environmentSubdomain = ".staging";
            break;
        case EEnvironment.Demo:
            environmentSubdomain = ".demo";
            break;
        default:
            break;
    }
    return appendQueryParams({
        path: "https://dashboard".concat(environmentSubdomain, ".rentio.be/").concat(path),
        queryParams: queryParams || {},
    });
};
export var getBackofficeUrl = function (params) {
    var _a = params.path, path = _a === void 0 ? "" : _a, queryParams = params.queryParams;
    var environmentSubdomain = "";
    switch (CONFIG.ENVIRONMENT) {
        case EEnvironment.Dev:
            environmentSubdomain = ".dev";
            break;
        case EEnvironment.Stag:
            environmentSubdomain = ".staging";
            break;
        case EEnvironment.Demo:
            environmentSubdomain = ".demo";
            break;
        default:
            break;
    }
    return appendQueryParams({
        path: "https://backoffice".concat(environmentSubdomain, ".rentio.be/").concat(path),
        queryParams: queryParams || {},
    });
};
export var getRentioPayUrl = function (params) {
    var _a = params.path, path = _a === void 0 ? "" : _a, queryParams = params.queryParams;
    var environmentSubdomain = "";
    switch (CONFIG.ENVIRONMENT) {
        case EEnvironment.Dev:
            environmentSubdomain = ".dev";
            break;
        case EEnvironment.Stag:
            environmentSubdomain = ".stag";
            break;
        case EEnvironment.Demo:
            environmentSubdomain = ".demo";
            break;
        default:
            break;
    }
    return appendQueryParams({
        path: "https://pay".concat(environmentSubdomain, ".rentio.be/").concat(path),
        queryParams: queryParams || {},
    });
};
export var getMoveUrl = function (params) {
    var _a = params.path, path = _a === void 0 ? "" : _a, queryParams = params.queryParams;
    var environmentSubdomain = "";
    switch (CONFIG.ENVIRONMENT) {
        case EEnvironment.Dev:
            environmentSubdomain = ".dev";
            break;
        case EEnvironment.Stag:
            environmentSubdomain = ".stag";
            break;
        case EEnvironment.Demo:
            environmentSubdomain = ".demo";
            break;
        default:
            break;
    }
    return appendQueryParams({
        path: "https://move".concat(environmentSubdomain, ".rentio.be/").concat(path),
        queryParams: queryParams || {},
    });
};
export var getMyTenantProfileUrl = function (params) {
    var _a = params.path, path = _a === void 0 ? "" : _a, queryParams = params.queryParams;
    var environmentSubdomain = "";
    switch (CONFIG.ENVIRONMENT) {
        case EEnvironment.Dev:
            environmentSubdomain = "dev.";
            break;
        case EEnvironment.Stag:
            environmentSubdomain = "staging.";
            break;
        case EEnvironment.Demo:
            environmentSubdomain = "demo.";
            break;
        default:
            break;
    }
    var domain = "mijnhuurprofiel";
    switch (getLocale()) {
        case ELocale.EN:
            domain = "mytenantprofile";
        case ELocale.FR:
            domain = "monprofillocataire";
        case ELocale.NL:
        case ELocale.INTERNAL:
        default:
            break;
    }
    return appendQueryParams({
        path: "https://".concat(environmentSubdomain).concat(domain, ".be/").concat(path),
        queryParams: queryParams || {},
    });
};
