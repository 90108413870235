import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EPropertyMemberTypes,
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Loading, TextStyle } from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { TSelectableItemProps } from "scenes/Properties/Properties.types";
import PropertyListItem from "../PropertyListItem";
import {
  PropertyListItemItem,
  PropertyListItemSpacer,
} from "../PropertyListItem/PropertyListItem.header";
import StatusDot from "../StatusDot";
import { EStatus } from "../StatusDot/StatusDot.types";
import StatusDotContractDocumentPackage from "../StatusDotContractDocumentPackage";
import StatusDotContractLocationDescription from "../StatusDotContractLocationDescription";
import StatusDotContractPaymentOrders from "../StatusDotContractPaymentOrders";
import { StatusDotContractPaymentOrders as StatusDotContractPaymentOrdersV2 } from "../StatusDotContractPaymentOrdersV2";
import StatusDotContractRentDeposit from "../StatusDotContractRentDeposit";
import StatusDotInsurance from "../StatusDotInsurance";
import StatusDotMove from "../StatusDotMove";
import StatusDotRegistration from "../StatusDotRegistration";
import StatusDotTemplateDocumentContract from "../StatusDotTemplateDocumentContract";
import ContractsFetchListItem from "./ContractsFetchListItem";
import {
  WIDTH_CONTRACT_DATE,
  WIDTH_OWNERS,
  WIDTH_TENANTS,
} from "./PropertyListItemRent.constants";
import * as S from "./PropertyListItemRent.styles";

interface IPropertyListItemRentProps {
  property: IProperty;
  selectedParentId?: number;
  onSetParentId: (parentId?: number) => void;
  selectable?: TSelectableItemProps;
}

const PropertyListItemRent = (props: IPropertyListItemRentProps) => {
  const { property, selectedParentId, onSetParentId, selectable } = props;

  // Redux selectors
  const { isBroker } = authHooks.useSelf();
  const hasMoveServiceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.MoveService,
  );
  const hasInsuranceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Insurance,
  );

  const hasPaymentV2ForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.PaymentV2,
  );

  const { internalModeEnabled } = useInternalMode();

  // Render
  if (property.typeId === EPropertyTypeId.Group) {
    return (
      <PropertyListItem
        property={property}
        selectedParentId={selectedParentId}
        onSetParentId={onSetParentId}
        selectable={selectable}
      />
    );
  }

  const renderOwners = () => {
    const ownerCount = property.members.filter(member =>
      member.roles.includes(EPropertyMemberTypes.Owner),
    ).length;

    const hasOwners = ownerCount > 0;

    return (
      <PropertyListItemItem
        style={{ width: WIDTH_OWNERS, textAlign: "center" }}
      >
        <TextStyle variation={hasOwners ? [] : ["strong", "warn"]}>
          {ownerCount}
        </TextStyle>
      </PropertyListItemItem>
    );
  };

  const renderTenants = (contract?: IContract) => {
    if (!contract) {
      return (
        <TextStyle variation={"subdued"}>
          {getLocalizedText("contracts.overview.contract.no_tenants")}
        </TextStyle>
      );
    }

    const tenants = contract.members.filter(
      member =>
        member.roles.includes(EContractMemberTypes.Tenant) ||
        member.roles.includes(EContractMemberTypes.Parent),
    );

    const resultComponents = [];
    if (tenants.length > 0) {
      resultComponents.push(`${getName(tenants[0].account)}`);
    }
    if (tenants.length > 1) {
      resultComponents.push(`+ ${tenants.length - 1}`);
    }

    return <TextStyle>{resultComponents.join(" ")}</TextStyle>;
  };

  const renderStartDate = (contract?: IContract) => {
    if (!contract) {
      return <TextStyle variation={"subdued"}>-</TextStyle>;
    }

    return <TextStyle>{formatDate(contract.startDate)}</TextStyle>;
  };

  const renderContractInfo = (params: {
    contract?: IContract;
    isFirst?: boolean;
    isLast?: boolean;
  }) => {
    const { contract, isFirst = true, isLast = true } = params;

    return (
      <div
        key={contract?.id || "empty"}
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: isFirst ? 0 : 4,
          paddingBottom: isLast ? 0 : 4,
        }}
      >
        <PropertyListItemItem
          style={{
            width: WIDTH_TENANTS - 8,
            marginRight: 8,
          }}
          tooltipContent={renderTenants(contract)}
        >
          {renderTenants(contract)}
        </PropertyListItemItem>

        <S.StartDateWrap>
          <PropertyListItemItem
            style={{
              width: WIDTH_CONTRACT_DATE,
            }}
          >
            {renderStartDate(contract)}
          </PropertyListItemItem>
        </S.StartDateWrap>
      </div>
    );
  };

  const renderContractStatusDots = (params: {
    contract?: IContract;
    isFirst?: boolean;
    isLast?: boolean;
  }) => {
    const { contract, isFirst = true, isLast = true } = params;

    const getStatusDotContractPaymentOrders = () => {
      if (!contract) {
        return <StatusDot status={EStatus.None} />;
      }

      // TODO: To be removed internalModeEnabled
      if (internalModeEnabled && hasPaymentV2ForBroker) {
        return <StatusDotContractPaymentOrdersV2 contract={contract} />;
      }

      return <StatusDotContractPaymentOrders contract={contract} />;
    };

    return (
      <div
        key={contract?.id || "empty"}
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: isFirst ? 0 : 4,
          paddingBottom: isLast ? 0 : 4,
        }}
      >
        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotTemplateDocumentContract contract={contract} />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotContractDocumentPackage contract={contract} />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotContractRentDeposit contract={contract} />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {getStatusDotContractPaymentOrders()}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotInsurance
              contract={contract}
              hasFeature={(isBroker && hasInsuranceForBroker) || !isBroker}
            />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotMove
              contract={contract}
              hasFeature={(isBroker && hasMoveServiceForBroker) || !isBroker}
            />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotContractLocationDescription contract={contract} />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotRegistration property={property} contract={contract} />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>
      </div>
    );
  };

  const renderNoTenants = () => (
    <>
      <S.InfoWrap>{renderContractInfo({})}</S.InfoWrap>

      <PropertyListItemSpacer />

      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {renderContractStatusDots({})}
      </div>
    </>
  );

  const renderContracts = () => {
    const hasTenants = !!property.members.find(
      member =>
        member.roles.includes(EPropertyMemberTypes.Tenant) ||
        member.roles.includes(EPropertyMemberTypes.Parent),
    );

    if (!hasTenants) {
      return renderNoTenants();
    }

    return (
      <ContractsFetchListItem
        propertyId={property.id}
        renderLoading={() => {
          return (
            <S.LoadingWrap>
              <S.LoadingInnerWrap>
                <Loading asDots={true} />
              </S.LoadingInnerWrap>
            </S.LoadingWrap>
          );
        }}
        renderContracts={(contracts: IContract[]) => {
          if (contracts.length === 0) {
            return renderNoTenants();
          }

          return (
            <>
              <S.InfoWrap>
                {contracts.map((contract, index) => {
                  const isFirst = index === 0;
                  const isLast = index === contracts.length - 1;

                  return renderContractInfo({ contract, isFirst, isLast });
                })}
              </S.InfoWrap>
              <PropertyListItemSpacer />
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                {contracts.map((contract, index) => {
                  const isFirst = index === 0;
                  const isLast = index === contracts.length - 1;

                  return renderContractStatusDots({
                    contract,
                    isFirst,
                    isLast,
                  });
                })}
              </div>
            </>
          );
        }}
      />
    );
  };

  return (
    <PropertyListItem
      property={property}
      selectedParentId={selectedParentId}
      onSetParentId={onSetParentId}
      selectable={selectable}
    >
      {renderOwners()}

      <PropertyListItemSpacer />

      <S.ChildrenWrap>{renderContracts()}</S.ChildrenWrap>
    </PropertyListItem>
  );
};

// eslint-disable-next-line import/no-default-export
export default PropertyListItemRent;
