import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { NumberParam, StringParam } from "serialize-query-params";
import { Drawer } from "../Drawer";
import { DocumentPackage } from "./Drawers/DocumentPackage";
import { RentDeposit } from "./Drawers/RentDeposit";

export const DetailsDrawer = () => {
  const [queryParams] = useQueryParams({
    documentPackageId: StringParam,
    documentId: StringParam,
    rentDepositId: NumberParam,
    templateDocumentId: StringParam,
  });

  return (
    <>
      <Drawer open={!!queryParams?.rentDepositId} formId="rentDepositId">
        {queryParams.rentDepositId && (
          <RentDeposit rentDepositId={queryParams.rentDepositId} />
        )}
      </Drawer>
      <Drawer
        open={!!queryParams?.documentPackageId}
        formId="documentPackageId"
      >
        {queryParams.documentPackageId && (
          <DocumentPackage documentPackageId={queryParams.documentPackageId} />
        )}
      </Drawer>
    </>
  );
};
