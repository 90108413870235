import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import Pagination from "@mui/material/Pagination";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Menu } from "../Menu";
import { TableStyles } from "./Table.styles";
export var ETableAlignment;
(function (ETableAlignment) {
    ETableAlignment["Left"] = "left";
    ETableAlignment["Right"] = "right";
    ETableAlignment["Center"] = "center";
    ETableAlignment["Justify"] = "justify";
    ETableAlignment["Inherit"] = "inherit";
})(ETableAlignment || (ETableAlignment = {}));
export var Table = function (_a) {
    var columns = _a.columns, dataRows = _a.dataRows, _b = _a.totalPages, totalPages = _b === void 0 ? 1 : _b, _c = _a.activePage, activePage = _c === void 0 ? 1 : _c, handlePageClick = _a.handlePageClick;
    var sx = TableStyles();
    return (_jsxs(_Fragment, { children: [_jsx(TableContainer, { children: _jsxs(MuiTable, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [columns.map(function (column) { return (_jsx(TableCell, { align: column.align || ETableAlignment.Left, sx: sx.content.tableHeaderCell, children: _jsx(Typography, { variant: "subtitle2", children: getLocalizedText(column.columnTitle) }) })); }), _jsx(TableCell, {})] }) }), _jsx(TableBody, { children: dataRows.map(function (row) {
                                var _a;
                                return (_jsxs(TableRow, { hover: true, children: [row === null || row === void 0 ? void 0 : row.content.map(function (item) { return (_jsx(TableCell, { sx: sx.content.tableBodyCell, children: typeof item === "string" ? (_jsx(Typography, { variant: "subtitle2", children: item })) : (item) })); }), _jsx(TableCell, { children: Boolean((_a = row === null || row === void 0 ? void 0 : row.actions) === null || _a === void 0 ? void 0 : _a.length) && (_jsx(Menu, { actions: (row === null || row === void 0 ? void 0 : row.actions) || [] })) })] }, row === null || row === void 0 ? void 0 : row.id));
                            }) })] }) }), _jsx(CardActions, { sx: sx.content.cardActions, children: totalPages > 1 && handlePageClick && (_jsx(Box, { sx: sx.content.paginationContainer, children: _jsx(Pagination, { count: totalPages, page: activePage, color: "primary", onChange: function (_, page) { return handlePageClick(page); } }) })) })] }));
};
