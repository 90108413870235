var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./paymentRequest.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["MarkPaid"] = "MARK_PAID";
    EActionCustom["MarkUnPaid"] = "MARK_UNPAID";
    EActionCustom["PaymentLink"] = "PAYMENT_LINK";
    EActionCustom["GrantAccessPaymentRequest"] = "GRANT_ACCESS_PAYMENT_REQUEST";
    EActionCustom["SendInvitation"] = "SEND_INVITATION";
    EActionCustom["InvoicedAt"] = "INVOICED_AT";
    EActionCustom["ReminderSms"] = "REMINDER_SMS";
    EActionCustom["ReminderPhone"] = "REMINDER_PHONE";
    EActionCustom["ReminderMail"] = "REMINDER_MAIL";
    EActionCustom["ReminderChatApp"] = "REMINDER_CHAT_APP";
    EActionCustom["ReminderRegisteredMail"] = "REMINDER_REGISTERED_MAIL";
})(EActionCustom || (EActionCustom = {}));
var generateInvoicedAtActions = function () { return ({
    invoicedAtStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.InvoicedAt,
        state: EActionState.Start,
    }),
}); };
var generateMarkPaidActions = function () { return ({
    markPaidStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkPaid,
        state: EActionState.Start,
    }),
    markPaidSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkPaid,
        state: EActionState.Success,
    }),
    markPaidFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkPaid,
        state: EActionState.Fail,
    }),
}); };
var generateMarkUnPaidActions = function () { return ({
    markUnPaidStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkUnPaid,
        state: EActionState.Start,
    }),
    markUnPaidSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkUnPaid,
        state: EActionState.Success,
    }),
    markUnPaidFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkUnPaid,
        state: EActionState.Fail,
    }),
}); };
var generateGetPaymentLinkActions = function () { return ({
    getPaymentLinkStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PaymentLink,
        state: EActionState.Start,
    }),
    getPaymentLinkSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PaymentLink,
        state: EActionState.Success,
    }),
    getPaymentLinkFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PaymentLink,
        state: EActionState.Fail,
    }),
}); };
var generateGrantAccessPaymentRequestActions = function () { return ({
    grantAccessPaymentRequestStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GrantAccessPaymentRequest,
        state: EActionState.Start,
    }),
    grantAccessPaymentRequestSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GrantAccessPaymentRequest,
        state: EActionState.Success,
    }),
    grantAccessPaymentRequestFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GrantAccessPaymentRequest,
        state: EActionState.Fail,
    }),
}); };
var sendPaymentRequestInvitationActions = function () { return ({
    sendPaymentRequestInvitationStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.SendInvitation,
        state: EActionState.Start,
    }),
    sendPaymentRequestInvitationSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.SendInvitation,
        state: EActionState.Success,
    }),
    sendPaymentRequestInvitationFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.SendInvitation,
        state: EActionState.Fail,
    }),
}); };
var generateReminderSmsActions = function () { return ({
    reminderSmsStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderSms,
        state: EActionState.Start,
    }),
    reminderSmsSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderSms,
        state: EActionState.Success,
    }),
    reminderSmsFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderSms,
        state: EActionState.Fail,
    }),
}); };
var generateReminderPhoneActions = function () { return ({
    reminderPhoneStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderPhone,
        state: EActionState.Start,
    }),
    reminderPhoneSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderPhone,
        state: EActionState.Success,
    }),
    reminderPhoneFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderPhone,
        state: EActionState.Fail,
    }),
}); };
var generateReminderMailActions = function () { return ({
    reminderMailStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderMail,
        state: EActionState.Start,
    }),
    reminderMailSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderMail,
        state: EActionState.Success,
    }),
    reminderMailFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderMail,
        state: EActionState.Fail,
    }),
}); };
var generateReminderChatAppActions = function () { return ({
    reminderChatAppStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderChatApp,
        state: EActionState.Start,
    }),
    reminderChatAppSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderChatApp,
        state: EActionState.Success,
    }),
    reminderChatAppFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderChatApp,
        state: EActionState.Fail,
    }),
}); };
var generateReminderRegisteredMailActions = function () { return ({
    reminderRegisteredMailStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderRegisteredMail,
        state: EActionState.Start,
    }),
    reminderRegisteredMailSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderRegisteredMail,
        state: EActionState.Success,
    }),
    reminderRegisteredMailFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderRegisteredMail,
        state: EActionState.Fail,
    }),
}); };
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, getInfiniteLoadStart = _a.getInfiniteLoadStart, getInfiniteLoadFailed = _a.getInfiniteLoadFailed, getInfiniteLoadSucceeded = _a.getInfiniteLoadSucceeded, createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed;
export var markPaidStart = (_b = generateMarkPaidActions(), _b.markPaidStart), markPaidSucceeded = _b.markPaidSucceeded, markPaidFailed = _b.markPaidFailed;
export var markUnPaidStart = (_c = generateMarkUnPaidActions(), _c.markUnPaidStart), markUnPaidSucceeded = _c.markUnPaidSucceeded, markUnPaidFailed = _c.markUnPaidFailed;
export var invoicedAtStart = generateInvoicedAtActions().invoicedAtStart;
export var getPaymentLinkStart = (_d = generateGetPaymentLinkActions(), _d.getPaymentLinkStart), getPaymentLinkSucceeded = _d.getPaymentLinkSucceeded, getPaymentLinkFailed = _d.getPaymentLinkFailed;
export var grantAccessPaymentRequestStart = (_e = generateGrantAccessPaymentRequestActions(), _e.grantAccessPaymentRequestStart), grantAccessPaymentRequestSucceeded = _e.grantAccessPaymentRequestSucceeded, grantAccessPaymentRequestFailed = _e.grantAccessPaymentRequestFailed;
export var sendPaymentRequestInvitationStart = (_f = sendPaymentRequestInvitationActions(), _f.sendPaymentRequestInvitationStart), sendPaymentRequestInvitationSucceeded = _f.sendPaymentRequestInvitationSucceeded, sendPaymentRequestInvitationFailed = _f.sendPaymentRequestInvitationFailed;
export var reminderSmsStart = (_g = generateReminderSmsActions(), _g.reminderSmsStart), reminderSmsSucceeded = _g.reminderSmsSucceeded, reminderSmsFailed = _g.reminderSmsFailed;
export var reminderPhoneStart = (_h = generateReminderPhoneActions(), _h.reminderPhoneStart), reminderPhoneSucceeded = _h.reminderPhoneSucceeded, reminderPhoneFailed = _h.reminderPhoneFailed;
export var reminderChatAppStart = (_j = generateReminderChatAppActions(), _j.reminderChatAppStart), reminderChatAppSucceeded = _j.reminderChatAppSucceeded, reminderChatAppFailed = _j.reminderChatAppFailed;
export var reminderMailStart = (_k = generateReminderMailActions(), _k.reminderMailStart), reminderMailSucceeded = _k.reminderMailSucceeded, reminderMailFailed = _k.reminderMailFailed;
export var reminderRegisteredMailStart = (_l = generateReminderRegisteredMailActions(), _l.reminderRegisteredMailStart), reminderRegisteredMailSucceeded = _l.reminderRegisteredMailSucceeded, reminderRegisteredMailFailed = _l.reminderRegisteredMailFailed;
