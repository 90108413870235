import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import {
  EExportActionType,
  EExportFileType,
  EExportType,
} from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import * as exportFilesActions from "@rentiohq/shared-frontend/dist/reduxV2/exportFile/exportFile.actions";
import * as beneficiaryReportHooks from "@rentiohq/shared-frontend/dist/reduxV2/reportBeneficiary/report.beneficiary.hooks";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import { EPaymentOrderOwnerPaymentMethod } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { IBeneficiaryReportPayout } from "@rentiohq/shared-frontend/dist/types/report.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  ActionButton,
  Card,
  DataTable,
  ELoadingSize,
  EmptyState,
  Loading,
  mapItemsToDataTableProps,
  Pagination,
} from "@rentiohq/web-shared/dist/components";
import { useMultiselect } from "@rentiohq/web-shared/dist/hooks/useMultiselect";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { flatten, sortBy, uniq } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import usePreference from "scenes/Settings/hooks/usePreference";
import { NumberParam, StringParam } from "serialize-query-params";
import {
  BeneficiaryReportFilter,
  IResultingFilter,
} from "./BeneficiaryReport.filter";
import { getItems, useLinkedPayouts } from "./BeneficiaryReport.util";

const SELECTION_LIMIT = 500;

export const BeneficiaryReport = () => {
  const [, setQueryParamValue] = useQueryParams({
    paymentRequestId: NumberParam,
    documentId: StringParam,
  });
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const {
    selectedIds,
    onSelectIds,
    onDeselectIds,
    onSelectId,
    onDeselectId,
    onDeselectAll,
  } = useMultiselect<number>([], SELECTION_LIMIT);

  const [searchQuery = "", setSearchQuery] = usePreference({
    preferenceKey: "search_query",
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });
  const [queryDebounced] = useDebounce(searchQuery);

  const [payoutToGetDocsFor, setPayoutToGetDocsFor] = useState<number>();

  // TODO: might be better to store this in redux -> this is only a temp solution
  // const allPayoutsRef = useRef<IBeneficiaryReportPayout[]>([]);
  const [filter, setFilter] = useState<IResultingFilter>();

  useEffect(() => {
    onDeselectAll();
    setPage(1);
  }, [queryDebounced, filter]);

  const {
    items: payouts,
    isFetching,
    totalPages = 0,
    refetch,
  } = beneficiaryReportHooks.usePaged(
    filter
      ? {
          shouldRefetch: true,
          query: {
            page,
            limit: CONFIG.DEFAULT_FETCH_LIMIT,
            sort: [
              { field: "requestPayeeAccountId", method: "ASC" },
              { field: "payoutDate", method: "DESC" },
            ],
            search: queryDebounced,
            ...filter,
          },
        }
      : {
          shouldRefetch: true,
        },
  );
  const allLinkedPayouts = useLinkedPayouts({
    payoutIdsToGetAndFetch: payouts?.map(p => p.id),
    payoutIdsToGet: selectedIds,
  });

  const { items: documentsForPaymentRequest, isFetching: isFetchingDocuments } =
    documentHooks.useGetAll({
      shouldRefetch: true,
      customPath: payoutToGetDocsFor
        ? `/payment-requests/${payoutToGetDocsFor}/documents`
        : undefined,
      query: payoutToGetDocsFor ? {} : undefined,
    });

  const items = getItems({ allLinkedPayouts });
  const tableSetup = mapItemsToDataTableProps(items, {
    hasActions: true,
  });

  const createReport = (
    selectedPayoutIds: number[],
    action: EExportActionType,
  ) => {
    if (selectedPayoutIds?.length === 0) {
      return;
    }

    const missingLinkedPayoutsForPayoutIds: number[] = [];

    const linkedPayoutIds = flatten(
      selectedPayoutIds.map(payoutId => {
        const linkedPayouts = allLinkedPayouts[payoutId]?.items;
        if (linkedPayouts) {
          return linkedPayouts
            .filter(
              x =>
                x.paymentRequestItems?.[0]?.paymentOrder.ownerPaymentMethod ===
                EPaymentOrderOwnerPaymentMethod.FromRent,
            )
            .map(x => x.id);
        }

        missingLinkedPayoutsForPayoutIds.push(payoutId);
        return [];
      }),
    );

    if (missingLinkedPayoutsForPayoutIds.length > 0) {
      showAlert({
        type: "error",
        message: "Incomplete data",
        content: `Missing linked payouts for ${join(
          missingLinkedPayoutsForPayoutIds.map(String),
        )}`,
      });
      return;
    }

    dispatch(
      exportFilesActions.createStart.getAction({
        data: {
          exportFileType: EExportFileType.Pdf,
          exportType: EExportType.BeneficiaryReport,
          exportParameters: {
            exportBeneficiaryReportParameters: {
              paymentRequestIds: uniq([
                ...selectedPayoutIds,
                ...linkedPayoutIds,
              ]),
              action,
            },
          },
        },
        onSuccess: () => {
          onDeselectAll();
          refetch();
        },
      }),
    );
  };

  const getRows = () => {
    if (!payouts) {
      return [];
    }

    return payouts.map((payout: IBeneficiaryReportPayout) => {
      const actions = [];

      const linkedPayouts = allLinkedPayouts[payout.id]?.items;

      actions.push([
        {
          content: getLocalizedText(
            "payments.follow_up.card_actions.detail_payment_request",
          ),
          onClick: () => {
            setQueryParamValue({
              paymentRequestId: payout.id,
            });
          },
        },
        {
          content: getLocalizedText(
            "payments.follow_up.card_actions.detail_payment_request_parent",
          ),
          onClick: () => {
            setQueryParamValue({
              paymentRequestId: payout.parent,
            });
          },
        },
      ]);

      const canCreateReport = !!linkedPayouts && payout.reportingEnabled;

      if (canCreateReport) {
        actions.push([
          {
            content: getLocalizedText("reports.beneficiary.create_report"),
            onClick: () => createReport([payout.id], EExportActionType.Create),
          },
          {
            content: getLocalizedText("reports.beneficiary.create_and_send"),
            onClick: () =>
              createReport([payout.id], EExportActionType.CreateAndEmail),
          },
        ]);
      }

      const docs = documentsForPaymentRequest ?? [];

      if (isFetchingDocuments) {
        actions.push([
          {
            content: <Loading asDots={true} size={ELoadingSize.Medium} />,
          },
        ]);
      } else {
        actions.push(
          sortBy(docs || [], "createdAt").map((doc: IDocument) => ({
            content: doc.filename,
            onClick: () => {
              setQueryParamValue({
                documentId: doc.id,
              });
            },
          })),
        );
      }

      return {
        id: payout.id,
        multiSelectDisabled: !canCreateReport,
        multiSelectDisabledTooltip: !linkedPayouts
          ? getLocalizedText("system.loading")
          : getLocalizedText("reports.beneficiary.reporting_disabled", {
              beneficiaryName: getName(payout.payeeAccount),
            }),
        content: items
          .map(item => item.renderContent(payout))
          .filter(c => c !== null),
        actions,
        onDropdownOpen: () => {
          // Forces the call to happen every time the dropdown is opened.
          setPayoutToGetDocsFor(undefined);
          setPayoutToGetDocsFor(payout.id);
        },
      };
    });
  };

  const renderPaging = () => {
    if (totalPages <= 1) {
      return null;
    }

    return (
      <Pagination
        initialPage={page ? page - 1 : 0}
        pageCount={totalPages}
        onPageChange={({ selected }) => setPage(selected + 1)}
      />
    );
  };

  const handleShowConfirmModal = () => {
    confirm({
      title: getLocalizedText("reports.beneficiary_report.confirm.title"),
      primaryActions: [
        {
          title: getLocalizedText(
            "reports.beneficiary_report.cta.without_email",
          ),
          onPress: () => {
            createReport(selectedIds, EExportActionType.Create);
            refetch();
          },
        },
        {
          title: getLocalizedText("reports.beneficiary_report.cta.with_email"),
          onPress: () => {
            createReport(selectedIds, EExportActionType.CreateAndEmail);
            refetch();
          },
        },
      ],
    });
  };

  return (
    <Card heading={getLocalizedText("reports.beneficiary.title")}>
      <BeneficiaryReportFilter
        onFilterChange={newFilter => {
          setFilter(newFilter);
        }}
        onSearchChange={setSearchQuery}
        searchQuery={searchQuery}
      />

      <Spacer weight={ESpacerWeight.W16} />

      {selectedIds && selectedIds.length > 0 && (
        <ActionButton
          title={getLocalizedText("reports.beneficiary.create_report")}
          value={selectedIds.length}
          onAction={handleShowConfirmModal}
          onClear={() => onDeselectAll()}
        />
      )}

      <DataTable
        isLoadingData={isFetching && !payouts}
        horizontalSpacing="extraTight"
        emptyState={
          <EmptyState
            backgroundColor="gray"
            heading={getLocalizedText("reports.beneficiary.payouts.empty")}
          />
        }
        {...tableSetup}
        rows={getRows()}
        multiSelect={true}
        selectedIds={selectedIds}
        onSelectPage={(ids: (number | string)[]) => {
          // TODO: Can select?
          onSelectIds(ids as number[]);
        }}
        onDeselectPage={(ids: (number | string)[]) => {
          onDeselectIds(ids as number[]);
        }}
        onSelectRow={(id: number | string) => {
          onSelectId(id as number);
        }}
        onDeselectRow={(id: number | string) => {
          onDeselectId(id as number);
        }}
        onRowClick={rowIndex => {
          setQueryParamValue({
            paymentRequestId: payouts?.[rowIndex].id,
          });
        }}
      />

      {renderPaging()}
    </Card>
  );
};
