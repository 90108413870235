import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IUser } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IBroker } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  IGroupActionContentBase,
  TGroupActionsLocale as TLocale,
} from "@rentiohq/shared-frontend/dist/types/groupAction.types";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EGroupActionModalStep,
  EGroupActionType,
} from "./GroupActionsModal.types";

export const getType = (type: string) => {
  switch (type) {
    case EGroupActionType.Sms:
      return "SMS";

    case EGroupActionType.Notification:
      return "notification";

    case EGroupActionType.Email:
      return "email";

    case EGroupActionType.Chat:
      return "chat";

    default:
      return "";
  }
};

export const getLocaleKey = (locale: ELocale) => {
  switch (locale) {
    case ELocale.NL:
      return "nl";
    case ELocale.FR:
      return "fr";
    case ELocale.EN:
      return "en";
    default:
      return "nl";
  }
};

const getContentLabelKey = (type: string, locale: ELocale) => {
  if (
    [
      EGroupActionType.Chat,
      EGroupActionType.Sms,
      EGroupActionType.Document,
    ].includes(type as EGroupActionType)
  ) {
    return `group_actions.modal.content.label.${getLocaleKey(locale)}`;
  }

  return "group_actions.modal.content.label";
};

const getGroupActionsPlaceholderValue = (
  type: string,
  locale: TLocale,
  lowerCaseType: string,
  broker?: IBroker,
  user?: IUser,
) => {
  if (type === EGroupActionType.Document) {
    let userName = "";
    if (broker) {
      userName = broker.name;
    } else {
      userName = user ? getName(user) : "";
    }

    return getLocalizedText(
      "group_actions.email.document_shared.body",
      {
        userName,
      },
      0,
      locale,
    );
  }

  return getLocalizedText(
    `group_actions.modal.${lowerCaseType}.content.placeholder`,
    {},
    0,
    locale,
  );
};

export const getLocalizedGroupActionsContent = (
  type: string,
  broker?: IBroker,
  user?: IUser,
): Record<TLocale, IGroupActionContentBase> => {
  const lowerCaseType = type.toLowerCase();
  const localizedContent = (locale: TLocale): IGroupActionContentBase => ({
    key: `group_actions_modal_${lowerCaseType}_content_${locale}`,
    label: getLocalizedText(getContentLabelKey(type, locale)),
    value: getGroupActionsPlaceholderValue(
      type,
      locale,
      lowerCaseType,
      broker,
      user,
    ),
  });

  return {
    nl_BE: localizedContent(ELocale.NL),
    fr_FR: localizedContent(ELocale.FR),
    en_UK: localizedContent(ELocale.EN),
  };
};

export const stepsForType: Record<EGroupActionType, EGroupActionModalStep[]> = {
  [EGroupActionType.Notification]: [
    EGroupActionModalStep.SelectAction,
    EGroupActionModalStep.SelectMembers,
    EGroupActionModalStep.AddContent,
  ],
  [EGroupActionType.Sms]: [
    EGroupActionModalStep.SelectAction,
    EGroupActionModalStep.SelectMembers,
    EGroupActionModalStep.AddContent,
  ],
  [EGroupActionType.Email]: [
    EGroupActionModalStep.SelectAction,
    EGroupActionModalStep.SelectMembers,
    EGroupActionModalStep.AddContent,
  ],
  [EGroupActionType.Task]: [EGroupActionModalStep.SelectAction],
  [EGroupActionType.Document]: [
    EGroupActionModalStep.SelectAction,
    EGroupActionModalStep.SelectDocument,
    EGroupActionModalStep.SelectMembers,
    EGroupActionModalStep.AddContent,
  ],
  [EGroupActionType.Chat]: [
    EGroupActionModalStep.SelectAction,
    EGroupActionModalStep.SelectMembers,
    EGroupActionModalStep.AddContent,
  ],
};
