import { getDashboardUrl, getMoveUrl } from "../../utils/url.utils";
export var getExternalUrl = function (moveRequest, isBroker) {
    var publicId = moveRequest.publicId, _a = moveRequest.publicToken, publicToken = _a === void 0 ? "demo" : _a;
    return getMoveUrl({
        path: "move/self-serve",
        queryParams: {
            moveRequestId: publicId,
            publicToken: publicToken,
            isBroker: isBroker ? "1" : "0",
        },
    });
};
export var getDashboardUrlFromMoveRequest = function (moveRequest) {
    var propertyId = moveRequest.propertyId, id = moveRequest.id;
    return getDashboardUrl({
        path: "properties/".concat(propertyId),
        queryParams: {
            moveRequestId: "".concat(id),
        },
    });
};
