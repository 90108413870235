var _a;
import { AddressAutoComplete } from "../ui-components/AddressAutoComplete";
import { AddressTextField } from "../ui-components/AddressTextField";
import { AutoComplete } from "../ui-components/AutoComplete";
import { ContactCheckSelectInline } from "../ui-components/ContactCheckSelectInline";
import { ContactSelectInline } from "../ui-components/ContactSelectInline";
import { DatePicker } from "../ui-components/DatePicker";
import { InlineSelect } from "../ui-components/InlineSelect";
import { MaskedInput } from "../ui-components/MaskedInput";
import { Phone } from "../ui-components/Phone";
import { PropertySelectInline } from "../ui-components/PropertySelectInline";
import { SegmentedControls } from "../ui-components/SegmentedControl";
import { Select } from "../ui-components/Select";
import { TextArea } from "../ui-components/TextArea";
import { TextField } from "../ui-components/TextField";
import { ERentioFormField } from "../utils/types/form";
export var fields = (_a = {},
    _a[ERentioFormField.TextField] = TextField,
    _a[ERentioFormField.TextArea] = TextArea,
    _a[ERentioFormField.Email] = TextField,
    _a[ERentioFormField.Select] = Select,
    _a[ERentioFormField.Date] = DatePicker,
    _a[ERentioFormField.Address] = TextField,
    _a[ERentioFormField.SegmentedControl] = SegmentedControls,
    _a[ERentioFormField.AddressTextField] = AddressTextField,
    _a[ERentioFormField.AddressAutoComplete] = AddressAutoComplete,
    _a[ERentioFormField.Phone] = Phone,
    _a[ERentioFormField.MaskedInput] = MaskedInput,
    _a[ERentioFormField.AutoComplete] = AutoComplete,
    _a[ERentioFormField.ContactSelectInline] = ContactSelectInline,
    _a[ERentioFormField.ContactCheckSelectInline] = ContactCheckSelectInline,
    _a[ERentioFormField.PropertySelectInline] = PropertySelectInline,
    _a[ERentioFormField.InlineSelect] = InlineSelect,
    _a);
