import { jsx as _jsx } from "react/jsx-runtime";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
export var PrivateRoute = function () {
    var hasAccessToken = useSelector(authSelectors.hasAccessToken);
    var user = authHooks.useSelf().user;
    var _a = user || {}, unverifiedEmail = _a.unverifiedEmail, unverifiedPhone = _a.unverifiedPhone, unverifiedPhoneIsVerifiable = _a.unverifiedPhoneIsVerifiable;
    if (unverifiedPhone && unverifiedPhoneIsVerifiable) {
        return _jsx(Navigate, { to: { pathname: "/auth/verify/phone" } });
    }
    if (unverifiedEmail) {
        return _jsx(Navigate, { to: { pathname: "/auth/verify/email" } });
    }
    if (!hasAccessToken || !user) {
        return _jsx(Navigate, { to: { pathname: "/auth/signin" } });
    }
    return _jsx(Outlet, {});
};
