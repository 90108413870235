export var EField;
(function (EField) {
    EField["Firstname"] = "firstname";
    EField["Lastname"] = "lastname";
    EField["Email"] = "email";
    EField["Phone"] = "phone";
    EField["Password"] = "password";
    EField["Captcha"] = "captcha";
    EField["Role"] = "role";
})(EField || (EField = {}));
export var ERole;
(function (ERole) {
    ERole["Broker"] = "broker";
    ERole["Professional"] = "professional";
    ERole["Tenant"] = "tenant";
    ERole["Other"] = "other";
})(ERole || (ERole = {}));
