import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.createDocumentPackage.types";
import { getNavigationTitle } from "./schema.createDocumentPackage.utils";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => ({
    required: [EField.DocumentPackageType],
    properties: {
      [EField.Header + "2"]: {
        default: undefined,
        // @ts-ignore
        showIf: (_: any) => !extraData.sendToTenant,
      },
      [EField.DocumentPackageType]: {
        default: getValue(
          EField.DocumentPackageType,
          initialValues,
          defaultValues,
        ),
        // @ts-ignore
        options: [
          EDocumentPackageTypes.Contract,
          EDocumentPackageTypes.RentalAssignment,
          EDocumentPackageTypes.Document,
        ].map(id => ({
          id,
          value: utils.i18n.t(
            `document_package.form.document_package_type.option.${id.toLowerCase()}.label`,
          ),
          subValue: utils.i18n.t(
            `document_package.form.document_package_type.option.${id.toLowerCase()}.description`,
          ),
        })),
        // @ts-ignore
        showIf: (_: any) => !extraData.sendToTenant,
      },
    },
  });

export const uiSchema = (_: IExtraData): IUiSchema<EField> => ({
  "rentio:title": utils.i18n.t(
    "document_package.form.document_package_type.step_heading",
  ),
  "rentio:navigationTitle": getNavigationTitle(),
  [EField.Header + "2"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: utils.i18n.t("document_package.form.document_package_type.heading"),
  },
  [EField.DocumentPackageType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: utils.i18n.t("document_package.form.document_package_type.label"),
    formatSummary: (id: any) =>
      utils.i18n.t(
        `document_package.form.document_package_type.option.${id.toLowerCase()}.label`,
      ),
    hideOnSummaryIfEmpty: true,
  },
});
