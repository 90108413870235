import * as commentActions from "@rentiohq/shared-frontend/dist/redux/comment/comment.actions";
import * as commentSelectors from "@rentiohq/shared-frontend/dist/redux/comment/comment.selectors";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IComment } from "@rentiohq/shared-frontend/dist/types/comment.types";
import { ITask } from "@rentiohq/shared-frontend/dist/types/task.types";
import { formatDateAndTime } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import { EFontWeights } from "@rentiohq/web-shared-next/dist/themes/types";
import {
  Box,
  Button,
  Icon,
  Link,
  Stack,
  Typography,
  TextField,
  SpinningLoader,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { EButtonVariants } from "@rentiohq/web-shared-next/dist/utils/types/button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { CommentStyles } from "./Comments.styles";

interface IProps {
  task: ITask;
}

const INLINE_FETCH_LIMIT = 7;

export const Comments = ({ task }: IProps) => {
  const identifier = `task-${task.id}`;
  const baseUrl = `tasks/${task.id}`;

  const dispatch = useDispatch();

  const sx = CommentStyles();

  const showLimit = useSelector((state: IRootStore) => state.comment.showLimit);

  const [hasFocus, setHasFocus] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>("");

  const isFetchingComments = useSelector((state: IRootStore) =>
    commentSelectors.getIsFetchingComments(state, identifier),
  );

  const isCreatingComment = useSelector(
    (state: IRootStore) => state.comment.isCreatingComment,
  );

  const comments = useSelector(
    (state: IRootStore) =>
      commentSelectors.getComments(state, identifier) || [],
  );

  const fetchData = (refetch: boolean) => {
    dispatch(
      commentActions.getComments.actions.start({
        commentIdentifier: identifier,
        baseUrl,
        refetch,
        limit: INLINE_FETCH_LIMIT,
      }),
    );
  };

  React.useEffect(() => {
    fetchData(true);
  }, []);

  const handleClickDelete = (comment: IComment) => {
    dispatch(
      commentActions.setShowLimit({
        showLimit: showLimit > 5 ? showLimit - 1 : showLimit,
      }),
    );
    dispatch(
      commentActions.deleteComment.actions.start({
        commentId: comment.id,
        commentIdentifier: identifier,
      }),
    );
  };

  const handlePressShowMore = () => {
    dispatch(commentActions.setShowLimit({ showLimit: showLimit + 3 }));
    fetchData(false);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setQuery(event.target.value);
  };

  const handleSubmit = () => {
    if (query.length > 0) {
      dispatch(
        commentActions.createComment.actions.start({
          content: query,
          commentIdentifier: identifier,
          baseUrl,
        }),
      );
      setQuery("");
      dispatch(commentActions.setShowLimit({ showLimit: 5 }));
    }
  };

  const renderShowAllButton = () => {
    if (comments.length <= showLimit) {
      return;
    }

    return (
      <Link
        label={getLocalizedText("task.inline.cta.show_more")}
        underlined={false}
        onClick={handlePressShowMore}
      />
    );
  };

  const renderAddCommentField = () => {
    let value = query;
    if (!hasFocus) {
      const splitted = query.split("\n")[0];
      value = /\r|\n/.exec(query) ? `${splitted}...` : splitted;
    }

    return (
      <Stack direction="row" gap={1} alignItems="center" width="100%" my={1}>
        <TextField
          value={value}
          fullWidth
          size="small"
          multiline={hasFocus}
          autoFocus={hasFocus}
          onChange={handleChange}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          placeholder={getLocalizedText(
            "tenant.task.public_comments.placeholder",
          )}
        />
        <Button
          variant={EButtonVariants.Outlined}
          onClick={handleSubmit}
          size="large"
          disabled={isCreatingComment}
        >
          {getLocalizedText("system.submit")}
        </Button>
      </Stack>
    );
  };

  const renderCommentItem = (comment: IComment) => {
    const { createdAt, content, createdByAccount } = comment;

    return (
      <Box sx={sx.content.comment}>
        <Box
          onClick={() => handleClickDelete(comment)}
          sx={sx.content.closeIcon}
        >
          <Icon path={EAssets.CloseIcon} width="14px" height="14px" />
        </Box>
        <Typography variant="caption">{`${getName(
          createdByAccount,
        )} ${formatDateAndTime(createdAt)}`}</Typography>
        <Typography variant="subtitle2" fontWeight={EFontWeights.Regular}>
          {content}
        </Typography>
      </Box>
    );
  };

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1}>
        <Typography variant="h5">
          {getLocalizedText("tenant.task.public_comments.title")}
        </Typography>
        {(isFetchingComments || isCreatingComment) && <SpinningLoader />}
      </Stack>
      {renderAddCommentField()}
      {comments?.length === 0 && (
        <Typography textAlign="center">
          {getLocalizedText("comment.empty.info")}
        </Typography>
      )}
      <Stack gap={2}>
        {comments.map(comment => renderCommentItem(comment))}
      </Stack>
      {renderShowAllButton()}
    </Stack>
  );
};
