import { ITask } from "@rentiohq/shared-frontend/dist/types/task.types";
import { ETenantTaskFilter } from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Box,
  Button,
  Divider,
  Drawer,
  ButtonGroup,
  Stack,
  Typography,
  Link,
  SpinningLoader,
  MainCard,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { EButtonVariants } from "@rentiohq/web-shared-next/dist/utils/types/button";
import { EAnchor } from "@rentiohq/web-shared-next/dist/utils/types/drawer";
import { useNavigate } from "react-router-dom";
import { TaskList } from "../TaskList";
import { TaskDrawerStyles } from "./TaskDrawer.styles";

interface TaskDrawerProps {
  taskId?: number;
  tasks: ITask[];
  taskFilter: ETenantTaskFilter;
  isLoading?: boolean;
  openTaskDrawer: boolean;
  handleDrawerOpen: () => void;
  handleLoadMore?: () => void;
  handleTaskTypeChange: (type: ETenantTaskFilter) => void;
}

export const TaskDrawer = ({
  taskId,
  tasks,
  taskFilter,
  isLoading,
  openTaskDrawer,
  handleLoadMore,
  handleDrawerOpen,
  handleTaskTypeChange,
}: TaskDrawerProps) => {
  const navigate = useNavigate();
  const sx = TaskDrawerStyles();

  const types = [
    {
      label: "system.soon",
      id: ETenantTaskFilter.Soon,
    },
    {
      label: "system.later",
      id: ETenantTaskFilter.Later,
    },
    {
      label: "system.follow",
      id: ETenantTaskFilter.Follow,
    },
    {
      label: "system.done",
      id: ETenantTaskFilter.Done,
    },
  ];

  const toggleShowArchivedTasks = () => {
    const filter =
      taskFilter === ETenantTaskFilter.Archived
        ? ETenantTaskFilter.Soon
        : ETenantTaskFilter.Archived;
    navigate("/tasks");
    handleTaskTypeChange(filter);
  };

  return (
    <Box sx={sx.wrapper}>
      <Drawer
        sx={sx.content.drawer}
        variant="persistent"
        anchor={EAnchor.Left}
        open={openTaskDrawer}
        ModalProps={{ keepMounted: true }}
        onClose={handleDrawerOpen}
      >
        {openTaskDrawer && (
          <MainCard border content={false} sx={sx.content.card}>
            <Box sx={sx.content.headerContainer}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h5" sx={sx.content.title}>
                  {taskFilter === ETenantTaskFilter.Done
                    ? getLocalizedText("tenant.tasks.done_tasks.title")
                    : getLocalizedText("system.tasks")}
                </Typography>
                <Button
                  variant={EButtonVariants.Contained}
                  sx={sx.content.addChatBtn}
                  disableElevation
                  onClick={() => navigate("/tasks/add")}
                >
                  +
                </Button>
              </Stack>
              <Divider sx={sx.content.divider} />
              {taskFilter !== ETenantTaskFilter.Archived && (
                <ButtonGroup fullWidth>
                  {types.map(type => (
                    <Button
                      variant={
                        type?.id === taskFilter
                          ? EButtonVariants.Contained
                          : EButtonVariants.Outlined
                      }
                      key={type?.id}
                      onClick={() => handleTaskTypeChange(type?.id)}
                      disableElevation
                    >
                      {getLocalizedText(type?.label)}
                    </Button>
                  ))}
                </ButtonGroup>
              )}
            </Box>
            <Box>
              {isLoading && (
                <Box pt={2}>
                  <SpinningLoader />
                </Box>
              )}
              {!isLoading && <TaskList tasks={tasks} taskId={taskId} />}
            </Box>
            {!isLoading && (
              <Stack
                direction="row"
                justifyContent="space-between"
                mb={{ lg: 3, xl: 0 }}
              >
                <Link
                  label={
                    taskFilter === ETenantTaskFilter.Archived
                      ? getLocalizedText(
                          "tenant.tasks.action.show_active_tasks",
                        )
                      : getLocalizedText("tenant.tasks.action.show_archived")
                  }
                  onClick={toggleShowArchivedTasks}
                />
                {handleLoadMore && (
                  <Link
                    label={getLocalizedText("chats.list.cta.show_more")}
                    onClick={handleLoadMore}
                  />
                )}
              </Stack>
            )}
          </MainCard>
        )}
      </Drawer>
    </Box>
  );
};
