var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import verifyPhoneSchemas from "@rentiohq/shared-frontend/dist/forms/verifyPhone";
import { isPerformingSubmitActionSelector, performingSubmitActionErrorSelector, performingSubmitActionResultSelector, submitActionCreator, } from "@rentiohq/shared-frontend/dist/forms/verifyPhone/schema.verifyPhone.actions";
import { useCountDown } from "@rentiohq/shared-frontend/dist/hooks/useCountDown";
import * as authActions from "@rentiohq/shared-frontend/dist/redux/auth/auth.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, DisplayText, Stack } from "../../components";
import { MultiStepForm } from "../../components/SharedForm/MultiStepForm";
import * as t from "../../services/translationService";
import { StyledAuthActions, StyledAuthBody, StyledAuthContent, StyledAuthFrame, StyledAuthLogo, StyledAuthMain, StyledAuthMedia, StyledAuthMediaWrap, } from "./Auth.styled";
import { useNavigateAfterSuccess } from "./Auth.utils";
import { InternalModeButton } from "./components/InternalModeButton";
var COUNT_LIMIT /* in milliseconds */ = 60000;
var VerifyPhone = function () {
    var navigate = useNavigate();
    // Router
    var navigateAfterSuccess = useNavigateAfterSuccess();
    var _a = useCountDown(COUNT_LIMIT), remainingCountDownInSeconds = _a.remainingCountDownInSeconds, startCountdown = _a.startCountdown;
    // State
    var _b = React.useState(true), preventRedirect = _b[0], setPreventRedirect = _b[1];
    // Redux
    var accessToken = useSelector(authSelectors.hasAccessToken);
    var user = authHooks.useSelf().user;
    var dispatch = useDispatch();
    // Lifecycle
    React.useEffect(function () {
        if (preventRedirect) {
            return;
        }
        if (!user || user.unverifiedPhone) {
            return;
        }
        navigateAfterSuccess();
    }, [accessToken, navigateAfterSuccess, preventRedirect, user]);
    var handleResendPhone = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            startCountdown();
            dispatch(authActions.signUpResendVerificationSms());
            return [2 /*return*/];
        });
    }); };
    // Render
    return (_jsxs(StyledAuthFrame, { children: [_jsx(StyledAuthContent, { children: _jsxs(StyledAuthMain, { children: [_jsx(InternalModeButton, {}), _jsx(StyledAuthLogo, {}), _jsxs(StyledAuthBody, { children: [_jsx(DisplayText, { children: t.authVerificationTitle() }), _jsx(Spacer, { weight: ESpacerWeight.W04 }), _jsx(MultiStepForm, { formId: "verify-phone", 
                                    // @ts-ignore state match
                                    isPerformingSubmitActionSelector: isPerformingSubmitActionSelector(), 
                                    // @ts-ignore state match
                                    performingSubmitActionResultSelector: performingSubmitActionResultSelector(), 
                                    // @ts-ignore state match
                                    performingSubmitActionErrorSelector: performingSubmitActionErrorSelector(), submitActionCreator: submitActionCreator(), schemas: verifyPhoneSchemas({}), asModal: false, withAside: false, onSuccess: function () {
                                        setPreventRedirect(false);
                                    }, submitLabel: getLocalizedText("auth.verification.submit.label"), fullWidthSubmitButton: true }), _jsx(StyledAuthActions, { children: _jsxs(Stack, { spacing: "tight", distribution: "center", children: [_jsx("p", { children: t.authVerifySmsResendPhoneMessage() }), _jsxs(Button, { appearance: "link", onClick: handleResendPhone, isDisabled: remainingCountDownInSeconds > 0, children: [t.authVerifyPhoneResendPhoneAction(), remainingCountDownInSeconds > 0 &&
                                                        " (".concat(remainingCountDownInSeconds, ")")] })] }) }), _jsx(StyledAuthActions, { children: _jsx(Button, { onClick: function () {
                                            dispatch({ type: "LOG_OUT" });
                                            navigate("/auth/signin");
                                        }, appearance: "link", children: t.authToLogin() }) })] })] }) }), _jsx(StyledAuthMediaWrap, { children: _jsx(StyledAuthMedia, {}) })] }));
};
// eslint-disable-next-line import/no-default-export
export default VerifyPhone;
