import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import {
  getValue,
  mapFormFieldBooleanToSummary,
} from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.registrationAddDocument.types";

export const getSchema =
  (
    initialValues: {
      [key: string]: unknown;
    },
    _: IExtraData,
  ) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    return {
      required: [EField.IsSharedLease, EField.ReferencePrice],
      properties: {
        [EField.Title1 + "8"]: {
          default: undefined,
        },
        [EField.IsSharedLease]: {
          default: getValue(EField.IsSharedLease, initialValues, defaultValues),
          // @ts-ignore
          options: ["yes", "no"].map(id => ({
            id,
            value: getLocalizedText(`system.${id}`.toLowerCase()),
          })),
        },
        [EField.ReferencePrice]: {
          default: getValue(
            EField.ReferencePrice,
            initialValues,
            defaultValues,
          ),
          minimum: 1,
        },
      },
    };
  };

export const uiSchema = (
  extraData: IExtraData,
): IUiSchema<EField | EField> => ({
  "rentio:title": getLocalizedText(
    "registration.create.form.step.contract_additional_information",
  ),
  [EField.Title1 + "8"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText(
      "contract_info.create.additional_information.heading",
    ),
  },
  [EField.IsSharedLease]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("contract.is_shared_lease.title"),
    formatSummary: (value?: string) => mapFormFieldBooleanToSummary(value),
  },
  [EField.ReferencePrice]: {
    "ui:field": ERentioFormField.Number,
    formatSummary: (formData?: number) => formData && formatCurrency(formData),
    title: getLocalizedText("contract.reference_price.label"),
    placeholder: "0",
    suffixText: "EUR",
    isFloat: true,
    decimals: CONFIG.DECIMALS_FOR_CURRENCY,
  },
});
