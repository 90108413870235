import { Loadable } from "@rentiohq/web-shared-next/dist/ui-components/Loadable";
import { lazy } from "react";
const Dashboard = Loadable(lazy(() => import("../scenes/Dashboard")));
const Applications = Loadable(lazy(() => import("../scenes/Applications")));
const Chats = Loadable(lazy(() => import("../scenes/Chats")));
const Contacts = Loadable(lazy(() => import("../scenes/Contacts")));
const Payments = Loadable(lazy(() => import("../scenes/Payments")));

export const PrivateRoutes = [
  {
    path: "",
    element: <Dashboard />,
  },
  {
    path: "applications",
    element: <Applications />,
  },
  {
    path: "chats",
    element: <Chats />,
  },
  {
    path: "chats/:chatId",
    element: <Chats />,
  },
  {
    path: "contacts",
    element: <Contacts />,
  },
  {
    path: "payments",
    element: <Payments />,
  },
];
