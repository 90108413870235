import { contactSelectInlineV2ValueSelector } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import {
  IAccount,
  IMember,
} from "@rentiohq/shared-frontend/dist/types/auth.types";
import { EPropertyMemberTypes } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ERentioFormField,
  IUiSchema,
} from "@rentiohq/web-shared-next/dist/utils/types/form";
import { uniqBy } from "lodash";
import { EField, IExtraData } from "../addForm/schema.addTask.types";

const getBrokerFilter =
  (accountIdsToRender: number[], extraData: IExtraData) =>
  (accountId: number) => {
    const { employees, broker } = extraData;

    if (broker) {
      const employeeIncluded = employees?.some(employee =>
        accountIdsToRender.includes(employee.id),
      );
      if (employeeIncluded) {
        return accountId !== broker.accountId;
      }
    }

    return true;
  };

const getDisallowedAccountIds =
  (extraData: IExtraData) => (_: any, selectedAccountIds: number[]) => {
    const { employees, broker } = extraData;

    if (broker) {
      const includesAtLeastOneEmployee = employees?.some(employee =>
        selectedAccountIds.includes(employee.id),
      );
      // We don't allow the broker to be selected when an employee is selected
      if (includesAtLeastOneEmployee) {
        return [broker.accountId];
      }
    }

    return [];
  };

const filterPropertyMembers = (
  state: IPartialRootState,
  members: IMember<EPropertyMemberTypes>[],
) => {
  const allAccounts = members
    .filter(
      member =>
        !(
          member.roles.includes(EPropertyMemberTypes.TaskMember) &&
          member.roles.length === 1
        ),
    )
    .map(member => member.account)
    .filter(account =>
      Object.values(state.contact.data).some(
        contact =>
          contact?.accountId === account.id ||
          contact?.accountIds.includes(account.id),
      ),
    );

  return uniqBy(uniqBy(allAccounts, "id"), x => x.userId || Math.random());
};

export const uiSchema = (extraData: IExtraData): IUiSchema => {
  const { property, employees } = extraData;
  return {
    stepTitle: getLocalizedText("task.members.section_header"),
    heading: getLocalizedText("task.members.section_header"),
    fields: [
      {
        fieldKey: EField.Executors,
        uiComponent: ERentioFormField.ContactCheckSelectInline,
        title: getLocalizedText("tasks.executors.label"),
        required: true,
        valueSelector: (
          state: IPartialRootState,
          selectedAccountIds?: number[] | string,
        ) =>
          contactSelectInlineV2ValueSelector(state, selectedAccountIds, [
            ...(property?.members.map(member => member.account) || []),
            ...(employees || []),
          ]),
        componentProps: {
          fieldLabel: getLocalizedText("tasks.add.executors"),
          placeholder: getLocalizedText("tasks.add.executors"),
          isMultiSelect: true,
          asOptionList: true,
          includeEmployees: true,
          autoFocus: true,
          includeSelf: true,
          employeeAndBrokerCanBeSelectedAtTheSameTime: false,
          accountIdsToRenderFilter: (accountIdsToRender: number[]) =>
            getBrokerFilter(accountIdsToRender, extraData),
          customExtraAccountsSelector: (
            state: IPartialRootState,
          ): IAccount[] | undefined => {
            if (property?.members) {
              return filterPropertyMembers(state, property?.members);
            }
            return undefined;
          },
          customIsFetchingSelector: () => false,
          customGetContactsActions: [],
          getDisallowedAccountIds: getDisallowedAccountIds(extraData),
        },
      },
      {
        fieldKey: EField.Followers,
        uiComponent: ERentioFormField.ContactCheckSelectInline,
        title: getLocalizedText("tasks.followers.label"),
        extraInfoUrl: getLocalizedText("task.members_info_url"),
        valueSelector: (
          state: IPartialRootState,
          selectedAccountIds?: number[] | string,
        ) =>
          contactSelectInlineV2ValueSelector(state, selectedAccountIds, [
            ...(property?.members.map(member => member.account) || []),
            ...(employees || []),
          ]),
        componentProps: {
          fieldLabel: getLocalizedText("tasks.add.followers"),
          placeholder: getLocalizedText("tasks.add.followers"),
          isMultiSelect: true,
          asOptionList: true,
          autoFocus: true,
          includeSelf: true,
          includeEmployees: true,
          employeeAndBrokerCanBeSelectedAtTheSameTime: false,
          customExtraAccountsSelector: (
            state: IPartialRootState,
          ): IAccount[] | undefined => {
            if (property?.members) {
              return filterPropertyMembers(state, property?.members);
            }
            return undefined;
          },
          accountIdsToRenderFilter: (accountIdsToRender: number[]) =>
            getBrokerFilter(accountIdsToRender, extraData),
          customGetContactsActions: [],
          getDisallowedAccountIds: getDisallowedAccountIds(extraData),
        },
      },
    ],
  };
};
