import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import {
  ITask,
  ETaskMemberType,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  getIsLastTask,
  getIsTaskAdmin,
} from "@rentiohq/shared-frontend/dist/utils/task.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  Box,
  Button,
  Stack,
  Typography,
  Link,
  Icon,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { Menu } from "@rentiohq/web-shared-next/dist/ui-components";
import { EButtonVariants } from "@rentiohq/web-shared-next/dist/utils/types/button";
import { useNavigate } from "react-router-dom";
import { TaskHeaderStyles } from "./TaskHeader.styles";

export interface IProps {
  task: ITask;
  handleDelete: () => void;
  handleArchive: () => void;
  handleUnArchive: () => void;
  handleFinishTask: () => void;
  handleActivateTask: () => void;
}

export const TaskHeader = ({
  task,
  handleDelete,
  handleArchive,
  handleUnArchive,
  handleFinishTask,
  handleActivateTask,
}: IProps) => {
  const navigate = useNavigate();

  const sx = TaskHeaderStyles();

  const {
    createdAt,
    title,
    propertyId,
    dueDate,
    archivedAt,
    finishedAt,
    executeDate,
  } = task;

  const admin = getMembersWithRole(task.members, ETaskMemberType.Admin)[0]
    ?.account;

  const { detail: property } = propertyHooks.useDetail({
    shouldRefetch: false,
    id: propertyId,
  });

  const navigateToDetail = () => {
    if (!property) {
      return;
    }

    navigate(`/properties/${property.id}`);
  };

  const actions = [
    !archivedAt &&
      getIsLastTask(task) && {
        label: "system.edit",
        onClick: () => navigate(`/tasks/${task.id}/edit`),
      },
    !archivedAt && {
      label: "system.archive",
      danger: true,
      onClick: handleArchive,
    },
    getIsTaskAdmin(task) && {
      label: "system.delete",
      danger: true,
      onClick: handleDelete,
    },
  ].filter(Boolean) as {
    label: string;
    onClick: () => void;
  }[];

  const renderActionButton = () => {
    if (archivedAt) {
      return (
        <Button variant={EButtonVariants.Outlined} onClick={handleUnArchive}>
          {getLocalizedText("task.unarchive")}
        </Button>
      );
    }

    if (!finishedAt) {
      return (
        <Button variant={EButtonVariants.Contained} onClick={handleFinishTask}>
          {getLocalizedText("system.check")}
        </Button>
      );
    }

    return (
      <Button variant={EButtonVariants.Outlined} onClick={handleActivateTask}>
        {getLocalizedText("task.activate")}
      </Button>
    );
  };

  return (
    <>
      <Box sx={sx.desktopWrapper}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="caption" sx={sx.content.title}>
              {getLocalizedText("system.created_at", {
                date: formatDate(createdAt),
              })}{" "}
              by {getName(admin)}
            </Typography>
            <Typography variant="h3" sx={sx.content.title} my={0.4}>
              {title}
            </Typography>
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              divider={<Typography>|</Typography>}
            >
              {property && (
                <Link
                  label={formatAddress(property)}
                  underlined={false}
                  fontSize={12}
                  onClick={navigateToDetail}
                />
              )}
              {executeDate && (
                <Typography variant="caption">
                  {`${getLocalizedText("task.execute_date.meta")}: ${formatDate(
                    executeDate,
                  )}`}
                </Typography>
              )}
              {dueDate && (
                <Typography variant="caption">
                  {`${getLocalizedText("task.due_date")}: ${formatDate(
                    dueDate,
                  )}`}
                </Typography>
              )}
            </Stack>
          </Box>
          <Stack direction="row" gap={1}>
            {renderActionButton()}
            {actions.length > 0 && <Menu actions={actions} />}
          </Stack>
        </Stack>
      </Box>
      <Box sx={sx.mobileWrapper}>
        <Box onClick={() => navigate("/tasks")} sx={sx.content.button}>
          <Icon path={EAssets.ArrowLeftIcon} width="18px" height="18px" />
        </Box>
        <Typography variant="h5" sx={sx.content.title}>
          {getLocalizedText("tenant.task_detail")}
        </Typography>
      </Box>
    </>
  );
};
