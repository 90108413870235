import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  DataList,
  DataListItem,
  IconAvatar,
  MainCard,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { ContractCard } from "../../../../components/ContractCard";

interface IProps {
  contracts: IContract[];
  title: string;
  showDetails?: boolean;
}

export const ContractList = ({
  contracts,
  title,
  showDetails = true,
}: IProps) => {
  if (contracts?.length === 0) {
    return null;
  }

  return (
    <DataList showDivider={false} gap={1}>
      {contracts.map(contract => (
        <MainCard
          title={title}
          border={true}
          key={contract?.id}
          startIcon={<IconAvatar icon={EAssets.NotesIcon} />}
        >
          <DataListItem
            mainContent={
              <ContractCard contract={contract} showDetails={showDetails} />
            }
          />
        </MainCard>
      ))}
    </DataList>
  );
};
