import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import {
  ETaskLocation,
  ETaskType,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ERentioFormField,
  IFormikValues,
  IUiSchema,
  TField,
} from "@rentiohq/web-shared-next/dist/utils/types/form";
import { EField, IExtraData } from "./schema.addTask.types";
import { getMappedCategories } from "./schema.addTask.utils";

const getCategoryTaskTypeKey = (taskType: ETaskType) =>
  `${EField.Category}-${taskType}`;

export const uiSchema = (extraData: IExtraData): IUiSchema => {
  const { taskId } = extraData;

  const categoryFields = Object.values(ETaskType).reduce(
    (all: TField[], taskType) => {
      const key = getCategoryTaskTypeKey(taskType);
      const mappedCategories = getMappedCategories(taskType);

      all.push({
        fieldKey: key,
        uiComponent: ERentioFormField.Select,
        title: getLocalizedText("task.category"),
        getSpan: formData => [6, 6],
        formatSummary: <T>(value: T) =>
          typeof value === "string"
            ? getLocalizedText(`task.category.${value.toLowerCase()}`)
            : "",
        optional: true,
        showIf: (formData: any) =>
          formData[EField.Type] === taskType && mappedCategories.length > 0,
        componentProps: {
          options: mappedCategories.map(category => ({
            value: getLocalizedText(`task.category.${category.toLowerCase()}`),
            id: category,
          })),
        },
      });

      return all;
    },
    [],
  );

  return {
    stepTitle: getLocalizedText("task.information.section_header"),
    heading: getLocalizedText("task.information.section_header"),
    fields: [
      {
        fieldKey: EField.RecurrenceSelection,
        title: getLocalizedText("task.frequency"),
        uiComponent: ERentioFormField.SegmentedControl,
        subtitle: getLocalizedText("contract_info.lease_type_info.content"),
        formatSummary: <T>(value: T) =>
          typeof value === "string"
            ? getLocalizedText(`task.recurring_type.${value.toLowerCase()}`)
            : "",
        isDisabled: formData => !!taskId,
        componentProps: {
          options: [
            {
              id: "ONCE",
              value: getLocalizedText("task.recurring_type.once"),
            },
            {
              id: "RECURRING",
              value: getLocalizedText("task.recurring_type.recurring"),
            },
          ],
        },
      },
      {
        fieldKey: EField.Type,
        title: getLocalizedText("task.type"),
        uiComponent: ERentioFormField.Select,
        optional: true,
        getSpan: formData => [6, 6],
        formatSummary: (input?: string) =>
          input ? getLocalizedText(`task.type.${input.toLowerCase()}`) : "",
        componentProps: {
          placeholder: getLocalizedText("task.type.placeholder"),
          options: Object.values(ETaskType).map((type: string) => ({
            value: getLocalizedText(`task.type.${type.toLowerCase()}`),
            id: type,
          })),
        },
      },
      ...categoryFields,
      {
        fieldKey: EField.Title,
        uiComponent: ERentioFormField.TextField,
        title: getLocalizedText("task.subject.label"),
        subtitle: getLocalizedText("task.subject.description"),
        required: true,
        componentProps: {
          autoFocus: true,
          maxLength: (formData: IFormikValues) => 64,
          placeholder: getLocalizedText("task.subject.placeholder"),
        },
      },
      {
        fieldKey: EField.Description,
        uiComponent: ERentioFormField.TextArea,
        title: getLocalizedText("system.description"),
        optional: true,
        componentProps: {
          placeholder: getLocalizedText("system.description.placeholder"),
        },
      },
      {
        fieldKey: EField.PropertySelect,
        uiComponent: ERentioFormField.PropertySelectInline,
        title: getLocalizedText("choose.property"),
        optional: true,
        componentProps: {
          maxItems: 1,
        },
        valueSelector: (state: IPartialRootState, propertyId: number) => {
          if (propertyId) {
            const property = state.PROPERTY_V2.detail[propertyId]?.entity;
            if (property) {
              return property.name;
            }

            return getLocalizedText("summary.view.property");
          }
          return getLocalizedText("property.no.summary");
        },
      },
      {
        fieldKey: EField.Location,
        uiComponent: ERentioFormField.Select,
        title: getLocalizedText("task.location"),
        formatSummary: <T>(value: T) =>
          value && typeof value === "string"
            ? getLocalizedText(`task.location.${value.toLowerCase()}`)
            : "",
        optional: true,
        componentProps: {
          options: Object.values(ETaskLocation).map(location => ({
            value: getLocalizedText(`task.location.${location.toLowerCase()}`),
            id: location,
          })),
        },
      },
    ],
  };
};
