import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { IContactListDataSourceItem } from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import {
  contactSelectInlineV2ValueSelector,
  getValue,
} from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { EContractMemberTypes } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { EPropertyMemberTypes } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { ts } from "../../services";
import {
  getFilename,
  getDefaultSignerInfo,
} from "./schema.createDocumentPackage.6.utils";
import { EField, IExtraData } from "./schema.createDocumentPackage.types";
import {
  getNavigationTitle,
  getRelatedContract,
  getRelatedProperty,
} from "./schema.createDocumentPackage.utils";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    const defaultFilename = getFilename({ formData: defaultValues, extraData });

    const {
      hasDefaultSigners,
      hasDefaultBrokerSignAsOwnerField,
      defaultSigners,
      defaultSignersAsBroker,
    } = getDefaultSignerInfo({
      formData: defaultValues,
      extraData,
    });

    return {
      required: [EField.Name],
      properties: {
        [EField.Header + "6"]: {
          default: undefined,
        },
        [EField.Name]: {
          default: defaultFilename,
        },
        [EField.LegalContactInfo]: {
          default: undefined,
          // @ts-ignore
          showIf: (_: any) =>
            extraData.isBroker &&
            !extraData.legalContact &&
            !extraData.sendToTenant,
        },
        [EField.Signers]: {
          default: hasDefaultSigners
            ? defaultSigners
            : getValue(EField.Signers, initialValues, defaultValues),
        },
        [EField.SignAsBroker]: {
          default: hasDefaultSigners
            ? defaultSignersAsBroker
            : getValue(EField.SignAsBroker, initialValues, defaultValues),
          // @ts-ignore
          showIf: (_: any) =>
            extraData.isBroker &&
            extraData.legalContact &&
            extraData.contactMeMaster &&
            (!hasDefaultSigners || hasDefaultBrokerSignAsOwnerField),
          options: (() => {
            if (!extraData.contactMeMaster) {
              return [];
            }

            return [
              {
                id: extraData.contactMeMaster.account.id,
                value:
                  extraData.contactMeMaster.account.company ||
                  `${extraData.contactMeMaster.account.firstname} ${extraData.contactMeMaster.account.lastname}`,
                subValue: utils.i18n.t("role.broker"),
              },
            ];
          })(),
        },
      },
    };
  };

export const uiSchema = (
  initialValues: { [key: string]: any },
  extraData: IExtraData,
): IUiSchema<EField> => ({
  "rentio:title": utils.i18n.t("document_package.form.info.heading"),
  "rentio:navigationTitle": getNavigationTitle(),
  [EField.Header + "6"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: utils.i18n.t("document_package.form.info.heading") + "as",
  },
  [EField.Name]: {
    "ui:field": ERentioFormField.Text,
    title: utils.i18n.t("document_package.form.name.label"),
  },
  [EField.LegalContactInfo]: {
    "ui:field": ERentioFormField.HelpInfo,
    type: "warning",
    title: getLocalizedText(
      "document_package.form.no_legal_contact_info.heading",
    ),
    subtitle: getLocalizedText(
      "document_package.form.no_legal_contact_info.content",
    ),
  },
  [EField.Signers]: {
    "ui:field": ERentioFormField.ContactSelectInlineV2,
    title: utils.i18n.t("document_package.form.signers.label"),
    isMultiSelect: true,
    asOptionList: true,
    isDisabled: (formData: any) =>
      getDefaultSignerInfo({ formData, extraData }).hasDefaultSigners,
    requiredContactFields: [
      "dateOfBirth",
      "placeOfBirth",
      "nationalRegisterNumber",
    ],
    selectContactFromListCtaTitle: utils.i18n.t(
      "document_package.form.signers.select_contact.action",
    ),
    customAddCtaLabel: (formData: any) => {
      if (
        [
          EDocumentPackageTypes.Contract,
          EDocumentPackageTypes.RentalAssignment,
        ].includes(formData[EField.DocumentPackageType])
      ) {
        return;
      }

      return utils.i18n.t("document_package.form.signers.add_contact.action");
    },
    valueSelector: (
      state: IPartialRootState,
      selectedAccountIds: number[] | string = [],
      formData: any,
    ) => {
      const relatedContract = getRelatedContract(extraData, formData);

      return contactSelectInlineV2ValueSelector(
        state,
        selectedAccountIds,
        relatedContract
          ? relatedContract.members.map(member => member.account)
          : [],
      );
    },
    customGetSubtitleSelector: (
      _: IPartialRootState,
      formData: any,
      item: IContactListDataSourceItem,
    ) => {
      const { accountId, contact } = item;

      const relatedContract = getRelatedContract(extraData, formData);
      const relatedProperty = getRelatedProperty(extraData, formData);

      if (relatedContract) {
        const contractMember = relatedContract.members.find(member => {
          if (accountId && accountId === member.account.id) {
            return true;
          }

          if (contact?.accountIds.includes(member.account.id)) {
            return true;
          }

          return false;
        });

        if (contractMember) {
          return contractMember.roles
            .filter(
              role =>
                ![
                  EContractMemberTypes.Signer,
                  EContractMemberTypes.Admin,
                ].includes(role),
            )
            .map(role => ts.role(role.toLowerCase()))
            .join(", ");
        }
      }

      if (relatedProperty) {
        const propertyMember = relatedProperty.members.find(member => {
          if (accountId && accountId === member.account.id) {
            return true;
          }

          if (contact?.accountIds.includes(member.account.id)) {
            return true;
          }

          return false;
        });

        if (propertyMember) {
          return propertyMember.roles
            .filter(
              role =>
                ![
                  EPropertyMemberTypes.Signer,
                  EPropertyMemberTypes.Admin,
                ].includes(role),
            )
            .map(role => ts.role(role.toLowerCase()))
            .join(", ");
        }
      }

      return;
    },
    customExtraAccountsSelector: (state: IPartialRootState, formData: any) => {
      const { defaultSigners } = getDefaultSignerInfo({
        formData,
        extraData,
      });

      const relatedContract = getRelatedContract(extraData, formData);
      const relatedProperty = getRelatedProperty(extraData, formData);

      if (!!formData[EField.Contract] && relatedContract) {
        return relatedContract.members
          .filter(
            member =>
              defaultSigners.includes(member.account.id) ||
              member.roles.some(role =>
                [
                  EContractMemberTypes.Owner,
                  EContractMemberTypes.Tenant,
                  EContractMemberTypes.Parent,
                  EContractMemberTypes.Broker,
                ].includes(role),
              ),
          )
          .map(member => member.account);
      }

      if (!!formData[EField.Property] && relatedProperty) {
        return relatedProperty.members
          .filter(member =>
            extraData.isBroker && extraData.legalContact
              ? true
              : defaultSigners.includes(member.account.id) ||
                member.roles.some(role =>
                  [
                    EPropertyMemberTypes.Owner,
                    EPropertyMemberTypes.Tenant,
                    EPropertyMemberTypes.Parent,
                  ].includes(role),
                ),
          )
          .map(member => member.account);
      }

      return extraData.contactMeMaster
        ? [extraData.contactMeMaster.account]
        : undefined;
    },
    customIsFetchingSelector: () => false,
    customGetContactsActions: [],
    getDisallowedAccountIds: (_: any) => [],
  },
  [EField.SignAsBroker]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("document_package.form.sign_as_broker.label"),
    isMultiSelect: true,
    asOptionList: true,
    hideOnSummaryIfEmpty: true,
    isDisabled: (formData: any) =>
      getDefaultSignerInfo({ formData, extraData }).hasDefaultSigners,
    formatSummary: (formData: any) => {
      if (!formData || !extraData.contactMeMaster) {
        return;
      }

      return (
        extraData.contactMeMaster.account.company ||
        `${extraData.contactMeMaster.account.firstname} ${extraData.contactMeMaster.account.lastname}`
      );
    },
  },
});

export const getValidate =
  (_: IExtraData) => (formData: any, errors: any, _mergedFormData: any) => {
    if (!formData[EField.Signers] && !formData[EField.SignAsBroker]) {
      errors[EField.Signers].addError(
        utils.i18n.t("document_package.form.signers.error.required"),
      );
    }

    return errors;
  };
