import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { ITask } from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  // Chip,
  Stack,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { Fragment } from "react";
import { NumberParam } from "serialize-query-params";
import { TaskListStyles } from "./TaskList.styles";

interface IProps {
  taskId?: number;
  tasks: ITask[];
}

export const TaskList = ({ tasks, taskId }: IProps) => {
  const [_, setQueryParamValue] = useQueryParams({
    taskId: NumberParam,
  });

  const sx = TaskListStyles();

  const showTaskDetails = (id: number) => {
    setQueryParamValue({
      taskId: id,
    });
  };

  if (tasks?.length === 0) {
    return (
      <Typography variant="h5" my={2}>
        {getLocalizedText("tasks.empty")}
      </Typography>
    );
  }

  return (
    <List component="nav">
      {tasks.map(task => {
        const { title, description, id } = task;

        return (
          <Fragment key={id}>
            <ListItemButton
              sx={sx.content.listItemBtn(task?.id === taskId)}
              onClick={() => showTaskDetails(task?.id)}
            >
              <ListItemText
                primary={
                  <Typography variant="h6" sx={sx.content.title}>
                    {title}
                  </Typography>
                }
                secondary={
                  <Stack gap={2} direction="row" justifyContent="space-between">
                    <Typography variant="caption" sx={sx.content.description}>
                      {description}
                    </Typography>
                    {/* <Chip sx={sx.content.chip} /> */}
                  </Stack>
                }
              />
            </ListItemButton>
            <Divider sx={sx.content.divider} />
          </Fragment>
        );
      })}
    </List>
  );
};
