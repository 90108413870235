var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Ajv from "ajv";
import { checkVAT, countries } from "jsvat-next";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import upperFirst from "lodash/upperFirst";
import { EIdCardOptions } from "../../forms/addContact/schema.addContact.1.broker";
import { generateRandomInteger } from "../../utils/general.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import * as contactSelectors from "../contact/contact.selectors";
import { getContactNamesForAccountIds } from "../contact/contact.selectors";
// Helpers
var modFunction = function (number) {
    return 97 - (number % 97);
};
export var generateFormId = function () { return generateRandomInteger(10000); };
export var getFieldValue = function (uiSchema, formContext, propertyName) {
    var value = uiSchema[propertyName];
    var getValue = uiSchema["get".concat(upperFirst(propertyName))];
    if (getValue) {
        return getValue(formContext.getFormData());
    }
    return value;
};
export var getFieldTitle = function (uiSchema, formContext) {
    return getFieldValue(uiSchema, formContext, "title");
};
export var getFieldInfo = function (uiSchema, formContext) {
    return getFieldValue(uiSchema, formContext, "info");
};
var sanitizeValue = function (value) {
    if (value === null) {
        return;
    }
    if (Array.isArray(value) && value.length === 0) {
        return;
    }
    if (value === "") {
        return;
    }
    return value;
};
export var getValue = function (key, initialValues, defaultValues) {
    var value = key in defaultValues ? defaultValues[key] : initialValues[key];
    return sanitizeValue(value);
};
export var getValueOrExtraValue = function (key, defaultValues, extraValue, withKeyCheck) {
    if (withKeyCheck === void 0) { withKeyCheck = true; }
    var value;
    if (withKeyCheck) {
        value = key in defaultValues ? defaultValues[key] : extraValue;
    }
    else {
        value =
            defaultValues[key] !== undefined && defaultValues[key] !== null
                ? defaultValues[key]
                : extraValue;
    }
    return sanitizeValue(value);
};
/**
 * Checks wether the input holds any value or not.
 *
 * Empty values are: *null*, *undefined*, [], {} & ''
 *
 * @param input: any
 */
export var hasValue = function (input) {
    if (isNumber(input)) {
        return true;
    }
    return !isNil(input) && !isEmpty(input);
};
export var contactSelectInlineV2ValueSelector = function (state, selectedAccountIds, accounts) {
    if (selectedAccountIds === void 0) { selectedAccountIds = []; }
    if (accounts === void 0) { accounts = []; }
    if (typeof selectedAccountIds === "string") {
        return;
    }
    if (selectedAccountIds.length === 0) {
        return "-";
    }
    var namesObject = getContactNamesForAccountIds(state, selectedAccountIds, accounts);
    var names = Object.values(namesObject);
    if (names.length === 0) {
        return getLocalizedText("summary.view.contact");
    }
    return names.join(", ");
};
export var ibanSelectInlineValueSelector = function (state, ibanId) {
    var iban = contactSelectors.getIban(state, ibanId);
    if (!iban) {
        return getLocalizedText("summary.view.iban");
    }
    return iban.iban;
};
export var isValidRRNo = function (original) {
    var string = original.replace(/\D/g, "");
    // RR numbers need to be 11 chars long
    if (string.length !== 11)
        return false;
    var checkDigit = parseInt(string.substr(string.length - 2, 2));
    var nrToCheck = parseInt(string.substr(0, 9));
    // first check without 2
    if (modFunction(nrToCheck) === checkDigit) {
        return true;
    }
    // then check with 2 appended for y2k+ births
    var Y2KNrToCheck = parseInt("2" + string.substr(0, 9));
    return modFunction(Y2KNrToCheck) === checkDigit;
};
export var isValidVATNumber = function (original) {
    var vatNumber = checkVAT("".concat(original), countries);
    if (vatNumber.isValid && vatNumber.isValidFormat) {
        return true;
    }
    return false;
};
export var formatContactForEdit = function (contact) {
    var street = contact.street, number = contact.number, box = contact.box, zip = contact.zip, city = contact.city, countryId = contact.countryId, nationality = contact.nationality, countryOfBirth = contact.countryOfBirth, placeOfBirth = contact.placeOfBirth;
    var idType = nationality === "BE"
        ? EIdCardOptions.BelgianIdCardNumber
        : EIdCardOptions.PassportNumber;
    return __assign(__assign({}, contact), { idType: idType, address: { street: street, number: number, box: box, zip: zip, city: city, country: countryId }, placeOfBirth: {
            city: placeOfBirth,
            country: countryOfBirth,
        } });
};
export var areContactFieldsValid = function (params) {
    var contact = params.contact, requiredContactFieldsSchema = params.requiredContactFieldsSchema, requiredCompanyFieldsSchema = params.requiredCompanyFieldsSchema;
    var ajv = new Ajv({ allErrors: true, jsonPointers: true });
    var validateContact = requiredContactFieldsSchema
        ? ajv.compile(requiredContactFieldsSchema)
        : null;
    var validateCompany = requiredCompanyFieldsSchema
        ? ajv.compile(requiredCompanyFieldsSchema)
        : null;
    var isCompany = !!contact.company;
    // Get correct validate function
    var validate = validateContact;
    if (isCompany && validateCompany) {
        validate = validateCompany;
    }
    if (!validate) {
        return true;
    }
    var contactWithAddress = formatContactForEdit(contact);
    var result = validate(contactWithAddress);
    return result;
};
export var mapNullableBooleanValueToFormField = function (value) {
    if (value === undefined || value === null) {
        return undefined;
    }
    return value ? "yes" : "no";
};
export var mapFormFieldToNullableBooleanValue = function (value) {
    if (value === undefined || value === null) {
        return undefined;
    }
    return value === "yes" ? true : false;
};
export var mapFormFieldBooleanToSummary = function (value) {
    if (value === undefined || value === null) {
        return undefined;
    }
    return value === "yes"
        ? getLocalizedText("system.yes")
        : getLocalizedText("system.no");
};
