export var EPaymentRequestFetchType;
(function (EPaymentRequestFetchType) {
    EPaymentRequestFetchType["Open"] = "open";
    EPaymentRequestFetchType["Pending"] = "pending";
    EPaymentRequestFetchType["Done"] = "done";
})(EPaymentRequestFetchType || (EPaymentRequestFetchType = {}));
export var EPaymentRequestFollowUpType;
(function (EPaymentRequestFollowUpType) {
    EPaymentRequestFollowUpType["Mail"] = "MAIL";
    EPaymentRequestFollowUpType["App"] = "APP";
    EPaymentRequestFollowUpType["Phone"] = "PHONE";
    EPaymentRequestFollowUpType["RegisteredMail"] = "REGISTERED_MAIL";
    EPaymentRequestFollowUpType["Sms"] = "SMS";
})(EPaymentRequestFollowUpType || (EPaymentRequestFollowUpType = {}));
// TODO: Remove
export var EPaymentRequestsDisplayType;
(function (EPaymentRequestsDisplayType) {
    EPaymentRequestsDisplayType["Grid"] = "grid";
    EPaymentRequestsDisplayType["Table"] = "table";
})(EPaymentRequestsDisplayType || (EPaymentRequestsDisplayType = {}));
export var EPaymentRequestStatus;
(function (EPaymentRequestStatus) {
    EPaymentRequestStatus["New"] = "new";
    EPaymentRequestStatus["Approved"] = "approved";
    EPaymentRequestStatus["Pending"] = "pending";
    EPaymentRequestStatus["PendingThirdParty"] = "pending_3rd_party";
    EPaymentRequestStatus["PayInPlanned"] = "planned_pay_in";
    EPaymentRequestStatus["PaidIn"] = "paid_in";
    EPaymentRequestStatus["Partial"] = "partial";
    // cSpell:ignore transfering
    EPaymentRequestStatus["Transferring"] = "transfering";
    // cSpell:ignore transfered
    EPaymentRequestStatus["Transferred"] = "transfered";
    EPaymentRequestStatus["PayingOut"] = "paying_out";
    EPaymentRequestStatus["FailedPaidIn"] = "failed_paid_in";
    EPaymentRequestStatus["FailedTransfer"] = "failed_transfer";
    EPaymentRequestStatus["FailedPayout"] = "failed_pay_out";
    EPaymentRequestStatus["FailedKyc"] = "failed_kyc";
    EPaymentRequestStatus["Failed"] = "failed";
    EPaymentRequestStatus["Paid"] = "paid";
})(EPaymentRequestStatus || (EPaymentRequestStatus = {}));
export var EPaymentRequestFeature;
(function (EPaymentRequestFeature) {
    EPaymentRequestFeature["PlannedPayOut"] = "PLANNED_PAY_OUT";
    EPaymentRequestFeature["BlockedPayment"] = "BLOCKED_PAYMENT";
    EPaymentRequestFeature["ThirdPartyPayInBrokerDirect"] = "THIRD_PARTY_PAYIN_BROKER_DIRECT";
    EPaymentRequestFeature["RestAmount"] = "REST_AMOUNT";
    EPaymentRequestFeature["PartialAmount"] = "PARTIAL_AMOUNT";
    EPaymentRequestFeature["RentDepositIn"] = "RENT_DEPOSIT_IN";
    EPaymentRequestFeature["DisputePayment"] = "DISPUTE_PAYMENT";
})(EPaymentRequestFeature || (EPaymentRequestFeature = {}));
