import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import {
  ETaskMemberType,
  ITask,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import { getMembersWithRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  Box,
  ContactSelectInline,
  Stack,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";

interface IProps {
  task: ITask;
  title: string;
  placeholder: string;
  role: ETaskMemberType;
}

export const TaskMembers = ({ task, title, placeholder, role }: IProps) => {
  const membersForRole = getMembersWithRole(task.members, role);

  const { updateMembers } = taskModule.hooks.useUpdate({
    id: task.id,
  });

  const handleAddMember = (_?: number[], newAccountId?: number) => {
    if (!newAccountId) {
      return;
    }

    updateMembers({
      membersToAdd: [
        {
          roles: [role],
          accountId: newAccountId,
        },
      ],
      membersToRemove: [],
    });
  };

  const handleRemoveMember = (contact?: IContact) => {
    if (!contact) {
      return;
    }

    updateMembers({
      membersToAdd: [],
      membersToRemove: [
        {
          roles: [role],
          accountId: contact.accountId,
        },
      ],
    });
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography variant="h5" mb={2}>
          {title}
        </Typography>
      </Stack>
      <ContactSelectInline
        placeholder={placeholder}
        includeSelf={true}
        value={membersForRole.map(member => member.account.id)}
        onChange={handleAddMember}
        handleContactRemove={handleRemoveMember}
      />
    </Box>
  );
};
