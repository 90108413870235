import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  ERegistrationBrusselsStatus,
  ERegistrationDocumentStatus,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { hasBrusselsRegistration } from "@rentiohq/shared-frontend/dist/utils/property.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import React from "react";
import { renderTooltipChecklist } from "../../../../utils/tooltip.utils";
import StatusDot, { EStatus } from "../StatusDot";

interface IProps {
  property: IProperty;
  contract: IContract;
}

const StatusDotRegistration: React.FC<IProps> = props => {
  const { property, contract } = props;

  // Helpers
  const getStatus = () => {
    const {
      manuallyCompletedRegistration,
      rentRegistrationRentContractStatus = ERegistrationDocumentStatus.None,
      rentRegistrationLocationDescriptionStatus = ERegistrationDocumentStatus.None,
      // rentRegistrationBrusselsStatus = ERegistrationBrusselsStatus.None,
    } = contract;
    const rentRegistrationBrusselsStatus =
      contract.rentRegistrationBrusselsStatus ||
      ERegistrationBrusselsStatus.None;

    const rentRegistrationAddendumStatuses =
      contract.rentRegistrationAddendumStatus || [];

    if (manuallyCompletedRegistration) {
      return EStatus.Success;
    }

    let emptyContract = false;
    let emptyLocationDescription = false;
    let emptyBrussels = false;

    let pendingContract = false;
    let pendingLocationDescription = false;
    let pendingBrussels = false;
    let pendingAddendum = false;

    let successContract = false;
    let successLocationDescription = false;
    let successBrussels = false;
    // let successAddendum = false;

    let failedContract = false;
    let failedLocationDescription = false;
    let failedBrussels = false;
    let failedAddendum = false;

    switch (rentRegistrationRentContractStatus) {
      case ERegistrationDocumentStatus.Registered:
      case ERegistrationDocumentStatus.RegisteredManually:
      case ERegistrationDocumentStatus.NotApplicable:
        successContract = true;
        break;

      case ERegistrationDocumentStatus.Registering:
      case ERegistrationDocumentStatus.NeedPayment:
        pendingContract = true;
        break;

      case ERegistrationDocumentStatus.None:
        emptyContract = true;
        break;

      case ERegistrationDocumentStatus.Rejected:
      case ERegistrationDocumentStatus.Failed:
        failedContract = true;
        break;

      case ERegistrationDocumentStatus.Incomplete:
      default:
        break;
    }

    switch (rentRegistrationLocationDescriptionStatus) {
      case ERegistrationDocumentStatus.Registered:
      case ERegistrationDocumentStatus.RegisteredManually:
      case ERegistrationDocumentStatus.NotApplicable:
        successLocationDescription = true;
        break;

      case ERegistrationDocumentStatus.Registering:
      case ERegistrationDocumentStatus.NeedPayment:
        pendingLocationDescription = true;
        break;

      case ERegistrationDocumentStatus.None:
        emptyLocationDescription = true;
        break;

      case ERegistrationDocumentStatus.Rejected:
      case ERegistrationDocumentStatus.Failed:
        failedLocationDescription = true;
        break;

      case ERegistrationDocumentStatus.Incomplete:
      default:
        break;
    }

    if (hasBrusselsRegistration(property, contract.leaseType)) {
      switch (rentRegistrationBrusselsStatus) {
        case ERegistrationBrusselsStatus.Registered:
        case ERegistrationBrusselsStatus.RegisteredManually:
          successBrussels = true;
          break;

        case ERegistrationBrusselsStatus.Registering:
          pendingBrussels = true;
          break;

        case ERegistrationBrusselsStatus.None:
          emptyBrussels = true;
          break;

        case ERegistrationBrusselsStatus.Failed:
          failedBrussels = true;
          break;

        default:
          break;
      }
    } else {
      successBrussels = true;
    }

    rentRegistrationAddendumStatuses.forEach(rentRegistrationAddendumStatus => {
      switch (rentRegistrationAddendumStatus) {
        case ERegistrationDocumentStatus.Registered:
        case ERegistrationDocumentStatus.RegisteredManually:
        case ERegistrationDocumentStatus.NotApplicable:
          // successAddendum = true;
          break;

        case ERegistrationDocumentStatus.Registering:
        case ERegistrationDocumentStatus.NeedPayment:
          pendingAddendum = true;
          break;

        case ERegistrationDocumentStatus.Rejected:
        case ERegistrationDocumentStatus.Failed:
          failedAddendum = true;
          break;

        case ERegistrationDocumentStatus.None:
        case ERegistrationDocumentStatus.Incomplete:
        default:
          break;
      }
    });

    if (
      successContract &&
      successLocationDescription &&
      successBrussels &&
      //
      !pendingAddendum &&
      !failedAddendum
    ) {
      return EStatus.Success;
    }

    const somethingFailed =
      failedContract ||
      failedLocationDescription ||
      failedBrussels ||
      failedAddendum;

    const somethingPending =
      emptyContract ||
      emptyLocationDescription ||
      emptyBrussels ||
      //
      pendingContract ||
      pendingLocationDescription ||
      pendingBrussels ||
      //
      pendingAddendum;

    if (somethingFailed || somethingPending) {
      return EStatus.Warning;
    }

    return EStatus.Error;
  };

  // Render
  const renderTooltipContent = () => {
    const {
      manuallyCompletedRegistration,
      rentRegistrationRentContractStatus = ERegistrationDocumentStatus.None,
      rentRegistrationLocationDescriptionStatus = ERegistrationDocumentStatus.None,
      // rentRegistrationBrusselsStatus = ERegistrationBrusselsStatus.None,
    } = contract;
    const rentRegistrationBrusselsStatus =
      contract.rentRegistrationBrusselsStatus ||
      ERegistrationBrusselsStatus.None;

    const rentRegistrationAddendumStatuses =
      contract.rentRegistrationAddendumStatus || [];

    if (manuallyCompletedRegistration) {
      return getLocalizedText(manuallyCompletedRegistration);
    }

    const checklist = [];

    checklist.push({
      label: getLocalizedText("registration.status_info.contract", {
        status: getLocalizedText(
          `registration.status.${stringToSnakeCase(
            rentRegistrationRentContractStatus,
          )}`.toLowerCase(),
        ),
      }),
      success: [
        ERegistrationDocumentStatus.Registered,
        ERegistrationDocumentStatus.RegisteredManually,
        ERegistrationDocumentStatus.NotApplicable,
      ].includes(rentRegistrationRentContractStatus),
    });

    checklist.push({
      label: getLocalizedText("registration.status_info.location_description", {
        status: getLocalizedText(
          `registration.status.${stringToSnakeCase(
            rentRegistrationLocationDescriptionStatus,
          )}`.toLowerCase(),
        ),
      }),
      success: [
        ERegistrationDocumentStatus.Registered,
        ERegistrationDocumentStatus.RegisteredManually,
        ERegistrationDocumentStatus.NotApplicable,
      ].includes(rentRegistrationLocationDescriptionStatus),
    });

    if (hasBrusselsRegistration(property, contract.leaseType)) {
      checklist.push({
        label: getLocalizedText("registration.status_info.brussels", {
          status: getLocalizedText(
            `registration.status.${stringToSnakeCase(
              rentRegistrationBrusselsStatus,
            )}`.toLowerCase(),
          ),
        }),
        success: [
          ERegistrationBrusselsStatus.Registered,
          ERegistrationBrusselsStatus.RegisteredManually,
        ].includes(rentRegistrationBrusselsStatus),
      });
    }

    rentRegistrationAddendumStatuses.forEach(status => {
      checklist.push({
        label: getLocalizedText("registration.status_info.addendum", {
          status: getLocalizedText(
            `registration.status.${stringToSnakeCase(status)}`.toLowerCase(),
          ),
        }),
        success: [
          ERegistrationDocumentStatus.Registered,
          ERegistrationDocumentStatus.RegisteredManually,
          ERegistrationDocumentStatus.NotApplicable,
        ].includes(rentRegistrationLocationDescriptionStatus),
      });
    });

    return renderTooltipChecklist(checklist);
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

// eslint-disable-next-line import/no-default-export
export default StatusDotRegistration;
