import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { EFontWeights } from "../../themes/types";
export var BottomMenuStyles = function () {
    var downSM = useMediaQuery(function (theme) { return theme.breakpoints.down("sm"); });
    var theme = useTheme();
    return {
        wrapper: {
            height: "72px",
            position: "fixed",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #E3E8EF",
            display: downSM ? "flex" : "none",
            ".MuiBottomNavigationAction-root": {
                rowGap: "4px",
            },
        },
        content: {
            moreBtn: {
                width: "64px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                rowGap: "4px",
                span: {
                    color: theme.palette.grey[500],
                    fontWeight: EFontWeights.Regular,
                },
            },
            navButtonSelected: {
                color: theme.palette.success.main,
            },
            navButtonDefault: {
                color: theme.palette.grey[500],
            },
        },
    };
};
