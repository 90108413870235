import { ERentioFormField } from "../../components/components/Form";
import { getName } from "../../redux/contact/contact.utils";
import { getValue } from "../../redux/form/form.utils";
import { EContractMemberTypes, EContractPaydate, } from "../../types/contract.types";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { formatCurrency } from "../../utils/number.utils";
import { ECancellationReasons, EField, } from "./schema.cancelContract.types";
import { getOptionsForReasonOfCancellation, shouldShowDropdownForRole, } from "./schema.cancelContract.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return {
            required: [
                EField.MemberThatCancels,
                EField.CancellationDate,
                EField.StopDate,
            ],
            properties: (_a = {},
                _a[EField.Info] = {
                    default: undefined,
                },
                _a[EField.MemberThatCancels] = {
                    default: getValue(EField.MemberThatCancels, initialValues, defaultValues),
                    // TODO: migrate options to uiSchema
                    //@ts-ignore
                    options: extraData.members.map(function (member) { return ({
                        id: member.account.id,
                        value: getName(member.account),
                    }); }),
                },
                _a[EField.CancellationDate] = {
                    default: getValue(EField.CancellationDate, initialValues, defaultValues),
                },
                _a[EField.StopDate] = {
                    default: getValue(EField.StopDate, initialValues, defaultValues),
                },
                _a[EField.ReasonForCancellationTenant] = {
                    default: getValue(EField.ReasonForCancellationTenant, initialValues, defaultValues),
                    // TODO: migrate options to uiSchema
                    //@ts-ignore
                    options: getOptionsForReasonOfCancellation(EField.ReasonForCancellationTenant),
                    //@ts-ignore
                    showIf: function (formData) {
                        return shouldShowDropdownForRole(EContractMemberTypes.Tenant, formData, extraData.members);
                    },
                    requiredIf: function (formData) {
                        return shouldShowDropdownForRole(EContractMemberTypes.Tenant, formData, extraData.members);
                    },
                },
                _a[EField.ReasonForCancellationOwner] = {
                    default: getValue(EField.ReasonForCancellationOwner, initialValues, defaultValues),
                    // TODO: migrate getOptions to uiSchema
                    //@ts-ignore
                    options: getOptionsForReasonOfCancellation(EField.ReasonForCancellationOwner),
                    // TODO: migrate showIf to uiSchema
                    //@ts-ignore
                    showIf: function (formData) {
                        return shouldShowDropdownForRole(EContractMemberTypes.Owner, formData, extraData.members) ||
                            shouldShowDropdownForRole(EContractMemberTypes.Admin, formData, extraData.members);
                    },
                    requiredIf: function (formData) {
                        return shouldShowDropdownForRole(EContractMemberTypes.Owner, formData, extraData.members) ||
                            shouldShowDropdownForRole(EContractMemberTypes.Admin, formData, extraData.members);
                    },
                },
                _a[EField.ReasonForCancellationOther] = {
                    default: getValue(EField.ReasonForCancellationOther, initialValues, defaultValues),
                    // TODO: migrate showIf to uiSchema
                    //@ts-ignore
                    showIf: function (formData) {
                        return (formData[EField.ReasonForCancellationTenant] ===
                            ECancellationReasons.Other &&
                            shouldShowDropdownForRole(EContractMemberTypes.Tenant, formData, extraData.members)) ||
                            (formData[EField.ReasonForCancellationOwner] ===
                                ECancellationReasons.Other &&
                                shouldShowDropdownForRole(EContractMemberTypes.Owner, formData, extraData.members));
                    },
                    requiredIf: function (formData) {
                        return (formData[EField.ReasonForCancellationTenant] ===
                            ECancellationReasons.Other &&
                            shouldShowDropdownForRole(EContractMemberTypes.Tenant, formData, extraData.members)) ||
                            (formData[EField.ReasonForCancellationOwner] ===
                                ECancellationReasons.Other &&
                                shouldShowDropdownForRole(EContractMemberTypes.Owner, formData, extraData.members));
                    },
                },
                _a[EField.CancellationDocuments] = {
                    default: getValue(EField.CancellationDocuments, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:navigationTitle": getLocalizedText("contract.cancellation.modal.heading"),
            "rentio:title": getLocalizedText("contract.cancellation.modal.heading"),
            "ui:field": ERentioFormField.Grid,
            grid: {
                row: [
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 1 /* EDesktopFieldWidth.Full */],
                            children: [EField.Info],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 1 /* EDesktopFieldWidth.Full */],
                            children: [EField.MemberThatCancels],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 1 /* EDesktopFieldWidth.Full */],
                            children: [
                                EField.ReasonForCancellationTenant,
                                EField.ReasonForCancellationOwner,
                            ],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 1 /* EDesktopFieldWidth.Full */],
                            children: [EField.ReasonForCancellationOther],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.5 /* EDesktopFieldWidth.Half */],
                            children: [EField.CancellationDate],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.5 /* EDesktopFieldWidth.Half */],
                            children: [EField.StopDate],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 1 /* EDesktopFieldWidth.Full */],
                            children: [EField.CancellationDocuments],
                        },
                    },
                ],
            }
        },
        _a[EField.Info] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("payment.payment_order.contract_info.heading"),
            getSubtitle: function () {
                var contract = extraData.contract;
                if (!contract) {
                    return getLocalizedText("payments.create.add_costs.no_contract.content");
                }
                var commonCostsCopy = contract.currentCostsCommon
                    ? getLocalizedText("payment.payment_order.contract_info.content.basic.common_costs", {
                        commonCosts: formatCurrency(contract.currentCostsCommon),
                    })
                    : null;
                var nonCommonCostsCopy = contract.currentCostsNonCommon
                    ? getLocalizedText("payment.payment_order.contract_info.content.basic.non_common_costs", {
                        nonCommonCosts: formatCurrency(contract.currentCostsNonCommon),
                    })
                    : null;
                var costsArray = [commonCostsCopy, nonCommonCostsCopy].filter(Boolean);
                return getLocalizedText("payment.payment_order.contract_info.content.basic".concat(contract.stopDate ? "" : ".no_end_date"), {
                    startDate: formatDate(contract.startDate),
                    stopDate: contract.stopDate ? formatDate(contract.stopDate) : "",
                    amount: formatCurrency(contract.currentPrice),
                    costs: costsArray.length > 0
                        ? " + ".concat(costsArray.join(" ".concat(getLocalizedText("system.and"), " ")))
                        : "",
                    payDate: contract.payDateStart === EContractPaydate.Calendar
                        ? getLocalizedText("contract.paydate.before_month")
                        : getLocalizedText("contract.paydate.before_period"),
                });
            },
        },
        _a[EField.MemberThatCancels] = {
            title: getLocalizedText("contract.cancellation.cancelling_member"),
            "ui:field": ERentioFormField.InlineSelect,
        },
        _a[EField.CancellationDate] = {
            title: getLocalizedText("contract.cancellation.cancellation_date"),
            "ui:field": ERentioFormField.Date,
            minDate: extraData.contract.startDate,
        },
        _a[EField.StopDate] = {
            title: getLocalizedText("contract.cancellation.new_end_date"),
            "ui:field": ERentioFormField.Date,
        },
        _a[EField.ReasonForCancellationTenant] = {
            title: getLocalizedText("contract.cancellation.reason"),
            "ui:field": ERentioFormField.DropdownSelect,
        },
        _a[EField.ReasonForCancellationOwner] = {
            title: getLocalizedText("contract.cancellation.reason"),
            "ui:field": ERentioFormField.DropdownSelect,
        },
        _a[EField.ReasonForCancellationOther] = {
            title: getLocalizedText("contract.cancellation.reason"),
            "ui:field": ERentioFormField.Text,
            placeholder: getLocalizedText("contract.cancellation.reason.other.placeholder"),
            multiline: true,
        },
        _a[EField.CancellationDocuments] = {
            "ui:field": ERentioFormField.DocumentSelectInline,
            title: getLocalizedText("contract.cancellation.documents"),
            useExistingDocuments: true,
            optional: true,
            multiple: true,
        },
        _a);
};
// TODO: add validation here so we can check if either TenantReason or OwnerReason is filled
