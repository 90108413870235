import { ITask } from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Box,
  MarkdownWrapper,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";

interface IProps {
  task: ITask;
}

export const Description = ({ task }: IProps) => {
  return (
    <Box>
      <Typography variant="h5">
        {getLocalizedText("system.description")}
      </Typography>
      {!task.description && (
        <Typography textAlign="center">
          {getLocalizedText("description.empty.info")}
        </Typography>
      )}
      <MarkdownWrapper source={task.description} />
    </Box>
  );
};
