var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getContractCost } from "../../reduxV2/contract/contract.utils";
import { EContractMemberTypes } from "../../types/contract.types";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.addLegalCase.types";
import { getLatePaymentOptions, manualLatePaymentsValueSelector, printDocumentNamesSelector, } from "./schema.addLegalCase.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        var latePaymentOptions = getLatePaymentOptions(extraData.allLatePayments);
        return {
            required: [],
            properties: (_a = {},
                _a[EField.ContractInformation] = {
                    default: undefined,
                },
                _a[EField.LatePayments] = {
                    default: getValue(EField.LatePayments, initialValues, defaultValues),
                    // @ts-ignore
                    options: latePaymentOptions,
                    // @ts-ignore
                    requiredIf: function (formData) {
                        var previouslyAddedPayments = Object.values(formData[EField.PreviouslyAddedLatePayments] || {});
                        var manualPayments = Object.values(formData[EField.ManualLatePayments] || {});
                        return (manualPayments.length === 0 &&
                            previouslyAddedPayments.length === 0);
                    },
                },
                _a[EField.Header + "manual"] = {
                    default: undefined,
                    //@ts-ignore
                    showIf: 
                    //
                    function (formData) {
                        return Object.values(formData[EField.PreviouslyAddedLatePayments] || {})
                            .length > 0;
                    },
                },
                _a[EField.PreviouslyAddedLatePayments] = {
                    default: getValue(EField.PreviouslyAddedLatePayments, initialValues, defaultValues),
                    //@ts-ignore
                    showIf: 
                    //
                    function (formData) {
                        return Object.values(formData[EField.PreviouslyAddedLatePayments] || {})
                            .length > 0;
                    },
                },
                _a[EField.Header] = {
                    default: undefined,
                },
                _a[EField.ManualLatePayments] = {
                    default: getValue(EField.ManualLatePayments, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: function (formData) {
                        var latePayments = formData[EField.LatePayments] || [];
                        var previouslyAddedPayments = Object.values(formData[EField.PreviouslyAddedLatePayments] || {});
                        return (latePayments.length === 0 && previouslyAddedPayments.length === 0);
                    },
                },
                _a[EField.Header + "registered_letters"] = {
                    default: undefined,
                },
                _a[EField.RegisteredLetters] = {
                    default: getValue(EField.RegisteredLetters, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    var contract = extraData.contract, legalCase = extraData.legalCase;
    return _a = {
            "rentio:title": getLocalizedText("rentio_legal.form.add.step_2"),
            "rentio:trackingKey": "rentio-legal-case"
        },
        _a[EField.ContractInformation] = {
            "ui:field": ERentioFormField.HelpInfo,
            subtitle: getLocalizedText("rentio_legal.form.contract_info.details", {
                amount: getContractCost(contract),
                start_date: formatDate(contract.startDate),
                end_date: contract.stopDate ? formatDate(contract.stopDate) : "...",
                tenants: contract.members
                    .filter(function (m) { return m.roles.includes(EContractMemberTypes.Tenant); })
                    .map(function (_a) {
                    var account = _a.account;
                    return "".concat(account.firstname, " ").concat(account.lastname);
                })
                    .join(", "),
            }),
            title: getLocalizedText("rentio_legal.form.contract_info.title"),
        },
        _a[EField.LatePayments] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("rentio_legal.form.late_payments"),
            isMultiSelect: true,
            customIsFetchingSelector: function () { return extraData.allLatePayments === undefined; },
            valueSelector: function (state, formData) {
                if (!Array.isArray(formData)) {
                    return formData;
                }
                return (formData || [])
                    .map(function (item) {
                    if (typeof item !== "number" || !Number.isInteger(item)) {
                        return undefined;
                    }
                    var pr = state.payment.paymentRequests[Number(item)];
                    return "".concat(pr.payoutReference, " - \u20AC").concat(pr.amount, " - ").concat(formatDate(pr.dueDateAt));
                })
                    .join(", ");
            },
        },
        _a[EField.Header + "manual"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rentio_legal.form.previously_added_late_payments"),
        },
        _a[EField.PreviouslyAddedLatePayments] = {
            "ui:field": ERentioFormField.ManualLatePaymentsSelect,
            title: getLocalizedText("rentio_legal.form.previously_added_late_payments"),
            infoTitle: getLocalizedText("rentio_legal.form.previously_added_late_payments.info"),
            documentsTitle: getLocalizedText("rentio_legal.form.previously_added_late_payments.documents"),
            buttonTitle: getLocalizedText("rentio_legal.form.previously_added_late_payments.button"),
            multiple: true,
            useExistingDocuments: true,
            useNewDocuments: true,
            showAddButton: false,
            valueSelector: manualLatePaymentsValueSelector,
        },
        _a[EField.Header] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rentio_legal.form.manual_late_payments"),
        },
        _a[EField.ManualLatePayments] = {
            "ui:field": ERentioFormField.ManualLatePaymentsSelect,
            title: getLocalizedText("rentio_legal.form.manual_late_payments"),
            infoTitle: getLocalizedText("rentio_legal.form.manual_late_payments.info"),
            documentsTitle: getLocalizedText("rentio_legal.form.manual_late_payments.documents"),
            buttonTitle: getLocalizedText("rentio_legal.form.manual_late_payments.button"),
            multiple: true,
            keyIndexOffset: ((legalCase === null || legalCase === void 0 ? void 0 : legalCase.manualPayments) || []).length,
            useExistingDocuments: true,
            useNewDocuments: true,
            valueSelector: manualLatePaymentsValueSelector,
        },
        _a[EField.Header + "registered_letters"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rentio_legal.form.registered_letters"),
        },
        _a[EField.RegisteredLetters] = {
            "ui:field": ERentioFormField.DocumentSelectInline,
            title: getLocalizedText("rentio_legal.form.registered_letters"),
            multiple: true,
            useExistingDocuments: true,
            useNewDocuments: true,
            optional: true,
            valueSelector: printDocumentNamesSelector,
        },
        _a;
};
export var getValidate = function (_) { return function (formData, errors) {
    var latePayments = formData[EField.LatePayments] || [];
    var previouslyAddedPaymentsRaw = formData[EField.PreviouslyAddedLatePayments] || {};
    var previouslyAddedPayments = Object.values(previouslyAddedPaymentsRaw);
    var manualLatePaymentsRaw = formData[EField.ManualLatePayments] || {};
    var manualLatePayments = Object.values(manualLatePaymentsRaw);
    var noPayments = __spreadArray(__spreadArray(__spreadArray([], latePayments, true), manualLatePayments, true), previouslyAddedPayments, true).length === 0;
    if (noPayments ||
        (latePayments.length === 0 &&
            __spreadArray(__spreadArray([], manualLatePayments, true), previouslyAddedPayments, true).every(function (lp) {
                return (lp.info === undefined || lp.info === "") &&
                    (lp.documents || []).length === 0;
            }))) {
        errors[EField.LatePayments].addError(getLocalizedText("rentio_legal.form.late_payments.error.none_selected"));
        errors[EField.ManualLatePayments].addError(getLocalizedText("rentio_legal.form.manual_late_payments.error.none_selected"));
    }
    return errors;
}; };
