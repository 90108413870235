import * as i18n from "../utils/i18n";
export var locale = function (locale) { return i18n.t("system.locale.".concat(locale)); };
export var modelType = function (type) {
    return i18n.t("system.model.".concat(type.toLowerCase()));
};
export var system = function (key) { return i18n.t("system.".concat(key)); };
export var firstname = function () { return i18n.t("system.firstname"); };
export var lastname = function () { return i18n.t("system.lastname"); };
export var fullName = function () { return i18n.t("full_name"); };
export var mobileNumber = function () { return i18n.t("system.mobile_number"); };
export var email = function () { return i18n.t("system.email"); };
export var VATNumber = function () { return i18n.t("system.vat_number"); };
export var box = function () { return i18n.t("system.box"); };
export var save = function () { return i18n.t("system.save"); };
export var logout = function () { return i18n.t("system.logout"); };
export var filename = function () { return i18n.t("system.filename"); };
export var filesize = function () { return i18n.t("system.filesize"); };
export var filetype = function () { return i18n.t("system.filetype"); };
export var category = function () { return i18n.t("system.category"); };
export var uploadDate = function () { return i18n.t("system.date.upload"); };
export var month = function () { return i18n.t("system.month"); };
export var callImperative = function () { return i18n.t("system.call.imperative"); };
export var mailImperative = function () { return i18n.t("system.mail.imperative"); };
export var from = function () { return i18n.t("system.from"); };
export var to = function () { return i18n.t("system.to"); };
export var loadMore = function () { return i18n.t("system.load_more"); };
export var archiveConfirm = function (type) {
    return i18n.t("system.archive.confirm", {
        values: { type: modelType(type).toLowerCase() },
    });
};
export var unarchiveConfirm = function (type) {
    return i18n.t("system.unarchive.confirm", {
        values: { type: modelType(type).toLowerCase() },
    });
};
export var remove = function () { return i18n.t("system.delete"); };
export var removeConfirm = function (type) {
    return i18n.t("system.delete.confirm", {
        values: { type: modelType(type).toLowerCase() },
    });
};
export var removeConfirmed = function (type) {
    return i18n.t("system.delete.success", { values: { type: modelType(type) } });
};
export var add = function () { return i18n.t("system.add"); };
export var addConfirm = function (type) {
    return i18n.t("system.add.confirm", {
        values: { type: modelType(type).toLowerCase() },
    });
};
export var addConfirmed = function (type) {
    return i18n.t("system.create.success", { values: { type: modelType(type) } });
};
export var edit = function () { return i18n.t("system.edit"); };
export var editConfirmed = function (type) {
    return i18n.t("system.update.success", { values: { type: modelType(type) } });
};
export var failed = function () { return i18n.t("system.fail"); };
export var datePlaceholder = function (format) {
    return i18n.t(format ? "date.placeholder.".concat(format) : "date.placeholder");
};
export var unknownUser = function () { return i18n.t("system.user.unknown"); };
export var fetchLoading = function () { return i18n.t("fetch.loading"); };
export var fetchError = function () { return i18n.t("fetch.error"); };
export var serverError = function () { return i18n.t("system.500.server.error"); };
export var serverErrorAccessDenied = function () {
    return i18n.t("system.error.access_denied");
};
export var serverErrorUnprocessableEntity = function () {
    return i18n.t("system.error.unprocessable_entity");
};
export var unsavedWarning = function () {
    return i18n.t("system.confirmation.unsaved_changes");
};
export var imageUpdate = function () { return i18n.t("image.update"); };
export var role = function (roleName) {
    return i18n.t("role.".concat(roleName.toLowerCase()));
};
export var formError = function (code) {
    return i18n.t("system.form.error.".concat(code.toLowerCase()));
};
export var formErrorGeneral = function () { return i18n.t("system.form.error.general"); };
export var formErrorRequired = function (field) {
    if (field) {
        return i18n.t("system.form.error.required.field", { values: { field: field } });
    }
    else {
        return i18n.t("system.form.error.required");
    }
};
export var formErrorFormat = function (type) {
    return i18n.t("system.form.error.format.".concat(type));
};
export var formErrorNumber = function () { return i18n.t("system.form.error.number"); };
export var formErrorNotNegative = function () {
    return i18n.t("system.form.error.not_negative");
};
export var formErrorInvalidIBAN = function () {
    return i18n.t("system.form.error.invalid_IBAN");
};
export var formErrorInvalidNationalNumber = function () {
    return i18n.t("system.form.error.invalid_national_number");
};
export var formErrorInvalidEmail = function () {
    return i18n.t("system.form.error.invalid_email");
};
export var formErrorInvalidPassword = function () {
    return i18n.t("system.form.error.invalid_password");
};
export var formErrorInvalidPhone = function () {
    return i18n.t("system.form.error.invalid_phone");
};
export var formErrorInvalidToken = function () {
    return i18n.t("system.form.error.invalid_token");
};
export var formErrorInvalidAddress = function () {
    return i18n.t("system.form.error.invalid_address");
};
export var formErrorMinItems = function (_a) {
    var limit = _a.limit;
    return i18n.t("system.form.error.min_items", { values: { limit: limit }, n: limit });
};
export var formErrorMinLength = function (_a) {
    var limit = _a.limit;
    return i18n.t("system.form.error.min_length", { values: { limit: limit }, n: limit });
};
export var formErrorNumberMin = function (_a) {
    var value = _a.value;
    return i18n.t("system.form.error.number.min", { values: { value: value } });
};
export var formErrorNumberMax = function (_a) {
    var value = _a.value;
    return i18n.t("system.form.error.number.max", { values: { value: value } });
};
export var formSelectUnselect = function () { return i18n.t("system.form.select.unselect"); };
export var formExtraInfoHeading = function () { return i18n.t("form.extra_info.heading"); };
export var formCompleteMissingFields = function () {
    return i18n.t("form.complete_missing_fields");
};
export var formCompleteMissingFieldsMessage = function () {
    return i18n.t("form.complete_missing_fields.message");
};
export var maxUploadSizeWarning = function (size) {
    return i18n.t("files.error.size_limit_exceeded", { values: { size: size } });
};
export var systemDatePlaceholder = function () { return i18n.t("system.date.placeholder"); };
export var searchFirstUse = function () { return i18n.t("search.first_use"); };
export var searchNoResultsDefault = function () { return i18n.t("search.no_results"); };
export var searchNoResults = function (type) {
    return i18n.t("search.no_results.".concat(type));
};
export var searchResultsCount = function (_a) {
    var count = _a.count;
    return i18n.t("search.results_count", { values: { count: count }, n: count });
};
export var searchCreateCTADefault = function () { return i18n.t("search.create_CTA"); };
export var searchCreateCTA = function (type) {
    return i18n.t("search.create_CTA.".concat(type));
};
export var searchIncentive = function () { return i18n.t("search.incentive"); };
export var searchMore = function () { return i18n.t("search.more"); };
// File
export var fileDropZonePlaceholder = function () {
    return i18n.t("file.dropzone.placeholder");
};
export var fileDropZoneDrop = function () { return i18n.t("file.dropzone.drop"); };
// Date
export var daysAgo = function (days) {
    return i18n.t("date.days_ago", { values: { days: String(days) } });
};
// Members
export var memberAddSuccess = function (args) {
    return i18n.t("member.add.success", args);
};
export var memberDeleteSuccess = function (args) {
    return i18n.t("member.delete.success", args);
};
export var memberUpdateSuccess = function (args) {
    return i18n.t("member.update.success", args);
};
// Contact
export var contactEditContact = function (type) {
    return i18n.t(type
        ? "contact.edit_contact.".concat(type.toLowerCase())
        : "contact.edit_contact");
};
export var contactToggleCompanyLabel = function () {
    return i18n.t("contact.toggle_company.label");
};
export var contactCompanyNameLabel = function () {
    return i18n.t("contact.company.name.label");
};
export var contactCompanyVATNumberLabel = function () {
    return i18n.t("contact.company.vat_number.label");
};
export var contactIbanAccountLabel = function () {
    return i18n.t("contact.company.iban_account.label");
};
export var contactIbanAccountHelpText = function () {
    return i18n.t("contact.company.iban_account.help_text");
};
export var contactAddContact = function (type) {
    return i18n.t("contact.add_contact.".concat(type.toLowerCase()));
};
export var contactRemoveAction = function (type) {
    return i18n.t("contact.remove_".concat(type.toLowerCase(), ".action"));
};
export var contactRemoveContact = function (type) {
    return i18n.t("contact.remove_contact.".concat(type.toLowerCase()));
};
export var contactAssignContact = function (type) {
    return i18n.t("contact.assign_contact.".concat(type.toLowerCase()));
};
export var contactAssignPermissions = function () {
    return i18n.t("contact.assign_permissions");
};
export var contactCompanyPersonalDataLegend = function () {
    return i18n.t("contact.company.contact_data.legend");
};
export var contactViewContactAction = function () {
    return i18n.t("contact.view_contact.action");
};
export var propertyViewPropertyAction = function () {
    return i18n.t("property.view_property.action");
};
export var propertyAddOwnerWhoLabelMe = function () {
    return i18n.t("property.add_owner.who.label.me");
};
export var propertyAddOwnerPersonalDataLegend = function () {
    return i18n.t("property.add_owner.personal_data.legend");
};
export var contactAddContactWhoLabelMe = function (type) {
    return i18n.t("contact.add_contact.who.label.me", {
        values: { type: type.toLowerCase() },
    });
};
export var contactUnknown = function () { return i18n.t("contact.unknown"); };
export var contactAddAsContact = function () { return i18n.t("add.to.contacts"); };
// Search
export var searchPlaceholder = function () { return i18n.t("search.placeholder"); };
// Address
export var addressFullAddressLabel = function () {
    return i18n.t("address.full_address.label");
};
export var addressFullAddressPlaceholder = function () {
    return i18n.t("address.full_address.placeholder");
};
export var addressFullAddressHelpText = function () {
    return i18n.t("address.full_address.help_text");
};
export var addressStreetLabel = function () { return i18n.t("address.street.label"); };
export var addressStateLabel = function () { return i18n.t("address.state.label"); };
export var addressNumberLabel = function () { return i18n.t("address.number.label"); };
export var addressBoxLabel = function () { return i18n.t("address.box.label"); };
export var addressZipLabel = function () { return i18n.t("address.zip.label"); };
export var addressCityLabel = function () { return i18n.t("address.city.label"); };
export var addressCountryLabel = function () { return i18n.t("address.country.label"); };
export var addressBirthAddressPlaceholder = function () {
    return i18n.t("address.birth_address.placeholder");
};
// Auth
export var authPhoneOrEmailLabel = function () { return i18n.t("auth.phone_or_email.label"); };
export var authEmailLabel = function () { return i18n.t("auth.email.label"); };
export var authPhoneLabel = function () { return i18n.t("auth.phone.label"); };
export var authPasswordLabel = function () { return i18n.t("auth.password.label"); };
export var authNewPasswordLabel = function () { return i18n.t("auth.new_password.label"); };
export var authCantLogin = function () { return i18n.t("auth.cant_login"); };
export var authSignUp = function () { return i18n.t("auth.sign_up"); };
export var authToLogin = function () { return i18n.t("auth.to_login"); };
export var authError = function (code) {
    return i18n.t("auth.error.".concat(code.toLowerCase()));
};
export var authPasswordHelpText = function () { return i18n.t("auth.password.help_text"); };
export var authLoginTitle = function () { return i18n.t("auth.login.title"); };
export var authLoginSubmitLabel = function () { return i18n.t("auth.login.submit.label"); };
export var authRequestPasswordResetTitle = function () {
    return i18n.t("auth.request_password_reset.title");
};
export var authRequestPasswordResetSubmitLabel = function () {
    return i18n.t("auth.request_password_reset.submit.label");
};
export var authRequestPasswordResetSentMessage = function () {
    return i18n.t("auth.request_password_reset.sent.message");
};
export var authVerificationTitle = function () { return i18n.t("auth.verification.title"); };
export var authVerificationTokenLabel = function () {
    return i18n.t("auth.verification.token.label");
};
export var authVerificationSubmitLabel = function () {
    return i18n.t("auth.verification.submit.label");
};
export var authVerificationSentToMessage = function () {
    return i18n.t("system.verification.message");
};
export var authVerifiedPhoneHeading = function () {
    return i18n.t("auth.verified_phone.heading");
};
export var authSignUpTitle = function () { return i18n.t("auth.sign_up.title"); };
export var authSignUpSubmitLabel = function () { return i18n.t("auth.sign_up.submit.label"); };
export var authSignUpTACMessage = function () { return i18n.t("auth.sign_up.tac.message"); };
export var authSignUpTACLink = function () { return i18n.t("auth.sign_up.tac.link"); };
export var authChangePasswordResetSubmitLabel = function () {
    return i18n.t("auth.change_password.submit.label");
};
export var authChangePasswordTitle = function () {
    return i18n.t("auth.change_password.title");
};
export var authChangePasswordProblems = function () {
    return i18n.t("auth.change_password.problems");
};
export var authChangePasswordChangesMessage = function () {
    return i18n.t("auth.change_password.changed.message");
};
export var authVerifyEmailTitle = function () { return i18n.t("verify_email.info.title"); };
export var authVerifyEmailMessage = function (vars) {
    return i18n.t("verify_email.info.description", { values: vars });
};
export var authVerifyEmailResendEmailMessage = function () {
    return i18n.t("verify_email.cta.resend_email.disclosure");
};
export var authVerifyEmailResendEmailAction = function () {
    return i18n.t("verify_email.cta.resend_email");
};
export var authVerifyPhoneResendPhoneAction = function () {
    return i18n.t("verify_sms.cta.resend_sms");
};
export var authVerifySmsResendPhoneMessage = function () {
    return i18n.t("verify_sms.cta.resend_sms.disclosure");
};
export var authVerifyEmailVerifiedAction = function () {
    return i18n.t("verify_email.cta.done");
};
export var authVerifyNotVerifiedTitle = function () {
    return i18n.t("verify_email.alert.not_verified.title");
};
export var authVerifyNotVerifiedMessage = function () {
    return i18n.t("verify_email.alert.not_verified.description");
};
// File
export var filetypeLabel = function (type) { return i18n.t("file.type.".concat(type)); };
export var filetypeError = function (type) {
    return i18n.t("file.type.error", { values: { type: filetypeLabel(type) } });
};
export var filesErrorCountLimitExceeded = function (args) {
    return i18n.t("files.error.count_limit_exceeded", args);
};
export var filesErrorSizeLimitExceeded = function (args) {
    return i18n.t("files.error.size_limit_exceeded", args);
};
// Document
export var documentTypeLabel = function (type) {
    return i18n.t("document.type.".concat(type.toLowerCase()));
};
export var documentPreviewPdf = function () { return i18n.t("document.preview_pdf"); };
// Array items
export var arrayItemRemove = function (type) {
    return i18n.t("array_item.remove.".concat(type.toLowerCase()));
};
export var arrayItemAdd = function (type) {
    return i18n.t("array_item.add.".concat(type.toLowerCase()));
};
export var arrayItemTitle = function (type) {
    return i18n.t("array_item.title.".concat(type.toLowerCase()));
};
export var arrayItemCreate = function (type) {
    return i18n.t("array_item.create.".concat(type.toLowerCase()));
};
export var arrayItemFindOrCreateLabel = function (type) {
    return i18n.t("array_item.find_or_create.".concat(type.toLowerCase(), ".label"));
};
export var arrayItemFindOrCreatePlaceholder = function () {
    return i18n.t("array_item.find_or_create.placeholder");
};
// ContactSelect
export var contactSelectAddNewContact = function () {
    return i18n.t("contact_select.add.new_contact");
};
export var contactSelectAddNewCompany = function () {
    return i18n.t("contact_select.add.new_company");
};
export var contactSelectRemoveContact = function (role) {
    return i18n.t("contact_select.remove.contact", {
        values: { role: i18n.t("role.".concat(role)) },
    });
};
export var contactSelectEditContact = function (role) {
    return i18n.t("contact_select.edit.contact", {
        values: { role: i18n.t("role.".concat(role)) },
    });
};
export var contactSelectSearchLabel = function () {
    return i18n.t("contact_select.search.label");
};
export var contactSelectSearchOrAddLabel = function () {
    return i18n.t("contact_select.search_or_add.label");
};
export var contactSelectInvitationMessage = function (_a, n) {
    var contacts = _a.contacts;
    if (n === void 0) { n = 1; }
    return i18n.t("contact_select.invitation.message", { values: { contacts: contacts }, n: n });
};
export var contactListModalSelectedContactLegend = function () {
    return i18n.t("contact_list.modal.selected_contact.legend");
};
// ContactEditModal
export var contactEditModalHeading = function (args) {
    return i18n.t("contact.edit.modal.heading", args);
};
export var contactCompanyEditModalHeading = function (args) {
    return i18n.t("contact.company.edit.modal.heading", args);
};
export var contactDetailsPersonal = function () { return i18n.t("contact.details.personal"); };
export var contactDetailsCompany = function () { return i18n.t("contact.details.company"); };
export var contactDetailsFinancial = function () {
    return i18n.t("contact.details.financial");
};
export var contactDetailsExtra = function () { return i18n.t("contact.details.extra"); };
export var contactDetailsReporting = function () {
    return i18n.t("contact.details.reporting");
};
export var contactDetailsContact = function () {
    return i18n.t("contact.company.details.contact");
};
export var contactDetailsShowIbanFormAction = function () {
    return i18n.t("contact.details.show_iban_form.action");
};
export var contactDetailsHideIbanFormAction = function () {
    return i18n.t("contact.details.hide_iban_form.action");
};
// Property Select
export var propertySelectSearchLabel = function () {
    return i18n.t("property_select.search.label");
};
export var propertySelectSearchPlaceholder = function () {
    return i18n.t("property_select.search.placeholder");
};
export var propertySelectRemoveProperty = function () {
    return i18n.t("property_select.remove.property");
};
// History
export var historyViewHistoryAction = function () {
    return i18n.t("history.view_history.action");
};
export var historyEmptyMessage = function () { return i18n.t("history.empty_message"); };
export var historyFilterActivitiesLabel = function () {
    return i18n.t("history.filter_activities.label");
};
export var activityType = function (type) {
    return i18n.t("activity.".concat(type.toLowerCase()));
};
// Help
export var helpMenuHeading = function () { return i18n.t("help_menu.heading"); };
export var helpMenuActionFAQ = function () { return i18n.t("help_menu.action.faq"); };
export var helpMenuActionContact = function () { return i18n.t("help_menu.action.contact"); };
export var helpMenuActionChat = function () { return i18n.t("help_menu.action.chat"); };
export var helpMenuActionMail = function () { return i18n.t("help_menu.action.mail"); };
export var helpMenuActionPhone = function () { return i18n.t("help_menu.action.phone"); };
// TAC
export var TACUpdateHeading = function () { return i18n.t("tac.update.title"); };
export var TACUpdateDescription = function () { return i18n.t("tac.update.description"); };
export var TACUpdateAcceptAction = function () { return i18n.t("tac.update.cta.accept"); };
// Bank Accounts
export var bankAccountAddModalHeading = function () {
    return i18n.t("bank_account.add_modal.heading");
};
export var bankAccountAddAction = function () { return i18n.t("bank_account.add.action"); };
export var bankAccountIbanLabel = function () { return i18n.t("bank_account.iban.label"); };
export var bankAccountIbanPlaceholder = function () {
    return i18n.t("bank_account.iban.placeholder");
};
export var bankAccountOwnerNameLabel = function () {
    return i18n.t("bank_account.owner_name.label");
};
// Contract
export var contractType = function (type) { return i18n.t("contract.type.".concat(type)); };
export var contractStatus = function (status) {
    return i18n.t("contract.status.".concat(status));
};
// Index contract
export var contractIndexPriceCurrent = function () {
    return i18n.t("contract.index.price_current");
};
export var contractIndexPriceNew = function () { return i18n.t("contract.index.price_new"); };
export var contractIndexPriceNewDescription = function (args) {
    return i18n.t("contract.index.price_new.description", args);
};
export var contractIndexRetroactivity = function (args) {
    return i18n.t("contract.index.retroactive_info", args);
};
export var contractIndexIndexViewAction = function () {
    return i18n.t("contract.index.view_index.action");
};
export var contractIndexIndexNowAction = function () {
    return i18n.t("contract.index.index_now.action");
};
export var contractIndexInquiryAction = function () {
    return i18n.t("contract.index.inquiry.action");
};
export var contractIndexSkipAction = function () {
    return i18n.t("contract.index.skip.action");
};
export var contractIndexEpcInfoForceFull = function (args) {
    return i18n.t("contract.index.epc_info.force_full", args);
};
export var contractIndexEpcInfoFullIndexation = function (args) { return i18n.t("contract.index.epc_info.full_indexation", args); };
export var contractIndexEpcInfoHalfIndexation = function (args) { return i18n.t("contract.index.epc_info.half_indexation", args); };
export var contractIndexEpcInfoNoEpcSelected = function (args) {
    return i18n.t("contract.index.epc_info.no_epc_selected", args);
};
export var contractIndexEpcInfoBadEPC = function (args) {
    return i18n.t("contract.index.epc_info.bad_epc", args);
};
export var contractIndexSkipSuccessHeading = function () {
    return i18n.t("contract.index.skip.success.heading");
};
export var contractIndexSkipSuccessContent = function () {
    return i18n.t("contract.index.skip.success.content");
};
export var contractIndexInquirySuccessHeading = function () {
    return i18n.t("contract.index.inquiry.success.heading");
};
export var contractIndexInquirySuccessContent = function () {
    return i18n.t("contract.index.inquiry.success.content");
};
export var contractIndexIndexNowSuccessHeading = function () {
    return i18n.t("contract.index.index_now.success.heading");
};
export var contractIndexIndexNowSuccessContent = function () {
    return i18n.t("contract.index.index_now.success.content");
};
