import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import { mapFormFieldToNullableBooleanValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as registrationActions from "@rentiohq/shared-frontend/dist/redux/registration/registration.actions";
import * as registrationSelectors from "@rentiohq/shared-frontend/dist/redux/registration/registration.selectors";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  ERegistrationDocumentStatus,
  ERegistrationDocumentType,
  ERegistrationMemberType,
  ICreateRegistrationDocument,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import { EAN_PREFIX } from "forms/requestMoveService/schema.requestMoveService.config";
import { IRootStore } from "redux/reducers";
import { EField, IExtraData } from "./schema.registrationAddDocument.types";

export const isPerformingSubmitActionSelector =
  (extraData: IExtraData) => (state: IRootStore) => {
    const { documentType } = extraData;
    if (documentType === ERegistrationDocumentType.Brussels) {
      return registrationSelectors.isRetryingBrussels(
        state,
        extraData.registrationId,
      );
    }

    return registrationSelectors.isUpdating(state, extraData.registrationId);
  };

export const performingSubmitActionResultSelector =
  (extraData: IExtraData) => (state: IRootStore) =>
    registrationSelectors.registration(state, extraData.registrationId);

export const performingSubmitActionErrorSelector =
  (extraData: IExtraData) => (state: IRootStore) => {
    const { documentType } = extraData;
    if (documentType === ERegistrationDocumentType.Brussels) {
      return registrationSelectors.retryBrusselsError(
        state,
        extraData.registrationId,
      );
    }

    return registrationSelectors.updateError(state, extraData.registrationId);
  };

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const {
      contract,
      property,
      documentType,
      registrationId,
      existingDocumentId,
    } = extraData;

    if (documentType === ERegistrationDocumentType.Brussels) {
      const electricityEan = formData[EField.ElectricityEan];
      const parsedElectricityEan = electricityEan
        ? `${EAN_PREFIX}${electricityEan}`
        : undefined;

      const naturalGasEan = formData[EField.NaturalGasEan];
      const parsedNaturalGasEan = naturalGasEan
        ? `${EAN_PREFIX}${naturalGasEan}`
        : undefined;

      const updatedProperty: Partial<IProperty> | undefined = {
        id: property.id,

        // General
        constructionYear: formData[EField.ConstructionYear] ?? null,

        // EPC Fields
        epcLabel: formData[EEpcField.Label] ?? null,
        epcValue: formData[EEpcField.Value] ?? null,
        epcExpirationDate: formData[EEpcField.ExpirationDate] ?? null,
        epcCertificateId: formData[EEpcField.CertificateId] ?? null,

        // Electricity
        electricityType: formData[EField.ElectricityType] ?? null,

        electricityMeterNumberBoth:
          formData[EField.ElectricityMeterNumberBoth] ?? null,
        electricityEan: parsedElectricityEan,
        electricityMeterType: formData[EField.ElectricityMeterType] ?? null,

        // Natural Gas
        naturalGasAvailable: formData[EField.NaturalGasAvailable] ?? null,
        naturalGasEan: parsedNaturalGasEan,
        naturalGasMeterNumber: formData[EField.NaturalGasMeterNumber] ?? null,
        naturalGasMeterType: formData[EField.NaturalGasMeterType] ?? null,

        // Water
        waterMeterNumber: formData[EField.WaterMeterNumber] ?? null,

        // Exterior
        buildingAdjoined: formData[EField.BuildingAdjoined] ?? null,
        buildingSurfaceArea: formData[EField.BuildingSurfaceArea] ?? null,
        buildingGardenSurfaceArea:
          formData[EField.BuildingGardenSurfaceArea] ?? null,
        buildingTerraceSurfaceArea:
          formData[EField.BuildingTerraceSurfaceArea] ?? null,

        // Interior
        buildingFloor: formData[EField.BuildingFloor] ?? null,
        buildingFloorCount: formData[EField.BuildingFloorCount] ?? null,
        roomCountBedroom: formData[EField.RoomCountBedroom] ?? null,
        roomCountBathroom: formData[EField.RoomCountBathroom] ?? null,
        roomCountGarage: formData[EField.RoomCountGarage] ?? null,
        buildingHasStorage: mapFormFieldToNullableBooleanValue(
          formData[EField.BuildingHasStorage],
        ),
        isFurnished: mapFormFieldToNullableBooleanValue(
          formData[EField.IsFurnished],
        ),

        // Amenities
        hasOven: mapFormFieldToNullableBooleanValue(formData[EField.HasOven]),
        hasFridge: mapFormFieldToNullableBooleanValue(
          formData[EField.HasFridge],
        ),
        hasDishwasher: mapFormFieldToNullableBooleanValue(
          formData[EField.HasDishWasher],
        ),
        hasRangeHood: mapFormFieldToNullableBooleanValue(
          formData[EField.HasRangeHood],
        ),
        hasLift: mapFormFieldToNullableBooleanValue(formData[EField.HasLift]),

        // Energy
        hasDoubleGlazing: mapFormFieldToNullableBooleanValue(
          formData[EField.HasDoubleGlazing],
        ),
        hasCentralHeating: mapFormFieldToNullableBooleanValue(
          formData[EField.HasCentralHeating],
        ),
        heatingType: formData[EField.HeatingType] ?? null,
      };

      const updatedContract: Partial<IContract> | undefined = {
        id: contract.id,

        [EField.IsSharedLease]: mapFormFieldToNullableBooleanValue(
          formData[EField.IsSharedLease],
        ),
        [EField.ReferencePrice]: formData[EField.ReferencePrice] ?? null,
      };

      return registrationActions.brusselsRegistrationRetry.actions.start({
        registrationId,
        updatedProperty,
        updatedContract,
      });
    }

    const signers = [
      ...(formData[EField.Owners] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Owner,
        accountId,
      })),
      ...(formData[EField.SignAsBroker] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Broker,
        accountId,
      })),
      ...(formData[EField.Tenants] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Tenant,
        accountId,
      })),
    ];

    const document: ICreateRegistrationDocument = {
      documentId: formData[EField.DocumentToUpload]?.[0],
      documentType,
      type: formData[EField.Type],
      signedAt:
        extraData.documentType === ERegistrationDocumentType.Addendum
          ? formData[EField.SignedAtAddendum]
          : formData[EField.SignedAt],
      signers,
    };

    if (existingDocumentId) {
      return registrationActions.registrationUpdateDocument.actions.start({
        registrationId: registrationId || -1,
        documentId: existingDocumentId,
        document: {
          ...document,
          status: ERegistrationDocumentStatus.Registering,
        },
      });
    }

    return registrationActions.registrationAddDocuments.actions.start({
      registrationId: registrationId || -1,
      documents: [document],
    });
  };
