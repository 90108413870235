import {
  EContractLengthType,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EContractEndingStatus } from "./components/ContractCard/components/ContractStatusChip/contractStatusChip.types";

export const getContractDuration = (
  contract: IContract,
): EContractEndingStatus => {
  if (contract.dateOfCancellation) {
    return EContractEndingStatus.Cancelled;
  }

  if (contract.tacitRenewal) {
    if (contract.contractLengthType === EContractLengthType.Short) {
      return EContractEndingStatus.ShortTerm;
    }

    return EContractEndingStatus.LongTerm;
  }

  return EContractEndingStatus.AutoEnding;
};
