var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { orderBy } from "lodash";
import { EDocumentCategory } from "../../types/document.types";
import { ERegistrationDocumentType, } from "../../types/registration.types";
import { getPagedId as getPagedIdBase, } from "../generic/paged.utils";
export var getPagedId = function (params) {
    return getPagedIdBase(__assign(__assign({}, params), { entityName: "registration" }));
};
export var getContractDocument = function (registration) {
    return registration.documents.find(function (document) { return document.documentType === ERegistrationDocumentType.Contract; });
};
export var getAddenda = function (registration) {
    return orderBy(registration.documents.filter(function (document) { return document.documentType === ERegistrationDocumentType.Addendum; }), "id");
};
export var getLocationDescription = function (registration) {
    return registration.documents.find(function (document) {
        return document.documentType ===
            ERegistrationDocumentType.PropertyInspectionReport;
    });
};
export var getDocumentCategoryId = function (params) {
    var documentType = params.documentType;
    switch (documentType) {
        case ERegistrationDocumentType.Addendum:
            return EDocumentCategory.ContractAddendum;
        case ERegistrationDocumentType.Contract:
            return EDocumentCategory.Contract;
        case ERegistrationDocumentType.PropertyInspectionReport:
            return EDocumentCategory.LocationDescription;
        default:
            return EDocumentCategory.Other;
    }
};
