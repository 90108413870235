import { getSchema as getSchema1Broker, uiSchema as uiSchema1Broker, } from "./schema.addContact.1.broker";
import { getSchema as getSchema1Company, uiSchema as uiSchema1Company, getValidate as getValidate1Company, } from "./schema.addContact.1.company";
import { getSchema as getSchema1Complete, uiSchema as uiSchema1Complete, } from "./schema.addContact.1.complete";
import { getSchema as getSchema1Contact, uiSchema as uiSchema1Contact, } from "./schema.addContact.1.contact";
import { EField } from "./schema.addContact.types";
import { getInitialValues } from "./schema.addContact.utils";
// eslint-disable-next-line import/no-default-export
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    if (extraData.fields) {
        return [
            {
                schema: getSchema1Complete(extraData, initialValues),
                uiSchema: uiSchema1Complete(extraData),
            },
        ];
    }
    if (extraData.isBroker) {
        return [
            {
                schema: getSchema1Broker(extraData, initialValues),
                uiSchema: uiSchema1Broker(extraData),
            },
        ];
    }
    if (extraData.isCompany) {
        return [
            {
                schema: getSchema1Company(extraData, initialValues),
                uiSchema: uiSchema1Company(extraData),
                validate: getValidate1Company(),
            },
        ];
    }
    return [
        {
            schema: getSchema1Contact(extraData, initialValues),
            uiSchema: uiSchema1Contact(extraData),
        },
    ];
});
