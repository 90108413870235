import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  Box,
  Drawer as MuiDrawer,
  Icon,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { EAnchor } from "@rentiohq/web-shared-next/dist/utils/types/drawer";
import * as React from "react";
import { NumberParam, StringParam } from "serialize-query-params";
import { DrawerStyles } from "./Drawer.styles";

interface IProps {
  anchor?: EAnchor;
  open: boolean;
  formId: string;
  children: React.ReactNode;
}

export const Drawer = ({
  anchor = EAnchor.Right,
  open,
  children,
  formId,
}: IProps) => {
  const [_, setQueryParamValue] = useQueryParams({
    documentPackageId: StringParam,
    documentId: StringParam,
    rentDepositId: NumberParam,
    templateDocumentId: StringParam,
  });

  const handleDrawerClose = () => {
    setQueryParamValue({ [formId]: undefined });
  };

  const sx = DrawerStyles();

  return (
    <MuiDrawer
      anchor={anchor}
      open={open}
      onClose={handleDrawerClose}
      sx={sx.wrapper}
    >
      <Box sx={sx.content.closeIcon} onClick={handleDrawerClose}>
        <Icon path={EAssets.CloseIcon} width="24px" height="24px" />
      </Box>
      {children}
    </MuiDrawer>
  );
};
