import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import * as React from "react";
import { EAssets } from "../../assets";
import { EFontWeights } from "../../themes/types";
import { Icon } from "../Icon";
import { MenuStyles } from "./MenuStyles";
export var Menu = function (_a) {
    var actions = _a.actions;
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        return setAnchorEl(event.currentTarget);
    };
    var handleClose = function () { return setAnchorEl(null); };
    var sx = MenuStyles();
    return (_jsxs("div", { children: [_jsx(IconButton, { "aria-label": "more", id: "rentio-menu", "aria-controls": open ? "rentio-menu" : undefined, "aria-expanded": open ? "true" : undefined, "aria-haspopup": "true", onClick: handleClick, children: _jsx(Icon, { path: EAssets.MenuVerticalIcon, width: "18px", height: "18px" }) }), _jsx(MuiMenu, { id: "rentio-menu", MenuListProps: {
                    "aria-labelledby": "rentio-menu",
                }, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                }, anchorEl: anchorEl, open: open, onClose: handleClose, slotProps: {
                    paper: {
                        style: sx.wrapper,
                    },
                }, children: actions.map(function (action) {
                    var label = action.label, icon = action.icon, onClick = action.onClick, danger = action.danger;
                    return (_jsx(MenuItem, { onClick: function () {
                            onClick();
                            handleClose();
                        }, children: _jsx(Typography, { variant: "subtitle2", fontWeight: EFontWeights.Regular, sx: sx.content.item(danger), children: _jsxs(Stack, { direction: "row", alignItems: "center", gap: 1, children: [icon && _jsx(Icon, { path: icon, width: "14px", height: "14px" }), getLocalizedText(label)] }) }) }, label));
                }) })] }));
};
