import { getPagedSelectors } from "../generic/paged.selectors";
import CONFIG from "./registration.config";
export var paged = getPagedSelectors(function (state) { return state[CONFIG.REDUCER_KEY]; });
export var registration = function (state, id) {
    return id ? state[CONFIG.REDUCER_KEY].data[id] : undefined;
};
export var isFetching = function (state, id) {
    var _a;
    return id
        ? ((_a = state[CONFIG.REDUCER_KEY].registrationsById[id]) === null || _a === void 0 ? void 0 : _a.isFetching) || false
        : false;
};
export var fetchError = function (state, id) { var _a; return id ? (_a = state[CONFIG.REDUCER_KEY].registrationsById[id]) === null || _a === void 0 ? void 0 : _a.fetchError : undefined; };
export var isUpdating = function (state, id) { var _a; return ((_a = state[CONFIG.REDUCER_KEY].registrationsById[id]) === null || _a === void 0 ? void 0 : _a.isUpdating) || false; };
export var updateError = function (state, id) { var _a; return (_a = state[CONFIG.REDUCER_KEY].registrationsById[id]) === null || _a === void 0 ? void 0 : _a.updateError; };
export var isCreating = function (state) {
    return state[CONFIG.REDUCER_KEY].isCreating || false;
};
export var createError = function (state) {
    return state[CONFIG.REDUCER_KEY].createError;
};
export var createdRegistration = function (state) {
    return state[CONFIG.REDUCER_KEY].createdRegistration;
};
export var isRetryingBrussels = function (state, id) { var _a; return ((_a = state[CONFIG.REDUCER_KEY].registrationsById[id]) === null || _a === void 0 ? void 0 : _a.isRetryingBrussels) || false; };
export var retryBrusselsError = function (state, id) { var _a; return (_a = state[CONFIG.REDUCER_KEY].registrationsById[id]) === null || _a === void 0 ? void 0 : _a.brusselsRetryError; };
