import { Box } from "@rebass/grid";
import logger from "@rentiohq/shared-frontend/dist/logger";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentApi from "@rentiohq/shared-frontend/dist/redux/payment/payment.api";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import * as paymentActionsV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import { useDetail } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import {
  EPaymentRequestStatus,
  IPaymentRequest,
  IPaymentRequestItem,
} from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { differenceInCalendarDays } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import {
  canMarkPaid,
  getPaymentOrders,
} from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import {
  Activities,
  Card,
  ContactFetchListItem,
  DisplayText,
  ESpacings,
  Grid,
  Icon,
  RentioInternalRenderer,
  ResourceList,
  ResourceListItem,
  TextStyle,
  TextStyleVariationTypes,
} from "@rentiohq/web-shared/dist/components";
import { toast } from "@rentiohq/web-shared/dist/managers/Toast";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import utils from "@rentiohq/web-shared/dist/utils";
import { PaymentMethodsCard } from "components/DetailDrawers/Drawers/PaymentOrder/components/PaymentMethodsCard";
import { DocumentListItem } from "components/DetailDrawers/Drawers/RentDeposit/components/RentDepositInfo/components/DocumentRow";
import { ManuallyMarkPaidModal } from "components/OpenPaymentRequests/components/ManuallyMarkPaidModal";
import pluralize from "pluralize";
import React from "react";
import { useDispatch } from "react-redux";
import {
  END_INCOMING_DSO,
  createTitle,
  getFollowUpReason,
  getPayees,
  getPaymentRequestActivityFilter,
  hasMandate,
  isFromRent,
  isRentDiscount,
} from "utils/payment";
import { PaymentFollowUpModal } from "../../../../../../components";
import * as t from "../../../../../../services/translationService";
import { EPaymentRequestTabs } from "../../PaymentRequest.types";

interface IProps {
  paymentRequest: IPaymentRequest;
  setTab?: (tab: EPaymentRequestTabs) => void;
}

interface IPayerRowProps extends IPaymentRequest {
  actions: any;
}

const renderPayerRow: React.FC<IPayerRowProps> = paymentRequest => {
  const paymentOrders = getPaymentOrders(paymentRequest);
  const payerHasMandate = hasMandate(paymentOrders);
  const { payerAccount } = paymentRequest;
  const daysDiff =
    paymentRequest.status === EPaymentRequestStatus.Paid
      ? differenceInCalendarDays(
          paymentRequest.paidAt,
          paymentRequest.dueDateAt,
        )
      : differenceInCalendarDays(new Date(), paymentRequest.dueDateAt);

  let statusVariation: TextStyleVariationTypes = "positive";
  let statusMessage = "";

  switch (true) {
    case !!paymentRequest.paidManuallyAt:
      statusVariation = "positive";
      statusMessage = `${t.paymentRequestPaidManuallyLabel()} (${formatDate(
        paymentRequest.paidManuallyAt!,
      )})`;
      break;

    case paymentRequest.status === EPaymentRequestStatus.Paid && daysDiff <= 0:
      statusVariation = "positive";
      statusMessage = t.paymentRequestPaidOnTimeLabel();
      break;

    case paymentRequest.status === EPaymentRequestStatus.Paid && daysDiff > 0:
      statusVariation = "warn";
      statusMessage = t.paymentRequestNotPaidOnTimeLabel({ days: daysDiff });
      break;

    case paymentRequest.status !== EPaymentRequestStatus.Paid:
      statusMessage = getFollowUpReason(paymentRequest);
      switch (true) {
        case daysDiff <= END_INCOMING_DSO:
          statusVariation = "positive";
          break;

        default:
          statusVariation = "warn";
          break;
      }
      break;
  }

  const renderAmount = () => {
    if (paymentRequest.status === EPaymentRequestStatus.Partial) {
      return formatCurrency(paymentRequest.amount);
    }
    return formatCurrency(paymentRequest.originalAmount);
  };

  const renderContact = (contact: IContact) => {
    return (
      <ResourceListItem
        boxProps={{ py: ESpacings.tight }}
        item={contact}
        media={<Icon source="profile" />}
        mediaSize="medium"
        actions={paymentRequest.actions(contact)}
      >
        <Grid alignItems="center" justifyContent="space-between">
          <Grid.Item>
            <div>{getName(contact)}</div>
            {utils.paymentRequest.hasRecurring(paymentRequest) &&
              (payerHasMandate ? (
                <TextStyle variation="subdued" size="small" element="div">
                  {t.paymentRequestPayerMandateLabel()}
                </TextStyle>
              ) : (
                <TextStyle variation="subdued" size="small" element="div">
                  {t.paymentRequestPayerNoMandateLabel()}
                </TextStyle>
              ))}
            <TextStyle variation={statusVariation} size="small" element="div">
              {statusMessage}
            </TextStyle>
          </Grid.Item>
          <Grid.Item>
            <DisplayText size="medium" element="div">
              <TextStyle element="div" variation="code">
                {renderAmount()}
              </TextStyle>
            </DisplayText>
          </Grid.Item>
        </Grid>
      </ResourceListItem>
    );
  };

  const renderAccount = (account: IAccount) => {
    return (
      <ResourceListItem
        boxProps={{ py: ESpacings.tight }}
        item={account}
        media={<Icon source="profile" />}
        mediaSize="medium"
        actions={paymentRequest.actions()}
      >
        <Grid alignItems="center" justifyContent="space-between">
          <Grid.Item>
            <div>{getName(account)}</div>
            {utils.paymentRequest.hasRecurring(paymentRequest) &&
              (payerHasMandate ? (
                <TextStyle variation="subdued" size="small" element="div">
                  {t.paymentRequestPayerMandateLabel()}
                </TextStyle>
              ) : (
                <TextStyle variation="subdued" size="small" element="div">
                  {t.paymentRequestPayerNoMandateLabel()}
                </TextStyle>
              ))}
            <TextStyle variation={statusVariation} size="small" element="div">
              {statusMessage}
            </TextStyle>
          </Grid.Item>
          <Grid.Item>
            <DisplayText size="medium" element="div">
              <TextStyle element="div" variation="code">
                {renderAmount()}
              </TextStyle>
            </DisplayText>
          </Grid.Item>
        </Grid>
      </ResourceListItem>
    );
  };
  return (
    <ContactFetchListItem
      accountId={payerAccount.id}
      renderContact={renderContact}
      renderAccount={renderAccount}
    />
  );
};

const renderPayeeRow: React.FC<any> = item => {
  return (
    <ResourceListItem
      boxProps={{ py: ESpacings.tight }}
      item={item}
      media={<Icon source="profile" />}
      mediaSize="medium"
      actions={item.actions(item)}
    >
      <Grid alignItems="center" justifyContent="space-between">
        <Grid.Item>
          <div>{getName(item.contact || item)}</div>
          {item.bankaccount && (
            <TextStyle variation="subdued" size="small" element="div">
              {item.bankaccount.iban}
            </TextStyle>
          )}
          {/* {item.contact && item.contact.pspInfo && !item.contact.pspInfo.mangoKycValidated && (
            <TextStyle variation="negative" size="small" element="div">
              {t.paymentsCollectionFollowUpReason({ reason: 'failed' })}
            </TextStyle>
          )} */}
        </Grid.Item>
        {/* <Grid.Item>
          <DisplayText size="medium" element="div">
            <TextStyle element="div" variation="code">
              {t.formatCurrency(1506)}
            </TextStyle>
          </DisplayText>
        </Grid.Item> */}
      </Grid>
    </ResourceListItem>
  );
};

const renderPropertyRow: React.FC<any> = (property: IProperty) => {
  return (
    <ResourceListItem
      boxProps={{ py: ESpacings.tight }}
      item={property}
      media={<Icon source="house" />}
      mediaSize="medium"
      actions={[
        {
          content: t.propertyViewPropertyAction(),
          link: `/properties/${property.id}`,
        },
      ]}
    >
      {property && (
        <div>{property.propertyAddress?.full || formatAddress(property)}</div>
      )}
      <TextStyle variation="subdued" element="div">
        {property.name}
      </TextStyle>
    </ResourceListItem>
  );
};

const renderPaymentOrderRow =
  (paymentRequest: IPaymentRequest) =>
  (paymentRequestItem: IPaymentRequestItem) => {
    const { paymentOrder } = paymentRequestItem;

    const actions = [];

    if (paymentOrder.isEditable && !paymentOrder.deletedAt) {
      actions.push({
        content: t.paymentOrderViewPaymentOrderAction(),
        link: `/properties/${paymentRequest.requestPropertyId}/payments/${paymentOrder.id}/edit`,
      });
    }

    return (
      <ResourceListItem
        boxProps={{ py: ESpacings.tight }}
        item={paymentRequestItem}
        media={<Icon source="forRentSign" />}
        mediaSize="medium"
        actions={actions}
      >
        <Grid alignItems="center" justifyContent="space-between">
          <Grid.Item>
            <div>{createTitle({ paymentOrders: [paymentOrder] })}</div>
          </Grid.Item>
          <Grid.Item>
            <DisplayText size="medium" element="div">
              <TextStyle
                variation="code"
                element="div"
                style={{ textAlign: "right" }}
              >
                {formatCurrency(paymentRequestItem.amount)}
              </TextStyle>
            </DisplayText>
          </Grid.Item>
        </Grid>
      </ResourceListItem>
    );
  };

export const PaymentRequestInfo = (props: IProps) => {
  const { paymentRequest, setTab } = props;

  const { user } = authHooks.useSelf();

  const dispatch = useDispatch();
  const { internalModeEnabled } = useInternalMode();

  const propertyId = paymentRequest.requestPropertyId;

  const { detail: property } = useDetail({
    id: propertyId,
    shouldRefetch: false,
  });

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const paymentOrders = getPaymentOrders(paymentRequest);
  const payees = getPayees(paymentOrders);

  const [showManuallyMarkPaidModal, setShowManuallyMarkPaidModal] =
    React.useState(false);
  const [showFollowUpItemModal, setShowFollowUpModalItem] =
    React.useState(false);

  const { items: documentsForPaymentRequest } = documentHooks.useGetAll({
    shouldRefetch: true,
    customPath: `/payment-requests/${paymentRequest.id}/documents`,
    query: {},
  });

  const handleKYCReminderClick = async (contact: IContact) => {
    try {
      await paymentApi.sendKYCReminder(contact.id);
      toast({
        heading: t.contactResendKYCRegistrationSentSuccess({
          name: contact.firstname,
        }),
      });
    } catch (unknownError) {
      const error = unknownError as any;
      logger.logError({ error });

      toast({
        heading: t.contactResendKYCRegistrationSentFail({
          name: contact.firstname,
        }),
        variation: "error",
      });
    }
  };

  const handleInvitationClick = async () => {
    if (internalModeEnabled && hasPaymentV2ForBroker) {
      dispatch(
        paymentActionsV2.sendPaymentRequestInvitationStart.getAction({
          id: paymentRequest.id,
        }),
      );

      return;
    }

    dispatch(
      paymentActions.sendPaymentRequestInvitation.actions.start({
        paymentRequestId: paymentRequest.id,
      }),
    );
  };

  const handleShowModalClick = () => {
    setShowFollowUpModalItem(true);
  };

  const handleFollowUpModalClose = () => {
    setShowFollowUpModalItem(false);
  };

  const handleMarkPaidClick = () => {
    setShowManuallyMarkPaidModal(true);
  };

  const handleManuallyMarkPaidModalClose = () => {
    setShowManuallyMarkPaidModal(false);
  };

  const generatePayerActions = (payer?: IContact) => {
    const paymentOrders = getPaymentOrders(paymentRequest);

    const hasFromRent = isFromRent(paymentOrders);
    const hasRentDiscount = isRentDiscount(paymentOrders);

    return paymentRequest.status !== "paid"
      ? [
          [
            !!payer && {
              content: t.contactViewContactAction(),
              link: `/contacts/${payer.id}`,
            },

            ![
              EPaymentRequestStatus.Approved,
              EPaymentRequestStatus.Pending,
              EPaymentRequestStatus.Paid,
            ].includes(paymentRequest.status) && {
              content: t.paymentsFollowUpCardActionsContactPayer(),
              onClick: handleShowModalClick,
            },
          ].filter(Boolean),

          [
            ![
              EPaymentRequestStatus.Approved,
              EPaymentRequestStatus.Pending,
              EPaymentRequestStatus.PayInPlanned,
              EPaymentRequestStatus.PaidIn,
              EPaymentRequestStatus.Paid,
              EPaymentRequestStatus.Transferring,
              EPaymentRequestStatus.Transferred,
              EPaymentRequestStatus.PayingOut,
              EPaymentRequestStatus.FailedKyc,
            ].includes(paymentRequest.status) &&
              !hasFromRent &&
              !hasRentDiscount && {
                content: t.paymentRequestResendInvitationAction(),
                onClick: handleInvitationClick,
              },

            user &&
              property &&
              canMarkPaid({ paymentRequest, property, user }) && {
                content: (
                  <TextStyle variation={"negative"}>
                    {t.paymentRequestMarkPaidAction()}
                  </TextStyle>
                ),
                onClick: handleMarkPaidClick,
              },
          ].filter(Boolean),
        ]
      : [
          !!payer && {
            content: t.contactViewContactAction(),
            link: `/contacts/${payer.id}`,
          },
        ].filter(Boolean);
  };

  const generatePayeeActions = ({ contact }: { contact: IContact }) => {
    const onKYCReminderClick = () => {
      handleKYCReminderClick(contact);
    };

    if (!contact) {
      return [];
    }

    // TODO: Contacts refactor
    // @ts-ignore
    return contact.pspInfo && contact!.pspInfo.mangoKycValidated
      ? [
          {
            content: t.contactViewContactAction(),
            link: `/contacts/${contact.id}`,
          },
        ]
      : [
          [
            {
              content: t.contactResendKYCRegistrationAction(),
              onClick: onKYCReminderClick,
            },
          ],
          [
            {
              content: t.contactViewContactAction(),
              link: `/contacts/${contact.id}`,
            },
          ],
        ];
  };

  const renderDocuments = (heading: string) => {
    const hasDocuments =
      (documentsForPaymentRequest && documentsForPaymentRequest.length > 0) ||
      false;
    if (!hasDocuments) return null;
    return (
      <Card heading={heading}>
        {hasDocuments &&
          documentsForPaymentRequest &&
          documentsForPaymentRequest.map(({ id: docId }) => (
            <DocumentListItem key={docId} documentId={docId} />
          ))}
      </Card>
    );
  };

  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  const handleActivityMoreClick = () => {
    setTab?.(EPaymentRequestTabs.History);
  };

  return (
    <>
      <RentioInternalRenderer
        items={{
          status: paymentRequest.status,

          payInId: paymentRequest.paymentPayInId,
          paymentPayInStatus: paymentRequest.paymentPayInStatus,
          paymentInterface: paymentRequest.paymentInterface,
          features: paymentRequest.features.join(", "),
          createId: paymentRequest.createId,
          paymentPayInId: paymentRequest.paymentPayInId,
          mangopayDisputeId: paymentRequest.mangopayDisputeId,
          mangopayTransferSettlementId:
            paymentRequest.mangopayTransferSettlementId,
          payInAttempt: paymentRequest.payInAttempt,
          paymentSource: paymentRequest.paymentSource,
          mollieStatus: paymentRequest.mollieStatus,
          mollieReference: paymentRequest.mollieReference,
          plannedExecutionAt: paymentRequest.plannedExecutionAt,
        }}
      />

      <Card
        heading={renderCardHeader(t.system("payer"))}
        space={ESpacings.base}
      >
        <ResourceList
          items={[
            {
              ...paymentRequest,
              actions: generatePayerActions,
            },
          ]}
          renderItem={renderPayerRow}
        />
      </Card>

      {paymentRequest.allowedPaymentMethods?.length > 0 &&
        [EPaymentRequestStatus.New, EPaymentRequestStatus.Failed].includes(
          paymentRequest.status,
        ) && (
          <PaymentMethodsCard
            paymentMethods={paymentRequest.allowedPaymentMethods}
            ibanisationReference={paymentRequest.ibanisationReference}
            contractId={
              paymentRequest.paymentRequestItems[0]?.paymentOrder?.contractId
            }
          />
        )}

      {payees.length > 0 && (
        <Card
          heading={renderCardHeader(t.system(pluralize("payee")))}
          space={ESpacings.base}
        >
          <ResourceList
            items={payees.map(payee => ({
              ...payee,
              actions: generatePayeeActions,
            }))}
            renderItem={renderPayeeRow}
          />
        </Card>
      )}

      <Card
        heading={renderCardHeader(t.system("payment_orders"))}
        space={ESpacings.base}
      >
        <ResourceList
          items={paymentRequest.paymentRequestItems}
          renderItem={renderPaymentOrderRow(paymentRequest)}
        />
      </Card>

      {property && (
        <Card
          heading={renderCardHeader(t.system("property"))}
          space={ESpacings.base}
        >
          <ResourceList items={[property]} renderItem={renderPropertyRow} />
        </Card>
      )}

      {showFollowUpItemModal && (
        <PaymentFollowUpModal
          onClose={handleFollowUpModalClose}
          followUpItem={paymentRequest}
        />
      )}

      {showManuallyMarkPaidModal && (
        <ManuallyMarkPaidModal
          showFromRentRefreshPageCheck={false}
          paymentRequest={paymentRequest}
          onClose={handleManuallyMarkPaidModalClose}
          onSuccess={handleManuallyMarkPaidModalClose}
        />
      )}

      {documentsForPaymentRequest &&
        documentsForPaymentRequest.length > 0 &&
        renderDocuments(t.system("documents"))}

      <Card
        heading={renderCardHeader(t.system("history"))}
        space={ESpacings.base}
      >
        <Box mb={ESpacings.base}>
          <Activities
            identifier={`payment-request-${paymentRequest.id}-compact`}
            filter={getPaymentRequestActivityFilter(paymentRequest)}
            limit={3}
            isCompact={true}
            onMoreClick={handleActivityMoreClick}
          />
        </Box>
      </Card>
    </>
  );
};
