import {
  ETaskMemberType,
  ETenantTaskFilter,
} from "@rentiohq/shared-frontend/dist/types/task.types";

export const getMonthStartAndEnd = () => {
  const today = new Date();
  const dateIn30Days = new Date();
  dateIn30Days.setDate(today.getDate() + 30);
  const todayISO = today.toISOString();
  const dateIn30DaysISO = dateIn30Days.toISOString();
  return {
    today: todayISO,
    in30Days: dateIn30DaysISO,
  };
};

export const getDateAfter30Days = () => {
  const today = new Date();
  const dateIn30Days = new Date();
  dateIn30Days.setDate(today.getDate() + 30);
  return dateIn30Days.toISOString();
};

export const getFilter = (taskType: ETenantTaskFilter) => {
  switch (taskType) {
    case ETenantTaskFilter.Soon:
      return {
        filter: {
          and: [
            {
              finishedAt: { is: null },
            },
            {
              or: [
                {
                  and: [
                    {
                      executeDate: {
                        gte: getMonthStartAndEnd().today,
                      },
                    },
                    {
                      executeDate: {
                        lte: getMonthStartAndEnd().in30Days,
                      },
                    },
                  ],
                },
                {
                  executeDate: {
                    is: null,
                  },
                },
                {
                  dueDate: {
                    is: null,
                  },
                },
              ],
            },
          ],
        },
      };
    case ETenantTaskFilter.Later:
      return {
        filter: {
          and: [
            {
              finishedAt: { is: null },
            },
            {
              executeDate: {
                gt: getDateAfter30Days(),
              },
            },
          ],
        },
      };
    case ETenantTaskFilter.Done:
      return {
        filter: {
          and: [
            {
              finishedAt: { isNot: null },
            },
          ],
        },
      };
    case ETenantTaskFilter.Follow:
      return {
        rolesFilter: [ETaskMemberType.Follower],
        filter: {
          finishedAt: { is: null },
        },
      };
  }
};
