var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import signUpSchemas from "@rentiohq/shared-frontend/dist/forms/signUp";
import * as signUpActions from "@rentiohq/shared-frontend/dist/forms/signUp/schema.signUp.actions";
import * as authApi from "@rentiohq/shared-frontend/dist/redux/auth/auth.api";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { ECaptchaVersion, ETacPlatform, } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { getLocale, getLocalizedText, } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button, DisplayText, MarkdownWrapper, Modal } from "../../components";
import { MultiStepForm } from "../../components/SharedForm/MultiStepForm";
import * as t from "../../services/translationService";
import { StyledAuthActions, StyledAuthBody, StyledAuthContent, StyledAuthFrame, StyledAuthLogo, StyledAuthMain, StyledAuthMedia, StyledAuthMediaWrap, } from "./Auth.styled";
import { useNavigateAfterSuccess } from "./Auth.utils";
import { InternalModeButton } from "./components/InternalModeButton";
var SignUp = function () {
    var navigate = useNavigate();
    // Router
    var navigateAfterSuccess = useNavigateAfterSuccess();
    // State
    var dispatch = useDispatch();
    var _a = React.useState(true), preventRedirect = _a[0], setPreventRedirect = _a[1];
    var _b = React.useState(false), isTacModalOpen = _b[0], setIsTacModalOpen = _b[1];
    var _c = React.useState(undefined), tac = _c[0], setTac = _c[1];
    // Data
    var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, authApi.getTac(getLocale(), ETacPlatform.Dashboard)];
                case 1:
                    data = (_a.sent()).data;
                    setTac(data.content);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // Redux
    var user = authHooks.useSelf().user;
    // Lifecycle
    React.useEffect(function () {
        if (preventRedirect) {
            return;
        }
        if (!user) {
            return;
        }
        navigateAfterSuccess();
    }, [navigateAfterSuccess, preventRedirect, user]);
    // Lifecycle
    React.useEffect(function () {
        fetchData();
    }, []);
    // Render
    return (_jsxs(_Fragment, { children: [_jsxs(StyledAuthFrame, { children: [_jsx(StyledAuthContent, { children: _jsxs(StyledAuthMain, { children: [_jsx(InternalModeButton, {}), _jsx(StyledAuthLogo, {}), _jsxs(StyledAuthBody, { children: [_jsx(DisplayText, { children: t.authSignUpTitle() }), _jsx(MultiStepForm, { formId: "sign-up", 
                                            // @ts-ignore state match
                                            isPerformingSubmitActionSelector: signUpActions.isPerformingSubmitActionSelector(), 
                                            // @ts-ignore state match
                                            performingSubmitActionResultSelector: signUpActions.performingSubmitActionResultSelector(), 
                                            // @ts-ignore state match
                                            performingSubmitActionErrorSelector: signUpActions.performingSubmitActionErrorSelector(), submitActionCreator: signUpActions.submitActionCreator(getLocale(), ETacPlatform.Dashboard, ECaptchaVersion.Legacy), schemas: signUpSchemas({}), asModal: false, withAside: false, onSuccess: function () {
                                                setPreventRedirect(false);
                                            }, submitLabel: getLocalizedText("system.register.title"), fullWidthSubmitButton: true }), !!tac && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsxs("p", { children: [t.authSignUpTACMessage(), " ", _jsx(Button, { appearance: "link", onClick: function () {
                                                                setIsTacModalOpen(true);
                                                            }, children: t.authSignUpTACLink() }), "."] })] })), _jsx(StyledAuthActions, { children: _jsx(Button, { onClick: function () {
                                                    dispatch({ type: "LOG_OUT" });
                                                    navigate("/auth/signin");
                                                }, appearance: "link", children: t.authToLogin() }) })] })] }) }), _jsx(StyledAuthMediaWrap, { children: _jsx(StyledAuthMedia, {}) })] }), isTacModalOpen && (_jsx(Modal, { onClose: function () {
                    setIsTacModalOpen(false);
                }, heading: t.authSignUpTACLink(), children: tac && _jsx(MarkdownWrapper, { source: tac }) }))] }));
};
// eslint-disable-next-line import/no-default-export
export default SignUp;
