import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import { mapFormFieldToNullableBooleanValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as registrationActions from "@rentiohq/shared-frontend/dist/redux/registration/registration.actions";
import * as registrationSelectors from "@rentiohq/shared-frontend/dist/redux/registration/registration.selectors";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  ERegistrationContractDestination,
  ERegistrationContractType,
  ERegistrationDocumentType,
  ERegistrationMemberType,
  ICreateRegistration,
  ICreateRegistrationDocument,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import { hasBrusselsRegistration } from "@rentiohq/shared-frontend/dist/utils/property.utils";
import { EAN_PREFIX } from "forms/requestMoveService/schema.requestMoveService.config";
import { IRootStore } from "redux/reducers";
import { EField, IExtraData } from "./schema.registration.types";

export const isPerformingSubmitActionSelector =
  (_: IExtraData) => (state: IRootStore) =>
    registrationSelectors.isCreating(state);

export const performingSubmitActionResultSelector =
  (_: IExtraData) => (state: IRootStore) =>
    registrationSelectors.createdRegistration(state);

export const performingSubmitActionErrorSelector =
  (_: IExtraData) => (state: IRootStore) =>
    registrationSelectors.createError(state);

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { property, contract } = extraData;

    const withPropertyContractUpdate = hasBrusselsRegistration(
      property,
      contract.leaseType,
    );

    const electricityEan = formData[EField.ElectricityEan];
    const parsedElectricityEan = electricityEan
      ? `${EAN_PREFIX}${electricityEan}`
      : undefined;

    const naturalGasEan = formData[EField.NaturalGasEan];
    const parsedNaturalGasEan = naturalGasEan
      ? `${EAN_PREFIX}${naturalGasEan}`
      : undefined;

    const updatedProperty: Partial<IProperty> | undefined =
      withPropertyContractUpdate
        ? {
            id: property.id,

            // General
            constructionYear: formData[EField.ConstructionYear] ?? null,

            // EPC Fields
            epcLabel: formData[EEpcField.Label] ?? null,
            epcValue: formData[EEpcField.Value] ?? null,
            epcExpirationDate: formData[EEpcField.ExpirationDate] ?? null,
            epcCertificateId: formData[EEpcField.CertificateId] ?? null,

            // Electricity
            electricityType: formData[EField.ElectricityType] ?? null,

            electricityMeterNumberBoth:
              formData[EField.ElectricityMeterNumberBoth] ?? null,
            electricityEan: parsedElectricityEan,
            electricityMeterType: formData[EField.ElectricityMeterType] ?? null,

            // Natural Gas
            naturalGasAvailable: formData[EField.NaturalGasAvailable] ?? null,
            naturalGasEan: parsedNaturalGasEan,
            naturalGasMeterNumber:
              formData[EField.NaturalGasMeterNumber] ?? null,
            naturalGasMeterType: formData[EField.NaturalGasMeterType] ?? null,

            // Water
            waterMeterNumber: formData[EField.WaterMeterNumber] ?? null,

            // Exterior
            buildingAdjoined: formData[EField.BuildingAdjoined] ?? null,
            buildingSurfaceArea: formData[EField.BuildingSurfaceArea] ?? null,
            buildingGardenSurfaceArea:
              formData[EField.BuildingGardenSurfaceArea] ?? null,
            buildingTerraceSurfaceArea:
              formData[EField.BuildingTerraceSurfaceArea] ?? null,

            // Interior
            buildingFloor: formData[EField.BuildingFloor] ?? null,
            buildingFloorCount: formData[EField.BuildingFloorCount] ?? null,
            roomCountBedroom: formData[EField.RoomCountBedroom] ?? null,
            roomCountBathroom: formData[EField.RoomCountBathroom] ?? null,
            roomCountGarage: formData[EField.RoomCountGarage] ?? null,
            buildingHasStorage: mapFormFieldToNullableBooleanValue(
              formData[EField.BuildingHasStorage],
            ),
            isFurnished: mapFormFieldToNullableBooleanValue(
              formData[EField.IsFurnished],
            ),

            // Amenities
            hasOven: mapFormFieldToNullableBooleanValue(
              formData[EField.HasOven],
            ),
            hasFridge: mapFormFieldToNullableBooleanValue(
              formData[EField.HasFridge],
            ),
            hasDishwasher: mapFormFieldToNullableBooleanValue(
              formData[EField.HasDishWasher],
            ),
            hasRangeHood: mapFormFieldToNullableBooleanValue(
              formData[EField.HasRangeHood],
            ),
            hasLift: mapFormFieldToNullableBooleanValue(
              formData[EField.HasLift],
            ),

            // Energy
            hasDoubleGlazing: mapFormFieldToNullableBooleanValue(
              formData[EField.HasDoubleGlazing],
            ),
            hasCentralHeating: mapFormFieldToNullableBooleanValue(
              formData[EField.HasCentralHeating],
            ),
            heatingType: formData[EField.HeatingType] ?? null,
          }
        : undefined;

    const updatedContract: Partial<IContract> | undefined =
      withPropertyContractUpdate
        ? {
            id: contract.id,

            [EField.IsSharedLease]: mapFormFieldToNullableBooleanValue(
              formData[EField.IsSharedLease],
            ),
            [EField.ReferencePrice]: formData[EField.ReferencePrice] ?? null,
          }
        : undefined;

    const members = [
      ...(formData[EField.Owners] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Owner,
        accountId,
      })),
      ...(formData[EField.SignAsBroker] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Broker,
        accountId,
      })),
      ...(formData[EField.Tenants] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Tenant,
        accountId,
      })),
    ];

    const registration: ICreateRegistration = {
      contractId: contract.id,
      propertyId: property.id,
      members,
      documents: [],
      rentContractDestination: formData[EField.RentContractDestination],
      preferredContractLanguage: formData[EField.RentContractLanguage],
      rentContractPropertyTaxPaidByTenant:
        formData[EField.RentContractPropertyTaxPaidByTenant] === "yes",
    };

    if (
      formData[EField.RentContractDestination] ===
      ERegistrationContractDestination.Housing
    ) {
      registration.rentContractPropertyTaxPaidByTenant = false;
    }

    const registrationContractDocument: ICreateRegistrationDocument = {
      documentId: formData[EField.RentContractDocumentToUpload]?.[0],
      documentType: ERegistrationDocumentType.Contract,
      type: ERegistrationContractType.Lease,
      signedAt: formData[EField.RentContractSignedAt],
      signers: members,
    };

    registration.documents.push(registrationContractDocument);

    if (formData[EField.LocationDescriptionShouldUpload] === "now") {
      const locationDescriptionDocument: ICreateRegistrationDocument = {
        documentId: formData[EField.LocationDescriptionDocumentToUpload]?.[0],
        documentType: ERegistrationDocumentType.PropertyInspectionReport,
        type: ERegistrationContractType.Lease,
        signedAt: formData[EField.LocationDescriptionSignedAt],
        signers: members,
      };

      registration.documents.push(locationDescriptionDocument);
    }

    return registrationActions.createRegistration.actions.start({
      registration,
      updatedProperty,
      updatedContract,
    });
  };
