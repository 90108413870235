import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IBroker } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { IGroupActionMember } from "@rentiohq/shared-frontend/dist/types/groupAction.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  ERecurrenceType,
  ITask,
} from "@rentiohq/shared-frontend/dist/types/task.types";

export enum EField {
  Header = "_header",
  RecurrenceType = "recurrenceType",
  RecurrenceSelection = "recurrenceSelection",
  Title = "title",
  Subtitle = "_subtitle",
  Description = "description",
  GroupActionExecutors = "groupActionExecutors",
  Executors = "executors",
  GroupActionFollowers = "groupActionFollowers",
  Followers = "followers",
  ExecuteDate = "executeDate",
  DueDate = "dueDate",
  ReminderType = "reminderType",
  ReminderOption = "reminderOption",
  RecurrenceEndDate = "RecurrenceEndDate",
  Reminder = "reminder",
  NextReminderDate = "nextReminderDate",
  PropertySelect = "propertySelect",
  DocumentSelect = "documentSelect",
  IncidentDate = "incidentDate",
  DamageType = "damageType",
  ContactPerson = "contactPerson",
  Payer = "payer",
  Type = "taskType",
  Location = "location",
  Category = "category",
}

export interface IExtraData {
  task?: ITask;
  taskId?: number;

  propertyId?: number;
  property?: IProperty;

  followers?: number[];
  executors?: number[];
  employees?: IAccount[];
  groupActions?: IGroupActionMember[];

  recurrenceSelection?: ERecurrenceType;

  isGroupActions?: boolean;

  payerAccountId?: number;
  repairAccountId?: number;

  broker?: IBroker;

  propertyIds?: number[];
  clearGroupActionPropertyIds?: () => void;
  onGroupActionSuccess?: () => void;
  onTaskCreateSuccess?: (task: ITask) => void;
}
