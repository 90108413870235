import { createTaskForm } from "@rentiohq/shared-frontend/dist/forms/createTask";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import {
  Form,
  Page,
  SpinningLoader,
} from "@rentiohq/web-shared-next/dist/ui-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { IExtraData } from "../TaskForm/addForm/schema.addTask.types";
import { addTaskSchema } from "./addForm";

export const TaskForm = () => {
  const params = useParams<{ taskId: string }>();
  const taskId = params.taskId ? +params.taskId : undefined;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { broker } = authHooks.useSelf();
  const { employees, isFetching: isFetchingEmployees } =
    brokerHooks.useGetBrokerEmployees();

  const { detail: task, isFetching } = taskModule.hooks.useDetail({
    shouldRefetch: false,
    id: taskId,
  });

  const contactMeMaster = useSelector((state: IPartialRootState) =>
    contactSelectors.getContactMeMaster(state),
  );

  const payerContact = useSelector((state: IPartialRootState) =>
    task ? contactSelectors.getContact(state, task?.payerContactId) : undefined,
  );

  const repairContact = useSelector((state: IPartialRootState) =>
    task
      ? contactSelectors.getContact(state, task?.repairContactId)
      : undefined,
  );

  const executors = [];

  if (contactMeMaster) {
    executors.push(contactMeMaster.accountId);
  }

  const extraData: IExtraData = taskId
    ? {
        task,
        taskId,
        payerAccountId: payerContact?.accountId || undefined,
        repairAccountId: repairContact?.accountId || undefined,
        broker,
        employees,
        onTaskCreateSuccess: task => navigate(`/tasks/?taskId=${task?.id}`),
      }
    : {
        executors,
        employees,
        broker,
        onTaskCreateSuccess: task => navigate(`/tasks/?taskId=${task?.id}`),
      };

  React.useEffect(() => {
    if (task) {
      dispatch(
        contactActions.getContacts.actions.start({
          refetch: false,
          filterData: {
            where: {
              id: { inq: [task.payerContactId, task.repairContactId] },
            },
          },
        }),
      );
    }
  }, [task]);

  const handleSubmit = (formData: any) => {
    dispatch(
      createTaskForm.submitCreateActionCreator?.(extraData as any)(formData),
    );
  };

  if (isFetching || isFetchingEmployees) {
    return <SpinningLoader />;
  }

  if (taskId && !task) {
    return null;
  }

  return (
    <Page title={taskId ? "tasks.edit" : "tasks.main.title.cta"}>
      <Form
        onSubmit={handleSubmit}
        getSchema={addTaskSchema(extraData)}
        asMultiStep={true}
        showSummary={true}
      />
    </Page>
  );
};
