import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useDispatch } from "react-redux";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { EAssets } from "../../assets";
import { SHOW_APP_MODAL } from "../../redux/actions";
import { Icon } from "../../ui-components/Icon";
import menuItems from "../../utils/menu-items";
import { EMenuItems } from "../../utils/types";
import { BottomMenuStyles } from "./BottomMenu.styles";
export var BottomMenu = function () {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var pathname = useLocation().pathname;
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var open = Boolean(anchorEl);
    var sx = BottomMenuStyles();
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleShowConfirmModal = function (item) {
        dispatch({
            type: SHOW_APP_MODAL,
            payload: {
                link: item === null || item === void 0 ? void 0 : item.url,
                title: item === null || item === void 0 ? void 0 : item.title,
                showAppModal: true,
                icon: item === null || item === void 0 ? void 0 : item.icon,
                description: item === null || item === void 0 ? void 0 : item.message,
                btnText: getLocalizedText("system.open.website"),
            },
        });
    };
    var navItems = menuItems
        .filter(function (item) {
        return [
            EMenuItems.Welcome,
            EMenuItems.Property,
            EMenuItems.Payments,
            EMenuItems.Chats,
        ].includes(item.id);
    })
        .map(function (item) {
        var id = item.id, title = item.title, icon = item.icon;
        var isSelected = item.matchPaths.some(function (path) {
            return matchPath(path, pathname);
        });
        return (_jsx(BottomNavigationAction, { label: title, onClick: function () {
                return (item === null || item === void 0 ? void 0 : item.openModal)
                    ? handleShowConfirmModal(item)
                    : navigate((item === null || item === void 0 ? void 0 : item.url) || "");
            }, icon: _jsx(Icon, { path: icon, width: "24px", height: "24px" }), sx: isSelected ? sx.content.navButtonSelected : {} }, id));
    });
    var renderMoreItemsMenu = function () {
        return (_jsxs(_Fragment, { children: [_jsxs(Box, { id: "more-menu-btn", "aria-controls": open ? "more-menu" : undefined, "aria-haspopup": "true", "aria-expanded": open ? "true" : undefined, onClick: handleClick, sx: sx.content.moreBtn, children: [_jsx(Icon, { path: EAssets.HorizontalMenu, width: "24px", height: "24px" }), _jsx(Typography, { variant: "caption", children: getLocalizedText("system.more") })] }), _jsx(Menu, { id: "more-menu", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                        "aria-labelledby": "more-menu-btn",
                    }, children: menuItems
                        .filter(function (item) {
                        return ![
                            EMenuItems.Welcome,
                            EMenuItems.Property,
                            EMenuItems.Payments,
                            EMenuItems.Chats,
                        ].includes(item.id);
                    })
                        .map(function (item) {
                        var isSelected = item.matchPaths.some(function (path) {
                            return matchPath(path, pathname);
                        });
                        return (_jsx(MenuItem, { onClick: function () {
                                (item === null || item === void 0 ? void 0 : item.openModal)
                                    ? handleShowConfirmModal(item)
                                    : navigate((item === null || item === void 0 ? void 0 : item.url) || "");
                                handleClose();
                            }, children: _jsxs(Stack, { direction: "row", gap: 1, alignItems: "center", sx: isSelected
                                    ? sx.content.navButtonSelected
                                    : sx.content.navButtonDefault, children: [_jsx(Icon, { path: item.icon, width: "22px", height: "22px" }), _jsx(Typography, { variant: "body2", sx: isSelected
                                            ? sx.content.navButtonSelected
                                            : sx.content.navButtonDefault, children: item.title })] }) }, item.id));
                    }) })] }));
    };
    return (_jsxs(BottomNavigation, { showLabels: true, sx: sx.wrapper, children: [navItems, renderMoreItemsMenu()] }));
};
