var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { SegmentedControlStyles } from "./SegmentedControl.styles";
function a11yProps(index) {
    return {
        id: "segmented-controls-tab-".concat(index),
        "aria-controls": "segmented-controls-tabpanel-".concat(index),
    };
}
export var SegmentedControls = function (props) {
    var sx = SegmentedControlStyles();
    var options = props.options, onChange = props.onChange, value = props.value, disabled = props.disabled;
    return (_jsx(Tabs, { "aria-label": "tabs", sx: sx.wrapper, value: value, onChange: function (_, newValue) { return onChange(newValue); }, children: options.map(function (option) { return (_jsx(Tab, __assign({ label: option === null || option === void 0 ? void 0 : option.label, value: option === null || option === void 0 ? void 0 : option.value, disabled: disabled }, a11yProps(option === null || option === void 0 ? void 0 : option.value)), option === null || option === void 0 ? void 0 : option.value)); }) }));
};
