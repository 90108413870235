import { useTheme } from "../../themes";
export var ContactFetchCheckListItemStyles = function (checked) {
    var theme = useTheme();
    return {
        wrapper: {
            width: "100%",
            p: checked ? "7px" : "8px",
            borderRadius: "4px",
            cursor: "pointer",
            border: checked
                ? "2px solid ".concat(theme.palette.success.main)
                : "1px solid ".concat(theme.palette.grey[400]),
            background: checked
                ? theme.palette.success.light
                : theme.palette.background.paper,
            ".MuiCheckbox-root": {
                height: "24px",
                padding: 0,
                color: checked ? theme.palette.success.main : theme.palette.grey[400],
            },
            "&:hover": {
                ".MuiTypography-subtitle1": {
                    color: theme.palette.success.main,
                },
                ".MuiCheckbox-root": {
                    color: theme.palette.success.main,
                },
            },
        },
        content: {
            title: {
                color: checked ? theme.palette.success.main : theme.palette.grey[600],
            },
        },
    };
};
