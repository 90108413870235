var _a;
import { isValidRRNo, isValidVATNumber, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { isValidPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import * as Yup from "yup";
import { ERentioFormFormat } from "../utils/types/form";
import { ERentioFormField, } from "./types/form";
var customFormats = (_a = {},
    _a[ERentioFormFormat.NationalRegisterNumber] = isValidRRNo,
    _a[ERentioFormFormat.VATNumber] = isValidVATNumber,
    _a);
export var generateValidationSchema = function (formik, fieldsSchema) {
    if (fieldsSchema === void 0) { fieldsSchema = []; }
    var validationsList = {};
    fieldsSchema.forEach(function (fieldSchema) {
        var required = fieldSchema.required, requiredIf = fieldSchema.requiredIf, title = fieldSchema.title, uiComponent = fieldSchema.uiComponent, format = fieldSchema.format, additionalValidation = fieldSchema.additionalValidation;
        var isRequired = required || (requiredIf === null || requiredIf === void 0 ? void 0 : requiredIf(formik === null || formik === void 0 ? void 0 : formik.values, formik));
        var fieldValidationSchema = Yup.mixed();
        var hasValidation = false;
        if (uiComponent === ERentioFormField.Email) {
            fieldValidationSchema = Yup.string().email(getLocalizedText("system.form.error.format.email"));
            hasValidation = true;
        }
        if (uiComponent === ERentioFormField.Phone) {
            fieldValidationSchema = Yup.string().test({
                name: "is_phone_valid",
                message: getLocalizedText("system.form.error.invalid_phone"),
                test: function (value) { return isValidPhone(value); },
            });
            hasValidation = true;
        }
        if (uiComponent === ERentioFormField.ContactCheckSelectInline) {
            if (isRequired) {
                fieldValidationSchema = Yup.array().test({
                    name: "has_values",
                    message: title
                        ? getLocalizedText("system.form.error.required.field", {
                            field: title,
                        })
                        : getLocalizedText("system.form.error.required"),
                    test: function (value) {
                        if (value === void 0) { value = []; }
                        return (value === null || value === void 0 ? void 0 : value.length) > 0;
                    },
                });
                hasValidation = true;
            }
        }
        if (format) {
            fieldValidationSchema = Yup.string().test({
                name: "is_or_no_valid",
                message: getLocalizedText("system.form.error.invalid_national_number"),
                test: function (value) {
                    if (value === void 0) { value = ""; }
                    var validator = customFormats[format];
                    return Boolean(value) ? validator(value) : true;
                },
            });
            hasValidation = true;
        }
        if (additionalValidation) {
            var name_1 = additionalValidation.name, message = additionalValidation.message, test_1 = additionalValidation.test;
            fieldValidationSchema = Yup.string().test({
                name: name_1,
                message: message,
                test: function (value) { return test_1(value, formik === null || formik === void 0 ? void 0 : formik.values); },
            });
            hasValidation = true;
        }
        if (isRequired) {
            fieldValidationSchema = fieldValidationSchema.required(title
                ? getLocalizedText("system.form.error.required.field", {
                    field: title,
                })
                : getLocalizedText("system.form.error.required"));
            hasValidation = true;
        }
        if (hasValidation) {
            validationsList[fieldSchema === null || fieldSchema === void 0 ? void 0 : fieldSchema.fieldKey] = fieldValidationSchema;
        }
    });
    return Yup.object(validationsList);
};
