import { EPaymentRepetitionType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { uniq } from "lodash";
export var isEditable = function (paymentRequest) {
    return paymentRequest.paymentRequestItems.every(function (paymentRequestItem) { return paymentRequestItem.paymentOrder.isEditable; });
};
export var hasRecurring = function (paymentRequest) {
    return paymentRequest.paymentRequestItems.some(function (paymentRequestItem) {
        return paymentRequestItem.paymentOrder.repetitionType !==
            EPaymentRepetitionType.Once;
    });
};
export var getReadableType = function (paymentRequest, translateFunction) {
    return join(uniq(paymentRequest.paymentRequestItems.map(function (paymentRequestItem) { return paymentRequestItem.paymentOrder.type; })).map(function (type) { return translateFunction(type); }));
};
