var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CONFIG } from "../../config/app.config";
import api from "../../utils/api/api.utils";
import { appendFilter } from "../../utils/api.utils";
export var getRegistrations = function (params) {
    var skip = params.skip, _a = params.limit, limit = _a === void 0 ? CONFIG.DEFAULT_FETCH_LIMIT : _a, filter = params.filter;
    var filterFinal = __assign({ order: ["createdAt DESC"], skip: skip, limit: limit }, filter);
    return api.get(appendFilter("/rent-registrations", filterFinal));
};
export var getRegistration = function (id) {
    return api.get("/rent-registrations/".concat(id));
};
export var createRegistration = function (data) {
    return api.post("/rent-registrations", { data: data });
};
export var registrationAddDocuments = function (id, data) {
    return api.post("/rent-registrations/".concat(id, "/documents"), {
        data: data,
    });
};
export var registrationUpdateDocuments = function (rentRegistrationId, documentId, data) {
    return api.put("/rent-registrations/".concat(rentRegistrationId, "/documents/").concat(documentId), {
        data: data,
    });
};
export var registrationDeleteDocuments = function (documentId) {
    return api.delete("/rent-registration-documents/".concat(documentId));
};
export var updateRegistration = function (id, data) { return api.put("/rent-registrations/".concat(id), { data: data }); };
export var deleteRegistration = function (id) {
    return api.delete("/rent-registrations/".concat(id));
};
export var retryBrusselsRegistration = function (rentRegistrationId) {
    return api.post("/rent-registrations/".concat(rentRegistrationId, "/send-to-brussels"), {
        data: {},
    });
};
