var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { removeExtraSpaces } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { TextAreaStyles } from "./TextArea.styles";
export var TextArea = function (props) {
    var sx = TextAreaStyles();
    var onChange = props.onChange, value = props.value, _a = props.minRows, minRows = _a === void 0 ? 3 : _a, minLength = props.minLength, maxLength = props.maxLength, formikProps = props.formikProps, restProps = __rest(props, ["onChange", "value", "minRows", "minLength", "maxLength", "formikProps"]);
    var handleChange = function (input) {
        var max = maxLength ? maxLength((formikProps === null || formikProps === void 0 ? void 0 : formikProps.values) || {}) : Infinity;
        var min = minLength ? minLength((formikProps === null || formikProps === void 0 ? void 0 : formikProps.values) || {}) : 0;
        if (min <= (input === null || input === void 0 ? void 0 : input.length) && max >= (input === null || input === void 0 ? void 0 : input.length)) {
            onChange(input);
        }
    };
    return (_jsx(TextareaAutosize, __assign({ style: sx.wrapper, value: value, minRows: minRows, onChange: function (e) { return handleChange(e.target.value); }, onBlur: function (e) { return handleChange(removeExtraSpaces(e.target.value)); } }, restProps)));
};
