import { jsx as _jsx } from "react/jsx-runtime";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { InlineSelectStyles } from "./InlineSelect.styles";
export var InlineSelect = function (props) {
    var options = props.options, onChange = props.onChange, formValue = props.value, id = props.id, name = props.name, formData = props.formikProps.values;
    var sx = InlineSelectStyles();
    return (_jsx(RadioGroup, { value: formValue, name: name, id: id, onChange: function (e) { return onChange(e.target.value); }, "aria-labelledby": "rentio-radio-buttons-group-label", sx: sx.wrapper, children: options.map(function (option) {
            var value = option.value, label = option.label, isDisabled = option.isDisabled;
            var disabled = isDisabled ? isDisabled(formData) : false;
            return (_jsx(FormControlLabel, { value: value, control: _jsx(Radio, { checked: value === formValue }), label: label, disabled: disabled, sx: sx.content.formControlLabel(formValue === value) }, value));
        }) }));
};
