var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery } from "redux-saga/effects";
import logger from "../../logger";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralUpdateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import { generateSaga } from "../generators/generator.saga";
import { getDetailStart, getPaymentLinkFailed, getPaymentLinkStart, getPaymentLinkSucceeded, grantAccessPaymentRequestFailed, grantAccessPaymentRequestStart, grantAccessPaymentRequestSucceeded, invoicedAtStart, markPaidFailed, markPaidStart, markPaidSucceeded, markUnPaidStart, markUnPaidFailed, markUnPaidSucceeded, reminderChatAppFailed, reminderChatAppStart, reminderChatAppSucceeded, reminderMailFailed, reminderMailStart, reminderMailSucceeded, reminderPhoneFailed, reminderPhoneStart, reminderPhoneSucceeded, reminderRegisteredMailFailed, reminderRegisteredMailStart, reminderRegisteredMailSucceeded, reminderSmsFailed, reminderSmsStart, reminderSmsSucceeded, sendPaymentRequestInvitationFailed, sendPaymentRequestInvitationStart, sendPaymentRequestInvitationSucceeded, updateFailed, updateSucceeded, } from "./paymentRequest.actions";
import * as paymentRequestApi from "./paymentRequest.api";
import { GENERATOR_PARAMS } from "./paymentRequest.constants";
function markPaymentRequestPaidStartFlow(action) {
    var _a, id, manuallyPaidAt, amount, onSuccess, unknownError_1, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, manuallyPaidAt = _a.manuallyPaidAt, amount = _a.amount, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(paymentRequestApi.markPaymentRequestPaid, {
                        id: id,
                        manuallyPaidAt: manuallyPaidAt,
                        amount: amount,
                    })];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(markPaidSucceeded.getAction({
                        id: id,
                    }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(getDetailStart.getAction({
                        id: id,
                    }))];
            case 4:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(id);
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.marked_paid.toast.heading"),
                });
                return [3 /*break*/, 7];
            case 5:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(markPaidFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 6:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function markPaymentRequestUnPaidStartFlow(action) {
    var _a, id, onSuccess, unknownError_2, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentRequestApi.markPaymentRequestUnPaid, id)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(markUnPaidSucceeded.getAction({
                        id: id,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(id);
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.marked_unpaid.toast.heading"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(markUnPaidFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function paymentRequestInvoicedAtStartFlow(action) {
    var _a, id, invoicedAt, onSuccess, result, unknownError_3, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, invoicedAt = _a.invoicedAt, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(
                    // @ts-ignore
                    paymentRequestApi.paymentRequestInvoicedAt, id, invoicedAt)];
            case 2:
                result = _b.sent();
                return [4 /*yield*/, put(updateSucceeded.getAction({
                        id: id,
                        result: result,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result.data);
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText(GENERATOR_PARAMS.entity),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(updateFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getPaymentLinkStartFlow(action) {
    var _a, id, onSuccess, result, unknownError_4, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentRequestApi.generatePaymentLink, id)];
            case 2:
                result = _b.sent();
                return [4 /*yield*/, put(getPaymentLinkSucceeded.getAction({
                        id: id,
                        result: result,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(id, result);
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.generate_payment_link.toast.heading"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_4 = _b.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(getPaymentLinkFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function grantAccessPaymentRequestStartFlow(action) {
    var _a, id, data, onSuccess, result, unknownError_5, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, data = _a.data, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentRequestApi.grantAccessPaymentRequest, id, data)];
            case 2:
                result = _b.sent();
                return [4 /*yield*/, put(grantAccessPaymentRequestSucceeded.getAction({
                        id: id,
                        result: result,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(id, result);
                return [3 /*break*/, 6];
            case 4:
                unknownError_5 = _b.sent();
                error = unknownError_5;
                logger.logError({ error: error });
                return [4 /*yield*/, put(grantAccessPaymentRequestFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function sendPaymentRequestInvitationStartFlow(action) {
    var id, result, unknownError_6, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                id = action.payload.id;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(paymentRequestApi.sendPaymentRequestInvitation, id)];
            case 2:
                result = _a.sent();
                return [4 /*yield*/, put(sendPaymentRequestInvitationSucceeded.getAction({
                        id: id,
                        result: result,
                    }))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(getDetailStart.getAction({
                        id: id,
                    }))];
            case 4:
                _a.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.resend_invitation.sent.success"),
                });
                return [3 /*break*/, 7];
            case 5:
                unknownError_6 = _a.sent();
                error = unknownError_6;
                logger.logError({ error: error });
                return [4 /*yield*/, put(sendPaymentRequestInvitationFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 6:
                _a.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function sendReminderSmsStartFlow(action) {
    var _a, id, data, onSuccess, unknownError_7, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, data = _a.data, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentRequestApi.paymentRequestReminderSms, id, data)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(reminderSmsSucceeded.getAction({
                        id: id,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.follow_up.sms.success"),
                });
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [3 /*break*/, 6];
            case 4:
                unknownError_7 = _b.sent();
                error = unknownError_7;
                logger.logError({ error: error });
                return [4 /*yield*/, put(reminderSmsFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function sendReminderPhoneStartFlow(action) {
    var _a, id, data, onSuccess, unknownError_8, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, data = _a.data, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentRequestApi.paymentRequestReminderPhone, id, data)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(reminderPhoneSucceeded.getAction({
                        id: id,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.follow_up.phone.success"),
                });
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [3 /*break*/, 6];
            case 4:
                unknownError_8 = _b.sent();
                error = unknownError_8;
                logger.logError({ error: error });
                return [4 /*yield*/, put(reminderPhoneFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function sendReminderMailStartFlow(action) {
    var _a, id, data, onSuccess, unknownError_9, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, data = _a.data, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentRequestApi.paymentRequestReminderMail, id, data)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(reminderMailSucceeded.getAction({
                        id: id,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.follow_up.mail.success"),
                });
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [3 /*break*/, 6];
            case 4:
                unknownError_9 = _b.sent();
                error = unknownError_9;
                logger.logError({ error: error });
                return [4 /*yield*/, put(reminderMailFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function sendReminderChatAppStartFlow(action) {
    var _a, id, data, onSuccess, unknownError_10, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, data = _a.data, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentRequestApi.paymentRequestReminderChatApp, id, data)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(reminderChatAppSucceeded.getAction({
                        id: id,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.follow_up.app.success"),
                });
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [3 /*break*/, 6];
            case 4:
                unknownError_10 = _b.sent();
                error = unknownError_10;
                logger.logError({ error: error });
                return [4 /*yield*/, put(reminderChatAppFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function sendReminderRegisteredMailStartFlow(action) {
    var _a, id, data, onSuccess, unknownError_11, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, data = _a.data, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentRequestApi.paymentRequestReminderRegisteredMail, id, data)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(reminderRegisteredMailSucceeded.getAction({
                        id: id,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.follow_up.registered_mail.success"),
                });
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [3 /*break*/, 6];
            case 4:
                unknownError_11 = _b.sent();
                error = unknownError_11;
                logger.logError({ error: error });
                return [4 /*yield*/, put(reminderRegisteredMailFailed.getAction({
                        id: id,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(markPaidStart.type, markPaymentRequestPaidStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(markUnPaidStart.type, markPaymentRequestUnPaidStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(invoicedAtStart.type, paymentRequestInvoicedAtStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(getPaymentLinkStart.type, getPaymentLinkStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(grantAccessPaymentRequestStart.type, grantAccessPaymentRequestStartFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(sendPaymentRequestInvitationStart.type, sendPaymentRequestInvitationStartFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(reminderSmsStart.type, sendReminderSmsStartFlow)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeEvery(reminderPhoneStart.type, sendReminderPhoneStartFlow)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(reminderMailStart.type, sendReminderMailStartFlow)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(reminderChatAppStart.type, sendReminderChatAppStartFlow)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeEvery(reminderRegisteredMailStart.type, sendReminderRegisteredMailStartFlow)];
            case 11:
                _a.sent();
                return [4 /*yield*/, generateSaga(GENERATOR_PARAMS)];
            case 12:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
