import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import * as paymentUtils from "@rentiohq/shared-frontend/dist/redux/payment/payment.utils";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import {
  ETemplateType,
  ITemplate,
} from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import * as templateUtils from "@rentiohq/shared-frontend/dist/redux/template/template.utils";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import { ITemplateDocument } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import {
  IIndexInfoSimulation,
  getIndexInfoSimulation,
} from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.api";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { getIndexInfoAvailable } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import {
  EContractIndexationStatus,
  EContractMemberTypes,
  ELeaseType,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EPaymentOrderType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  EPropertyEPCLabel,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { isResidentialLease } from "@rentiohq/shared-frontend/dist/utils/contract.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { isFlemishZip } from "@rentiohq/shared-frontend/dist/utils/property.utils";
import { getMembersWithRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  AccountList,
  Banner,
  DisplayText,
  ESpacings,
  Loading,
  Modal,
  MultiStepForm,
  PropertyList,
  RentioInternalRenderer,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import indexContractSchemas from "@rentiohq/web-shared/dist/forms/indexContract";
import { EField } from "@rentiohq/web-shared/dist/forms/indexContract/schema.indexContract.types";
import { checkIfEpcExpired } from "@rentiohq/web-shared/dist/forms/indexContract/schema.indexContract.utils";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { parseFields } from "@rentiohq/web-shared/dist/scenes/TemplateEditor/components/Editor/Editor.utils";
import { ts as tsCommon } from "@rentiohq/web-shared/dist/services";
import {
  getDefaultDocumentConfig,
  getDefaultVariablesData,
} from "components/TemplateDocumentContractCreateModal/TemplateDocumentContractCreateModal.utils";
import { compact, floor } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  PREFERENCE_KEY_BRAND_COLOR,
  PREFERENCE_KEY_BRAND_LOGO,
} from "./Index.constants";
import { getIndexationLetterTitle } from "./Index.utils";

const formId = generateFormId();

const getShouldDisableIndexButton = (params: {
  property: IProperty;
  contract: IContract;
  epcLabel?: EPropertyEPCLabel;
  epcExpiry: Date;
  forceFullIndexation: boolean;
}) => {
  const {
    property,
    contract,
    epcLabel = EPropertyEPCLabel.Unknown,
    epcExpiry,
    forceFullIndexation,
  } = params;

  if (forceFullIndexation) {
    return false;
  }

  const epcExpired = checkIfEpcExpired(epcExpiry);
  const noEPC = [
    EPropertyEPCLabel.Unknown,
    EPropertyEPCLabel.NoEPCAvailable,
  ].includes(epcLabel);
  if (epcExpired || noEPC) {
    const propertyInFlanders = property?.zip && isFlemishZip(property.zip);

    const residentialLease = isResidentialLease(contract?.leaseType);

    if (!propertyInFlanders && residentialLease) {
      return true;
    }
  }
  return false;
};

const getCanIndex = (params: {
  contract: IContract;
  epcLabel?: EPropertyEPCLabel;
  forceFullIndexation: boolean;
}) => {
  const {
    contract,
    epcLabel = EPropertyEPCLabel.Unknown,
    forceFullIndexation,
  } = params;
  const indexInfoAvailable = getIndexInfoAvailable(contract.indexInfo);
  if (!indexInfoAvailable) {
    return false;
  }

  if (forceFullIndexation) {
    return indexInfoAvailable.increase !== 0;
  }

  switch (epcLabel) {
    case EPropertyEPCLabel.APlus:
    case EPropertyEPCLabel.A:
    case EPropertyEPCLabel.B:
    case EPropertyEPCLabel.C:
      return indexInfoAvailable.increase !== 0;

    case EPropertyEPCLabel.D:
      return indexInfoAvailable.increaseEpcD !== 0;

    case EPropertyEPCLabel.E:
      return indexInfoAvailable.increaseEpcE !== 0;

    case EPropertyEPCLabel.F:
      return indexInfoAvailable.increaseEpcF !== 0;

    case EPropertyEPCLabel.G:
      return indexInfoAvailable.increaseEpcG !== 0;

    case EPropertyEPCLabel.Unknown:
    case EPropertyEPCLabel.NoEPCAvailable:
    default:
      return indexInfoAvailable.increaseNoEpc !== 0;
  }
};

export interface IIndexContractModalProps {
  contractId: string;
  onClose: (documentId?: string) => void;
}

export const IndexContractModal = (props: IIndexContractModalProps) => {
  const { contractId, onClose } = props;

  const [formState, setFormState] = useState<any>({});
  const [stepIndex, setStepIndex] = useState(0);
  const [isLoadingIndexSimulationInfo, setIsLoadingIndexSimulationInfo] =
    useState(false);
  const [indexAsSimulation, setIndexAsSimulation] = useState(false);
  const [indexSimulationToShow, setIndexSimulationToShow] =
    useState<IIndexInfoSimulation>();

  const dispatch = useDispatch();
  const { detail: contract } = contractHooks.useDetail({
    shouldRefetch: false,
    id: contractId,
  });

  const { detail: property } = propertyHooks.useDetail({
    shouldRefetch: false,
    id: contract?.propertyId,
  });

  const { update: updateProperty, isUpdating: isUpdatingProperty } =
    propertyHooks.useUpdate({
      id: contract?.propertyId,
    });

  const { index, askOwner, skip, isIndexing } = contractHooks.useIndex({
    contractId: contract?.id,
  });

  const paymentOrdersIdentifier = property?.id
    ? paymentUtils.getPaymentOrdersIdentifierForProperty(property.id)
    : undefined;
  const paymentOrders = useSelector((state: IRootStore) =>
    paymentOrdersIdentifier
      ? paymentSelectors.getPaymentOrdersByIdentifier(
          state,
          paymentOrdersIdentifier,
        )
      : undefined,
  );
  const isFetchingPaymentOrders = useSelector((state: IRootStore) =>
    paymentOrdersIdentifier
      ? state.payment.paymentOrdersByIdentifier[paymentOrdersIdentifier]
          ?.isFetching
      : false,
  );

  const contacts = useSelector((state: IRootStore) =>
    contactSelectors.getAllContacts(state),
  );
  const legalContact = useSelector((state: IRootStore) =>
    contactSelectors.getContactByCustomId(state, EContactCustomId.Legal),
  );
  const meMaster = useSelector((state: IRootStore) =>
    contactSelectors.getContactByCustomId(state, EContactCustomId.MeMaster),
  );

  const { broker } = authHooks.useSelf();

  const brandColor = useSelector((state: IRootStore) =>
    systemSelectors.getPreference<string>(state, PREFERENCE_KEY_BRAND_COLOR),
  );
  const brokerLogoDocumentId = useSelector((state: IRootStore) =>
    systemSelectors.getPreference<string>(state, PREFERENCE_KEY_BRAND_LOGO),
  );

  const preferences = useSelector(
    (state: IRootStore) => state.systemLocal.preferences,
  );

  const templates = useSelector((state: IRootStore) =>
    templateSelectors.paged.dataForPage(state, {
      id: templateUtils.getPagedId({}),
      page: 0,
    }),
  );
  const availableTemplates = templates?.filter(
    x => x.type === ETemplateType.Indexation,
  );

  useEffect(() => {
    if (
      paymentOrders ||
      !paymentOrdersIdentifier ||
      !contract ||
      isFetchingPaymentOrders
    ) {
      return;
    }

    dispatch(
      paymentActions.getPaymentOrdersByIdentifier.actions.start({
        paymentOrdersIdentifier,
        refetch: true,
        filterData: {
          where: { completedAt: null, propertyId: contract.propertyId },
          order: "startedAt DESC, id DESC",
        },
      }),
    );
  }, [contract, paymentOrdersIdentifier]);

  if (!contract) {
    return null;
  }

  const getIndexInfoSimulationForContract = async (input: {
    contractId: string;
    forceFullIndexation: boolean;
    newPrice: number;
    retroactivityMonths: number;
  }) => {
    try {
      setIsLoadingIndexSimulationInfo(true);

      const response = await getIndexInfoSimulation(contract.id, {
        newPrice: input.newPrice,
        retroactivityMonths: input.retroactivityMonths,
        forceFullIndexation: input.forceFullIndexation,
      });

      setIsLoadingIndexSimulationInfo(false);

      return response.data.data;
    } catch (error) {
      setIsLoadingIndexSimulationInfo(false);
    }
  };

  const indexWithIndexationLetter = async (
    template: ITemplate,
    input: {
      updatedProperty: IProperty;
      contractId: string;
      forceFullIndexation: boolean;
      newPrice?: number;
      retroactivityMonths: number;
    },
  ) => {
    if (!input.newPrice) {
      return;
    }

    if (!property || !contract) {
      return;
    }

    if (!template.fields) {
      return;
    }

    const rentPaymentOrder = paymentOrders?.find(
      x => x.type === EPaymentOrderType.Rent,
    );

    const indexInfoSimulation = await getIndexInfoSimulationForContract({
      contractId: contract.id,
      forceFullIndexation: input.forceFullIndexation,
      newPrice: input.newPrice,
      retroactivityMonths: input.retroactivityMonths,
    });

    dispatch(
      templateDocumentActions.create.actions.start({
        suppressSuccessMessage: true,
        data: {
          templateId: template.id,
          propertyId: property.id,
          contractId: contract.id,
          name: getIndexationLetterTitle({ property }),
          type: template.type,
          documentConfig: getDefaultDocumentConfig({
            brandColor,
          }),
          variablesData: getDefaultVariablesData({
            legalContact,
            meMaster,
            broker,
            brokerLogoDocumentId,
            property: input.updatedProperty,
            contract,
            contacts,
            fields: parseFields(template.fields),
            preferences,
            rentPaymentOrder,
            indexInfo: contract.indexInfo,
            indexInfoSimulation,
            indexInfoRequest: {
              newPrice: input.newPrice,
              isRetroactive: input.retroactivityMonths > 0,
              forceFullIndexation: input.forceFullIndexation,
            },
          }),
        },
        onSuccess: (templateDocument: ITemplateDocument) => {
          dispatch(
            templateDocumentActions.generateTemplateDocumentPdf.actions.start({
              id: templateDocument.id,
              suppressSuccessMessage: true,
              data: {},
              onSuccess: (templateDocumentAfterGenerate: ITemplateDocument) => {
                index({
                  ...input,
                  onSuccess: () => {
                    onClose(templateDocumentAfterGenerate.documentId);
                  },
                  onFailure(error) {
                    showAlert({
                      type: "error",
                      error,
                    });

                    onClose();
                  },
                });
              },
            }),
          );
        },
      }),
    );
  };

  const indexWithoutIndexationLetter = (input: {
    contractId: string;
    forceFullIndexation: boolean;
    newPrice?: number;
    retroactivityMonths: number;
  }) => {
    index({
      ...input,
      onSuccess: onClose,
    });
  };

  const handleIndexSuccess = async (formData: any) => {
    if (!indexInfoAvailable) {
      return;
    }

    const input = {
      contractId,
      newPrice: floor(formData[EField.NewPrice], 2),
      retroactivityMonths: formData[EField.Retroactivity]
        ? indexInfoAvailable.retroactivityMonths || 0
        : 0,
      forceFullIndexation: formData[EField.ForceFullIndexation] || false,
    };

    if (indexAsSimulation) {
      const indexInfoSimulation = await getIndexInfoSimulationForContract({
        contractId: contract.id,
        forceFullIndexation: input.forceFullIndexation,
        newPrice: input.newPrice,
        retroactivityMonths: input.retroactivityMonths,
      });

      setIndexSimulationToShow(indexInfoSimulation);

      return;
    }

    const templateId = formData[EField.LetterType];
    const template = formData[EField.GenerateLetter]
      ? availableTemplates?.find(template => template.id === templateId)
      : undefined;

    updateProperty({
      data: {
        epcLabel: formData[EEpcField.Label],
        epcExpirationDate: formData[EEpcField.ExpirationDate],
        epcValue: formData[EEpcField.Value],
      },
      suppressSuccessAlert: true,
      onSuccess: async result => {
        if (template) {
          await indexWithIndexationLetter(template, {
            ...input,
            updatedProperty: result.data,
          });
          return;
        }

        indexWithoutIndexationLetter(input);
      },
      onFailure: error => {
        // TODO: (how?) do we handle this?
        // eslint-disable-next-line no-console
        console.log(`IndexContractModal: updating property failed. ${error}`);
      },
    });
  };

  const handleWithoutIndexation = (params: { isAskOwner: boolean }) => {
    const { isAskOwner } = params;

    updateProperty({
      data: {
        epcLabel: formState[EEpcField.Label],
        epcExpirationDate: formState[EEpcField.ExpirationDate],
        epcValue: formState[EEpcField.Value],
      },
      suppressSuccessAlert: true,
      onSuccess: () => {
        isAskOwner
          ? askOwner({
              onSuccess: () => {
                onClose();
              },
              onFailure: error => {
                showAlert({
                  type: "error",
                  error,
                });

                onClose();
              },
            })
          : skip({
              onSuccess: () => {
                onClose();
              },
              onFailure: error => {
                showAlert({
                  type: "error",
                  error,
                });

                onClose();
              },
            });
      },
    });
  };

  const handleSkipIndex = async () => {
    confirm({
      title: getLocalizedText("indexation.confirm.skip_index.title"),
      info: getLocalizedText("indexation.confirm.skip_index.info"),
      type: "warning",
      primaryActions: [
        {
          title: tsCommon.contractIndexSkipAction(),
          onPress: () => {
            handleWithoutIndexation({ isAskOwner: false });
          },
        },
      ],
    });
  };

  const handleAskOwner = async () => {
    confirm({
      title: getLocalizedText("indexation.confirm.ask_owner.title"),
      info: getLocalizedText("indexation.confirm.ask_owner.info"),
      type: "warning",
      primaryActions: [
        {
          title: tsCommon.contractIndexInquiryAction(),
          onPress: () => {
            handleWithoutIndexation({ isAskOwner: true });
          },
        },
      ],
    });
  };

  const {
    //repetitionType = EContractPaymentRepetitionType.Yearly,
    roles,
    indexationStatus,
  } = contract;

  const indexInfoAvailable = getIndexInfoAvailable(contract?.indexInfo);

  if (!property || !indexInfoAvailable || !paymentOrders) {
    return (
      <Modal
        onClose={() => {
          onClose();
        }}
        shouldCloseOnOverlayClick={false}
        hasDismiss={false}
        actions={[]}
      >
        <Loading asDots={true} />
      </Modal>
    );
  }

  const renderInternalIndexInfo = () => {
    return (
      <RentioInternalRenderer
        mapKeys={false}
        items={{
          "New price & increase": `${indexInfoAvailable.newPrice} | ${indexInfoAvailable.increase}`,
          "New price & increase - EPC D": `${indexInfoAvailable.newPriceEpcD} | ${indexInfoAvailable.increaseEpcD}`,
          "New price & increase - EPC E": `${indexInfoAvailable.newPriceEpcE} | ${indexInfoAvailable.increaseEpcE}`,
          "New price & increase - EPC F": `${indexInfoAvailable.newPriceEpcF} | ${indexInfoAvailable.increaseEpcF}`,
          "New price & increase - EPC G": `${indexInfoAvailable.newPriceEpcG} | ${indexInfoAvailable.increaseEpcG}`,
          "New price & increase - NO EPC": `${indexInfoAvailable.newPriceNoEpc} | ${indexInfoAvailable.increaseNoEpc}`,

          "Correction factors - EPC D": indexInfoAvailable.correctionFactorEpcD,
          "Correction factors - EPC E": indexInfoAvailable.correctionFactorEpcE,
          "Correction factors - EPC F": indexInfoAvailable.correctionFactorEpcF,
          "Correction factors - EPC G": indexInfoAvailable.correctionFactorEpcG,
          "Correction factors - NO EPC":
            indexInfoAvailable.correctionFactorNoEpc,

          "Retroactive months": indexInfoAvailable.retroactivityMonths,
          "Retroactive price": indexInfoAvailable.retroactivityPrice,
          "Retroactive period start":
            indexInfoAvailable.retroactivityPeriodStart
              ? formatDate(indexInfoAvailable.retroactivityPeriodStart)
              : undefined,
          "Retroactive period end": indexInfoAvailable.retroactivityPeriodEnd
            ? formatDate(indexInfoAvailable.retroactivityPeriodEnd)
            : undefined,

          "Epc based indexation type":
            indexInfoAvailable.epcBasedIndexationType,
        }}
      />
    );
  };

  if (indexSimulationToShow) {
    return (
      <Modal
        heading={`${getLocalizedText("properties.indexation")} [simulation]`}
        shouldCloseOnOverlayClick={true}
        onClose={() => {
          onClose();
        }}
        hasDismiss={false}
        actions={[
          {
            content: getLocalizedText("system.back"),
            appearance: "primary",
            onClick: () => {
              setIndexSimulationToShow(undefined);
            },
          },
        ]}
      >
        <DisplayText size="medium" space="none">
          Simulation info
        </DisplayText>

        <ResourceList
          items={compact(
            Object.keys(indexSimulationToShow)
              .filter(key => !key.includes("indexInfo"))
              .map(key => ({
                key,
                value: indexSimulationToShow[key as keyof IIndexInfoSimulation],
              })),
          )}
          renderItem={item => (
            <ResourceListItem item={item}>
              <TextStyle variation="subdued" element="div">
                {item.key}
              </TextStyle>
              <div>{item.value as any}</div>
            </ResourceListItem>
          )}
        />

        {renderInternalIndexInfo()}
      </Modal>
    );
  }

  const getIndexContactSchemas = () => {
    return indexContractSchemas({
      indexInfo: indexInfoAvailable,
      contract,
      showGenerateLetter: !!availableTemplates?.[0],
      letterTemplates: availableTemplates,
      property,
      isCommercialLease: [
        ELeaseType.CommercialLease,
        ELeaseType.Other,
      ].includes(contract?.leaseType),
      epcIsRequired: false,
    });
  };

  const getIntro = () => {
    const showIndexationStatusInfo =
      contract.indexationStatus &&
      [
        EContractIndexationStatus.OwnerAsked,
        EContractIndexationStatus.OwnerApproved,
        EContractIndexationStatus.OwnerDeclined,
      ].includes(contract.indexationStatus);

    return (
      <Box mb={ESpacings.loose}>
        <Box mb={ESpacings.extraTight}>
          <TextStyle variation={["strong"]}>
            {getLocalizedText("system.property")}
          </TextStyle>
        </Box>

        <PropertyList properties={[property]} />

        {contract.members && (
          <>
            <Box mt={ESpacings.base} mb={ESpacings.tight}>
              <TextStyle variation={["strong"]}>
                {getLocalizedText("system.tenants")}
              </TextStyle>
            </Box>

            <AccountList
              accounts={getMembersWithRole(
                contract.members,
                EContractMemberTypes.Tenant,
              ).map(member => member.account)}
            />
          </>
        )}

        {contract.members && (
          <>
            <Box mt={ESpacings.base} mb={ESpacings.tight}>
              <TextStyle variation={["strong"]}>
                {getLocalizedText("system.owners")}
              </TextStyle>
            </Box>

            <AccountList
              accounts={getMembersWithRole(
                contract.members,
                EContractMemberTypes.Owner,
              ).map(member => member.account)}
            />
          </>
        )}

        {contract.indexationStatus && showIndexationStatusInfo && (
          <>
            <Spacer weight={ESpacerWeight.W16} />
            <Banner
              title={getLocalizedText("indexation.status_banner.title")}
              icon="alertDiamond"
              variation="warning"
              hasDismiss={false}
            >
              <TextStyle>
                {getLocalizedText(
                  `indexation.status_banner.content.${contract.indexationStatus}`.toLowerCase(),
                )}
              </TextStyle>
            </Banner>
          </>
        )}

        {renderInternalIndexInfo()}
      </Box>
    );
  };

  const onChange = (newFormData: any, stepIndex: number) => {
    setFormState({ ...formState, ...newFormData });
    setStepIndex(stepIndex);
  };

  const canIndex = getCanIndex({
    contract,
    epcLabel: formState[EEpcField.Label],
    forceFullIndexation: formState[EField.ForceFullIndexation],
  });

  const shouldDisableIndexButton = getShouldDisableIndexButton({
    property,
    contract,
    epcLabel: formState[EEpcField.Label],
    epcExpiry: formState[EEpcField.ExpirationDate],
    forceFullIndexation: formState[EField.ForceFullIndexation],
  });

  const isLoading =
    isUpdatingProperty || isLoadingIndexSimulationInfo || isIndexing;

  return (
    <MultiStepForm
      intro={getIntro()}
      formId={`index-contact-epc-${formId}`}
      schemas={getIndexContactSchemas()}
      asModal={true}
      withAside={false}
      onSuccess={handleIndexSuccess}
      onChange={onChange}
      modalProps={{
        shouldCloseOnOverlayClick: true,
        onClose: () => {
          onClose();
        },
        heading: `${getLocalizedText("properties.indexation")}${
          indexAsSimulation ? " [simulation]" : ""
        }`,
      }}
      modalActions={compact([
        {
          isInternal: true,
          content: "Toggle simulation",
          appearance: "outline",
          onClick: () => {
            setIndexAsSimulation(!indexAsSimulation);
          },
        },
        {
          content: tsCommon.contractIndexSkipAction(),
          appearance: canIndex ? "outline" : "primary",
          isDisabled: indexAsSimulation,
          onClick: handleSkipIndex,
          isSubmitting: isLoading,
        },
        !roles.includes(EContractMemberTypes.Owner) &&
        indexationStatus &&
        ![
          EContractIndexationStatus.OwnerAsked,
          EContractIndexationStatus.OwnerApproved,
          EContractIndexationStatus.OwnerDeclined,
        ].includes(indexationStatus)
          ? {
              content: tsCommon.contractIndexInquiryAction(),
              appearance: "primary",
              isDisabled: !canIndex || indexAsSimulation,
              onClick: handleAskOwner,
              isSubmitting: isLoading,
            }
          : undefined,
      ])}
      isSubmitButtonDisabled={
        !canIndex || (Boolean(stepIndex) && shouldDisableIndexButton)
      }
      submitLabel={
        indexAsSimulation ? "Simulate" : tsCommon.contractIndexIndexNowAction()
      }
      isLoading={isLoading}
    />
  );
};
