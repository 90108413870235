export var ELinkTarget;
(function (ELinkTarget) {
    ELinkTarget["blank"] = "_blank";
    ELinkTarget["self"] = "_self";
    ELinkTarget["parent"] = "_parent";
    ELinkTarget["top"] = "_top";
})(ELinkTarget || (ELinkTarget = {}));
export var EMenuItems;
(function (EMenuItems) {
    EMenuItems["Welcome"] = "welcome";
    EMenuItems["Property"] = "property";
    EMenuItems["Contacts"] = "contacts";
    EMenuItems["Applications"] = "applications";
    EMenuItems["Payments"] = "payments";
    EMenuItems["Tasks"] = "tasks";
    EMenuItems["Chats"] = "chats";
})(EMenuItems || (EMenuItems = {}));
