import {
  ETaskMemberType,
  ETaskRecurrence,
  ITask,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import {
  endOfDay,
  endOfWeek,
  isAfter,
  isBefore,
  isEqual,
  isToday,
  startOfToday,
  startOfWeek,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";

export interface IResultingFilter {
  rolesFilter?: Array<string>;
  employeeAccountIds?: Array<number>;
  filter?: Record<string, any>;
  unassignedToEmployee?: boolean;
  customFilters?: Array<string>;
}

export enum EInitialType {
  ToExecute = "to_execute",
  Keypoint = "keypoint",
  FromDashboard = "from_dashboard",
}

export enum ETaskStatusFilter {
  Open = "open",
  Finished = "finished",
  Both = "both",
}

export enum EThirdPartyFilter {
  Rentio = "rentio",
  Keypoint = "keypoint",
  Both = "both",
}

export enum ETimeFilter {
  BeforeToday = "before_today",
  Today = "today",
  ThisWeek = "this_week",
  NextWeek = "next_week",
  All = "all",
}

export const getTimeFilter = (filter: ETimeFilter) => {
  // End of the week (23:59:59) + 1 sec
  const now = new Date();
  const startOfNextWeek = new Date(
    endOfWeek(now, { weekStartsOn: 1 }).getTime() + 1000,
  );

  switch (filter) {
    case ETimeFilter.BeforeToday:
      return {
        executeDate: {
          lt: startOfToday(),
        },
      };

    case ETimeFilter.Today:
      return {
        and: [
          {
            executeDate: {
              gte: startOfToday(),
            },
          },
          {
            executeDate: {
              lte: endOfDay(startOfToday()),
            },
          },
        ],
      };

    case ETimeFilter.ThisWeek:
      return {
        and: [
          {
            executeDate: {
              gte: startOfWeek(startOfToday(), { weekStartsOn: 1 }),
            },
          },
          {
            executeDate: {
              lte: endOfWeek(startOfToday(), { weekStartsOn: 1 }),
            },
          },
        ],
      };

    case ETimeFilter.NextWeek:
      return {
        and: [
          {
            executeDate: {
              gte: startOfWeek(startOfNextWeek, { weekStartsOn: 1 }),
            },
          },
          {
            executeDate: {
              lte: endOfWeek(startOfNextWeek, { weekStartsOn: 1 }),
            },
          },
        ],
      };

    case ETimeFilter.All:
    default:
      break;
  }
};

export const getTaskStatus = ({ dueDate, roles, finishedAt }: ITask) => {
  // End of the week (23:59:59) + 1 sec
  const now = new Date();
  const startOfNextWeek = new Date(
    endOfWeek(now, { weekStartsOn: 1 }).getTime() + 1000,
  );

  const isExecutor = roles?.includes(ETaskMemberType.Executor);

  if (finishedAt) {
    return ETaskRecurrence.Done;
  }

  if (!dueDate) {
    return isExecutor ? ETaskRecurrence.Todo : ETaskRecurrence.FollowUp;
  }

  if (isBefore(dueDate, startOfToday()) || isToday(dueDate)) {
    return isExecutor
      ? ETaskRecurrence.TodoToday
      : ETaskRecurrence.FollowUpToday;
  }

  if (
    isAfter(dueDate, startOfToday()) &&
    (isBefore(dueDate, startOfNextWeek) || isEqual(dueDate, startOfNextWeek))
  ) {
    return isExecutor
      ? ETaskRecurrence.TodoThisWeek
      : ETaskRecurrence.FollowUpThisWeek;
  }

  return isExecutor
    ? ETaskRecurrence.TodoLater
    : ETaskRecurrence.TodoFollowUpLater;
};
