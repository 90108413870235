import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { EPropertyEPCLabel } from "../../types/property.types";
import { addYears, endOfYear, startOfYear, subYears, } from "../../utils/date-fns.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getAvailableEpcLabelsForProperty } from "../../utils/property.utils";
import { EField } from "./schema.epcInfo.types";
var hasEpcLabel = function (formData) {
    var epcLabel = formData[EField.Label];
    if (!epcLabel) {
        return false;
    }
    return ![
        EPropertyEPCLabel.Unknown,
        EPropertyEPCLabel.NoEPCAvailable,
    ].includes(epcLabel);
};
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        var _b, _c;
        var epcIsRequired = extraData.epcIsRequired;
        var requiredFields = epcIsRequired
            ? [
                EField.Label,
                EField.Value,
                EField.ExpirationDate,
                EField.CertificateId,
            ]
            : [EField.Label];
        return {
            required: requiredFields,
            properties: (_a = {},
                _a[EField.Label] = {
                    default: getValue(EField.Label, initialValues, defaultValues),
                    //@ts-ignore
                    options: getAvailableEpcLabelsForProperty({
                        countryId: (_b = extraData.property) === null || _b === void 0 ? void 0 : _b.countryId,
                        zip: (_c = extraData.property) === null || _c === void 0 ? void 0 : _c.zip,
                        epcIsRequired: epcIsRequired,
                    }),
                },
                _a[EField.Value] = {
                    default: getValue(EField.Value, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: extraData.epcIsRequired ? undefined : hasEpcLabel,
                    // requiredIf: hasEpcLabel,
                },
                _a[EField.ExpirationDate] = {
                    default: getValue(EField.ExpirationDate, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: extraData.epcIsRequired ? undefined : hasEpcLabel,
                    requiredIf: extraData.epcIsRequired ? undefined : hasEpcLabel,
                },
                _a[EField.CertificateId] = {
                    default: getValue(EField.CertificateId, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: extraData.epcIsRequired ? undefined : hasEpcLabel,
                    // requiredIf: hasEpcLabel,
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData, initialValues) {
    var _a;
    var NOW = new Date();
    return _a = {},
        _a[EField.Label] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("editor.variable.property_epc_label"),
            formatSummary: function (value) {
                return getLocalizedText("epc.label.".concat(value).toLowerCase());
            },
        },
        _a[EField.Value] = {
            "ui:field": ERentioFormField.Number,
            title: getLocalizedText("properties.epc.value"),
            suffixText: getLocalizedText("epc.energy_usage.per_year"),
            optional: !extraData.epcIsRequired,
            isFloat: true,
            min: 0,
        },
        _a[EField.ExpirationDate] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("editor.variable.property_epc_end_date"),
            formatSummary: function (input) { return (input ? formatDate(input) : ""); },
            minDate: startOfYear(subYears(NOW, 20)),
            maxDate: endOfYear(addYears(NOW, 10)),
        },
        _a[EField.CertificateId] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("editor.variable.property_epc_certificate_id"),
            optional: !extraData.epcIsRequired,
        },
        _a;
};
