import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  Box,
  Link,
  Stack,
  Typography,
  Icon,
} from "@rentiohq/web-shared-next/dist/ui-components";

export interface IProps {
  handleActivateTask: () => void;
}

export const Archived = ({ handleActivateTask }: IProps) => {
  return (
    <Box p={3}>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Icon
          path={EAssets.ArchivedPropertyIcon}
          realColor="#EF3D59"
          width="60px"
          height="60px"
        />
        <Typography variant="h3" my={2}>
          {getLocalizedText("task.dashboard.card.archived.title")}
        </Typography>
        <Typography variant="body1">
          {getLocalizedText("task.dashboard.card.archived.content")}
        </Typography>
        <Link
          label={getLocalizedText("task.dashboard.card.archived.cta")}
          onClick={handleActivateTask}
        />
      </Stack>
    </Box>
  );
};
