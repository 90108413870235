import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";

export interface IExtraData {
  property: IProperty;
  contract: IContract;
  contactMeMaster?: IContact;
  legalContact?: IContact;
  isBroker?: boolean;
}

export enum EField {
  Header = "_header",

  Owners = "owners",
  SignAsBroker = "signAsBroker",
  Tenants = "tenants",

  RentContractDocumentToUpload = "rentContractDocumentToUpload",
  RentContractDestination = "rentContractDestination",
  RentContractSignedAt = "rentContractSignedAt",
  RentContractPropertyTaxPaidByTenant = "rentContractPropertyTaxPaidByTenant",
  RentContractLanguage = "preferredContractLanguage",

  LocationDescriptionShouldUpload = "locationDescriptionShouldUpload",
  LocationDescriptionDocumentToUpload = "locationDescriptionDocumentToUpload",
  LocationDescriptionSignedAt = "locationDescriptionSignedAt",

  Title1 = "title1",
  Info1 = "info1",

  // Brussels registration fields => contract
  IsSharedLease = "isSharedLease",
  ReferencePrice = "referencePrice",

  // Brussels registration field => property
  // General
  ConstructionYear = "constructionYear",

  // EPC
  // Custom EPC-schema

  // Electricity
  ElectricityType = "electricityType",
  ElectricityEan = "electricityEan",
  ElectricityMeterNumberBoth = "electricityMeterNumberBoth",
  ElectricityMeterType = "electricityMeterType",

  // Natural Gas
  NaturalGasAvailable = "naturalGasAvailable",
  NaturalGasEan = "naturalGasEan",
  NaturalGasMeterNumber = "naturalGasMeterNumber",
  NaturalGasMeterType = "naturalGasMeterType",

  // Water
  WaterMeterNumber = "waterMeterNumber",

  // Exterior
  BuildingAdjoined = "buildingAdjoined",
  BuildingSurfaceArea = "buildingSurfaceArea",
  BuildingGardenSurfaceArea = "buildingGardenSurfaceArea",
  BuildingTerraceSurfaceArea = "buildingTerraceSurfaceArea",

  // Interior
  BuildingFloor = "buildingFloor",
  BuildingFloorCount = "buildingFloorCount",
  RoomCountBedroom = "roomCountBedroom",
  RoomCountBathroom = "roomCountBathroom",
  RoomCountGarage = "roomCountGarage",
  BuildingHasStorage = "buildingHasStorage",
  IsFurnished = "isFurnished",

  // Amenities
  HasOven = "hasOven",
  HasFridge = "hasFridge",
  HasDishWasher = "hasDishwasher",
  HasRangeHood = "hasRangeHood",
  HasLift = "hasLift",

  // Energy
  HasDoubleGlazing = "hasDoubleGlazing",
  HasCentralHeating = "hasCentralHeating",
  HeatingType = "heatingType",
}
