// Import "Why Did You Render" first
// import "./wdyr";
import { ERole } from "@rentiohq/shared-frontend/dist/forms/signUp/schema.signUp.types";
import * as authActions from "@rentiohq/shared-frontend/dist/redux/auth/auth.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { useDashboardType } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import * as authUtils from "@rentiohq/shared-frontend/dist/redux/auth/auth.utils";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as expertInquiryActions from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.actions";
import * as expertInquirySelectors from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.selectors";
import * as templateActions from "@rentiohq/shared-frontend/dist/redux/template/template.actions";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import * as templateUtils from "@rentiohq/shared-frontend/dist/redux/template/template.utils";
import { EDashboardType } from "@rentiohq/shared-frontend/dist/redux/types";
import * as chatActions from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.actions";
import { makeGetUnreadChatsCount } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import * as chatUtils from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import * as propertyActions from "@rentiohq/shared-frontend/dist/reduxV2/property/property.actions";
import { EPropertyFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.types";
import * as propertyUtils from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import {
  getLocalizedText,
  setLocale,
} from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getBackofficeUrl } from "@rentiohq/shared-frontend/dist/utils/url.utils";
import {
  Frame,
  Icon,
  Navigation,
  RentioInternalRenderer,
  TAssetSource,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { useFeatureFlag } from "@rentiohq/web-shared/dist/hooks/useFeatureFlag";
import { toast } from "@rentiohq/web-shared/dist/managers/Toast";
import * as systemActions from "@rentiohq/web-shared/dist/redux/system/system.actions";
import {
  useInternalMode,
  useLocaleAsKeys,
} from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { BackgroundChecks } from "components";
import EnabledAccountsSelect from "components/EnabledAccountsSelect";
import { compact } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { useIsRentioLegalActivated } from "scenes/RentioLegal/hooks/useIsRentioLegalActivated";
import { EFeatureFlags } from "types/featureFlags";
import { ChatListener } from "./components/ChatListener";
import { DetailDrawers } from "./components/DetailDrawers";
import HeaderNotificationMenu from "./components/HeaderNotificationMenu";
import * as t from "./services/translationService";

let didShowUpdateToast = false;

const AppMemo = React.memo(() => (
  <>
    <Outlet />

    <ChatListener renderTime={new Date()} />

    <BackgroundChecks />

    <DetailDrawers />
  </>
));

export const App = () => {
  const navigate = useNavigate();
  const { setSelectedDashboardType, selectedDashboardType } =
    useDashboardType();

  const reportsEnabled = useFeatureFlag(EFeatureFlags.Payments.Reports, false);

  const unreadChatsCount = useSelector(makeGetUnreadChatsCount());

  const hasAccessToken = useSelector(authSelectors.hasAccessToken);
  const dispatch = useDispatch();

  const partnerships = useSelector((state: IRootStore) =>
    expertInquirySelectors.partnerships(state),
  );
  const { user, isBroker } = authHooks.useSelf();

  const indexLastModified = useSelector((state: IRootStore) =>
    systemSelectors.getIndexLastModified(state),
  );
  const prevIndexLastModified = usePrevious(indexLastModified);

  const enabledAccountsCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(state, "enabled-accounts"),
  );
  const enabledAccountsCountIsFetching = useSelector((state: IRootStore) =>
    countSelectors.getIsFetchingCount(state, "enabled-accounts"),
  );
  const [showEnabledAccounts, setShowEnabledAccounts] = React.useState(false);

  const hasRentioLegal = useIsRentioLegalActivated();

  const { detail: broker } = brokerHooks.useDetail({
    shouldRefetch: false,
    id: user?.brokerId,
  });

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const templates = useSelector((state: IRootStore) =>
    templateSelectors.paged.dataForPage(state, {
      id: templateUtils.getPagedId({}),
      page: 0,
    }),
  );

  const { internalModeEnabled } = useInternalMode();
  const { localeAsKeysEnabled } = useLocaleAsKeys();

  React.useEffect(() => {
    if (internalModeEnabled) {
      return;
    }

    if (!user) {
      return;
    }

    // Don't fetch if broker => account switch will always be shown
    if (isBroker) {
      return;
    }

    if (enabledAccountsCountIsFetching) {
      return;
    }

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: "enabled-accounts",
        countBase: `/users/${user.id}/enabled-accounts/count`,
      }),
    );
  }, [user]);

  React.useEffect(() => {
    dispatch(
      propertyActions.getCountStart.getAction({
        query: propertyUtils.getFilter(EPropertyFetchType.Groups),
      }),
    );
  }, []);

  React.useEffect(() => {
    if (!hasAccessToken) {
      return;
    }

    dispatch(
      contactActions.getContact.actions.start({
        contactId: EContactCustomId.Me,
      }),
    );

    dispatch(
      contactActions.getContact.actions.start({
        contactId: EContactCustomId.MeMaster,
      }),
    );

    dispatch(authActions.getUser.actions.start({}));

    dispatch(
      chatActions.getPagedStart.getAction({
        query: chatUtils.getChatsQuery({}),
      }),
    );
  }, [hasAccessToken]);

  React.useEffect(() => {
    // if (!hasPartnershipsFeature) {
    //   return;
    // }

    if (partnerships) {
      return;
    }

    dispatch(expertInquiryActions.getPartnerships.actions.start({}));
  }, []);

  React.useEffect(() => {
    if (templates) {
      return;
    }

    dispatch(
      templateActions.getPaged.actions.start({
        page: 0,
        limit: 100,
      }),
    );
  }, []);

  React.useEffect(() => {
    if (user?.brokerId) {
      dispatch(
        contactActions.getContact.actions.start({
          contactId: EContactCustomId.Legal,
          brokerId: user.brokerId,
        }),
      );

      dispatch(
        contactActions.getContact.actions.start({
          contactId: EContactCustomId.Financial,
          brokerId: user.brokerId,
        }),
      );
    }
  }, [user]);

  React.useEffect(() => {
    const locale = user?.locale || navigator.language;

    setLocale(localeAsKeysEnabled ? ELocale.INTERNAL : locale);
  }, [user, localeAsKeysEnabled]);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    // @ts-ignore
    window.zE?.identify?.({
      name: getName(user),
      email: user.email || user.unverifiedEmail,
    });
  }, [user]);

  React.useEffect(() => {
    if (!prevIndexLastModified) {
      return;
    }

    if (indexLastModified === prevIndexLastModified) {
      return;
    }

    if (didShowUpdateToast) {
      return;
    }

    didShowUpdateToast = true;

    // eslint-disable-next-line no-console
    console.warn("SHOW UPDATE MESSAGE: ", {
      indexLastModified,
      prevIndexLastModified,
    });

    toast({
      heading: t.systemOutdatedVersionNoticeHeading(),
      content: t.systemOutdatedVersionNoticeContent(),
      variation: "warning",
      autoDismiss: false,
      action: {
        content: t.systemOutdatedVersionNoticeAction(),
        onClick: () => {
          setTimeout(() => {
            window.location.reload();
          }, 300);
        },
      },
      onDismiss: () => {
        didShowUpdateToast = false;
      },
    });
  }, [indexLastModified, prevIndexLastModified]);

  React.useEffect(() => {
    const intervalTimeInMinutes = 10;
    const intervalTimeInMilliseconds = 1000 * 60 * intervalTimeInMinutes;

    const interval = setInterval(() => {
      dispatch(
        systemActions.getIndexLastModified.actions.start({
          url: `${window.location.origin}/index.html`,
        }),
      );
    }, intervalTimeInMilliseconds);

    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    if (!hasAccessToken) {
      return;
    }

    checkUserType();
  }, [user?.id]);

  async function checkUserType() {
    const userType = await authUtils.getUserType(user?.id || -1);

    if (!selectedDashboardType && userType) {
      if (userType === ERole.Tenant) {
        setSelectedDashboardType(EDashboardType.TenantDashboard);
      } else {
        setSelectedDashboardType(EDashboardType.ProDashboard);
      }
    }
  }

  const navigationItems: {
    label: string;
    icon: TAssetSource;
    url: string;
    matchPath?: { path: string; end: boolean };
  }[] = compact([
    {
      label: t.navigationDashboard(),
      icon: "dashboard",
      url: "/",
    },
    {
      label: t.navigationProperties(),
      icon: "house",
      url: "/properties",
      matchPath: {
        path: "/properties",
        end: false,
      },
    },
    {
      label: t.navigationContacts(),
      icon: "contacts",
      url: "/contacts",
      matchPath: {
        path: "/contacts",
        end: false,
      },
    },
    {
      label: t.navigationChats(),
      icon: "chat",
      url: "/chats",
      showActivityIndicator: unreadChatsCount > 0,
      matchPath: {
        path: "/chats",
        end: false,
      },
    },
    {
      label: t.navigationTasks(),
      icon: "checklist",
      url: "/tasks",
      matchPath: {
        path: "/tasks",
        end: false,
      },
    },
    {
      label: t.navigationPaymentsFollowUp(),
      icon: "currencyEuroCircle",
      url: "/follow-up/payments",
      matchPath: {
        path: "/follow-up/payments",
        end: false,
      },
    },
    {
      label: t.navigationPaymentsOverview(),
      icon: "financialSearch",
      url: "/payments-overview/payment-history",
      matchPath: {
        path: "/payments-overview",
        end: false,
      },
    },
    reportsEnabled && isBroker
      ? {
          label: t.navigationReports(),
          icon: "rentDeposit",
          url: "/reports",
          matchPath: {
            path: "/reports",
            end: false,
          },
        }
      : undefined,
    hasRentioLegal
      ? {
          label: t.rentioLegal(),
          icon: "legalHammer",
          url: "/rentio-legal",
          matchPath: {
            path: "/rentio-legal",
            end: false,
          },
        }
      : undefined,
  ]);

  const internalModeExtraNavigationItems: {
    label: string;
    icon: TAssetSource;
    url: string;
    matchPath?: { path: string; end: boolean };
  }[] = internalModeEnabled
    ? [
        {
          label: "Templates",
          icon: "filePDF",
          url: "/templates",
        },
      ]
    : [];

  const navItems = [...navigationItems, ...internalModeExtraNavigationItems];

  if (!user) {
    return null;
  }

  const userMenuActions: IAction[] = [
    {
      content: getLocalizedText("usermenu.profile"),
      url: "/profile",
      media: <Icon source="profile" />,
    },
  ];

  if (isBroker) {
    userMenuActions.push({
      content: getLocalizedText("usermenu.company"),
      url: "/company/contact",
      media: <Icon source="briefcase" />,
    });
  }

  // Broker always has multiple enabled accounts (broker & personal)
  if (internalModeEnabled || isBroker || (enabledAccountsCount || 0) > 1) {
    userMenuActions.push({
      content: getLocalizedText("usermenu.enabled_accounts_select"),
      onClick: () => {
        setShowEnabledAccounts(true);
      },
      media: <Icon source="multipleUsersCircle" />,
    });
  }

  userMenuActions.push({
    content: getLocalizedText("usermenu.open_tenant_dashboard"),
    onClick: () => {
      setSelectedDashboardType(EDashboardType.TenantDashboard);
      navigate("/");
    },
    media: <Icon source="profile" />,
  });

  if (isBroker) {
    userMenuActions.push({
      content: getLocalizedText("usermenu.settings"),
      url: "/settings",
      media: <Icon source="cog" />,
    });
  }

  userMenuActions.push({
    content: <TextStyle variation="negative">{t.usermenuLogout()}</TextStyle>,
    onClick: () => {
      dispatch({ type: "LOG_OUT" });
    },
    media: <Icon source="logout" color="red" />,
  });

  if (internalModeEnabled) {
    if (isBroker && broker) {
      userMenuActions.push({
        content: (
          <RentioInternalRenderer>
            Open broker in backoffice
          </RentioInternalRenderer>
        ),
        onClick: () => {
          window.open(
            getBackofficeUrl({
              path: `brokers/${broker.id}`,
            }),
            "_blank",
          );
        },
        media: <Icon source="profile" />,
      });
    }

    userMenuActions.push({
      content: (
        <RentioInternalRenderer>Open user in backoffice</RentioInternalRenderer>
      ),
      onClick: () => {
        window.open(
          getBackofficeUrl({
            path: `users/${user.id}`,
          }),
          "_blank",
        );
      },
      media: <Icon source="profile" />,
    });
  }

  if (!user.email) {
    return <Navigate to="/auth/verify/email" />;
  }

  if (!user.phone && user.unverifiedPhoneIsVerifiable) {
    return <Navigate to="/auth/verify/phone" />;
  }

  const headerItems = [
    <HeaderNotificationMenu key={"HeaderNotificationMenu"} />,
  ];

  if (internalModeEnabled) {
    headerItems.unshift(
      <button
        onClick={() => {
          navigate("/rentio-internal");
        }}
      >
        <RentioInternalRenderer>INTERNAL MODE</RentioInternalRenderer>
      </button>,
    );
  }

  if (internalModeEnabled && hasPaymentV2ForBroker) {
    headerItems.unshift(
      <button
        onClick={() => {
          window.open(
            getBackofficeUrl({
              path: `brokers/${broker?.id}/features`,
            }),
            "_blank",
          );
        }}
      >
        <RentioInternalRenderer>💰V2</RentioInternalRenderer>
      </button>,
    );
  }

  return (
    <>
      <Frame
        user={user}
        userMenuActions={userMenuActions}
        navigation={<Navigation items={navItems} />}
        showHelp={true}
        showSearch={true}
        headerItems={headerItems}
      >
        <AppMemo />
      </Frame>

      <EnabledAccountsSelect
        showModal={showEnabledAccounts}
        onClose={() => {
          setShowEnabledAccounts(false);
        }}
      />
    </>
  );
};
