import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import {
  IValues,
  mapNullableBooleanValueToFormField,
} from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { EAN_PREFIX } from "forms/requestMoveService/schema.requestMoveService.config";
import { EField, IExtraData } from "./schema.editPropertyExtraInfo.types";

const truncateEAN = (eanCode?: string) => {
  return eanCode?.startsWith(EAN_PREFIX)
    ? eanCode.replace(EAN_PREFIX, "")
    : undefined;
};

export const getInitialValues = ({ property }: IExtraData): IValues => {
  return {
    // General
    [EField.ConstructionYear]: property.constructionYear,

    // EPC Fields
    [EEpcField.Label]: property.epcLabel,
    [EEpcField.Value]: property.epcValue,
    [EEpcField.ExpirationDate]: property.epcExpirationDate,
    [EEpcField.CertificateId]: property.epcCertificateId,

    // Bookkeeping
    [EField.CostUnit]: property.costUnit,
    [EField.BookkeepingPaidOutStartDate]: property.bookkeepingPaidOutStartDate,

    // Bookkeeping Group
    [EField.CostCenter]: property.costCenter,

    // Electricity
    [EField.ElectricityType]: property.electricityType,
    [EField.ElectricityEan]: truncateEAN(property.electricityEan),
    [EField.ElectricityMeterNumberBoth]: property.electricityMeterNumberBoth,
    [EField.ElectricityMeterType]: property.electricityMeterType,

    // Natural Gas
    [EField.NaturalGasAvailable]: property.naturalGasAvailable,
    [EField.NaturalGasEan]: truncateEAN(property.naturalGasEan),
    [EField.NaturalGasMeterNumber]: property.naturalGasMeterNumber,
    [EField.NaturalGasMeterType]: property.naturalGasMeterType,

    // Water
    [EField.WaterMeterNumber]: property.waterMeterNumber,

    // Exterior
    [EField.BuildingAdjoined]: property.buildingAdjoined,
    [EField.BuildingSurfaceArea]: property.buildingSurfaceArea,
    [EField.BuildingGardenSurfaceArea]: property.buildingGardenSurfaceArea,
    [EField.BuildingTerraceSurfaceArea]: property.buildingTerraceSurfaceArea,

    // Interior
    [EField.BuildingFloor]: property.buildingFloor,
    [EField.BuildingFloorCount]: property.buildingFloorCount,
    [EField.RoomCountBedroom]: property.roomCountBedroom,
    [EField.RoomCountBathroom]: property.roomCountBathroom,
    [EField.RoomCountGarage]: property.roomCountGarage,
    [EField.BuildingHasStorage]: mapNullableBooleanValueToFormField(
      property.buildingHasStorage,
    ),
    [EField.IsFurnished]: mapNullableBooleanValueToFormField(
      property.isFurnished,
    ),

    // Amenities
    [EField.HasOven]: mapNullableBooleanValueToFormField(property.hasOven),
    [EField.HasFridge]: mapNullableBooleanValueToFormField(property.hasFridge),
    [EField.HasDishWasher]: mapNullableBooleanValueToFormField(
      property.hasDishwasher,
    ),
    [EField.HasRangeHood]: mapNullableBooleanValueToFormField(
      property.hasRangeHood,
    ),
    [EField.HasLift]: mapNullableBooleanValueToFormField(property.hasLift),

    // Energy
    [EField.HasDoubleGlazing]: mapNullableBooleanValueToFormField(
      property.hasDoubleGlazing,
    ),
    [EField.HasCentralHeating]: mapNullableBooleanValueToFormField(
      property.hasCentralHeating,
    ),
    [EField.HeatingType]: property.heatingType,
  };
};

export const propertyTypeCanHaveFloors = (params: { property: IProperty }) => {
  const { property } = params;
  const { typeId } = property;

  switch (typeId) {
    case EPropertyTypeId.Apartment:
    case EPropertyTypeId.StudentResidence:
    case EPropertyTypeId.Commercial:
    case EPropertyTypeId.ParkingLot:
    case EPropertyTypeId.Garage:
    case EPropertyTypeId.Office:
      return true;

    case EPropertyTypeId.Group:
    case EPropertyTypeId.House:
    case EPropertyTypeId.Warehouse:
    case EPropertyTypeId.Shed:
    case EPropertyTypeId.Storage:
      return false;
  }
};

export const propertyTypeCanHaveAdjoinedType = (params: {
  property: IProperty;
}) => {
  const { property } = params;
  const { typeId } = property;

  switch (typeId) {
    case EPropertyTypeId.House:
    case EPropertyTypeId.Commercial:
    case EPropertyTypeId.Garage:
    case EPropertyTypeId.Office:
    case EPropertyTypeId.Warehouse:
    case EPropertyTypeId.Shed:
    case EPropertyTypeId.Storage:
      return true;

    case EPropertyTypeId.Group:
    case EPropertyTypeId.Apartment:
    case EPropertyTypeId.StudentResidence:
    case EPropertyTypeId.ParkingLot:
      return false;
  }
};
