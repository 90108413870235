import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import {
  uiSchema as epcUiSchema,
  getSchema as getEpcSchema,
} from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EElectricityMeterType,
  EElectricityType,
  EHeatingType,
  ENaturalGasAvailable,
  ENaturalGasMeterType,
  EPropertyAdjoined,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAN_MASK } from "forms/requestMoveService/schema.requestMoveService.config";
import { validationErrorsForEan } from "forms/requestMoveService/schema.requestMoveService.utils";
import { JSONSchema6 } from "json-schema";
import { FormValidation } from "react-native-jsonschema-form-test";
import {
  EExtraInfoGroup,
  EField,
  IExtraData,
} from "./schema.editPropertyExtraInfo.types";
import {
  propertyTypeCanHaveAdjoinedType,
  propertyTypeCanHaveFloors,
} from "./schema.editPropertyExtraInfo.utils";

const booleanOptions = [
  {
    value: getLocalizedText("system.no"),
    id: "no",
  },
  {
    value: getLocalizedText("system.yes"),
    id: "yes",
  },
];

export const getSchema =
  (
    initialValues: {
      [key: string]: any;
    },
    extraData: IExtraData,
  ) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    switch (extraData.extraInfoGroup) {
      case EExtraInfoGroup.General:
        return {
          required: [],
          properties: {
            [EField.ConstructionYear]: {
              default: getValue(
                EField.ConstructionYear,
                initialValues,
                defaultValues,
              ),
            },
          },
        };

      case EExtraInfoGroup.Epc:
        return getEpcSchema(
          { property: extraData.property, epcIsRequired: false },
          initialValues,
        )(defaultValues);

      case EExtraInfoGroup.Bookkeeping:
        return {
          required: [],
          properties: {
            [EField.CostUnit]: {
              default: getValue(EField.CostUnit, initialValues, defaultValues),
              maxLength: 8,
            },
            [EField.BookkeepingPaidOutStartDate]: {
              default: getValue(
                EField.BookkeepingPaidOutStartDate,
                initialValues,
                defaultValues,
              ),
            },
          },
        };

      case EExtraInfoGroup.BookkeepingGroup:
        return {
          required: [],
          properties: {
            [EField.CostCenter]: {
              default: getValue(
                EField.CostCenter,
                initialValues,
                defaultValues,
              ),
              maxLength: 8,
            },
          },
        };

      case EExtraInfoGroup.Electricity:
        return {
          required: [EField.ElectricityType],
          properties: {
            [EField.ElectricityType]: {
              default: getValue(
                EField.ElectricityType,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: [
                EElectricityType.Single,
                EElectricityType.Double,
                EElectricityType.Unknown,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `move.option.electricity_type.${id}`.toLowerCase(),
                ),
              })),
            },

            [EField.ElectricityEan]: {
              default: getValue(
                EField.ElectricityEan,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: (formData: any) =>
                formData[EField.ElectricityType] &&
                formData[EField.ElectricityType] !== EElectricityType.Unknown,
            },

            [EField.ElectricityMeterNumberBoth]: {
              default: getValue(
                EField.ElectricityMeterNumberBoth,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: (formData: any) =>
                formData[EField.ElectricityType] &&
                formData[EField.ElectricityType] !== EElectricityType.Unknown,
            },
            [EField.ElectricityMeterType]: {
              default: getValue(
                EField.ElectricityMeterType,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: [
                EElectricityMeterType.Standard,
                EElectricityMeterType.Opening,
                EElectricityMeterType.Budget,
                EElectricityMeterType.Empty,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `move.option.electricity_meter_type.${id}`.toLowerCase(),
                ),
              })),
              showIf: (formData: any) =>
                formData[EField.ElectricityType] &&
                formData[EField.ElectricityType] !== EElectricityType.Unknown,
              requiredIf: (formData: any) =>
                formData[EField.ElectricityType] &&
                formData[EField.ElectricityType] !== EElectricityType.Unknown,
            },
          },
        };

      case EExtraInfoGroup.NaturalGas:
        return {
          required: [EField.NaturalGasAvailable],
          properties: {
            [EField.NaturalGasAvailable]: {
              default: getValue(
                EField.NaturalGasAvailable,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: [
                ENaturalGasAvailable.Available,
                ENaturalGasAvailable.Unavailable,
                ENaturalGasAvailable.Unknown,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `move.option.natural_gas_type.${id}`.toLowerCase(),
                ),
              })),
            },
            [EField.NaturalGasEan]: {
              default: getValue(
                EField.NaturalGasEan,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: (formData: any) =>
                formData[EField.NaturalGasAvailable] ===
                ENaturalGasAvailable.Available,
            },
            [EField.NaturalGasMeterNumber]: {
              default: getValue(
                EField.NaturalGasMeterNumber,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: (formData: any) =>
                formData[EField.NaturalGasAvailable] ===
                ENaturalGasAvailable.Available,
            },
            [EField.NaturalGasMeterType]: {
              default: getValue(
                EField.NaturalGasMeterType,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: [
                ENaturalGasMeterType.Standard,
                ENaturalGasMeterType.Opening,
                ENaturalGasMeterType.Budget,
                ENaturalGasMeterType.Empty,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `move.option.natural_gas_meter_type.${id}`.toLowerCase(),
                ),
              })),
              showIf: (formData: any) =>
                formData[EField.NaturalGasAvailable] ===
                ENaturalGasAvailable.Available,
              requiredIf: (formData: any) =>
                formData[EField.NaturalGasAvailable] ===
                ENaturalGasAvailable.Available,
            },
          },
        };

      case EExtraInfoGroup.Water:
        return {
          required: [EField.WaterMeterNumber],
          properties: {
            [EField.WaterMeterNumber]: {
              default: getValue(
                EField.WaterMeterNumber,
                initialValues,
                defaultValues,
              ),
            },
          },
        };

      case EExtraInfoGroup.Exterior:
        const canHaveAdjoinedType = propertyTypeCanHaveAdjoinedType(extraData);

        return {
          required: [],
          properties: {
            [EField.BuildingAdjoined]: {
              default: getValue(
                EField.BuildingAdjoined,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: () => canHaveAdjoinedType,
              options: [
                EPropertyAdjoined.Freestanding,
                EPropertyAdjoined.OneSide,
                EPropertyAdjoined.BothSides,
                EPropertyAdjoined.Unknown,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `property.adjoined.option.${id}.label`.toLowerCase(),
                ),
              })),
            },
            [EField.BuildingSurfaceArea]: {
              default: getValue(
                EField.BuildingSurfaceArea,
                initialValues,
                defaultValues,
              ),
            },
            [EField.BuildingGardenSurfaceArea]: {
              default: getValue(
                EField.BuildingGardenSurfaceArea,
                initialValues,
                defaultValues,
              ),
            },
            [EField.BuildingTerraceSurfaceArea]: {
              default: getValue(
                EField.BuildingTerraceSurfaceArea,
                initialValues,
                defaultValues,
              ),
            },
          },
        };

      case EExtraInfoGroup.Interior:
        const canHaveFloors = propertyTypeCanHaveFloors(extraData);

        return {
          required: [],
          properties: {
            [EField.BuildingFloor]: {
              default: getValue(
                EField.BuildingFloor,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: () => canHaveFloors,
            },
            [EField.BuildingFloorCount]: {
              default: getValue(
                EField.BuildingFloorCount,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: () => canHaveFloors,
            },
            [EField.RoomCountBedroom]: {
              default: getValue(
                EField.RoomCountBedroom,
                initialValues,
                defaultValues,
              ),
            },
            [EField.RoomCountBathroom]: {
              default: getValue(
                EField.RoomCountBathroom,
                initialValues,
                defaultValues,
              ),
            },
            [EField.RoomCountGarage]: {
              default: getValue(
                EField.RoomCountGarage,
                initialValues,
                defaultValues,
              ),
            },
            [EField.BuildingHasStorage]: {
              default: getValue(
                EField.BuildingHasStorage,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: booleanOptions,
            },
            [EField.IsFurnished]: {
              default: getValue(
                EField.IsFurnished,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: booleanOptions,
            },
          },
        };

      case EExtraInfoGroup.Amenities:
        return {
          required: [],
          properties: {
            [EField.HasOven]: {
              default: getValue(EField.HasOven, initialValues, defaultValues),
              // @ts-ignore
              options: booleanOptions,
            },
            [EField.HasFridge]: {
              default: getValue(EField.HasFridge, initialValues, defaultValues),
              // @ts-ignore
              options: booleanOptions,
            },
            [EField.HasDishWasher]: {
              default: getValue(
                EField.HasDishWasher,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: booleanOptions,
            },
            [EField.HasRangeHood]: {
              default: getValue(
                EField.HasRangeHood,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: booleanOptions,
            },
            [EField.HasLift]: {
              default: getValue(EField.HasLift, initialValues, defaultValues),
              // @ts-ignore
              options: booleanOptions,
            },
          },
        };

      case EExtraInfoGroup.Energy:
        return {
          required: [],
          properties: {
            [EField.HasDoubleGlazing]: {
              default: getValue(
                EField.HasDoubleGlazing,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: booleanOptions,
            },
            [EField.HasCentralHeating]: {
              default: getValue(
                EField.HasCentralHeating,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: booleanOptions,
            },
            [EField.HeatingType]: {
              default: getValue(
                EField.HeatingType,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: [
                EHeatingType.NaturalGas,
                EHeatingType.Electricity,
                EHeatingType.HeatPump,
                EHeatingType.FuelOil,
                EHeatingType.Coal,
                EHeatingType.Other,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `property.extra.option.energy.${id}`.toLowerCase(),
                ),
              })),
            },
          },
        };

      default:
        return {
          required: [],
          properties: {},
        };
    }
  };

export const uiSchema = (
  initialValues: {
    [key: string]: any;
  },
  extraData: IExtraData,
): IUiSchema<EField> => ({
  "rentio:navigationTitle": getLocalizedText(
    `property.extra.${extraData.extraInfoGroup}.edit`.toLowerCase(),
  ),

  // General
  [EField.ConstructionYear]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("property.extra.general.item.construction_year"),
    optional: true,
  },

  // EPC
  ...epcUiSchema({ epcIsRequired: false }, initialValues),

  // Bookkeeping
  [EField.CostUnit]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("property.extra.bookkeeping.item.cost_unit.title"),
  },
  [EField.BookkeepingPaidOutStartDate]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText(
      "property.extra.bookkeeping.item.bookkeeping_paid_out_start_date.title",
    ),
  },

  // Bookkeeping group
  [EField.CostCenter]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText(
      "property.extra.bookkeeping.item.cost_center.title",
    ),
  },

  // Electricity
  [EField.ElectricityType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.electricity_type.title"),
  },
  [EField.ElectricityEan]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.electricity_ean.title"),
    optional: true,
    iMaskProps: {
      mask: EAN_MASK,
      lazy: false,
      unmask: true,
      placeholderChar: ". ",
    },
  },
  [EField.ElectricityMeterNumberBoth]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.electricity_meter_number_both.title"),
    optional: true,
  },
  [EField.ElectricityMeterType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.electricity_meter_type.title"),
  },

  // Natural Gas
  [EField.NaturalGasAvailable]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.natural_gas_type.title"),
  },
  [EField.NaturalGasEan]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.natural_gas_ean.title"),
    optional: true,
    iMaskProps: {
      mask: EAN_MASK,
      lazy: false,
      unmask: true,
      placeholderChar: ". ",
    },
  },
  [EField.NaturalGasMeterNumber]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.natural_gas_meter_number.title"),
    optional: true,
  },
  [EField.NaturalGasMeterType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.natural_gas_meter_type.title"),
  },

  // Water
  [EField.WaterMeterNumber]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.water_meter_number.title"),
    optional: true,
  },

  // Exterior
  [EField.BuildingAdjoined]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: getLocalizedText("property.extra.exterior.field.building_adjoined"),
    optional: true,
  },
  [EField.BuildingSurfaceArea]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.exterior.field.building_surface_area",
    ),
    suffixText: "m²",
    isFloat: true,
    optional: true,
  },
  [EField.BuildingGardenSurfaceArea]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.exterior.field.building_garden_surface_area",
    ),
    suffixText: "m²",
    isFloat: true,
    optional: true,
  },
  [EField.BuildingTerraceSurfaceArea]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.exterior.field.building_terrace_surface_area",
    ),
    suffixText: "m²",
    isFloat: true,
    optional: true,
  },

  // Interior
  [EField.BuildingFloor]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("property.extra.interior.field.building_floor"),
    optional: true,
  },
  [EField.BuildingFloorCount]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("property.extra.interior.field.building_layers"),
    optional: true,
  },
  [EField.RoomCountBedroom]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("property.extra.interior.field.room_count_bedroom"),
    optional: true,
  },
  [EField.RoomCountBathroom]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.interior.field.room_count_bathroom",
    ),
    optional: true,
  },
  [EField.RoomCountGarage]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("property.extra.interior.field.room_count_garage"),
    optional: true,
  },
  [EField.BuildingHasStorage]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText(
      "property.extra.interior.field.building_has_storage",
    ),
    optional: true,
  },
  [EField.IsFurnished]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.interior.field.is_furnished"),
    optional: true,
  },

  // Amenities
  [EField.HasOven]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.amenities.item.has_oven"),
    optional: true,
  },
  [EField.HasFridge]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.amenities.item.has_fridge"),
    optional: true,
  },
  [EField.HasDishWasher]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.amenities.item.has_dish_washer"),
    optional: true,
  },
  [EField.HasRangeHood]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.amenities.item.has_range_hood"),
    optional: true,
  },
  [EField.HasLift]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.amenities.item.has_lift"),
    optional: true,
  },

  // Energy
  [EField.HasDoubleGlazing]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.energy.item.has_double_glazing"),
    optional: true,
  },
  [EField.HasCentralHeating]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("property.extra.energy.item.has_central_heating"),
    optional: true,
  },
  [EField.HeatingType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("property.extra.energy.item.has_heating_type"),
    optional: true,
  },
});

export const getValidate =
  (extraData: IExtraData) => (formData: any, errors: FormValidation) => {
    switch (extraData.extraInfoGroup) {
      case EExtraInfoGroup.Electricity: {
        validationErrorsForEan(formData[EField.ElectricityEan]).forEach(
          error => {
            errors[EField.ElectricityEan].addError(error);
          },
        );

        break;
      }

      case EExtraInfoGroup.NaturalGas: {
        validationErrorsForEan(formData[EField.NaturalGasEan]).forEach(
          error => {
            errors[EField.NaturalGasEan].addError(error);
          },
        );

        break;
      }
    }

    return errors;
  };
