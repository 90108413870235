import { ERecurrenceType } from "@rentiohq/shared-frontend/dist/types/task.types";
import { addYears } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ERentioFormField,
  IFormikValues,
  IUiSchema,
} from "@rentiohq/web-shared-next/dist/utils/types/form";
import { EField, IExtraData } from "../addForm/schema.addTask.types";
import { FREQUENCY_OPTIONS } from "./schema.addTask.utils";

const NOW = new Date();

export const uiSchema = (extraData: IExtraData): IUiSchema => {
  return {
    stepTitle: getLocalizedText("task.frequency.section_header"),
    heading: getLocalizedText("task.frequency.section_header"),
    fields: [
      {
        fieldKey: EField.RecurrenceType,
        title: getLocalizedText("task.frequency.select.title"),
        uiComponent: ERentioFormField.InlineSelect,
        required: true,
        formatSummary: value => {
          if (value) {
            return getLocalizedText(`task.frequency.${value.toLowerCase()}`);
          }
          return;
        },
        componentProps: {
          customIsFetchingSelector: () => false,
          getOptions: (formData: IFormikValues) =>
            FREQUENCY_OPTIONS.map(id => ({
              id,
              value: getLocalizedText(`task.frequency.${id.toLowerCase()}`),
            })).filter(option => {
              if (!formData[EField.ExecuteDate] && !!formData[EField.DueDate]) {
                return option.id !== ERecurrenceType.Daily;
              }
              return FREQUENCY_OPTIONS;
            }),
        },
      },
      {
        fieldKey: EField.RecurrenceEndDate,
        title: getLocalizedText("task.frequency.end_date"),
        uiComponent: ERentioFormField.Date,
        optional: true,
        formatSummary: value =>
          value
            ? formatDate(value)
            : getLocalizedText("task.date.no_selection"),
        componentProps: {
          getMinDate: (initialValues: any) =>
            initialValues[EField.ExecuteDate] || initialValues[EField.DueDate],
          maxDate: addYears(NOW, 40),
        },
      },
    ],
  };
};
