import { Box } from "@rebass/grid";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countTypes from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import * as rentDepositActions from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.actions";
import * as rentDepositSelectors from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.selectors";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EContractMemberTypes } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IPaymentOrder } from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  ERentDepositStatus,
  IRentDeposit,
} from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import {
  endOfDay,
  isAfter,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Card,
  DataTable,
  EmptyState,
  Error,
  ESpacings,
  Filters,
  IAppliedFilterInterface,
  Loading,
  LoadingWrapper,
  Lozenge,
  OptionListShared,
  Page,
  Pagination,
  Stages,
  TextStyle,
  TSortDirection,
} from "@rentiohq/web-shared/dist/components";
import {
  ERentDepositPaymentStatus,
  ERentDepositSignatureStatus,
  ERentDepositStage,
  ERentDepositStageSimple,
  IAction,
} from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import {
  DEFAULT_FILTER,
  getFilterQuery,
  getHasActiveFilter,
  getPaymentStatus,
  getPaymentStatusVariation,
  getSignatureStatus,
  getSignatureStatusVariation,
  getSimpleStage,
  hasPaymentRequests,
} from "@rentiohq/web-shared/dist/utils/rentDeposit";
import { CancelContractModal } from "components/CancelContractModal";
import {
  canCancelRentalDeposit,
  canCancelRentalDepositRelease,
} from "components/DetailDrawers/Drawers/RentDeposit";
import hash from "object-hash";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import {
  ArrayParam,
  createEnumParam,
  NumberParam,
  StringParam,
  withDefault,
} from "serialize-query-params";
import { useDebouncedCallback } from "use-debounce";
import { ts } from "../../../services";

const ORDER_MAP = [
  "rentContractStartedAt",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
];

const rentDepositsIdentifier =
  countTypes.ECountIdentifier.RentDepositsOverviewPaged;

export const showJudicialReleaseModal = () =>
  confirm({
    modalProps: {
      hasDismiss: true,
      shouldCloseOnOverlayClick: true,
    },
    title: getLocalizedText("rent_deposit.release.via_judgement.popup.title"),
    info: (
      <span
        dangerouslySetInnerHTML={{
          __html: getLocalizedText(
            "rent_deposit.release.via_judgement.popup.content",
          ),
        }}
      />
    ),
  });

export const RentDeposits: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showCancelContractModal, setShowCancelContractModal] =
    React.useState(false);
  const [rentDeposit, setRentDeposit] = React.useState<
    IRentDeposit | undefined
  >();

  const rentDepositsNewCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.RentDepositsNew,
      ),
    ) || 0;
  const rentDepositsPayingInCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.RentDepositsPayingIn,
      ),
    ) || 0;
  const rentDepositsEstablishedCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.RentDepositsEstablished,
      ),
    ) || 0;
  const rentDepositsSigningOutCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.RentDepositsSigningOut,
      ),
    ) || 0;
  const rentDepositsPayingOutCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.RentDepositsPayingOut,
      ),
    ) || 0;

  const { detail: contract } = contractHooks.useDetail({
    id: rentDeposit?.basicContractId,
    shouldRefetch: false,
  });

  const isCancellingRentDeposit = useSelector(
    (state: IRootStore) => state.rentDeposit.isCancellingRentDeposit,
  );
  const cancelRentDepositErrors = useSelector(
    (state: IRootStore) => state.rentDeposit.cancelRentDepositError,
  );
  const prevIsCancellingRentDeposit = usePrevious(isCancellingRentDeposit);
  const hasCancelledRentDeposit =
    !isCancellingRentDeposit &&
    !!prevIsCancellingRentDeposit &&
    !cancelRentDepositErrors;

  const [queryParams, setQueryParamValue] = useQueryParams({
    query: withDefault(StringParam, ""),
    stage: ArrayParam,
    signatureStatus: ArrayParam,
    paymentStatus: ArrayParam,
    orderField: withDefault(StringParam, ORDER_MAP[0]),
    orderMethod: withDefault(createEnumParam(["ASC", "DESC"]), "DESC"),
    page: withDefault(NumberParam, 1),
    paymentRequestId: NumberParam,
    rentDepositId: NumberParam,
  });

  const [queryInput, setQueryInput] = React.useState<string>(
    queryParams.query || "",
  );

  const filterData = React.useMemo(() => {
    return getFilterQuery(queryParams, DEFAULT_FILTER).filter;
  }, [queryParams]);

  const uniqueIdentifier = React.useMemo(() => {
    if (!filterData) {
      return rentDepositsIdentifier;
    }
    // Create unique identifier for filter
    return `${rentDepositsIdentifier}-${hash(filterData)}`;
  }, [filterData]);

  const countBase = React.useMemo(() => {
    const { where, search } = filterData;
    let countFilter: { [key: string]: any } = { where };

    if (search) {
      countFilter.search = search;
    }
    return append("/rent-deposits/count", countFilter);
  }, [filterData]);

  const rentDeposits =
    useSelector((state: IRootStore) =>
      rentDepositSelectors.getRentDepositsForPage(
        state,
        uniqueIdentifier,
        queryParams.page,
      ),
    ) || [];
  const isFetching = useSelector((state: IRootStore) =>
    rentDepositSelectors.isFetchingRentDepositsForPage(
      state,
      uniqueIdentifier,
      queryParams.page,
    ),
  );
  const fetchError = useSelector((state: IRootStore) =>
    rentDepositSelectors.rentDepositsForPageFetchError(
      state,
      uniqueIdentifier,
      queryParams.page,
    ),
  );
  const totalCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(state, `${uniqueIdentifier}-count`),
    ) || 0;

  const [setDebouncedValue, cancel] = useDebouncedCallback(
    // to memoize debouncedFunction we use useCallback hook.
    // In this case all linters work correctly
    React.useCallback(
      (values: { [key: string]: any }) => {
        setQueryParamValue(values);
      },
      [setQueryParamValue],
    ),
    250,
  );

  React.useEffect(() => cancel, [cancel]);

  React.useEffect(() => {
    setDebouncedValue({ query: queryInput, page: 1 });
  }, [queryInput, setDebouncedValue]);

  React.useEffect(() => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: countTypes.ECountIdentifier.RentDepositsNew,
        countBase:
          countTypes.COUNT_BASE[countTypes.ECountIdentifier.RentDepositsNew],
      }),
    );
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: countTypes.ECountIdentifier.RentDepositsPayingIn,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier.RentDepositsPayingIn
          ],
      }),
    );
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: countTypes.ECountIdentifier.RentDepositsEstablished,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier.RentDepositsEstablished
          ],
      }),
    );
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: countTypes.ECountIdentifier.RentDepositsSigningOut,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier.RentDepositsSigningOut
          ],
      }),
    );
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: countTypes.ECountIdentifier.RentDepositsPayingOut,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier.RentDepositsPayingOut
          ],
      }),
    );
  }, []);

  // Fetch rent deposits
  React.useEffect(() => {
    dispatch(
      rentDepositActions.getRentDepositsPaged.actions.start({
        identifier: uniqueIdentifier,
        page: queryParams.page,
        extraFilterData: filterData,
      }),
    );
  }, [uniqueIdentifier, queryParams.page]);

  React.useEffect(() => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: `${uniqueIdentifier}-count`,
        countBase,
      }),
    );
  }, [uniqueIdentifier, filterData]);

  // Filter functions
  const handleFiltersQueryChange = React.useCallback((value: any) => {
    setQueryInput(value);
  }, []);

  const handleFilterQueryValueRemove = React.useCallback(
    () => setQueryInput(""),
    [],
  );

  const handleStageChange = (values: any[]) => {
    const simpleStages = Object.values(ERentDepositStageSimple);
    const onlySimple = values.every((value: any) =>
      simpleStages.includes(value),
    );

    if (!onlySimple && values.length > 1) {
      setDebouncedValue({
        stage: values.filter((value: ERentDepositStageSimple) =>
          simpleStages.includes(value),
        ),
        page: 1,
      });
    } else {
      setDebouncedValue({ stage: values, page: 1 });
    }
  };

  const handleSignatureStatusChange = (values: any[]) => {
    setDebouncedValue({ signatureStatus: values[0], page: 1 });
  };

  const handlePaymentStatusChange = (values: any[]) => {
    setDebouncedValue({ paymentStatus: values[0], page: 1 });
  };

  const handleStageRemove = () => {
    handleStageChange([]);
  };

  const handleSignatureStatusRemove = () => {
    handleSignatureStatusChange([]);
  };

  const handlePaymentStatusRemove = () => {
    handlePaymentStatusChange([]);
  };

  const handleFiltersClearAll = React.useCallback(() => {
    handleFilterQueryValueRemove();
    handleStageRemove();
    handleSignatureStatusRemove();
    handlePaymentStatusRemove();
  }, [
    handleFilterQueryValueRemove,
    handleStageRemove,
    handleSignatureStatusRemove,
    handlePaymentStatusRemove,
  ]);

  React.useEffect(() => {
    if (hasCancelledRentDeposit) {
      // refetch();
    }
  }, [hasCancelledRentDeposit]);

  React.useEffect(() => {
    const contractAlreadyEnded =
      contract?.stopDate &&
      isAfter(endOfDay(new Date()), endOfDay(contract.stopDate));

    if (rentDeposit && contract) {
      if (contract?.dateOfCancellation || contractAlreadyEnded) {
        navigate(`/forms/rent-deposit/${rentDeposit.id}/release`);
      } else {
        setShowCancelContractModal(true);
      }
    }
  }, [rentDeposit, contract]);

  if (fetchError) {
    return <Error errors={[fetchError]} />;
  }

  const hasActiveFilter = getHasActiveFilter(queryParams);

  const handleSort = (headingIndex: number, direction: string) => {
    const sortItem = ORDER_MAP[headingIndex];
    if (!sortItem) {
      return;
    }

    setQueryParamValue({ orderField: sortItem, orderMethod: direction });
  };

  const handlePageClick = ({ selected }: any) => {
    setDebouncedValue({ page: selected + 1 });
  };

  const appliedFilters: IAppliedFilterInterface[] = [];
  if (queryParams.stage && !isEmpty(queryParams.stage)) {
    appliedFilters.push({
      key: "stage",
      label: join(
        queryParams.stage.map((item: any) =>
          Object.values(ERentDepositStageSimple).includes(item)
            ? ts.followUpRentDepositsFilterStageLabel({ extra: { key: item } })
            : ts.followUpRentDepositsStageLabel({ extra: { key: item } }),
        ),
      ),
      onRemove: handleStageRemove,
    });
  }
  if (queryParams.signatureStatus && !isEmpty(queryParams.signatureStatus)) {
    appliedFilters.push({
      key: "signatureStatus",
      label: join(
        queryParams.signatureStatus.map((item: any) =>
          ts.followUpRentDepositsFilterSignatureStatusLabel({
            extra: { key: item },
          }),
        ),
      ),
      onRemove: handleSignatureStatusRemove,
    });
  }
  if (queryParams.paymentStatus && !isEmpty(queryParams.paymentStatus)) {
    appliedFilters.push({
      key: "paymentStatus",
      label: join(
        queryParams.paymentStatus.map((item: any) =>
          ts.followUpRentDepositsFilterPaymentStatusLabel({
            extra: { key: item },
          }),
        ),
      ),
      onRemove: handlePaymentStatusRemove,
    });
  }

  const initialPage = queryParams.page ? queryParams.page - 1 || 0 : 0;
  const pageCount = totalCount
    ? Math.ceil(totalCount / CONFIG.DEFAULT_FETCH_LIMIT)
    : 1;

  // const renderStatus = (status: ERentDepositPaymentStatus) => {
  //   // const [simpleStatus, variation]: any = getSimpleStatus(status)
  //   return <Lozenge appearance={variation}>{ts.revenuesPaymentStatus(simpleStatus)}</Lozenge>
  // }

  const handleStageClick = (stage: string) => {
    switch (stage) {
      case ERentDepositStage.New:
        setDebouncedValue({
          stage: [ERentDepositStageSimple.In],
          paymentStatus: undefined,
          signatureStatus: [ERentDepositSignatureStatus.NotSigned],
          page: 1,
        });
        return;
      case ERentDepositStage.PayingIn:
        setDebouncedValue({
          stage: [ERentDepositStageSimple.In],
          paymentStatus: [ERentDepositPaymentStatus.NotPaid],
          signatureStatus: undefined,
          page: 1,
        });
        return;
      case ERentDepositStage.Established:
        setDebouncedValue({
          stage: [ERentDepositStageSimple.Established],
          paymentStatus: undefined,
          signatureStatus: undefined,
          page: 1,
        });
        return;
      case ERentDepositStage.SigningOut:
        setDebouncedValue({
          stage: [ERentDepositStageSimple.Out],
          paymentStatus: undefined,
          signatureStatus: [ERentDepositSignatureStatus.NotSigned],
          page: 1,
        });
        return;
      case ERentDepositStage.PayingOut:
        setDebouncedValue({
          stage: [ERentDepositStageSimple.Out],
          paymentStatus: [ERentDepositPaymentStatus.NotPaid],
          signatureStatus: undefined,
          page: 1,
        });
        return;
    }
  };

  const handleRowClick = (rowIndex: number) => {
    const rentDepositId = rentDeposits?.[rowIndex]?.id;
    if (!rentDepositId) {
      return;
    }

    setQueryParamValue({
      rentDepositId,
    });
  };

  const handleCancelClick = (rentDepositId: number) => () => {
    dispatch(
      rentDepositActions.cancelRentDeposit.actions.start({ rentDepositId }),
    );
  };
  const renderTable = () => {
    const hasNoResultWithoutFilter =
      rentDeposits.length === 0 && !isFetching && !hasActiveFilter;
    const hasNoResultWithFilter =
      rentDeposits.length === 0 && !isFetching && hasActiveFilter;

    if (hasNoResultWithoutFilter) {
      return (
        <EmptyState
          heading={ts.followUpRentDepositsEmptyStateHeading()}
          icon="rentDeposit"
        >
          {ts.followUpRentDepositsEmptyStateContent()}
        </EmptyState>
      );
    }

    return (
      <>
        <Box mb={ESpacings.loose}>
          <Filters
            appliedFilters={appliedFilters}
            queryValue={queryInput}
            queryPlaceholder={ts.followUpRentDepositsFilterQueryPlaceholder()}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleFilterQueryValueRemove}
            onClearAll={handleFiltersClearAll}
            filters={[
              {
                key: "stage",
                label: ts.followUpRentDepositsFilterLabel({
                  extra: { key: "stage" },
                }),
                content: (
                  <OptionListShared
                    id="stage"
                    value={queryParams.stage}
                    variant="simple"
                    multiple={true}
                    options={Object.values(ERentDepositStageSimple).map(
                      (type: string) => {
                        return {
                          label: ts.followUpRentDepositsFilterStageLabel({
                            extra: { key: type },
                          }),
                          value: type,
                          id: type,
                        };
                      },
                    )}
                    onChange={handleStageChange}
                  />
                ),
              },
              {
                key: "signatureStatus",
                label: ts.followUpRentDepositsFilterLabel({
                  extra: { key: "signature_status" },
                }),
                content: (
                  <OptionListShared
                    id="signature-status"
                    value={queryParams.signatureStatus}
                    variant="simple"
                    multiple={true}
                    options={Object.values(ERentDepositSignatureStatus).map(
                      (status: string) => {
                        return {
                          label:
                            ts.followUpRentDepositsFilterSignatureStatusLabel({
                              extra: { key: status },
                            }),
                          value: status,
                          id: status,
                        };
                      },
                    )}
                    onChange={handleSignatureStatusChange}
                  />
                ),
              },
              {
                key: "paymentStatus",
                label: ts.followUpRentDepositsFilterLabel({
                  extra: { key: "payment_status" },
                }),
                content: (
                  <OptionListShared
                    id="payment-status"
                    value={queryParams.paymentStatus}
                    variant="simple"
                    multiple={true}
                    options={Object.values(ERentDepositPaymentStatus).map(
                      (status: string) => {
                        return {
                          label:
                            ts.followUpRentDepositsFilterPaymentStatusLabel({
                              extra: { key: status },
                            }),
                          value: status,
                          id: status,
                        };
                      },
                    )}
                    onChange={handlePaymentStatusChange}
                  />
                ),
              },
            ]}
          />
        </Box>
        {hasNoResultWithFilter && (
          <EmptyState heading={ts.emptyStateSearchHeading()} icon="archive">
            {ts.emptyStateSearchContent()}
          </EmptyState>
        )}

        {rentDeposits && rentDeposits.length > 0 && (
          <LoadingWrapper isLoading={isFetching}>
            <DataTable
              columnContentTypes={[
                "text",
                "text",
                "text",
                "text",
                "numeric",
                "numeric",
                "numeric",
                "numeric",
                "text",
                "text",
                "action",
              ]}
              onRowClick={handleRowClick}
              headings={[
                ts.followUpRentDepositsTableContractStartDateHeading(),
                ts.followUpRentDepositsTableAddressHeading(),
                ts.followUpRentDepositsTableTenantsHeading(),
                ts.followUpRentDepositsTableOwnersHeading(),
                ts.followUpRentDepositsTableTotalAmountHeading(),
                ts.followUpRentDepositsTableTenantAmountHeading(),
                ts.followUpRentDepositsTableOwnerAmountHeading(),
                ts.followUpRentDepositsTableBrokerAmountHeading(),
                ts.followUpRentDepositsTableSignatureStatusHeading(),
                ts.followUpRentDepositsTablePaymentStatusHeading(),
                "",
              ]}
              sortable={ORDER_MAP.map(x => !!x)}
              sortColumnIndex={ORDER_MAP.indexOf(queryParams.orderField)}
              sortDirection={queryParams.orderMethod as TSortDirection}
              onSort={handleSort}
              rows={rentDeposits.map(rentDeposit => {
                const handleRentDepositClick = () => {
                  setQueryParamValue({
                    rentDepositId: rentDeposit.id,
                  });
                };
                const handlePaymentOrderClick = (
                  paymentOrder: IPaymentOrder,
                ) => {
                  return () =>
                    setQueryParamValue({
                      paymentRequestId: paymentOrder.paymentRequests![0].id,
                    });
                };
                const addressParts = utils.address.addressObjToString(
                  rentDeposit.property,
                  true,
                );
                const simpleStage = getSimpleStage(rentDeposit.status);
                const paymentStatus = getPaymentStatus(rentDeposit.status);
                const signatureStatus = getSignatureStatus(rentDeposit.status);

                const canCancelDeposit = canCancelRentalDeposit(rentDeposit);
                const canCancelRelease =
                  canCancelRentalDepositRelease(rentDeposit);

                const actions = [
                  {
                    content: ts.followUpRentDepositsDetailRentDepositAction(),
                    onClick: handleRentDepositClick,
                  },
                  rentDeposit.status === ERentDepositStatus.Established && {
                    content: ts.rentDepositReleaseAction(),
                    onClick: () => setRentDeposit(rentDeposit),
                  },
                  rentDeposit.status === ERentDepositStatus.Established && {
                    content: ts.rentDepositJudicialReleaseAction(),
                    onClick: showJudicialReleaseModal,
                  },
                  hasPaymentRequests(rentDeposit.paymentOrder) &&
                    [ERentDepositStatus.PayingIn].includes(
                      rentDeposit.status,
                    ) && {
                      content:
                        ts.followUpRentDepositsDetailPaymentRequestAction(),
                      onClick: handlePaymentOrderClick(
                        rentDeposit.paymentOrder!,
                      ),
                    },
                  hasPaymentRequests(rentDeposit.paymentOrderOutTenant) &&
                    [ERentDepositStatus.PayingOut].includes(
                      rentDeposit.status,
                    ) && {
                      content:
                        ts.followUpRentDepositsDetailPaymentRequestTenantAction(),
                      onClick: handlePaymentOrderClick(
                        rentDeposit.paymentOrderOutTenant!,
                      ),
                    },
                  hasPaymentRequests(rentDeposit.paymentOrderOutRenter) &&
                    [ERentDepositStatus.PayingOut].includes(
                      rentDeposit.status,
                    ) && {
                      content:
                        ts.followUpRentDepositsDetailPaymentRequestRenterAction(),
                      onClick: handlePaymentOrderClick(
                        rentDeposit.paymentOrderOutRenter!,
                      ),
                    },
                  canCancelDeposit && {
                    content: (
                      <TextStyle variation={"negative"}>
                        {ts.followUpRentDepositsDetailCancelAction()}
                      </TextStyle>
                    ),
                  },
                  canCancelRelease && {
                    content: (
                      <TextStyle variation={"negative"}>
                        {ts.rentDepositCancelReleaseAction()}
                      </TextStyle>
                    ),
                    onClick: handleCancelClick(rentDeposit.id),
                  },
                ].filter(Boolean);

                return {
                  id: rentDeposit.id,
                  content: [
                    utils.date.format(rentDeposit.rentContractStartedAt),
                    <div key={`${rentDeposit.id}-property`}>
                      <div>{addressParts[0]}</div>
                      <TextStyle variation={"subdued"}>
                        {addressParts[1]}
                      </TextStyle>
                    </div>,
                    !!rentDeposit.rentDepositContractIn
                      ? join(
                          rentDeposit.rentDepositContractIn.members
                            .filter(
                              member =>
                                member.roles.includes(
                                  EContractMemberTypes.Tenant,
                                ) ||
                                member.roles.includes(
                                  EContractMemberTypes.Parent,
                                ),
                            )
                            .map(member => getName(member.account as any)),
                        )
                      : "-",
                    !!rentDeposit.rentDepositContractIn
                      ? join(
                          rentDeposit.rentDepositContractIn.members
                            .filter(member =>
                              member.roles.includes(EContractMemberTypes.Owner),
                            )
                            .map(member => getName(member.account as any)),
                        )
                      : "-",
                    formatCurrency(rentDeposit.amount),
                    rentDeposit.amountOutTenant
                      ? formatCurrency(rentDeposit.amountOutTenant)
                      : "",
                    rentDeposit.amountOutRenter
                      ? formatCurrency(rentDeposit.amountOutRenter)
                      : "",
                    rentDeposit.amountOutBroker
                      ? formatCurrency(rentDeposit.amountOutBroker)
                      : "",
                    <Lozenge
                      key={`${rentDeposit.id}-signature-status`}
                      appearance={getSignatureStatusVariation(signatureStatus)}
                    >
                      {[
                        ERentDepositStatus.WaitingForIBAN,
                        ERentDepositStatus.WaitingForValidations,
                        ERentDepositStatus.ManualVerification,
                        ERentDepositStatus.ManualVerificationJudicial,
                      ].includes(rentDeposit.status) ? (
                        ts.followUpRentDepositsWaitingStatusLabel({
                          extra: { status: rentDeposit.status },
                        })
                      ) : (
                        <>
                          {ts.followUpRentDepositsFilterSignatureStatusLabel({
                            extra: { key: signatureStatus },
                          })}
                          :
                          {simpleStage !== ERentDepositStageSimple.Out
                            ? `
                  ${rentDeposit.signerInSigned}/${rentDeposit.signerInTotal}
                  `
                            : `${rentDeposit.signerOutSigned}/${rentDeposit.signerOutTotal}`}
                        </>
                      )}
                    </Lozenge>,
                    <Lozenge
                      key={`${rentDeposit.id}-payment-status`}
                      appearance={getPaymentStatusVariation(paymentStatus)}
                    >
                      {ts.followUpRentDepositsFilterPaymentStatusLabel({
                        extra: { key: paymentStatus },
                      })}
                    </Lozenge>,
                  ],
                  actions: actions as IAction[],
                };
              })}
              totalPages={Math.ceil(totalCount / CONFIG.DEFAULT_FETCH_LIMIT)}
            />
          </LoadingWrapper>
        )}
        {rentDeposits && rentDeposits.length === 0 && isFetching && (
          <Loading asDots={true} />
        )}
        {!!totalCount && pageCount > 1 && (
          <Pagination
            initialPage={initialPage}
            pageCount={Math.ceil(totalCount / CONFIG.DEFAULT_FETCH_LIMIT)}
            onPageChange={handlePageClick}
          />
        )}
      </>
    );
  };

  return (
    <Page
      fullWidth
      title={ts.followUpRentDepositsPageTitle()}
      metadata={ts.followUpRentDepositsPageDescription()}
      breadcrumbs={{
        to: "/",
        content: getLocalizedText("system.back"),
      }}
    >
      <Card>
        <Stages
          stages={[
            {
              heading: ts.followUpRentDepositsStageLabel({
                extra: { key: "in" },
              }),
              icon: "contentPenWrite",
              count: rentDepositsNewCount,
              countDescription: ts.followUpRentDepositsStageLabel({
                extra: { key: "new" },
              }),
              onClick: () => {
                handleStageClick("new");
              },
            },
            {
              heading: ts.followUpRentDepositsStageLabel({
                extra: { key: "in" },
              }),
              icon: "billStack",
              count: rentDepositsPayingInCount,
              countDescription: ts.followUpRentDepositsStageLabel({
                extra: { key: "paying_in" },
              }),
              onClick: () => {
                handleStageClick("paying_in");
              },
            },
            {
              heading: ts.followUpRentDepositsStageLabel({
                extra: { key: "established" },
              }),
              icon: "safe",
              count: rentDepositsEstablishedCount,
              onClick: () => {
                handleStageClick("established");
              },
            },
            {
              heading: ts.followUpRentDepositsStageLabel({
                extra: { key: "out" },
              }),
              icon: "contentPenWrite",
              count: rentDepositsSigningOutCount,
              countDescription: ts.followUpRentDepositsStageLabel({
                extra: { key: "signing_out" },
              }),
              onClick: () => {
                handleStageClick("signing_out");
              },
            },
            {
              heading: ts.followUpRentDepositsStageLabel({
                extra: { key: "out" },
              }),
              icon: "billStack",
              count: rentDepositsPayingOutCount,
              countDescription: ts.followUpRentDepositsStageLabel({
                extra: { key: "paying_out" },
              }),
              onClick: () => {
                handleStageClick("paying_out");
              },
            },
          ]}
        />
      </Card>
      <Card>{renderTable()}</Card>
      {showCancelContractModal && rentDeposit?.basicContractId && contract && (
        <CancelContractModal
          contract={contract}
          onSuccess={() => {
            navigate(`/forms/rent-deposit/${rentDeposit.id}/release`);
          }}
          onClose={() => {
            setRentDeposit(undefined);
            setShowCancelContractModal(false);
          }}
        />
      )}
    </Page>
  );
};
