import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Markdown from "react-markdown";
import { MarkdownWrapperStyles } from "./MarkdownWrapper.styles";
export var MarkdownWrapper = function (_a) {
    var source = _a.source, listAsChecklist = _a.listAsChecklist, checklistCharacter = _a.checklistCharacter;
    if (!source) {
        return null;
    }
    var sx = MarkdownWrapperStyles(listAsChecklist, checklistCharacter);
    return (_jsx(Box, { sx: sx.wrapper, children: _jsx(Markdown, { children: source }) }));
};
