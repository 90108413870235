import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { useNavigate } from "react-router-dom";
import { DropdownMenu, Icon } from "..";
import { Loading } from "../Loading";
import * as S from "./QuickActionCard.styles";
export var QuickActionCard = function (props) {
    var _a;
    var heading = props.heading, subheading = props.subheading, icon = props.icon, iconColor = props.iconColor, count = props.count, countDescription = props.countDescription, isFetching = props.isFetching, url = props.url, actions = props.actions, onClick = props.onClick, children = props.children;
    var navigate = useNavigate();
    var description = countDescription !== null && countDescription !== void 0 ? countDescription : getLocalizedText("system.action_required");
    var handleClick = function () {
        if (onClick) {
            onClick();
        }
        else if (url) {
            navigate(url);
        }
    };
    return (_jsxs(S.StylesQuickActionCard, { onClick: handleClick, children: [_jsxs(S.QuickActionCardHeaderContainer, { children: [_jsxs("div", { children: [_jsx(S.QuickActionCardHeader, { children: _jsx("h1", { children: heading }) }), subheading && (_jsx(S.QuickActionCardSubheader, { children: _jsx("p", { children: subheading }) }))] }), _jsx("div", { style: { display: "flex", alignItems: "center" }, children: _jsx(Icon, { source: icon, size: "large", color: (_a = iconColor) !== null && _a !== void 0 ? _a : "primary" }) })] }), _jsxs("div", { style: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                }, children: [count !== undefined && (_jsxs("div", { children: [_jsx(S.QuickActionCount, { children: count }), _jsx(S.QuickActionCountDescription, { children: description.toUpperCase() })] })), count === undefined && isFetching && _jsx(Loading, { asDots: true }), actions && (_jsx(DropdownMenu, { passDownClickEvents: false, children: _jsx("div", { children: _jsx(Icon, { source: "navigationMenuVertical" }) }), actions: actions })), children] })] }));
};
