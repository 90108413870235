import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";

export enum EExtraInfoGroup {
  General = "general",
  Epc = "epc",
  Bookkeeping = "bookkeeping",
  BookkeepingGroup = "bookkeeping_group",
  Electricity = "electricity",
  NaturalGas = "natural_gas",
  Water = "water",
  Exterior = "exterior",
  Interior = "interior",
  Amenities = "amenities",
  Energy = "energy",
}

export interface IExtraData {
  property: IProperty;
  extraInfoGroup: EExtraInfoGroup;
}

export enum EField {
  Header = "_header",

  // EPC
  // Custom EPC-schema

  // Amenities
  HasOven = "hasOven",
  HasFridge = "hasFridge",
  HasDishWasher = "hasDishwasher",
  HasRangeHood = "hasRangeHood",
  HasLift = "hasLift",

  // Bookkeeping
  CostUnit = "costUnit",
  BookkeepingPaidOutStartDate = "bookkeepingPaidOutStartDate",

  // Bookkeeping Group
  CostCenter = "costCenter",

  // Electricity
  ElectricityType = "electricityType",
  ElectricityEan = "electricityEan",
  ElectricityMeterNumberBoth = "electricityMeterNumberBoth",
  ElectricityMeterType = "electricityMeterType",

  // Energy
  HasDoubleGlazing = "hasDoubleGlazing",
  HasCentralHeating = "hasCentralHeating",
  HeatingType = "heatingType",

  // Exterior
  BuildingAdjoined = "buildingAdjoined",
  BuildingSurfaceArea = "buildingSurfaceArea",
  BuildingGardenSurfaceArea = "buildingGardenSurfaceArea",
  BuildingTerraceSurfaceArea = "buildingTerraceSurfaceArea",

  // General
  ConstructionYear = "constructionYear",

  // Interior
  BuildingFloor = "buildingFloor",
  BuildingFloorCount = "buildingFloorCount",
  RoomCountBedroom = "roomCountBedroom",
  RoomCountBathroom = "roomCountBathroom",
  RoomCountGarage = "roomCountGarage",
  BuildingHasStorage = "buildingHasStorage",
  IsFurnished = "isFurnished",

  // Natural Gas
  NaturalGasAvailable = "naturalGasAvailable",
  NaturalGasEan = "naturalGasEan",
  NaturalGasMeterNumber = "naturalGasMeterNumber",
  NaturalGasMeterType = "naturalGasMeterType",

  // Water
  WaterMeterNumber = "waterMeterNumber",
}
