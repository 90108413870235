var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactApi from "@rentiohq/shared-frontend/dist/redux/contact/contact.api";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { EContactType, } from "@rentiohq/shared-frontend/dist/types/contact.types";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { createImperativePromise } from "awesome-imperative-promise";
import { uniq } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EAssets } from "../../assets";
import { AsyncAutoComplete } from "../AsyncAutoComplete";
import { ContactCheckList } from "../ContactCheckList";
import { ContactListItem } from "../ContactListItem";
import { Icon } from "../Icon";
import { ContactCheckSelectInlineStyles } from "./ContactCheckSelectInline.styles";
export var ContactCheckSelectInline = function (_a) {
    var _b = _a.showContactList, showContactList = _b === void 0 ? true : _b, placeholder = _a.placeholder, fieldLabel = _a.fieldLabel, onChange = _a.onChange, _c = _a.value, selectedContactIds = _c === void 0 ? [] : _c, includeSelf = _a.includeSelf, maxItems = _a.maxItems, hideForm = _a.hideForm, autoFocus = _a.autoFocus, formData = _a.formikProps.values, customExtraAccountsSelector = _a.customExtraAccountsSelector, accountIdsToRenderFilter = _a.accountIdsToRenderFilter, customGetContactsActions = _a.customGetContactsActions, getDisallowedAccountIds = _a.getDisallowedAccountIds;
    var dispatch = useDispatch();
    var contactMeMaster = useSelf().contactMeMaster;
    var extraAccounts = useSelector(function (state) {
        return customExtraAccountsSelector(state, formData);
    });
    var _d = React.useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var _e = React.useState(false), showSearch = _e[0], setShowSearch = _e[1];
    var sx = ContactCheckSelectInlineStyles(showSearch);
    React.useEffect(function () {
        customGetContactsActions.forEach(function (action) {
            dispatch(action());
        });
    }, [customGetContactsActions]);
    var handleAutocompleteChange = function (_a) {
        var selValue = _a.value;
        var combinedIds = uniq(__spreadArray(__spreadArray([], selectedContactIds, true), [selValue], false));
        onChange(uniq(combinedIds));
        setShowSearch(false);
    };
    var getExtraFilterData = function () {
        var filterData = { limit: 30 };
        var contactTypes = [EContactType.Normal];
        filterData.where = {
            or: [{ type: { eq: null } }, { type: { inq: contactTypes } }],
        };
        return filterData;
    };
    var globalSearch = function (query) {
        if (query === void 0) { query = ""; }
        return createImperativePromise(query === ""
            ? undefined
            : contactApi.searchContacts(query, getExtraFilterData()));
    };
    var debouncedSearch = AwesomeDebouncePromise(globalSearch, 550);
    var filterContacts = function (contactId, disallowedAccountIds) {
        return (!selectedContactIds.find(function (id) { return id === contactId; }) &&
            !disallowedAccountIds.includes(contactId));
    };
    var handleLoadOptions = function (query, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, promise, cancel, contactList, contacts, disallowedAccountIds_1, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, debouncedSearch(query)];
                case 1:
                    _a = _b.sent(), promise = _a.promise, cancel = _a.cancel;
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    if (!query && query === "") {
                        cancel();
                        return [2 /*return*/, []];
                    }
                    return [4 /*yield*/, promise];
                case 3:
                    contactList = (_b.sent()).data;
                    contacts = __spreadArray(__spreadArray([], contactList, true), (extraAccounts || []), true);
                    if (includeSelf && contactMeMaster) {
                        contacts.unshift(contactMeMaster);
                    }
                    disallowedAccountIds_1 = (getDisallowedAccountIds === null || getDisallowedAccountIds === void 0 ? void 0 : getDisallowedAccountIds(formData, contacts.map(function (contact) { return contact.id; }))) || [];
                    callback(contacts
                        .filter(function (contact) {
                        return filterContacts(contact === null || contact === void 0 ? void 0 : contact.accountId, disallowedAccountIds_1);
                    })
                        .map(function (contact) { return ({
                        data: contact,
                        label: getName(contact),
                        value: contact === null || contact === void 0 ? void 0 : contact.accountId,
                    }); }));
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _b.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var getSubtitle = function (contact) {
        return {
            subTitle: "".concat(contact === null || contact === void 0 ? void 0 : contact.phone, " | ").concat(contact === null || contact === void 0 ? void 0 : contact.email),
        };
    };
    var renderOption = function (option) {
        return (_jsx(Box, { p: 1, children: _jsx(ContactListItem, { contact: option === null || option === void 0 ? void 0 : option.data, getSubtitle: getSubtitle, showActions: false }) }));
    };
    var handleSelectContact = function (id) {
        if (selectedContactIds.includes(id)) {
            onChange(selectedContactIds.filter(function (contactId) { return contactId !== id; }));
        }
        else {
            var combinedIds = __spreadArray(__spreadArray([], selectedContactIds, true), [id], false);
            onChange(uniq(combinedIds));
        }
    };
    var renderContactsList = React.useMemo(function () {
        return (showContactList &&
            (selectedContactIds === null || selectedContactIds === void 0 ? void 0 : selectedContactIds.length) > 0 && (_jsx(Box, { mb: 1, children: _jsx(ContactCheckList, { contactIds: selectedContactIds.filter(accountIdsToRenderFilter
                    ? accountIdsToRenderFilter(selectedContactIds)
                    : function () { return true; }), getSubtitle: getSubtitle, handleSelectContact: handleSelectContact }) })));
    }, [showContactList, selectedContactIds === null || selectedContactIds === void 0 ? void 0 : selectedContactIds.length]);
    var showForm = hideForm
        ? !hideForm
        : !maxItems ||
            !selectedContactIds ||
            (maxItems && selectedContactIds.length < maxItems);
    return (_jsxs("div", { children: [renderContactsList, showForm && (_jsxs(Stack, { direction: "row", gap: 1, sx: sx.wrapper, children: [_jsx(Checkbox, { checked: showSearch, onChange: function (event) { return setShowSearch(event.target.checked); } }), _jsxs(Stack, { gap: 1, width: "100%", children: [_jsx(Typography, { variant: "subtitle1", onClick: function () { return setShowSearch(function (prevState) { return !prevState; }); }, children: fieldLabel }), showSearch && (_jsxs(Stack, { direction: "row", gap: 1, alignItems: "center", width: "100%", children: [_jsx(AsyncAutoComplete, { loadOptions: handleLoadOptions, renderOption: renderOption, onChange: handleAutocompleteChange, placeholder: placeholder || "", isLoading: isLoading, autoFocus: autoFocus }), _jsx(IconButton, { onClick: function (e) {
                                            e.stopPropagation();
                                            setShowSearch(false);
                                        }, children: _jsx(Icon, { path: EAssets.CloseIcon, width: "20px", height: "20px" }) })] }))] })] }))] }));
};
