import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  ERegistrationDocumentType,
  IRegistration,
} from "@rentiohq/shared-frontend/dist/types/registration.types";

export interface IExtraData {
  documentType: ERegistrationDocumentType;
  registrationId: number;
  registration: IRegistration;
  existingDocumentId?: number;
  property: IProperty;
  contract: IContract;

  contactMeMaster?: IContact;
  legalContact?: IContact;
  isBroker?: boolean;
}

export enum EField {
  Owners = "owners",
  SignAsBroker = "signAsBroker",
  Tenants = "tenants",

  DocumentToUpload = "documentToUpload",
  Type = "type",
  SignedAt = "signedAt",
  SignedAtAddendum = "signedAtAddendum",

  Title1 = "title1",
  Info1 = "info1",

  // Brussels registration fields => contract
  IsSharedLease = "isSharedLease",
  ReferencePrice = "referencePrice",

  // Brussels registration field => property
  // General
  ConstructionYear = "constructionYear",

  // EPC
  // Custom EPC-schema

  // Electricity
  ElectricityType = "electricityType",
  ElectricityEan = "electricityEan",
  ElectricityMeterNumberBoth = "electricityMeterNumberBoth",
  ElectricityMeterType = "electricityMeterType",

  // Natural Gas
  NaturalGasAvailable = "naturalGasAvailable",
  NaturalGasEan = "naturalGasEan",
  NaturalGasMeterNumber = "naturalGasMeterNumber",
  NaturalGasMeterType = "naturalGasMeterType",

  // Water
  WaterMeterNumber = "waterMeterNumber",

  // Exterior
  BuildingAdjoined = "buildingAdjoined",
  BuildingSurfaceArea = "buildingSurfaceArea",
  BuildingGardenSurfaceArea = "buildingGardenSurfaceArea",
  BuildingTerraceSurfaceArea = "buildingTerraceSurfaceArea",

  // Interior
  BuildingFloor = "buildingFloor",
  BuildingFloorCount = "buildingFloorCount",
  RoomCountBedroom = "roomCountBedroom",
  RoomCountBathroom = "roomCountBathroom",
  RoomCountGarage = "roomCountGarage",
  BuildingHasStorage = "buildingHasStorage",
  IsFurnished = "isFurnished",

  // Amenities
  HasOven = "hasOven",
  HasFridge = "hasFridge",
  HasDishWasher = "hasDishwasher",
  HasRangeHood = "hasRangeHood",
  HasLift = "hasLift",

  // Energy
  HasDoubleGlazing = "hasDoubleGlazing",
  HasCentralHeating = "hasCentralHeating",
  HeatingType = "heatingType",
}
