import { EField as EContactField } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import {
  IValues,
  mapNullableBooleanValueToFormField,
} from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import {
  ERegistrationContractType,
  ERegistrationMemberType,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import {
  getAllRequiredCompanyFields,
  getAllRequiredContactFields,
} from "@rentiohq/shared-frontend/dist/utils/forms.utils";
import { EAN_PREFIX } from "forms/requestMoveService/schema.requestMoveService.config";
import { EField, IExtraData } from "./schema.registrationAddDocument.types";

const truncateEAN = (eanCode?: string) => {
  return eanCode?.startsWith(EAN_PREFIX)
    ? eanCode.replace(EAN_PREFIX, "")
    : undefined;
};

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { registration, contract, property, documentType } = extraData;

  const initialValues = {
    [EField.Owners]: registration.members
      ?.filter(member => member.roles.includes(ERegistrationMemberType.Owner))
      .map(member => member.account.id),
    [EField.SignAsBroker]: registration.members
      ?.filter(member => member.roles.includes(ERegistrationMemberType.Broker))
      .map(member => member.account.id),
    [EField.Tenants]: registration.members
      ?.filter(member => member.roles.includes(ERegistrationMemberType.Tenant))
      .map(member => member.account.id),
    [EField.Type]: ERegistrationContractType.Amendment,
    // Take already existing date for contract/property inspection
    [EField.SignedAt]:
      registration.documents.find(
        document => document.documentType === documentType,
      )?.signedAt || new Date(),
    // Always take today for addenda
    [EField.SignedAtAddendum]: new Date(),

    // Brussels registration fields => contract
    [EField.IsSharedLease]: mapNullableBooleanValueToFormField(
      contract.isSharedLease,
    ),
    [EField.ReferencePrice]: contract.referencePrice,

    // Brussels registration field => property
    // General
    [EField.ConstructionYear]: property.constructionYear,

    // EPC Fields
    [EEpcField.Label]: property.epcLabel,
    [EEpcField.Value]: property.epcValue,
    [EEpcField.ExpirationDate]: property.epcExpirationDate,
    [EEpcField.CertificateId]: property.epcCertificateId,

    // Electricity
    [EField.ElectricityType]: property.electricityType,
    [EField.ElectricityEan]: truncateEAN(property.electricityEan),
    [EField.ElectricityMeterNumberBoth]: property.electricityMeterNumberBoth,
    [EField.ElectricityMeterType]: property.electricityMeterType,

    // Natural Gas
    [EField.NaturalGasAvailable]: property.naturalGasAvailable,
    [EField.NaturalGasEan]: truncateEAN(property.naturalGasEan),
    [EField.NaturalGasMeterNumber]: property.naturalGasMeterNumber,
    [EField.NaturalGasMeterType]: property.naturalGasMeterType,

    // Water
    [EField.WaterMeterNumber]: property.waterMeterNumber,

    // Exterior
    [EField.BuildingAdjoined]: property.buildingAdjoined,
    [EField.BuildingSurfaceArea]: property.buildingSurfaceArea,
    [EField.BuildingGardenSurfaceArea]: property.buildingGardenSurfaceArea,
    [EField.BuildingTerraceSurfaceArea]: property.buildingTerraceSurfaceArea,

    // Interior
    [EField.BuildingFloor]: property.buildingFloor,
    [EField.BuildingFloorCount]: property.buildingFloorCount,
    [EField.RoomCountBedroom]: property.roomCountBedroom,
    [EField.RoomCountBathroom]: property.roomCountBathroom,
    [EField.RoomCountGarage]: property.roomCountGarage,
    [EField.BuildingHasStorage]: mapNullableBooleanValueToFormField(
      property.buildingHasStorage,
    ),
    [EField.IsFurnished]: mapNullableBooleanValueToFormField(
      property.isFurnished,
    ),

    // Amenities
    [EField.HasOven]: mapNullableBooleanValueToFormField(property.hasOven),
    [EField.HasFridge]: mapNullableBooleanValueToFormField(property.hasFridge),
    [EField.HasDishWasher]: mapNullableBooleanValueToFormField(
      property.hasDishwasher,
    ),
    [EField.HasRangeHood]: mapNullableBooleanValueToFormField(
      property.hasRangeHood,
    ),
    [EField.HasLift]: mapNullableBooleanValueToFormField(property.hasLift),

    // Energy
    [EField.HasDoubleGlazing]: mapNullableBooleanValueToFormField(
      property.hasDoubleGlazing,
    ),
    [EField.HasCentralHeating]: mapNullableBooleanValueToFormField(
      property.hasCentralHeating,
    ),
    [EField.HeatingType]: property.heatingType,
  };

  return initialValues;
};

export const getRegistrationAddDocumentCompanyFieldsSchema = (
  contact: IContact,
) =>
  getAllRequiredCompanyFields({
    contact,
    requiredFields: [
      EContactField.Address,
      EContactField.DateOfBirth,
      EContactField.Nationality,
      EContactField.PlaceOfBirth,
      EContactField.VATNumber,
    ],
    appendIdentityNumberToRequiredFields: true,
  });

export const getRegistrationAddDocumentContactFieldsSchema = (
  contact: IContact,
) =>
  getAllRequiredContactFields({
    contact,
    requiredFields: [
      EContactField.Address,
      EContactField.DateOfBirth,
      EContactField.Nationality,
      EContactField.PlaceOfBirth,
    ],
    appendIdentityNumberToRequiredFields: true,
  });
