import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as documentUtils from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { EFontWeights } from "@rentiohq/web-shared-next/dist/themes/types";
import {
  ETagVariant,
  Grid,
  Tag,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";

interface IProps {
  document: IDocument;
}

export const TaskDocument = ({ document }: IProps) => {
  const { user } = authHooks.useSelf();

  const { filename, categoryId, members, createdAt } = document;
  return (
    <Grid container>
      <Grid item md={4} lg={4}>
        <Typography variant="subtitle2" fontWeight={EFontWeights.Bold}>
          {filename}
        </Typography>
      </Grid>
      <Grid item md={8} lg={2}>
        <Tag variant={ETagVariant.Default}>
          {documentUtils.getDocumentCategoryName(categoryId)}
        </Tag>
      </Grid>
      <Grid item md={12} lg={2}>
        <Typography variant="caption" fontWeight={EFontWeights.Regular}>
          {utils.date.format(createdAt)}
        </Typography>
      </Grid>
      <Grid item md={12} lg={4}>
        <Typography variant="subtitle2" fontWeight={EFontWeights.Regular}>
          {getLocalizedText("documents.members.header.title")}:{" "}
          {members.length > 1
            ? members
                .filter(
                  (member: any) => member.account.id !== user?.activeAccountId,
                )
                .map((member: any) => getName(member.account))
                .join(", ")
            : "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};
