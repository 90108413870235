import { ERentioFormField } from "../../components/components/Form";
import { CONFIG } from "../../config/app.config";
import { getValue } from "../../redux/form/form.utils";
import * as contractUtils from "../../reduxV2/contract/contract.utils";
import { EContractPaymentRepetitionType, } from "../../types/contract.types";
import { EPaymentOrderType } from "../../types/payment.types";
import { addYears, differenceInCalendarDays, getDaysInMonth, } from "../../utils/date-fns.utils";
import { formatDate, formatMediumDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { addWithDecimalPrecision, formatCurrency, } from "../../utils/number.utils";
import { shouldShowCommonCosts, shouldShowNonCommonCosts, } from "./schema.createPayment.2a";
import { EField } from "./schema.createPayment.types";
import { getNavigationTitle, getRelatedContract, getStartDate, } from "./schema.createPayment.utils";
var NOW = new Date();
var getPeriodString = function (repetitionType) {
    var periodKey = "month";
    if (repetitionType === EContractPaymentRepetitionType.Quarterly) {
        periodKey = "quarter";
    }
    else if (repetitionType === EContractPaymentRepetitionType.Yearly) {
        periodKey = "year";
    }
    return getLocalizedText("system.".concat(periodKey)).toLowerCase();
};
var getPrice = function (formData, field, contract) {
    if (!contract) {
        return 0;
    }
    switch (formData[EField.TypeRecurring]) {
        case EPaymentOrderType.RentAndCosts:
        case EPaymentOrderType.Rent:
        case EPaymentOrderType.CommonCosts:
        case EPaymentOrderType.NonCommonCosts:
            if (field === EField.FirstPrice) {
                return contract.currentPrice;
            }
            if (field === EField.FirstPriceCommonCosts) {
                return contract.currentCostsCommon;
            }
            if (field === EField.FirstPriceNonCommonCosts) {
                return contract.currentCostsNonCommon;
            }
            return 0;
        default:
            return 0;
    }
};
export var getProRataDays = function (formData, contract) {
    if (!contract) {
        return 0;
    }
    var startDate = getStartDate(formData);
    if (!startDate) {
        return 0;
    }
    var intermediateDate = contractUtils.getProRataBaseEndDate(startDate, contract);
    // Add 1 day to include last day
    return differenceInCalendarDays(intermediateDate, startDate) + 1;
};
export var getProRataPeriodDays = function (formData, contract) {
    var _a = contract.repetitionType, repetitionType = _a === void 0 ? EContractPaymentRepetitionType.Monthly : _a;
    if (repetitionType === EContractPaymentRepetitionType.Quarterly) {
        // TODO:
        return 30 * 3;
    }
    if (repetitionType === EContractPaymentRepetitionType.Yearly) {
        // TODO:
        return 365;
    }
    var startDate = getStartDate(formData) || new Date();
    var result = getDaysInMonth(startDate);
    return result;
};
var getProRataPrice = function (formData, field, contract) {
    if (!contract) {
        return 0;
    }
    var proRataDays = getProRataDays(formData, contract);
    var price = getPrice(formData, field, contract);
    var periodDays = getProRataPeriodDays(formData, contract);
    return Math.round(price * (proRataDays / periodDays) * 100) / 100;
};
var getOptionsValue = function (formData, withExtraMonth, contract) {
    if (!contract) {
        return "";
    }
    var startDate = getStartDate(formData);
    if (!startDate) {
        return "";
    }
    var intermediateDate = contractUtils.getProRataBaseEndDate(startDate, contract);
    var endDate = contractUtils.getProRataExtraMonthEndDate(startDate, contract);
    var key = "payment.payment_order.first_payment.period.".concat(withExtraMonth ? "value_incl_month" : "value", ".basic");
    return getLocalizedText(key, {
        startDate: formatMediumDate(startDate),
        endDate: formatMediumDate(withExtraMonth ? endDate : intermediateDate),
    });
};
var getOptionsSubValue = function (formData, withExtraMonth, contract) {
    if (!contract) {
        return "";
    }
    var startDate = getStartDate(formData);
    if (!startDate) {
        return "";
    }
    var proRataDays = getProRataDays(formData, contract);
    var key = "payment.payment_order.first_payment.period.".concat(withExtraMonth ? "value_incl_month" : "value", ".days");
    return getLocalizedText(key, {
        days: "".concat(proRataDays),
        period: getPeriodString(contract.repetitionType),
    });
};
var prefillPriceOnChange = function (formData, fieldName, fieldValue, field, contract) {
    if (contract && fieldName === EField.ProRata) {
        var price = getPrice(formData, field, contract);
        var proRataPrice = getProRataPrice(formData, field, contract);
        if (fieldValue === "NORMAL") {
            return { shouldSetNewValue: true, newValue: proRataPrice };
        }
        return {
            shouldSetNewValue: true,
            newValue: addWithDecimalPrecision(price, proRataPrice),
        };
    }
    return { shouldSetNewValue: false };
};
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        var contract = getRelatedContract(extraData, defaultValues);
        return {
            required: [EField.ProRata, EField.FirstPaymentReceivedDateProRata],
            properties: (_a = {},
                _a[EField.Header + "3"] = {
                    default: undefined,
                },
                _a[EField.ProRata] = {
                    default: getValue(EField.ProRata, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        {
                            id: "NORMAL",
                            getValue: function (formData) {
                                return getOptionsValue(formData, false, contract);
                            },
                            getSubValue: function (formData) {
                                return getOptionsSubValue(formData, false, contract);
                            },
                        },
                        {
                            id: "EXTRA_MONTH",
                            getValue: function (formData) {
                                return getOptionsValue(formData, true, contract);
                            },
                            getSubValue: function (formData) {
                                return getOptionsSubValue(formData, true, contract);
                            },
                        },
                    ],
                },
                _a[EField.FirstPriceVatInfo] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function (formData) {
                        if (!contract) {
                            return false;
                        }
                        var _a = contract.priceVat, priceVat = _a === void 0 ? 0 : _a, _b = contract.costsCommonVat, costsCommonVat = _b === void 0 ? 0 : _b, _c = contract.costsNonCommonVat, costsNonCommonVat = _c === void 0 ? 0 : _c;
                        return priceVat > 0 || costsCommonVat > 0 || costsNonCommonVat > 0;
                    },
                },
                _a[EField.FirstPrice] = {
                    default: getValue(EField.FirstPrice, initialValues, defaultValues),
                    // @ts-ignore
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        return prefillPriceOnChange(formData, fieldName, fieldValue, EField.FirstPrice, contract);
                    },
                    // @ts-ignore
                    requiredIf: function (formData) {
                        var type = formData[EField.TypeRecurring] || "-";
                        return ![
                            EPaymentOrderType.CommonCosts,
                            EPaymentOrderType.NonCommonCosts,
                        ].includes(type);
                    },
                    // @ts-ignore
                    showIf: function (formData) {
                        var type = formData[EField.TypeRecurring] || "-";
                        return ![
                            EPaymentOrderType.CommonCosts,
                            EPaymentOrderType.NonCommonCosts,
                        ].includes(type);
                    },
                },
                _a[EField.FirstPriceCommonCosts] = {
                    default: getValue(EField.FirstPriceCommonCosts, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) { return shouldShowCommonCosts(formData, contract); },
                    requiredIf: function (formData) {
                        return shouldShowCommonCosts(formData, contract);
                    },
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        return prefillPriceOnChange(formData, fieldName, fieldValue, EField.FirstPriceCommonCosts, contract);
                    },
                },
                _a[EField.FirstPriceNonCommonCosts] = {
                    default: getValue(EField.FirstPriceNonCommonCosts, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return shouldShowNonCommonCosts(formData, contract);
                    },
                    requiredIf: function (formData) {
                        return shouldShowNonCommonCosts(formData, contract);
                    },
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        return prefillPriceOnChange(formData, fieldName, fieldValue, EField.FirstPriceNonCommonCosts, contract);
                    },
                },
                _a[EField.FirstPaymentReceivedDateProRata] = {
                    default: getValue(EField.FirstPaymentReceivedDateProRata, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (initialValues, extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payments.create.pro_rata.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "3"] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function (_a) {
                var otherDescription = _a.otherDescription, typeRecurring = _a.typeRecurring, typeOnce = _a.typeOnce;
                return getLocalizedText("payments.create.pro_rata.heading", {
                    type: otherDescription ||
                        getLocalizedText("payment.payment_order.type.".concat(typeRecurring || typeOnce).toLowerCase()).toLowerCase(),
                });
            },
        },
        _a[EField.ProRata] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("payment.payment_order.first_payment.period.heading"),
            formatSummary: function (input, formData) {
                var key = "payment.payment_order.first_payment.period.".concat(input === "EXTRA_MONTH" ? "value_incl_month" : "value", ".days");
                var contract = getRelatedContract(extraData, formData);
                return getLocalizedText(key, {
                    days: "".concat(getProRataDays(formData, contract)),
                    period: getPeriodString(contract === null || contract === void 0 ? void 0 : contract.repetitionType),
                });
            },
        },
        _a[EField.FirstPriceVatInfo] = {
            "ui:field": ERentioFormField.HelpInfo,
            type: "warning",
            title: getLocalizedText("payment.payment_order.first_amount.vat_info.title"),
            subtitle: getLocalizedText("payment.payment_order.first_amount.vat_info.subtitle"),
        },
        _a[EField.FirstPrice] = {
            "ui:field": ERentioFormField.Number,
            getTitle: function (formData) {
                var type = formData[EField.TypeRecurring] || formData[EField.TypeOnce];
                if (type === EPaymentOrderType.RentAndCosts) {
                    type = EPaymentOrderType.Rent;
                }
                return getLocalizedText("payment.payment_order.first_amount", {
                    type: formData[EField.OtherDescription] ||
                        getLocalizedText("payment.payment_order.type.".concat(type.toLowerCase())).toLowerCase(),
                });
            },
            formatSummary: function (formData) { return formData && formatCurrency(formData); },
            placeholder: "0",
            suffixText: "EUR",
            getInfo: function (formData) {
                if (formData[EField.TypeRecurring] === EPaymentOrderType.Rent ||
                    formData[EField.TypeRecurring] === EPaymentOrderType.RentAndCosts) {
                    var contract = getRelatedContract(extraData, formData);
                    return getLocalizedText("payment.payment_order.first_payment.amount.help_text.basic", {
                        days: "".concat(getProRataDays(formData, contract)),
                        amount: formatCurrency(getProRataPrice(formData, EField.FirstPrice, contract)),
                        period: getPeriodString(contract === null || contract === void 0 ? void 0 : contract.repetitionType),
                    });
                }
                return;
            },
            isFloat: true,
            decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        },
        _a[EField.FirstPriceCommonCosts] = {
            "ui:field": ERentioFormField.Number,
            getTitle: function (formData) {
                var contract = getRelatedContract(extraData, formData);
                return getLocalizedText("payment.payment_order.first_amount_common_costs", {
                    priceType: getLocalizedText("payment.payment_order.payment_type.value.".concat((contract === null || contract === void 0 ? void 0 : contract.priceTypeCommonCosts) || "fixed").toLowerCase()),
                });
            },
            formatSummary: function (formData) { return formData && formatCurrency(formData); },
            placeholder: "0",
            suffixText: "EUR",
            getInfo: function (formData) {
                var contract = getRelatedContract(extraData, formData);
                return getLocalizedText("payment.payment_order.first_payment_common_costs.amount.help_text.basic", {
                    days: "".concat(getProRataDays(formData, contract)),
                    amount: formatCurrency(getProRataPrice(formData, EField.FirstPriceCommonCosts, contract)),
                    period: getPeriodString(contract ? contract.repetitionType : undefined),
                });
            },
            isFloat: true,
            decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        },
        _a[EField.FirstPriceNonCommonCosts] = {
            "ui:field": ERentioFormField.Number,
            getTitle: function (formData) {
                var contract = getRelatedContract(extraData, formData);
                return getLocalizedText("payment.payment_order.first_amount_non_common_costs", {
                    priceType: getLocalizedText("payment.payment_order.payment_type.value.".concat((contract === null || contract === void 0 ? void 0 : contract.priceTypeNonCommonCosts) || "fixed").toLowerCase()),
                });
            },
            formatSummary: function (formData) { return formData && formatCurrency(formData); },
            placeholder: "0",
            suffixText: "EUR",
            getInfo: function (formData) {
                var contract = getRelatedContract(extraData, formData);
                return getLocalizedText("payment.payment_order.first_payment_non_common_costs.amount.help_text.basic", {
                    days: "".concat(getProRataDays(formData, contract)),
                    amount: formatCurrency(getProRataPrice(formData, EField.FirstPriceNonCommonCosts, contract)),
                });
            },
            isFloat: true,
            decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        },
        _a[EField.FirstPaymentReceivedDateProRata] = {
            "ui:field": ERentioFormField.Date,
            formatSummary: function (input) { return (input ? formatDate(input) : ""); },
            getTitle: function (formData) {
                return getLocalizedText("payment.payment_order.due_date.label.basic", {
                    type: getLocalizedText(formData[EField.OtherDescription] ||
                        "payment.payment_order.type.".concat(formData[EField.TypeRecurring] || formData[EField.TypeOnce]).toLowerCase()),
                });
            },
            minDate: NOW,
            maxDate: addYears(NOW, 1),
        },
        _a);
};
