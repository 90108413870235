var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useConfig from "../../hooks/useConfig";
import { ThemeMode } from "../../utils/types/config";
export var HeaderStyles = function () {
    var theme = useTheme();
    var mode = useConfig().mode;
    var downLG = useMediaQuery(theme.breakpoints.down("lg"));
    return {
        wrapper: { width: downLG ? "auto" : 228, display: "flex" },
        content: {
            avatar: __assign(__assign(__assign({}, theme.typography.commonAvatar), theme.typography.mediumAvatar), { overflow: "hidden", transition: "all .2s ease-in-out", bgcolor: mode === ThemeMode.DARK ? "dark.main" : "secondary.light", color: mode === ThemeMode.DARK ? "secondary.main" : "secondary.dark", "&:hover": {
                    bgcolor: mode === ThemeMode.DARK ? "secondary.main" : "secondary.dark",
                    color: mode === ThemeMode.DARK ? "secondary.light" : "secondary.light",
                } }),
            logo: {
                display: { xs: "block", sm: "none" },
                flexGrow: 1,
            },
            space: { flexGrow: 1 },
        },
    };
};
