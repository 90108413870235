import { useTheme } from "../../themes";
export var InlineSelectStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            rowGap: "8px",
        },
        content: {
            formControlLabel: function (selected) { return ({
                width: "100%",
                borderRadius: "4px",
                cursor: "pointer",
                margin: 0,
                border: selected
                    ? "2px solid ".concat(theme.palette.success.main)
                    : "1px solid ".concat(theme.palette.grey[400]),
                background: selected
                    ? theme.palette.success.light
                    : theme.palette.background.paper,
                "&.Mui-disabled": {
                    ".MuiTypography-body1": {
                        color: "".concat(theme.palette.grey[300], " !important"),
                    },
                    ".MuiRadio-root": {
                        color: "".concat(theme.palette.grey[300], " !important"),
                    },
                    "&:hover": {
                        ".MuiTypography-body1": {
                            color: "".concat(theme.palette.grey[300], " !important"),
                        },
                        ".MuiRadio-root": {
                            color: "".concat(theme.palette.grey[300], " !important"),
                        },
                    },
                },
                "&:hover": {
                    ".MuiTypography-body1": {
                        color: theme.palette.success.main,
                    },
                    ".MuiRadio-root": {
                        color: theme.palette.success.main,
                    },
                },
            }); },
        },
    };
};
