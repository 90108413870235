import createPropertySchemas from "@rentiohq/shared-frontend/dist/forms/createProperty";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/createProperty/schema.createProperty.actions";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { EPropertyTypeId } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  Card,
  MultiStepForm as Form,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BooleanParam, NumberParam } from "serialize-query-params";
import { IPropertyFormProps } from "./PropertyForm.types";

export const PropertyForm: React.FC<IPropertyFormProps> = props => {
  const { isDuplicate, brokerContact, property, groupsCount } = props;

  // Router
  const [queryParams] = useQueryParams({
    parentId: NumberParam,
    group: BooleanParam,
  });

  const navigate = useNavigate();

  // Duplicate property
  if (isDuplicate && property) {
    const extraData = {
      isDuplicate: true,
      property,
      brokerContact,
      groupsCount,
      epcIsRequired: false,
    };

    return (
      <Card>
        <Form
          formId={"property-duplicate"}
          schemas={createPropertySchemas(extraData)}
          withSummary={false}
          withAside={true}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            "create",
          )}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            "create",
          )}
          performingSubmitActionResultSelector={performingSubmitActionResultSelector(
            "create",
          )}
          submitActionCreator={submitActionCreator(extraData)}
          onSuccess={(newPropertyId?: number) => {
            navigate(`/properties/${newPropertyId}`);
          }}
        />
      </Card>
    );
  }

  // Update property
  if (property) {
    const extraData = {
      propertyId: property.id,
      property,
      brokerContact,
      groupsCount,
      isGroup: property.typeId === EPropertyTypeId.Group,
      epcIsRequired: false,
    };

    return (
      <Card>
        <Form
          formId={`property-update-${property.id}`}
          schemas={createPropertySchemas(extraData)}
          withSummary={false}
          withAside={false}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            "update",
            property.id,
          )}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            "update",
          )}
          performingSubmitActionResultSelector={performingSubmitActionResultSelector(
            "update",
          )}
          submitActionCreator={submitActionCreator(extraData, property.id)}
          onSuccess={() => {
            navigate(`/properties/${property.id}`);
          }}
        />
      </Card>
    );
  }

  // Create property
  const extraData = {
    brokerContact,
    groupsCount,
    parentId: queryParams?.parentId,
    isGroup: queryParams?.group || false,
    epcIsRequired: false,
  };

  return (
    <Card>
      <Form
        formId={"property-create"}
        schemas={createPropertySchemas(extraData)}
        withSummary={false}
        withAside={true}
        isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
          "create",
        )}
        performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
          "create",
        )}
        performingSubmitActionResultSelector={performingSubmitActionResultSelector(
          "create",
        )}
        submitActionCreator={submitActionCreator(extraData)}
        onSuccess={(newPropertyId?: number) => {
          navigate(`/properties/${newPropertyId}`);
        }}
      />
    </Card>
  );
};
