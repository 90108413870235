import React from "react";
export var useCountDown = function (countDown /* in milliseconds */) {
    var intervalRef = React.useRef(null);
    var _a = React.useState(countDown), remainingCountDown = _a[0], setRemainingCountDown = _a[1];
    // Helpers
    var startCountdown = function () {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        setRemainingCountDown(countDown);
        intervalRef.current = setInterval(function () {
            setRemainingCountDown(function (prevValue) {
                if (prevValue > 0) {
                    return prevValue - 1000;
                }
                clearInterval(intervalRef.current);
                intervalRef.current = null;
                return 0;
            });
        }, 1000);
    };
    var stopCountdown = function () {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };
    // Hooks
    React.useEffect(function () {
        startCountdown();
    }, []);
    return {
        remainingCountDown: remainingCountDown,
        remainingCountDownInSeconds: Math.floor(remainingCountDown / 1000),
        startCountdown: startCountdown,
        stopCountdown: stopCountdown,
    };
};
