import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.createDocumentPackage.types";
import { getNavigationTitle } from "./schema.createDocumentPackage.utils";

const acceptPDF = "application/pdf";
const mimeTypesPDF = ["application/pdf"];

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => ({
    required: [EField.Documents],
    properties: {
      [EField.Header + "3"]: {
        default: undefined,
      },
      [EField.Documents]: {
        default: getValue(EField.Documents, initialValues, defaultValues),
      },
    },
  });

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => ({
  "rentio:title": utils.i18n.t("document_package.form.documents.step_heading"),
  "rentio:navigationTitle": getNavigationTitle(),
  [EField.Header + "3"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: utils.i18n.t("document_package.form.documents.heading"),
  },
  [EField.Documents]: {
    "ui:field": ERentioFormField.DocumentSelectInline,
    title: utils.i18n.t("document_package.form.documents.label"),
    accept: acceptPDF,
    mimeTypes: mimeTypesPDF,
    multiple: false,
    useExistingDocuments: true,
    getExistingDocumentsWhereFilter: (formData: any) => {
      if (extraData.property) {
        return {
          propertyId: { eq: extraData.property.id },
        };
      }

      if (formData[EField.Property]) {
        return {
          propertyId: { eq: formData[EField.Property] },
        };
      }

      return {};
    },
    valueSelector: (state, formData = []) =>
      (formData || [])
        .map(item => {
          if (typeof item === "string") {
            const document = state.DOCUMENTS_V2.detail[item]?.entity;
            if (document) {
              return document.filename;
            }

            return getLocalizedText("summary.view.document");
          }

          return item.name;
        })
        .join(", "),
  },
});
