export var ETaskRecurrence;
(function (ETaskRecurrence) {
    ETaskRecurrence["TodoToday"] = "TODO_TODAY";
    ETaskRecurrence["TodoThisWeek"] = "TODO_THIS_WEEK";
    ETaskRecurrence["TodoNextWeek"] = "TODO_NEXT_WEEK";
    ETaskRecurrence["Todo"] = "TODO";
    ETaskRecurrence["FollowUpToday"] = "FOLLOW_UP_TODAY";
    ETaskRecurrence["FollowUpThisWeek"] = "FOLLOW_UP_THIS_WEEK";
    ETaskRecurrence["FollowUpNextWeek"] = "FOLLOW_UP_NEXT_WEEK";
    ETaskRecurrence["FollowUp"] = "FOLLOW_UP";
    ETaskRecurrence["Done"] = "DONE";
    ETaskRecurrence["TodoFollowUp"] = "TODO_FOLLOW_UP";
    ETaskRecurrence["TodoFollowUpThisAndNextWeek"] = "TODO_FOLLOW_UP_THIS_AND_NEXT_WEEK";
    ETaskRecurrence["TodoLater"] = "TODO_LATER";
    ETaskRecurrence["TodoFollowUpLater"] = "TODO_FOLLOW_UP_LATER";
})(ETaskRecurrence || (ETaskRecurrence = {}));
export var ERecurrenceType;
(function (ERecurrenceType) {
    ERecurrenceType["Once"] = "ONCE";
    ERecurrenceType["Recurring"] = "RECURRING";
    ERecurrenceType["Daily"] = "DAILY";
    ERecurrenceType["Weekly"] = "WEEKLY";
    ERecurrenceType["Monthly"] = "MONTHLY";
    ERecurrenceType["Quarterly"] = "QUARTERLY";
    ERecurrenceType["HalfYearly"] = "HALF_YEARLY";
    ERecurrenceType["Yearly"] = "YEARLY";
    ERecurrenceType["TwoYearly"] = "BI_YEARLY";
    ERecurrenceType["ThreeYearly"] = "TRI_YEARLY";
    ERecurrenceType["FiveYearly"] = "FIVE_YEARLY";
})(ERecurrenceType || (ERecurrenceType = {}));
export var ETaskReminder;
(function (ETaskReminder) {
    ETaskReminder["DayBeforeExecuteDate"] = "DAY_BEFORE_EXECUTE_DATE";
    ETaskReminder["DayBeforeDueDate"] = "DAY_BEFORE_DUE_DATE";
    ETaskReminder["WeekBeforeExecuteDate"] = "WEEK_BEFORE_EXECUTE_DATE";
    ETaskReminder["WeekBeforeDueDate"] = "WEEK_BEFORE_DUE_DATE";
    ETaskReminder["MonthBeforeExecuteDate"] = "MONTH_BEFORE_EXECUTE_DATE";
    ETaskReminder["MonthBeforeDueDate"] = "MONTH_BEFORE_DUE_DATE";
    ETaskReminder["Date"] = "DATE";
    ETaskReminder["HasDate"] = "HAS_DATE";
    ETaskReminder["None"] = "NONE";
})(ETaskReminder || (ETaskReminder = {}));
export var ETaskTimeFilter;
(function (ETaskTimeFilter) {
    ETaskTimeFilter["BeforeToday"] = "BEFORE_TODAY";
    ETaskTimeFilter["Today"] = "TODAY";
    ETaskTimeFilter["ThisWeek"] = "THIS_WEEK";
    ETaskTimeFilter["NextWeek"] = "NEXT_WEEK";
    ETaskTimeFilter["All"] = "ALL";
})(ETaskTimeFilter || (ETaskTimeFilter = {}));
export var ETaskType;
(function (ETaskType) {
    ETaskType["LocationDescriptionIn"] = "LOCATION_DESCRIPTION_IN";
    ETaskType["LocationDescriptionOut"] = "LOCATION_DESCRIPTION_OUT";
    ETaskType["LocationDescriptionBoth"] = "LOCATION_DESCRIPTION_BOTH";
    ETaskType["Maintenance"] = "MAINTENANCE";
    ETaskType["Repair"] = "REPAIR";
    ETaskType["Inspection"] = "INSPECTION";
    ETaskType["Certificate"] = "CERTIFICATE";
    ETaskType["InternalTask"] = "INTERNAL_TASK";
    ETaskType["Visit"] = "VISIT";
    ETaskType["PropertyInspection"] = "PROPERTY_INSPECTION";
    ETaskType["Miscellaneous"] = "MISCELLANEOUS";
    ETaskType["ProblemTenant"] = "PROBLEM_TENANT";
})(ETaskType || (ETaskType = {}));
export var EDamageType;
(function (EDamageType) {
    EDamageType["Water"] = "water";
    EDamageType["Vandalism"] = "vandalism";
    EDamageType["Glass"] = "glass";
    EDamageType["StormWeatherNaturalDisasters"] = "stormWeatherNaturalDisasters";
    EDamageType["Electricity"] = "electricity";
    EDamageType["Theft"] = "theft";
    EDamageType["FireOrSmoke"] = "fireOrSmoke";
    EDamageType["AccidentAtWork"] = "accidentAtWork";
    EDamageType["Others"] = "others";
})(EDamageType || (EDamageType = {}));
export var ETaskStatus;
(function (ETaskStatus) {
    ETaskStatus["New"] = "new";
    ETaskStatus["Pending"] = "pending";
    ETaskStatus["Open"] = "open";
    ETaskStatus["Finished"] = "finished";
    ETaskStatus["Cancelled"] = "cancelled";
    ETaskStatus["Failed"] = "failed";
})(ETaskStatus || (ETaskStatus = {}));
export var ETaskPartner;
(function (ETaskPartner) {
    ETaskPartner["Keypoint"] = "keypoint";
})(ETaskPartner || (ETaskPartner = {}));
export var ETaskLocation;
(function (ETaskLocation) {
    ETaskLocation["Outside"] = "OUTSIDE";
    ETaskLocation["Entrance"] = "ENTRANCE";
    ETaskLocation["Toilet"] = "TOILET";
    ETaskLocation["LivingRoom"] = "LIVING_ROOM";
    ETaskLocation["Kitchen"] = "KITCHEN";
    ETaskLocation["Bathroom"] = "BATHROOM";
    ETaskLocation["Bedroom"] = "BEDROOM";
    ETaskLocation["Basement"] = "BASEMENT";
    ETaskLocation["StorageRoom"] = "STORAGE_ROOM";
    ETaskLocation["Garage"] = "GARAGE";
    ETaskLocation["Attic"] = "ATTIC";
    ETaskLocation["CommonAreas"] = "COMMON_AREAS";
    ETaskLocation["Other"] = "OTHER";
})(ETaskLocation || (ETaskLocation = {}));
export var ETaskMemberType;
(function (ETaskMemberType) {
    ETaskMemberType["Executor"] = "EXECUTOR";
    ETaskMemberType["Admin"] = "ADMIN";
    ETaskMemberType["Follower"] = "FOLLOWER";
})(ETaskMemberType || (ETaskMemberType = {}));
export var ETaskCategory;
(function (ETaskCategory) {
    ETaskCategory["Annual"] = "ANNUAL";
    ETaskCategory["Asbestos"] = "ASBESTOS";
    ETaskCategory["Cleaning"] = "CLEANING";
    ETaskCategory["Completion"] = "COMPLETION";
    ETaskCategory["Compliance"] = "COMPLIANCE";
    ETaskCategory["Electricity"] = "ELECTRICITY";
    ETaskCategory["ElectronicAppliances"] = "ELECTRONIC_APPLIANCES";
    ETaskCategory["Elevator"] = "ELEVATOR";
    ETaskCategory["Entry"] = "ENTRY";
    ETaskCategory["EntryAndExit"] = "ENTRY_AND_EXIT";
    ETaskCategory["EPC"] = "EPC";
    ETaskCategory["Exceptional"] = "EXCEPTIONAL";
    ETaskCategory["Exit"] = "EXIT";
    ETaskCategory["ExteriorCarpentry"] = "EXTERIOR_CARPENTRY";
    ETaskCategory["FireExtinguishers"] = "FIRE_EXTINGUISHERS";
    ETaskCategory["FireInsuranceOwner"] = "FIRE_INSURANCE_OWNER";
    ETaskCategory["FireInsuranceTenant"] = "FIRE_INSURANCE_TENANT";
    ETaskCategory["FireSafety"] = "FIRE_SAFETY";
    ETaskCategory["Flooring"] = "FlOORING";
    ETaskCategory["Garden"] = "GARDEN";
    ETaskCategory["Gate"] = "GATE";
    ETaskCategory["Glass"] = "GLASS";
    ETaskCategory["Heating"] = "HEATING";
    ETaskCategory["InspectionChimney"] = "INSPECTION_CHIMNEY";
    ETaskCategory["InspectionElectricity"] = "INSPECTION_ELECTRICITY";
    ETaskCategory["InspectionElevator"] = "INSPECTION_ELEVATOR";
    ETaskCategory["InspectionGasBoiler"] = "INSPECTION_GAS_BOILER";
    ETaskCategory["InspectionHeating"] = "INSPECTION_HEATING";
    ETaskCategory["InspectionOilBoiler"] = "INSPECTION_OIL_BOILER";
    ETaskCategory["InspectionOilTank"] = "INSPECTION_OIL_TANK";
    ETaskCategory["InteriorCarpentry"] = "INTERIOR_CARPENTRY";
    ETaskCategory["KeysLocks"] = "KEYS_LOCKS";
    ETaskCategory["Lighting"] = "LIGHTING";
    ETaskCategory["Other"] = "OTHER";
    ETaskCategory["Photos"] = "PHOTOS";
    ETaskCategory["Plumbing"] = "PLUMBING";
    ETaskCategory["Site"] = "SITE";
    ETaskCategory["SmokeDetectorsAlarm"] = "SMOKE_DETECTORS_ALARM";
    ETaskCategory["Soil"] = "SOIL";
    ETaskCategory["TvInternetIntercom"] = "TV_INTERNET_INTERCOM";
    ETaskCategory["Ventilation"] = "VENTILATION";
    ETaskCategory["Wall"] = "WALLS";
})(ETaskCategory || (ETaskCategory = {}));
export var ETenantTaskFilter;
(function (ETenantTaskFilter) {
    ETenantTaskFilter["Soon"] = "SOON";
    ETenantTaskFilter["Later"] = "LATER";
    ETenantTaskFilter["Follow"] = "FOLLOW";
    ETenantTaskFilter["Done"] = "DONE";
    ETenantTaskFilter["Archived"] = "ARCHIVED";
})(ETenantTaskFilter || (ETenantTaskFilter = {}));
export var TypeCategoriesMap = new Map([
    [
        ETaskType.Certificate,
        [
            ETaskCategory.Asbestos,
            ETaskCategory.FireInsuranceOwner,
            ETaskCategory.FireInsuranceTenant,
            ETaskCategory.EPC,
            ETaskCategory.InspectionElevator,
            ETaskCategory.InspectionElectricity,
            ETaskCategory.InspectionHeating,
            ETaskCategory.InspectionGasBoiler,
            ETaskCategory.InspectionGasBoiler,
            ETaskCategory.InspectionOilBoiler,
            ETaskCategory.InspectionOilTank,
            ETaskCategory.InspectionChimney,
            ETaskCategory.Compliance,
            ETaskCategory.Soil,
            ETaskCategory.FireExtinguishers,
            ETaskCategory.FireSafety,
        ],
    ],
    [
        ETaskType.PropertyInspection,
        [
            ETaskCategory.Entry,
            ETaskCategory.Exit,
            ETaskCategory.EntryAndExit,
            ETaskCategory.Completion,
            ETaskCategory.Other,
        ],
    ],
    [ETaskType.InternalTask, []],
    [ETaskType.ProblemTenant, []],
    [ETaskType.Miscellaneous, []],
    [
        ETaskType.Visit,
        [
            ETaskCategory.Site,
            ETaskCategory.Annual,
            ETaskCategory.Photos,
            ETaskCategory.Other,
        ],
    ],
    [ETaskType.Inspection, [ETaskCategory.Annual, ETaskCategory.Exceptional]],
    [
        ETaskType.Maintenance,
        [
            ETaskCategory.Plumbing,
            ETaskCategory.Ventilation,
            ETaskCategory.Electricity,
            ETaskCategory.ExteriorCarpentry,
            ETaskCategory.InteriorCarpentry,
            ETaskCategory.Lighting,
            ETaskCategory.Flooring,
            ETaskCategory.Glass,
            ETaskCategory.Wall,
            ETaskCategory.Heating,
            ETaskCategory.SmokeDetectorsAlarm,
            ETaskCategory.Gate,
            ETaskCategory.Garden,
            ETaskCategory.Elevator,
            ETaskCategory.KeysLocks,
            ETaskCategory.Cleaning,
            ETaskCategory.Other,
        ],
    ],
    [
        ETaskType.Repair,
        [
            ETaskCategory.Plumbing,
            ETaskCategory.Ventilation,
            ETaskCategory.Electricity,
            ETaskCategory.ElectronicAppliances,
            ETaskCategory.ExteriorCarpentry,
            ETaskCategory.InteriorCarpentry,
            ETaskCategory.Lighting,
            ETaskCategory.Flooring,
            ETaskCategory.Glass,
            ETaskCategory.Wall,
            ETaskCategory.TvInternetIntercom,
            ETaskCategory.Heating,
            ETaskCategory.SmokeDetectorsAlarm,
            ETaskCategory.Gate,
            ETaskCategory.Garden,
            ETaskCategory.Elevator,
            ETaskCategory.KeysLocks,
            ETaskCategory.Cleaning,
            ETaskCategory.Other,
        ],
    ],
]);
