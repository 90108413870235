import { EField as EContactField } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import {
  IValues,
  getValue,
  mapNullableBooleanValueToFormField,
} from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { EDocumentPackageStatus } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import {
  EPropertyEPCLabel,
  EPropertyTypeId,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { ERegistrationContractDestination } from "@rentiohq/shared-frontend/dist/types/registration.types";
import {
  getAllRequiredCompanyFields,
  getAllRequiredContactFields,
} from "@rentiohq/shared-frontend/dist/utils/forms.utils";
import { getStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import { EAN_PREFIX } from "forms/requestMoveService/schema.requestMoveService.config";
import { orderBy } from "lodash";
import { EField, IExtraData } from "./schema.registration.types";

const contractLeaseType: { [key: string]: string } = {
  student_housing: ERegistrationContractDestination.Housing,
  commercial_lease: ERegistrationContractDestination.Commercial,
};

const truncateEAN = (eanCode?: string) => {
  return eanCode?.startsWith(EAN_PREFIX)
    ? eanCode.replace(EAN_PREFIX, "")
    : undefined;
};

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { property, contract } = extraData;
  let contractDestination =
    contractLeaseType[extraData.contract.leaseType] ??
    ERegistrationContractDestination.Housing;
  // if (property.typeId === EPropertyTypeId.StudentResidence)
  //   contractDestination = ERegistrationContractDestination.University;
  if (property.typeId === EPropertyTypeId.Commercial) {
    contractDestination = ERegistrationContractDestination.Commercial;
  }

  const initialValues = {
    [EField.RentContractDestination]: contractDestination,
    [EField.RentContractSignedAt]: new Date(),
    [EField.RentContractPropertyTaxPaidByTenant]: "yes",

    [EField.LocationDescriptionShouldUpload]: "now",

    [EField.LocationDescriptionSignedAt]: new Date(),

    // Brussels registration fields => contract
    [EField.IsSharedLease]: mapNullableBooleanValueToFormField(
      contract.isSharedLease,
    ),
    [EField.ReferencePrice]: contract.referencePrice,

    // Brussels registration field => property
    // General
    [EField.ConstructionYear]: property.constructionYear,

    // EPC Fields
    [EEpcField.Label]: [
      EPropertyEPCLabel.NoEPCAvailable,
      EPropertyEPCLabel.Unknown,
    ].includes(property.epcLabel || EPropertyEPCLabel.Unknown)
      ? undefined
      : property.epcLabel,
    [EEpcField.Value]: property.epcValue,
    [EEpcField.ExpirationDate]: property.epcExpirationDate,
    [EEpcField.CertificateId]: property.epcCertificateId,

    // Electricity
    [EField.ElectricityType]: property.electricityType,
    [EField.ElectricityEan]: truncateEAN(property.electricityEan),
    [EField.ElectricityMeterNumberBoth]: property.electricityMeterNumberBoth,
    [EField.ElectricityMeterType]: property.electricityMeterType,

    // Natural Gas
    [EField.NaturalGasAvailable]: property.naturalGasAvailable,
    [EField.NaturalGasEan]: truncateEAN(property.naturalGasEan),
    [EField.NaturalGasMeterNumber]: property.naturalGasMeterNumber,
    [EField.NaturalGasMeterType]: property.naturalGasMeterType,

    // Water
    [EField.WaterMeterNumber]: property.waterMeterNumber,

    // Exterior
    [EField.BuildingAdjoined]: property.buildingAdjoined,
    [EField.BuildingSurfaceArea]: property.buildingSurfaceArea,
    [EField.BuildingGardenSurfaceArea]: property.buildingGardenSurfaceArea,
    [EField.BuildingTerraceSurfaceArea]: property.buildingTerraceSurfaceArea,

    // Interior
    [EField.BuildingFloor]: property.buildingFloor,
    [EField.BuildingFloorCount]: property.buildingFloorCount,
    [EField.RoomCountBedroom]: property.roomCountBedroom,
    [EField.RoomCountBathroom]: property.roomCountBathroom,
    [EField.RoomCountGarage]: property.roomCountGarage,
    [EField.BuildingHasStorage]: mapNullableBooleanValueToFormField(
      property.buildingHasStorage,
    ),
    [EField.IsFurnished]: mapNullableBooleanValueToFormField(
      property.isFurnished,
    ),

    // Amenities
    [EField.HasOven]: mapNullableBooleanValueToFormField(property.hasOven),
    [EField.HasFridge]: mapNullableBooleanValueToFormField(property.hasFridge),
    [EField.HasDishWasher]: mapNullableBooleanValueToFormField(
      property.hasDishwasher,
    ),
    [EField.HasRangeHood]: mapNullableBooleanValueToFormField(
      property.hasRangeHood,
    ),
    [EField.HasLift]: mapNullableBooleanValueToFormField(property.hasLift),

    // Energy
    [EField.HasDoubleGlazing]: mapNullableBooleanValueToFormField(
      property.hasDoubleGlazing,
    ),
    [EField.HasCentralHeating]: mapNullableBooleanValueToFormField(
      property.hasCentralHeating,
    ),
    [EField.HeatingType]: property.heatingType,
  };

  return initialValues;
};

export const getRegistrationCompanyFieldsSchema = (contact: IContact) =>
  getAllRequiredCompanyFields({
    contact,
    requiredFields: [],
  });

export const getRegistrationContactFieldsSchema = (contact: IContact) =>
  getAllRequiredContactFields({
    contact,
    requiredFields: [
      EContactField.Address,
      EContactField.DateOfBirth,
      EContactField.Nationality,
      EContactField.PlaceOfBirth,
    ],
    appendIdentityNumberToRequiredFields: true,
  });

export const getDocumentSignedAt = (params: {
  documentField: EField;
  signedAtField: EField;
  initialValues: { [key: string]: unknown };
  defaultValues: { [key: string]: unknown };
}) => {
  const { documentField, signedAtField, initialValues, defaultValues } = params;

  const documentIds = getValue(documentField, initialValues, defaultValues);
  const documentId = documentIds?.[0];

  if (typeof documentId === "string") {
    const store = getStore();
    if (store) {
      const state = store.getState();

      const documentPackage = Object.values(
        state.documentPackage.documentPackages,
      ).find(x => x.documentsInPackageIds.includes(documentId));

      if (documentPackage?.status === EDocumentPackageStatus.Finished) {
        // Find signer that last signed
        const lastSigner = orderBy(
          documentPackage.signers,
          ["completedAt"],
          "desc",
        )[0];
        return lastSigner.completedAt;
      }
    }
  }

  return getValue(signedAtField, initialValues, defaultValues);
};
