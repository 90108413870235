var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { alpha } from "@mui/material/styles";
import { ThemeMode } from "../utils/types/config";
import { EFontSizes } from "./types";
import { getFontSize } from ".";
// eslint-disable-next-line import/no-default-export
export default function componentStyleOverrides(theme, borderRadius, outlinedFilled) {
    var mode = theme.palette.mode;
    var bgColor = mode === ThemeMode.DARK ? theme.palette.dark[800] : theme.palette.grey[50];
    var menuSelectedBack = mode === ThemeMode.DARK
        ? alpha(theme.palette.secondary.main, 0.15)
        : theme.palette.secondary.light;
    var menuSelected = mode === ThemeMode.DARK
        ? theme.palette.secondary.main
        : theme.palette.secondary.dark;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: "4px",
                    width: "max-content",
                    boxShadow: "none",
                },
                contained: {
                    color: "#fff",
                },
                fullWidth: {
                    width: "100%",
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
                padding: "16px",
            },
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                },
                rounded: {
                    borderRadius: "".concat(borderRadius, "px"),
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: alpha(theme.palette.grey[700], 0.6),
                    padding: "16px",
                    fontWeight: 500,
                },
                title: {
                    color: alpha(theme.palette.grey[700], 0.6),
                    fontSize: "1.125rem",
                    fontWeight: 500,
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: "16px",
                    "&:last-child": {
                        paddingBottom: "16px",
                    },
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: "16px",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: "center",
                },
                outlined: {
                    border: "1px dashed",
                },
            },
        },
        MuiLink: {
            defaultProps: {
                fontSize: "10px",
            },
            styleOverrides: {
                root: {
                    color: theme.palette.success.main,
                    fontWeight: 500,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    "&.Mui-selected": {
                        color: menuSelected,
                        backgroundColor: menuSelectedBack,
                        "&:hover": {
                            backgroundColor: menuSelectedBack,
                        },
                        "& .MuiListItemIcon-root": {
                            color: menuSelected,
                        },
                    },
                    "&:hover": {
                        backgroundColor: menuSelectedBack,
                        color: menuSelected,
                        "& .MuiListItemIcon-root": {
                            color: menuSelected,
                        },
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    minWidth: "36px",
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.palette.text.dark,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.palette.text.dark,
                    "&::placeholder": {
                        color: theme.palette.text.secondary,
                        fontSize: "0.875rem",
                    },
                    "&.MuiInputBase-input::placeholder": {
                        color: theme.palette.grey[500],
                        opacity: 1,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: outlinedFilled ? bgColor : "transparent",
                    borderRadius: "".concat(borderRadius, "px"),
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: mode === ThemeMode.DARK
                            ? alpha(theme.palette.text.primary, 0.28)
                            : theme.palette.grey[400],
                    },
                    "&:hover $notchedOutline": {
                        borderColor: theme.palette.primary.light,
                    },
                    "&.MuiInputBase-multiline": {
                        padding: 1,
                    },
                },
                input: {
                    fontWeight: 400,
                    fontSize: getFontSize(EFontSizes.Small),
                    background: outlinedFilled ? bgColor : "transparent",
                    padding: "15.5px 14px",
                    borderRadius: "".concat(borderRadius, "px"),
                    "&.MuiInputBase-inputSizeSmall": {
                        padding: "10px 14px",
                        "&.MuiInputBase-inputAdornedStart": {
                            paddingLeft: 0,
                        },
                    },
                },
                inputAdornedStart: {
                    paddingLeft: 4,
                },
                notchedOutline: {
                    borderRadius: "".concat(borderRadius, "px"),
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        color: mode === ThemeMode.DARK
                            ? alpha(theme.palette.text.primary, 0.5)
                            : theme.palette.grey[300],
                    },
                },
                mark: {
                    backgroundColor: theme.palette.background.paper,
                    width: "4px",
                },
                valueLabel: {
                    color: mode === ThemeMode.DARK
                        ? theme.palette.primary.main
                        : theme.palette.primary.light,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    "& .MuiAutocomplete-tag": {
                        background: mode === ThemeMode.DARK
                            ? alpha(theme.palette.text.primary, 0.2)
                            : theme.palette.secondary.light,
                        borderRadius: 4,
                        color: theme.palette.text.dark,
                        ".MuiChip-deleteIcon": {
                            color: mode === ThemeMode.DARK
                                ? alpha(theme.palette.text.primary, 0.8)
                                : theme.palette.secondary[200],
                        },
                    },
                },
                popper: {
                    borderRadius: "".concat(borderRadius, "px"),
                    boxShadow: "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.palette.divider,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    "&:focus": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: mode === ThemeMode.DARK
                        ? theme.palette.dark.main
                        : theme.palette.primary.dark,
                    background: mode === ThemeMode.DARK
                        ? theme.palette.text.primary
                        : theme.palette.primary[200],
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    "&.MuiChip-deletable .MuiChip-deleteIcon": {
                        color: "inherit",
                    },
                },
            },
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.dark,
                    fontSize: "16px",
                },
            },
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14,
                },
            },
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: mode === ThemeMode.DARK
                        ? theme.palette.dark[900]
                        : theme.palette.primary.light,
                    "& .MuiTabs-flexContainer": {
                        borderColor: mode === ThemeMode.DARK
                            ? alpha(theme.palette.text.primary, 0.2)
                            : theme.palette.primary[200],
                    },
                    "& .MuiTab-root": {
                        color: mode === ThemeMode.DARK
                            ? theme.palette.text.secondary
                            : theme.palette.grey[900],
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: theme.palette.primary.dark,
                    },
                    "& .Mui-selected": {
                        color: theme.palette.primary.dark,
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    height: "100%",
                },
                indicator: {
                    backgroundColor: theme.palette.success.main,
                },
                root: {
                    borderBottom: "1px solid",
                    borderColor: alpha(theme.palette.grey[700], 0.3),
                    marginBottom: "24px",
                    a: {
                        fontWeight: "500",
                        color: alpha(theme.palette.grey[700], 0.5),
                        "&.Mui-selected": {
                            color: theme.palette.success.main,
                        },
                    },
                },
                vertical: {
                    minHeight: "36px",
                    width: "-webkit-fill-available",
                    minWidth: "200px",
                    maxWidth: "fit-content",
                    borderBottom: "none",
                    borderRight: "1px solid",
                    borderColor: alpha(theme.palette.grey[700], 0.3),
                    marginBottom: 0,
                },
                scroller: {
                    paddingRight: "24px",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: "12px 0 12px 0",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: mode === ThemeMode.DARK
                        ? alpha(theme.palette.text.primary, 0.15)
                        : theme.palette.grey[200],
                    "&.MuiTableCell-head": {
                        fontSize: "0.875rem",
                        color: mode === ThemeMode.DARK
                            ? theme.palette.grey[600]
                            : theme.palette.grey[900],
                        fontWeight: 500,
                    },
                },
            },
        },
        MuiDateTimePickerToolbar: {
            styleOverrides: {
                timeDigitsContainer: {
                    alignItems: "center",
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: theme.palette.text.white,
                    },
                },
            },
        },
        MuiPickersYear: {
            styleOverrides: {
                root: {
                    ".MuiPickersYear-yearButton": {
                        textAlign: "center",
                        "&.Mui-selected": {
                            backgroundColor: theme.palette.success.main,
                            color: theme.palette.text.white,
                        },
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.palette.text.white,
                    background: theme.palette.text.primary,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: "1.25rem",
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: "#fff",
                    },
                    margin: "3px",
                },
            },
        },
        MuiDataGrid: {
            defaultProps: {
                rowHeight: 54,
            },
            styleOverrides: {
                root: {
                    borderWidth: 0,
                    "& .MuiDataGrid-columnHeader--filledGroup": {
                        borderBottomWidth: 0,
                    },
                    "& .MuiDataGrid-columnHeader--emptyGroup": {
                        borderBottomWidth: 0,
                    },
                    "& .MuiFormControl-root>.MuiInputBase-root": {
                        backgroundColor: "".concat(theme.palette.background.default, " !important"),
                        borderColor: mode === ThemeMode.DARK
                            ? "".concat(alpha(theme.palette.divider, 0.05), " !important")
                            : "".concat(theme.palette.divider, " !important"),
                    },
                },
                toolbarContainer: {
                    "& .MuiButton-root": {
                        paddingLeft: "16px !important",
                        paddingRight: "16px !important",
                    },
                },
                withBorderColor: {
                    borderBottom: "1px solid",
                    borderColor: mode === ThemeMode.DARK
                        ? alpha(theme.palette.divider, 0.15)
                        : theme.palette.divider,
                },
                columnHeader: {
                    color: theme.palette.grey[600],
                    paddingLeft: 24,
                    paddingRight: 24,
                },
                footerContainer: {
                    "&.MuiDataGrid-withBorderColor": {
                        borderBottom: "none",
                    },
                },
                columnHeaderCheckbox: {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
                cellCheckbox: {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
                cell: {
                    borderWidth: 0,
                    paddingLeft: 24,
                    paddingRight: 24,
                    "&.MuiDataGrid-cell--withRenderer > div ": __assign(__assign({}, (mode === ThemeMode.DARK && {
                        color: theme.palette.grey[50],
                    })), { " > .high": {
                            background: mode === ThemeMode.DARK
                                ? theme.palette.success.dark
                                : theme.palette.success.light,
                        }, "& > .medium": {
                            background: mode === ThemeMode.DARK
                                ? theme.palette.warning.dark
                                : theme.palette.warning.light,
                        }, "& > .low": {
                            background: mode === ThemeMode.DARK
                                ? theme.palette.error.dark
                                : theme.palette.error.light,
                        } }),
                },
            },
        },
    };
}
