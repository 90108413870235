import { ERentioFormField } from "../../components/components/Form";
import { CONFIG } from "../../config/app.config";
import { getValue, mapFormFieldBooleanToSummary, } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { formatCurrency } from "../../utils/number.utils";
import { EField } from "./schema.createContract.types";
import { getNavigationTitle } from "./schema.createContract.utils";
export var getSchema = function (initialValues, _a) {
    var contract = _a.contract, property = _a.property;
    return function (defaultValues) {
        var _a;
        return {
            required: [],
            properties: (_a = {},
                _a[EField.Header + "13"] = {
                    default: undefined,
                },
                _a[EField.IsSharedLease] = {
                    default: getValue(EField.IsSharedLease, initialValues, defaultValues),
                    // @ts-ignore
                    options: ["yes", "no"].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("system.".concat(id).toLowerCase()),
                    }); }),
                },
                _a[EField.ReferencePrice] = {
                    default: getValue(EField.ReferencePrice, initialValues, defaultValues),
                    minimum: 1,
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("contract_info.create.additional_information.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "13"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contract_info.create.additional_information.heading"),
        },
        _a[EField.IsSharedLease] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("contract.is_shared_lease.title"),
            formatSummary: function (value) { return mapFormFieldBooleanToSummary(value); },
            optional: true,
        },
        _a[EField.ReferencePrice] = {
            "ui:field": ERentioFormField.Number,
            formatSummary: function (formData) {
                return formData && formatCurrency(formData);
            },
            title: getLocalizedText("contract.reference_price.label"),
            placeholder: "0",
            suffixText: "EUR",
            isFloat: true,
            decimals: CONFIG.DECIMALS_FOR_CURRENCY,
            optional: true,
        },
        _a;
};
