import * as tasksHooks from "@rentiohq/shared-frontend/dist/reduxV2/task/task.hooks";
import * as tasksSelectors from "@rentiohq/shared-frontend/dist/reduxV2/task/task.selectors";
import { ETaskCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/task/task.utils";
import { ETenantTaskFilter } from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Page, Button } from "@rentiohq/web-shared-next/dist/ui-components";
import { EButtonVariants } from "@rentiohq/web-shared-next/dist/utils/types/button";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { TaskApp } from "./components/TaskApp";
import { getFilter } from "./Tasks.utils";

export const Tasks = () => {
  const navigate = useNavigate();
  const params = useParams<{ taskId: string }>();
  const taskId = params.taskId ? +params.taskId : undefined;

  const [taskFilter, setTaskListFilter] = React.useState<ETenantTaskFilter>(
    ETenantTaskFilter.Soon,
  );

  const getMemoizedFilter = React.useMemo(
    () => getFilter(taskFilter),
    [taskFilter],
  );

  const getQuery = () => {
    return {
      limit: 20,
      sort: [
        {
          field: "createdAt",
          method: "DESC",
        },
      ],
      customFilters: [
        taskFilter === ETenantTaskFilter.Archived
          ? ETaskCustomFilter.Archived
          : ETaskCustomFilter.NotArchived,
      ],
      ...getMemoizedFilter,
    };
  };

  const task = useSelector((state: IRootStore) =>
    tasksSelectors.getDetail(state, taskId),
  );

  React.useEffect(() => {
    if (task) {
      document.title = `Rentio | ${task.title}`;
    } else {
      document.title = `Rentio | ${getLocalizedText("system.entity.tasks")}`;
    }
  }, [task]);

  const {
    items: tasks,
    isFetching,
    refresh,
    isExhausted,
    loadMore,
  } = tasksHooks.useInfiniteLoad({
    // @ts-ignore
    query: getQuery(),
    shouldRefetch: true,
  });

  const { count } = tasksHooks.useCount({
    shouldRefetch: true,
    query: {},
  });

  const handleTaskTypeChange = (type: ETenantTaskFilter) => {
    setTaskListFilter(type);
  };

  const handleLoadMore = () => {
    loadMore();
  };

  const isEmpty = count === 0 && !isFetching;
  return (
    <Page
      isEmpty={isEmpty}
      emptyHeading="tasks.empty"
      emptyMessage="tenant.tasks.empty.details"
      emptyActions={
        <Button
          variant={EButtonVariants.Contained}
          onClick={() => navigate("/tasks/add")}
        >
          {getLocalizedText("quick_action.new_task.title")}
        </Button>
      }
    >
      <TaskApp
        tasks={tasks || []}
        refresh={refresh}
        taskFilter={taskFilter}
        isLoading={isFetching}
        handleLoadMore={!isExhausted ? handleLoadMore : undefined}
        handleTaskTypeChange={handleTaskTypeChange}
      />
    </Page>
  );
};
