import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField, ERole } from "./schema.signUp.types";
export var getSchema = function (initialValues) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [
                EField.Firstname,
                EField.Lastname,
                EField.Email,
                EField.Phone,
                EField.Password,
                EField.Captcha,
            ],
            properties: (_a = {},
                _a[EField.Role] = {
                    default: getValue(EField.Role, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(ERole).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("signup.dashboard.user.type.".concat(id)),
                    }); }),
                },
                _a[EField.Firstname] = {
                    default: getValue(EField.Firstname, initialValues, defaultValues),
                },
                _a[EField.Lastname] = {
                    default: getValue(EField.Lastname, initialValues, defaultValues),
                },
                _a[EField.Email] = {
                    default: getValue(EField.Email, initialValues, defaultValues),
                },
                _a[EField.Phone] = {
                    default: getValue(EField.Phone, initialValues, defaultValues),
                },
                _a[EField.Password] = {
                    default: getValue(EField.Password, initialValues, defaultValues),
                    minLength: 8,
                },
                _a[EField.Captcha] = {
                    default: getValue(EField.Captcha, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function () {
    var _a;
    return (_a = {
            "rentio:trackingKey": "sign-up"
        },
        _a[EField.Role] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("system.me"),
        },
        _a[EField.Firstname] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.firstname"),
            placeholder: getLocalizedText("system.firstname"),
        },
        _a[EField.Lastname] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.lastname"),
            placeholder: getLocalizedText("system.lastname"),
        },
        _a[EField.Email] = {
            "ui:field": ERentioFormField.Email,
            title: getLocalizedText("system.email"),
            placeholder: getLocalizedText("system.email"),
        },
        _a[EField.Phone] = {
            "ui:field": ERentioFormField.Phone,
            title: getLocalizedText("system.phone"),
            placeholder: getLocalizedText("system.phone"),
        },
        _a[EField.Password] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.password"),
            placeholder: getLocalizedText("form.sign_up.password.placeholder"),
            secureTextEntry: true,
        },
        _a[EField.Captcha] = {
            "ui:field": ERentioFormField.Captcha,
            title: getLocalizedText("system.captcha"),
        },
        _a);
};
