import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { EAssets } from "../../../assets";
import { Icon } from "../../Icon";
import { DataListItemStyles } from "./DataListItem.styles";
export var EStartIcon;
(function (EStartIcon) {
    EStartIcon["Icon"] = "icon";
    EStartIcon["Avatar"] = "avatar";
})(EStartIcon || (EStartIcon = {}));
export var DataListItem = function (_a) {
    var configuration = _a.configuration, mainContent = _a.mainContent;
    var _b = configuration || {}, action = _b.action, onClick = _b.onClick, _c = _b.link, link = _c === void 0 ? "" : _c, _d = _b.showDetailsIcon, showDetailsIcon = _d === void 0 ? false : _d;
    var renderContent = function () {
        return mainContent;
    };
    var renderAction = function () {
        return action;
    };
    var renderLinkIcon = function () {
        return showDetailsIcon ? (_jsx(Box, { sx: sx.content.link, children: _jsx(Link, { to: link, children: _jsx(Icon, { path: EAssets.ArrowRightIcon, width: "16px", height: "16px" }) }) })) : null;
    };
    var sx = DataListItemStyles(!!onClick);
    return (_jsxs(Stack, { gap: 2, onClick: function () { return (onClick ? onClick() : {}); }, direction: { sm: "column", md: "row" }, justifyContent: "space-between", alignItems: { sm: "flex-start", md: "center" }, sx: sx.wrapper, children: [renderContent(), _jsxs(Stack, { direction: "row", alignItems: "center", gap: 4, children: [renderAction(), renderLinkIcon()] })] }));
};
