import { useTheme } from "@mui/material/styles";
export var TextAreaStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            padding: "10px 14px",
            borderRadius: "8px",
            borderColor: "#bdbdbd",
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            color: theme.palette.grey[700],
            background: theme.palette.grey[50],
        },
    };
};
