var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getMappedLanguages } from "../../utils/location/location.utils";
import { EIdCardOptions } from "./schema.addContact.1.broker";
import { EField } from "./schema.addContact.types";
export var getNavigationTitle = function (_a) {
    var isCompany = _a.isCompany, contactId = _a.contactId, contact = _a.contact, baseAccountId = _a.baseAccountId;
    var isEdit = (contactId || !!contact) && !baseAccountId;
    if (isCompany) {
        if (isEdit) {
            return getLocalizedText("company.edit");
        }
        return getLocalizedText("company.add");
    }
    if (isEdit) {
        return getLocalizedText("contact.edit");
    }
    return getLocalizedText("contact.add");
};
export var getInitialValues = function (_a) {
    var _b, _c;
    var isCompany = _a.isCompany, contact = _a.contact, prefilledAddress = _a.prefilledAddress, initialType = _a.initialType;
    var address = undefined;
    if (contact === null || contact === void 0 ? void 0 : contact.street) {
        address = {
            street: contact.street,
            number: contact.number,
            box: contact.box,
            zip: contact.zip,
            city: contact.city,
            country: contact.countryId,
        };
    }
    var placeOfBirth = undefined;
    if (contact === null || contact === void 0 ? void 0 : contact.placeOfBirth) {
        placeOfBirth = {
            city: contact.placeOfBirth,
            country: contact.countryOfBirth,
        };
    }
    return __assign(__assign((_b = {}, _b[EField.InitialType] = initialType, _b[EField.Language] = getMappedLanguages()[0].id, _b[EField.VATNumber] = "BE", _b), (contact || {})), (_c = {}, _c[EField.NationalRegisterNumber] = (contact === null || contact === void 0 ? void 0 : contact.nationalRegisterNumber) || undefined, _c[EField.ForeignIdentityNumber] = (contact === null || contact === void 0 ? void 0 : contact.foreignIdentityNumber) || undefined, _c[EField.DateOfBirth] = (contact === null || contact === void 0 ? void 0 : contact.dateOfBirth) || undefined, _c[EField.Nationality] = (contact === null || contact === void 0 ? void 0 : contact.nationality) || undefined, _c[EField.IdType] = (contact === null || contact === void 0 ? void 0 : contact.nationalRegisterNumber) || (contact === null || contact === void 0 ? void 0 : contact.nationality) === "BE"
        ? EIdCardOptions.BelgianIdCardNumber
        : EIdCardOptions.PassportNumber, _c[EField.PrefillAddress] = prefilledAddress ? "yes" : "no", _c[EField.SendInvite] = "yes", _c.address = address, _c.placeOfBirth = placeOfBirth, _c));
};
