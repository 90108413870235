import { useTheme } from "@rentiohq/web-shared-next/dist/themes";
import { EFontWeights } from "@rentiohq/web-shared-next/dist/themes/types";

export const TaskListStyles = () => {
  const theme = useTheme();
  return {
    content: {
      listItemBtn: (isActive?: boolean) => ({
        padding: "6px 12px",
        borderRadius: "6px",
        backgroundColor: isActive
          ? theme.palette.secondary.light
          : theme.palette.background.paper,
      }),
      title: {
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: theme.palette.grey[600],
      },
      divider: {
        margin: "12px 0",
      },
      description: {
        color: theme.palette.grey[600],
        fontWeight: EFontWeights.Regular,
      },
      chip: {
        minWidth: 14,
        maxWidth: 14,
        minHeight: 14,
        maxHeight: 14,
        backgroundColor: theme.palette.success.main,
        "&:hover": {
          backgroundColor: theme.palette.success.main,
        },
      },
    },
  };
};
