import { ERegistrationDocumentType } from "@rentiohq/shared-frontend/dist/types/registration.types";
import {
  getSchema as getSchema1,
  uiSchema as uiSchema1,
} from "./schema.registrationAddDocument.1";
import {
  getSchema as getSchema2,
  uiSchema as uiSchema2,
} from "./schema.registrationAddDocument.2";
import {
  getSchema as getSchema3,
  uiSchema as uiSchema3,
} from "./schema.registrationAddDocument.3";
import {
  getSchema as getSchema4,
  uiSchema as uiSchema4,
} from "./schema.registrationAddDocument.4";
import {
  getSchema as getSchema5,
  uiSchema as uiSchema5,
} from "./schema.registrationAddDocument.5";
import {
  getSchema as getSchema6,
  uiSchema as uiSchema6,
} from "./schema.registrationAddDocument.6";
import {
  getSchema as getSchema7,
  uiSchema as uiSchema7,
} from "./schema.registrationAddDocument.7";
import { IExtraData } from "./schema.registrationAddDocument.types";
import { getInitialValues } from "./schema.registrationAddDocument.utils";

// eslint-disable-next-line import/no-default-export
export default (extraData: IExtraData) => {
  const initialValues = getInitialValues(extraData);

  return [
    {
      schema: getSchema1(initialValues, extraData),
      uiSchema: uiSchema1(extraData),
      showIf: () =>
        extraData.documentType !== ERegistrationDocumentType.Brussels,
    },
    {
      schema: getSchema2(initialValues, extraData),
      uiSchema: uiSchema2(extraData),
      showIf: () =>
        extraData.documentType !== ERegistrationDocumentType.Brussels,
    },
    {
      schema: getSchema3(initialValues, extraData),
      uiSchema: uiSchema3(extraData),
      showIf: () =>
        extraData.documentType !== ERegistrationDocumentType.Brussels,
    },

    {
      schema: getSchema4(initialValues, extraData),
      uiSchema: uiSchema4(extraData),
      showIf: () =>
        extraData.documentType === ERegistrationDocumentType.Addendum,
    },
    {
      schema: getSchema5(initialValues, extraData),
      uiSchema: uiSchema5(extraData),
      showIf: () =>
        extraData.documentType ===
        ERegistrationDocumentType.PropertyInspectionReport,
    },

    {
      schema: getSchema6(initialValues, extraData),
      uiSchema: uiSchema6(initialValues, extraData),
      showIf: () =>
        extraData.documentType === ERegistrationDocumentType.Brussels,
    },
    {
      schema: getSchema7(initialValues, extraData),
      uiSchema: uiSchema7(extraData),
      showIf: () =>
        extraData.documentType === ERegistrationDocumentType.Brussels,
    },
  ];
};
