export var ERentioFormField;
(function (ERentioFormField) {
    ERentioFormField["Select"] = "rentio:field:select";
    ERentioFormField["TextField"] = "rentio:field:textfield";
    ERentioFormField["TextArea"] = "rentio:field:textarea";
    ERentioFormField["Email"] = "rentio:field:email";
    ERentioFormField["Date"] = "rentio:field:date";
    ERentioFormField["SegmentedControl"] = "rentio:field:segmented_control";
    ERentioFormField["Phone"] = "rentio:field:phone";
    ERentioFormField["Address"] = "rentio:field:address";
    ERentioFormField["AddressAutoComplete"] = "rentio:field:address_autocomplete";
    ERentioFormField["AddressTextField"] = "rentio:field:address_textfield";
    ERentioFormField["MaskedInput"] = "rentio:field:masked_input";
    ERentioFormField["AutoComplete"] = "rentio:field:autocomplete";
    ERentioFormField["ContactSelectInline"] = "rentio:field:contact_select_inline";
    ERentioFormField["ContactCheckSelectInline"] = "rentio:field:contact_select_check_inline";
    ERentioFormField["PropertySelectInline"] = "rentio:field:property_select_inline";
    ERentioFormField["InlineSelect"] = "rentio:field:inline_select";
})(ERentioFormField || (ERentioFormField = {}));
export var ERentioFormFormat;
(function (ERentioFormFormat) {
    ERentioFormFormat["VATNumber"] = "vat-number";
    ERentioFormFormat["NationalRegisterNumber"] = "national-register-number";
})(ERentioFormFormat || (ERentioFormFormat = {}));
