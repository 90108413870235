import * as contractActions from "../../reduxV2/contract/contract.actions";
import * as contractSelectors from "../../reduxV2/contract/contract.selectors";
import { ECancellationReasons, EField } from "./schema.cancelContract.types";
var getCancellationReason = function (formData) {
    var reasonIsOther = formData[EField.ReasonForCancellationOwner] ===
        ECancellationReasons.Other ||
        formData[EField.ReasonForCancellationTenant] === ECancellationReasons.Other;
    if (reasonIsOther) {
        return formData[EField.ReasonForCancellationOther];
    }
    var tenantReason = formData[EField.ReasonForCancellationTenant];
    if (tenantReason)
        return tenantReason;
    var ownerReason = formData[EField.ReasonForCancellationOwner];
    if (ownerReason)
        return ownerReason;
};
export var submitActionCreator = function (contractId) { return function (formData) {
    var dateOfCancellation = formData[EField.CancellationDate];
    var stopDate = formData[EField.StopDate];
    var reasonForCancellation = getCancellationReason(formData);
    var cancelledForAccountId = formData[EField.MemberThatCancels];
    var documents = formData[EField.CancellationDocuments];
    return contractActions.cancelStart.getAction({
        id: contractId,
        data: {
            dateOfCancellation: dateOfCancellation,
            reasonForCancellation: reasonForCancellation,
            cancelledForAccountId: cancelledForAccountId,
            stopDate: stopDate,
            documents: documents,
        },
    });
}; };
export var isPerformingSubmitActionSelector = function (contractId) { return function (state) {
    return contractSelectors.isCanceling(state, contractId);
}; };
export var performingSubmitActionErrorSelector = function (contractId) { return function (state) {
    return contractSelectors.cancellationError(state, contractId);
}; };
