export var FormStyles = function () {
    return {
        multiStepWrapper: function (downMD) { return ({
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: downMD ? "column" : "row",
            p: 2,
        }); },
        singleStepWrapper: {
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
        },
    };
};
