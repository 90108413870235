import { useTheme } from "@rentiohq/web-shared-next/dist/themes";

export const TaskHeaderStyles = () => {
  const theme = useTheme();
  return {
    desktopWrapper: {
      py: 3,
      px: 2,
      display: { xs: "none", md: "block" },
    },
    mobileWrapper: {
      position: "relative",
      background: theme.palette.success.main,
      color: theme.palette.background.paper,
      textAlign: "center",
      display: { xs: "block", md: "none" },
      p: 2,
    },
    content: {
      divider: {
        my: 2,
      },
      title: {
        color: {
          xs: theme.palette.background.paper,
          md: theme.palette.grey[700],
        },
      },
      button: {
        position: "absolute",
        left: "16px",
        cursor: "pointer",
      },
    },
  };
};
