import { useTheme } from "@mui/material/styles";
import { getFontSize } from "../../themes";
import { EFontSizes, EFontWeights } from "../../themes/types";
export var displayTextSizes = {
    extraLarge: EFontSizes.SuperLarge,
    large: EFontSizes.ExtraExtraLarge,
    medium: EFontSizes.ExtraLarge,
    small: EFontSizes.Large,
    extraSmall: EFontSizes.Normal,
};
export var MarkdownWrapperStyles = function (listAsChecklist, checklistCharacter) {
    var theme = useTheme();
    return {
        wrapper: {
            "p:not(:only-child)": {
                margin: "0px 0 16px 0",
            },
            h1: {
                fontSize: getFontSize(EFontSizes.UltraLarge),
                fontWeight: EFontWeights.ExtraBold,
                letterSpacing: "0.05rem",
                lineHeight: 1.3,
                color: theme.palette.text.secondary,
            },
            h2: {
                fontSize: getFontSize(EFontSizes.SuperLarge),
                fontWeight: EFontWeights.ExtraBold,
                letterSpacing: "0.05rem",
                lineHeight: 1.3,
                color: theme.palette.text.secondary,
            },
            h3: {
                fontSize: getFontSize(EFontSizes.ExtraExtraLarge),
                fontWeight: EFontWeights.ExtraBold,
                letterSpacing: "0.05rem",
                lineHeight: 1.3,
                color: theme.palette.text.secondary,
            },
            h4: {
                fontSize: getFontSize(EFontSizes.Medium),
                fontWeight: EFontWeights.Medium,
                letterSpacing: "0.05rem",
                lineHeight: 1.3,
                color: theme.palette.text.secondary,
            },
            "ul,ol": {
                paddingLeft: 3,
            },
            li: {
                fontSize: getFontSize(EFontSizes.Small),
                paddingLeft: 0.5,
                margin: "4px 0",
                color: theme.palette.text.secondary,
                "list-style-type": checklistCharacter || "✓",
            },
        },
    };
};
