import { ERecurrenceType } from "@rentiohq/shared-frontend/dist/types/task.types";
import {
  IFormikValues,
  IFormStep,
} from "@rentiohq/web-shared-next/dist/utils/types/form";
import { uiSchema as uiSchema1 } from "./schema.addTask.1";
import { uiSchema as uiSchema2 } from "./schema.addTask.2";
import { uiSchema as uiSchema3 } from "./schema.addTask.3";
import { uiSchema as uiSchema4 } from "./schema.addTask.4";
import { EField, IExtraData } from "./schema.addTask.types";
import { getInitialValues } from "./schema.addTask.utils";

export const addTaskSchema =
  (extraData: IExtraData) =>
  (): { formStepsSchema: IFormStep[]; initialValues: IFormikValues } => {
    const initialValues = getInitialValues(extraData);
    const formStepsSchema = [
      {
        uiSchema: uiSchema1(extraData),
      },
      ...(extraData.taskId
        ? []
        : [
            {
              uiSchema: uiSchema2(extraData),
            },
          ]),
      {
        uiSchema: uiSchema3(extraData),
      },
      {
        uiSchema: uiSchema4(extraData),
        showIf: (formData: IFormikValues) =>
          formData[EField.RecurrenceSelection] !== ERecurrenceType.Once,
      },
    ];

    // @ts-ignore
    return { formStepsSchema, initialValues };
  };
