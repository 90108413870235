import { useTheme } from "@mui/material/styles";
var ITEM_HEIGHT = 48;
export var MenuStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
        },
        content: {
            item: function (danger) { return ({
                color: danger ? theme.palette.error.dark : theme.palette.grey[700],
            }); },
        },
    };
};
