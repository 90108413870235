import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  ERecurrenceType,
  ETaskCategory,
  ETaskReminder,
  ETaskType,
  TypeCategoriesMap,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import {
  differenceInDays,
  isBefore,
  setHours,
  startOfDay,
  subDays,
  subMonths,
  subWeeks,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  IFormikProps,
  IFormikValues,
} from "@rentiohq/web-shared-next/dist/utils/types/form";
import { Platform } from "react-native";
import { EField, IExtraData } from "./schema.addTask.types";

const NOW = new Date();

export const getNavigationTitle = ({ taskId }: IExtraData) => {
  if (taskId) {
    return getLocalizedText("tasks.edit");
  }
  return getLocalizedText("task.new");
};

export const REMINDER_OPTIONS = [
  ETaskReminder.DayBeforeExecuteDate,
  ETaskReminder.DayBeforeDueDate,
  ETaskReminder.WeekBeforeExecuteDate,
  ETaskReminder.WeekBeforeDueDate,
  ETaskReminder.MonthBeforeExecuteDate,
  ETaskReminder.MonthBeforeDueDate,
  ETaskReminder.Date,
];

export const FREQUENCY_OPTIONS = [
  ERecurrenceType.Daily,
  ERecurrenceType.Weekly,
  ERecurrenceType.Monthly,
  ERecurrenceType.Quarterly,
  ERecurrenceType.Yearly,
  ERecurrenceType.HalfYearly,
  ERecurrenceType.TwoYearly,
  ERecurrenceType.ThreeYearly,
  ERecurrenceType.FiveYearly,
];

export const getMappedCategories = (type: ETaskType): ETaskCategory[] => {
  const categories = TypeCategoriesMap.get(type);
  if (!categories) return [];
  return categories;
};

export const getInitialValues = (extraData: IExtraData): IValues => {
  const {
    task,
    executors,
    followers,
    payerAccountId,
    repairAccountId,
    propertyId,
  } = extraData;

  if (task) {
    const isRecurrent = task.recurrenceType !== ERecurrenceType.Once;

    return {
      [EField.Title]: task.title,
      [EField.PropertySelect]: task.propertyId ? task.propertyId : undefined,
      [EField.ExecuteDate]: task.executeDate || undefined,
      [EField.RecurrenceSelection]: isRecurrent
        ? ERecurrenceType.Recurring
        : ERecurrenceType.Once,
      [EField.ReminderType]: task.nextReminderDate
        ? ETaskReminder.HasDate
        : ETaskReminder.None,
      [EField.ReminderOption]: task.reminderOption || undefined,
      [EField.DueDate]: task.dueDate || undefined,
      [EField.NextReminderDate]: task.nextReminderDate || undefined,
      [EField.RecurrenceType]:
        (isRecurrent && task.recurrenceType) || undefined,
      [EField.RecurrenceEndDate]:
        (isRecurrent &&
          task.recurrenceInfo &&
          task.recurrenceInfo.recurrenceEndDate) ||
        undefined,
      [EField.Description]: task.description,
      [EField.Type]: task.type,
      [EField.Category]: task.category,
      [EField.DamageType]: task.damageType,
      [EField.Location]: task.location,
      [EField.IncidentDate]: task.incidentDate,
      [EField.Payer]: payerAccountId ? [payerAccountId] : undefined,
      [EField.ContactPerson]: repairAccountId ? [repairAccountId] : undefined,
    };
  }

  return {
    [EField.Executors]: executors,
    [EField.Followers]: followers,
    [EField.PropertySelect]: propertyId,
    [EField.RecurrenceSelection]: ERecurrenceType.Once,
    [EField.ReminderType]: ETaskReminder.None,
    [EField.Type]: undefined,
    [EField.ExecuteDate]: undefined,
    [EField.DueDate]: undefined,
  };
};

export const prefillDateOnChange = (
  value: ETaskReminder,
  field: EField,
  formikProps: IFormikProps<IFormikValues>,
) => {
  const { setFieldValue, values: formData } = formikProps;
  const executeDate = formData[EField.ExecuteDate];
  const dueDate = formData[EField.DueDate];
  let newDate = new Date();
  switch (value) {
    case ETaskReminder.DayBeforeExecuteDate:
      newDate = subDays(new Date(executeDate), 1);
      break;
    case ETaskReminder.DayBeforeDueDate:
      newDate = subDays(new Date(dueDate), 1);
      break;
    case ETaskReminder.WeekBeforeExecuteDate:
      newDate = subWeeks(new Date(executeDate), 1);
      break;
    case ETaskReminder.WeekBeforeDueDate:
      newDate = subWeeks(new Date(dueDate), 1);
      break;
    case ETaskReminder.MonthBeforeExecuteDate:
      newDate = subMonths(new Date(executeDate), 1);
      break;
    case ETaskReminder.MonthBeforeDueDate:
      newDate = subMonths(new Date(dueDate), 1);
      break;
    case ETaskReminder.Date:
      if (Platform.OS === "web") {
        setFieldValue(field, undefined);
      }
      break;
    default:
  }

  setFieldValue(field, isBefore(newDate, NOW) ? NOW : setHours(newDate, 12));
};

export const isReminderOptionDisabled = (
  id: any,
  executeDate: Date,
  dueDate: Date,
) => {
  const chosenDate = executeDate || dueDate;
  if (!chosenDate && id !== ETaskReminder.Date) {
    return true;
  }
  if (id === ETaskReminder.DayBeforeExecuteDate) {
    if (!executeDate || isBefore(subDays(new Date(executeDate), 1), NOW)) {
      return true;
    }
  }
  if (id === ETaskReminder.DayBeforeDueDate) {
    if (!dueDate || isBefore(subDays(new Date(dueDate), 1), NOW)) {
      return true;
    }
  }
  if (id === ETaskReminder.WeekBeforeExecuteDate) {
    if (!executeDate || isBefore(subWeeks(new Date(executeDate), 1), NOW)) {
      return true;
    }
  }
  if (id === ETaskReminder.WeekBeforeDueDate) {
    if (!dueDate || isBefore(subWeeks(new Date(dueDate), 1), NOW)) {
      return true;
    }
  }
  if (id === ETaskReminder.MonthBeforeExecuteDate) {
    if (!executeDate || isBefore(subMonths(new Date(executeDate), 1), NOW)) {
      return true;
    }
  }
  if (id === ETaskReminder.MonthBeforeDueDate) {
    if (!dueDate || isBefore(subMonths(new Date(dueDate), 1), NOW)) {
      return true;
    }
  }
  return false;
};

export const reminderOptionChange = (
  value: Date,
  field: EField,
  formikProps: IFormikProps<IFormikValues>,
) => {
  const { setFieldValue, values: formData } = formikProps;
  const executeDate = formData[EField.ExecuteDate];
  const dueDate = formData[EField.DueDate];

  const amountOfDaysToExecuteDate = differenceInDays(
    startOfDay(executeDate),
    startOfDay(new Date(value)),
  );
  const amountOfDaysToDueDate = differenceInDays(
    startOfDay(dueDate),
    startOfDay(new Date(value)),
  );

  let reminderOption = undefined;
  if (amountOfDaysToExecuteDate === 31 || amountOfDaysToExecuteDate === 30) {
    reminderOption = ETaskReminder.MonthBeforeExecuteDate;
  }
  if (amountOfDaysToDueDate === 31 || amountOfDaysToDueDate === 30) {
    reminderOption = ETaskReminder.MonthBeforeDueDate;
  }
  if (amountOfDaysToExecuteDate === 7) {
    reminderOption = ETaskReminder.WeekBeforeExecuteDate;
  }
  if (amountOfDaysToDueDate === 7) {
    reminderOption = ETaskReminder.WeekBeforeDueDate;
  }
  if (amountOfDaysToExecuteDate === 1) {
    reminderOption = ETaskReminder.DayBeforeExecuteDate;
  }
  if (amountOfDaysToDueDate === 1) {
    reminderOption = ETaskReminder.DayBeforeDueDate;
  }

  if (reminderOption) {
    setFieldValue(field, reminderOption);
  } else {
    setFieldValue(field, ETaskReminder.Date);
  }
};
