import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countHooks from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countTypes from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { ECountIdentifier } from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as contractTypes from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as beneficiaryReportHooks from "@rentiohq/shared-frontend/dist/reduxV2/reportBeneficiary/report.beneficiary.hooks";
import * as tasksHooks from "@rentiohq/shared-frontend/dist/reduxV2/task/task.hooks";
import { ETaskCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/task/task.utils";
import { ELegalCaseContractStatus } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EPayoutType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { EPropertyMemberTypes } from "@rentiohq/shared-frontend/dist/types/property.types";
import { ETaskMemberType } from "@rentiohq/shared-frontend/dist/types/task.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { Page } from "@rentiohq/web-shared/dist/components";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  EEndDateWithinMonths,
  ERenewalDateWithinMonths,
} from "scenes/FollowUp/EndingContracts/EndingContracts.types";
import {
  getEndDateFilterQuery,
  getRenewalDateFilterQuery,
} from "scenes/FollowUp/EndingContracts/EndingContracts.utils";
import {
  EPaymentRequestFilterPhase,
  getPaymentRequestsFilter,
} from "scenes/FollowUp/Payments/Payments.utils";
import { BENEFICIARY_REPORTS_CUTOFF_DATE } from "scenes/Reports/BeneficiaryV2/BeneficiaryReport.filter";
import { EBeneficiaryReportReported } from "scenes/Reports/BeneficiaryV2/BeneficiaryReport.types";
import { Dashboard } from "./Dashboard";

const NOW = new Date();

export const DashboardContainer: FC<{}> = () => {
  const dispatch = useDispatch();

  const { user } = authHooks.useSelf();

  const {
    count: tasksToExecuteCount,
    isFetching: tasksToExecuteCountIsFetching,
  } = tasksHooks.useCount({
    shouldRefetch: true,
    query: {
      filter: {
        finishedAt: { is: null },
      },
      customFilters: [ETaskCustomFilter.NotArchived],
      rolesFilter: [ETaskMemberType.Executor],
    },
  });

  const {
    count: ongoingLegalCasesCount,
    isFetching: ongoingLegalCasesCountIsFetching,
  } = contractHooks.useCount({
    shouldRefetch: true,
    query: {
      filter: {
        stopDate: { gte: NOW },
        legalStatus: { eq: ELegalCaseContractStatus.Created },
      },
      propertyRolesFilter: [EPropertyMemberTypes.FinancialManager],
      customFilters: ["PROPERTY_TYPE_RESIDENTIAL"],
    },
  });

  const {
    count: tasksKeyPointRepair,
    isFetching: tasksKeyPointRepairIsFetching,
  } = tasksHooks.useCount({
    shouldRefetch: true,
    query: {
      filter: {
        finishedAt: { is: null },
        status: { isNot: null },
      },
      customFilters: [ETaskCustomFilter.NotArchived],
    },
  });

  const {
    count: indexableContractsCount,
    isFetching: indexableContractsCountIsFetching,
  } = contractHooks.useCount({
    shouldRefetch: true,
    query: contractTypes.ContractFilters.contractsIndexable,
  });

  const {
    count: contractsWithDeadlineWithin1MonthCount,
    isFetching: contractsWithDeadlineWithin1MonthCountIsFetching,
  } = contractHooks.useCount({
    shouldRefetch: true,
    query: getRenewalDateFilterQuery({
      tacitRenewal: true,
      renewalDateWithinMonths: ERenewalDateWithinMonths.Within1Month,
    }),
  });

  const {
    count: contractsWithEndDateWithin1MonthCount,
    isFetching: contractsWithEndDateWithin1MonthCountIsFetching,
  } = contractHooks.useCount({
    shouldRefetch: true,
    query: getEndDateFilterQuery({
      tacitRenewal: false,
      endDateWithinMonths: EEndDateWithinMonths.Within1Month,
    }),
  });

  const signaturesPendingCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(
      state,
      countTypes.ECountIdentifier.DocumentPackagesPending,
    ),
  );
  const signaturesPendingCountIsFetching = useSelector((state: IRootStore) =>
    countSelectors.getIsFetchingCount(
      state,
      countTypes.ECountIdentifier.DocumentPackagesPending,
    ),
  );

  const revenuesCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(state, ECountIdentifier.Revenues),
  );
  const revenuesCountIsFetching = useSelector((state: IRootStore) =>
    countSelectors.getIsFetchingCount(state, ECountIdentifier.Revenues),
  );

  const registrationsAllActionRequiredCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(
      state,
      ECountIdentifier.RegistrationsAllActionRequired,
    ),
  );
  const registrationsAllActionRequiredCountIsFetching = useSelector(
    (state: IRootStore) =>
      countSelectors.getIsFetchingCount(
        state,
        ECountIdentifier.RegistrationsAllActionRequired,
      ),
  );

  const registrationAddendaActionRequiredCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        ECountIdentifier.RegistrationsAddendaActionRequired,
      ),
  );
  const registrationAddendaActionRequiredCountIsFetching = useSelector(
    (state: IRootStore) =>
      countSelectors.getIsFetchingCount(
        state,
        ECountIdentifier.RegistrationsAddendaActionRequired,
      ),
  );

  const {
    count: paymentRequestsCount,
    isFetching: paymentRequestsCountIsFetching,
  } = countHooks.useCount({
    shouldRefetch: true,
    countBase: append("/payment-requests/count", {
      where: getPaymentRequestsFilter({}).where,
    }),
  });

  const {
    count: paymentRequestsRentioCount,
    isFetching: paymentRequestsRentioCountIsFetching,
  } = countHooks.useCount({
    shouldRefetch: true,
    countBase: append("/payment-requests/count", {
      where: mapObjectWithTimeZonedDatesToUtcIsoString(
        getPaymentRequestsFilter({
          phase: EPaymentRequestFilterPhase.Rentio,
        }).where,
      ),
    }),
  });

  const {
    count: paymentRequestsActionRequiredCount,
    isFetching: paymentRequestsActionRequiredCountIsFetching,
  } = countHooks.useCount({
    shouldRefetch: true,
    countBase: append("/payment-requests/count", {
      where: mapObjectWithTimeZonedDatesToUtcIsoString(
        getPaymentRequestsFilter({
          phase: EPaymentRequestFilterPhase.Action,
        }).where,
      ),
    }),
  });

  const paymentsTooLateCount =
    paymentRequestsRentioCount !== undefined &&
    paymentRequestsActionRequiredCount !== undefined
      ? paymentRequestsRentioCount + paymentRequestsActionRequiredCount
      : undefined;
  const paymentsTooLateCountIsFetching =
    paymentRequestsActionRequiredCountIsFetching ||
    paymentRequestsRentioCountIsFetching;

  const activeAccountId = user?.activeAccountId;

  const toSignRentDepositsCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(state, ECountIdentifier.RentDepositsToBeSigned),
  );
  const toSignRentDepositsCountIsFetching = useSelector((state: IRootStore) =>
    countSelectors.getIsFetchingCount(
      state,
      ECountIdentifier.RentDepositsToBeSigned,
    ),
  );

  const { count: toBeReportedCount, isFetching: toBeReportedCountIsFetching } =
    beneficiaryReportHooks.useCount({
      shouldRefetch: true,
      query: {
        filter: { createdAt: { gte: BENEFICIARY_REPORTS_CUTOFF_DATE } },
        customFilters: [EBeneficiaryReportReported.PaymentRequestNotReported],
      },
    });

  useEffect(() => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: ECountIdentifier.RentDepositsToBeSigned,
        countBase:
          countTypes.COUNT_BASE[ECountIdentifier.RentDepositsToBeSigned],
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: ECountIdentifier.DocumentPackagesPending,
        countBase:
          countTypes.COUNT_BASE[ECountIdentifier.DocumentPackagesPending],
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: ECountIdentifier.Revenues,
        countBase: append("/payment-requests/count", {
          where: {
            invoicedAt: null,
            externalInvoiceId: null,
            requestPayeeAccountId: activeAccountId,
            payoutType: EPayoutType.Payout,
          },
          selection: ["FUTURE_REVENUES"],
        }),
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: ECountIdentifier.RegistrationsAllActionRequired,
        countBase:
          countTypes.COUNT_BASE[
            ECountIdentifier.RegistrationsAllActionRequired
          ],
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: ECountIdentifier.RegistrationsAddendaActionRequired,
        countBase:
          countTypes.COUNT_BASE[
            ECountIdentifier.RegistrationsAddendaActionRequired
          ],
      }),
    );
  }, []);

  if (!user) {
    return null;
  }

  return (
    <Page>
      <Dashboard
        counts={{
          paymentRequestsCount,
          paymentRequestsCountIsFetching,

          revenuesCount,
          revenuesCountIsFetching,

          toBeReportedCount,
          toBeReportedCountIsFetching,

          signaturesPendingCount,
          signaturesPendingCountIsFetching,

          toSignRentDepositsCount,
          toSignRentDepositsCountIsFetching,

          registrationsAllActionRequiredCount,
          registrationsAllActionRequiredCountIsFetching,

          registrationAddendaActionRequiredCount,
          registrationAddendaActionRequiredCountIsFetching,

          paymentsTooLateCount,
          paymentsTooLateCountIsFetching,

          ongoingLegalCasesCount,
          ongoingLegalCasesCountIsFetching,

          tasksToExecuteCount,
          tasksToExecuteCountIsFetching,

          tasksKeyPointRepair,
          tasksKeyPointRepairIsFetching,

          indexableContractsCount,
          indexableContractsCountIsFetching,

          contractsWithDeadlineWithin1MonthCount,
          contractsWithDeadlineWithin1MonthCountIsFetching,

          contractsWithEndDateWithin1MonthCount,
          contractsWithEndDateWithin1MonthCountIsFetching,
        }}
      />
    </Page>
  );
};
