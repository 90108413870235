import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as paymentRequestActions from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentRequestSelectors from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import {
  IDocumentPackage,
  IDocumentPackageSigner,
} from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { IPaymentRequest } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { ITask } from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  SHOW_APP_MODAL,
  SHOW_LOADER,
} from "@rentiohq/web-shared-next/dist/redux/actions";
import {
  Button,
  DataList,
  DataListItem,
  EIconAvatarVariant,
  IconAvatar,
  MainCard,
  Stack,
} from "@rentiohq/web-shared-next/dist/ui-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { DocumentCard } from "../../../../components/DocumentCard";
import { PaymentRequestCard } from "../../../../components/PaymentRequestCard";
import { TaskCard } from "../../../../components/TaskCard";
import { getSignedInSigner } from "../propertyDetails.utils";
interface IProps {
  documentPackages: IDocumentPackage[];
  paymentRequests: IPaymentRequest[];
  tasks: ITask[];
}

export const TodoList = ({
  documentPackages,
  paymentRequests,
  tasks,
}: IProps) => {
  const dispatch = useDispatch();
  const { user } = useSelf();

  const [paymentRequestId, setPaymentRequestId] = React.useState<number>(-1);

  const paymentLink = useSelector((state: IRootStore) =>
    paymentRequestSelectors.getPaymentLink(state, paymentRequestId),
  );

  const isFetching = useSelector((state: IRootStore) =>
    paymentRequestSelectors.isFetchingPaymentLink(state, paymentRequestId),
  );

  const handleSignNowClick = (signers: IDocumentPackageSigner[]) => {
    const signedInSigner = getSignedInSigner(signers, user);
    if (signedInSigner) {
      window.open(signedInSigner.connectiveUrl, "_blank");
    }
  };

  const handlePayNowClick = (id: number) => {
    setPaymentRequestId(id);
    dispatch(paymentRequestActions.getPaymentLinkStart.getAction({ id }));
  };

  const handleViewDetails = () => {
    dispatch({
      type: SHOW_APP_MODAL,
      payload: {
        title: getLocalizedText("tenant.dashboard.task.view_details"),
        showAppModal: true,
      },
    });
  };

  React.useEffect(() => {
    if (paymentLink && paymentRequestId) {
      window.open(paymentLink);
      setPaymentRequestId(-1);
    }
  }, [paymentLink]);

  React.useEffect(() => {
    dispatch({ type: SHOW_LOADER, payload: isFetching });
  }, [isFetching]);

  return (
    <Stack direction="column" gap={1}>
      {documentPackages?.length > 0 && (
        <MainCard
          title={`${getLocalizedText(
            "document.sign.title",
          )} (${documentPackages?.length})`}
          border={true}
          startIcon={
            <IconAvatar
              icon={EAssets.DocToSignIcon}
              variant={EIconAvatarVariant.Warning}
            />
          }
        >
          <DataList>
            {documentPackages.map(documentPackage => (
              <DataListItem
                key={documentPackage?.id}
                configuration={{
                  action: (
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleSignNowClick(documentPackage?.signers)
                      }
                    >
                      {getLocalizedText("document.sign.now")}
                    </Button>
                  ),
                }}
                mainContent={<DocumentCard documentPackage={documentPackage} />}
              />
            ))}
          </DataList>
        </MainCard>
      )}
      {paymentRequests?.length > 0 && (
        <MainCard
          title={`${getLocalizedText(
            "card.general_info.section.payment_orders.cta",
          )} (${paymentRequests?.length})`}
          border={true}
          startIcon={
            <IconAvatar
              icon={EAssets.EuroIcon}
              variant={EIconAvatarVariant.Warning}
            />
          }
        >
          <DataList>
            {paymentRequests.map(paymentRequest => (
              <DataListItem
                key={paymentRequest?.id}
                configuration={{
                  action: (
                    <Button
                      variant="contained"
                      disabled={isFetching}
                      onClick={() => handlePayNowClick(paymentRequest?.id)}
                    >
                      {getLocalizedText("payment_reminder.card.button")}
                    </Button>
                  ),
                }}
                mainContent={
                  <PaymentRequestCard paymentRequest={paymentRequest} />
                }
              />
            ))}
          </DataList>
        </MainCard>
      )}
      {tasks?.length > 0 && (
        <MainCard
          title={`${getLocalizedText("tasks.execute.title")} (${tasks.length})`}
          border={true}
          startIcon={
            <IconAvatar
              icon={EAssets.TasksIcon}
              variant={EIconAvatarVariant.Warning}
            />
          }
        >
          <DataList>
            {tasks.map(task => (
              <DataListItem
                key={task?.id}
                configuration={{
                  action: (
                    <Button variant="contained" onClick={handleViewDetails}>
                      {getLocalizedText("system.check")}
                    </Button>
                  ),
                }}
                mainContent={<TaskCard task={task} />}
              />
            ))}
          </DataList>
        </MainCard>
      )}
    </Stack>
  );
};
