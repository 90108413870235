import alert from "../../assets/svg/alert.svg";
import apartment from "../../assets/svg/apartment.svg";
import application from "../../assets/svg/application.svg";
import archivedProperty from "../../assets/svg/archivedProperty.svg";
import arrowRight from "../../assets/svg/arrow-right.svg";
import arrowLeft from "../../assets/svg/arrowLeft.svg";
import ask from "../../assets/svg/ask.svg";
import attachment from "../../assets/svg/attachment.svg";
import bin from "../../assets/svg/bin.svg";
import calendarLight from "../../assets/svg/calendar-light.svg";
import calendar from "../../assets/svg/calendar.svg";
import chatRead from "../../assets/svg/chatRead.svg";
import chats from "../../assets/svg/chats.svg";
import chatSent from "../../assets/svg/chatSent.svg";
import close from "../../assets/svg/close.svg";
import commercialProperty from "../../assets/svg/commercial_property.svg";
import contentPenWrite from "../../assets/svg/content-pen-write.svg";
import docToSign from "../../assets/svg/docToSign.svg";
import euro from "../../assets/svg/euro.svg";
import fullLogo from "../../assets/svg/full-logo.svg";
import gallery from "../../assets/svg/gallery.svg";
import garage from "../../assets/svg/garage.svg";
import greenCheckedCircle from "../../assets/svg/green-checked-circle.svg";
import help from "../../assets/svg/help.svg";
import horizontalMenu from "../../assets/svg/horizontal_menu.svg";
import house from "../../assets/svg/house.svg";
import info from "../../assets/svg/info.svg";
import insurance from "../../assets/svg/insurance.svg";
import logo from "../../assets/svg/logo.svg";
import mail from "../../assets/svg/mail.svg";
import menu from "../../assets/svg/menu.svg";
import multiUsers from "../../assets/svg/multiUsers.svg";
import notes from "../../assets/svg/notes.svg";
import office from "../../assets/svg/office.svg";
import parkingSpot from "../../assets/svg/parking_spot.svg";
import pencil from "../../assets/svg/pencil.svg";
import profile from "../../assets/svg/profile.svg";
import rentioAppError from "../../assets/svg/rentio-app-error.svg";
import ringingPhone from "../../assets/svg/ringing-phone.svg";
import safe from "../../assets/svg/safe.svg";
import search from "../../assets/svg/search.svg";
import sendChat from "../../assets/svg/sendChat.svg";
import shed from "../../assets/svg/shed.svg";
import signout from "../../assets/svg/signout.svg";
import singleNeutral from "../../assets/svg/single-neutral.svg";
import Spinner from "../../assets/svg/spinner.svg";
import storage from "../../assets/svg/storage.svg";
import studentRoom from "../../assets/svg/student_room.svg";
import suitCase from "../../assets/svg/suit_case.svg";
import modeSwitch from "../../assets/svg/switch-mode.svg";
import synchronizeLight from "../../assets/svg/synchronize-light.svg";
import tasks from "../../assets/svg/tasks.svg";
import uploadFile from "../../assets/svg/upload.svg";
import greenUser from "../../assets/svg/user-circle-green.svg";
import userOutlined from "../../assets/svg/user-outlined.svg";
import user from "../../assets/svg/user.svg";
import menuVertical from "../../assets/svg/vertical_menu.svg";
import warehouse from "../../assets/svg/warehouse.svg";
export var ASSETS = {
    fullLogo: fullLogo,
    calendar: calendar,
    application: application,
    euro: euro,
    tasks: tasks,
    chats: chats,
    user: user,
    profile: profile,
    modeSwitch: modeSwitch,
    help: help,
    signout: signout,
    ask: ask,
    menu: menu,
    house: house,
    apartment: apartment,
    studentRoom: studentRoom,
    commercialProperty: commercialProperty,
    office: office,
    parkingSpot: parkingSpot,
    garage: garage,
    storage: storage,
    shed: shed,
    warehouse: warehouse,
    greenUser: greenUser,
    alert: alert,
    Spinner: Spinner,
    calendarLight: calendarLight,
    synchronizeLight: synchronizeLight,
    notes: notes,
    suitCase: suitCase,
    menuVertical: menuVertical,
    arrowRight: arrowRight,
    safe: safe,
    insurance: insurance,
    docToSign: docToSign,
    close: close,
    greenCheckedCircle: greenCheckedCircle,
    rentioAppError: rentioAppError,
    ringingPhone: ringingPhone,
    logo: logo,
    contentPenWrite: contentPenWrite,
    pencil: pencil,
    singleNeutral: singleNeutral,
    uploadFile: uploadFile,
    userOutlined: userOutlined,
    mail: mail,
    info: info,
    search: search,
    multiUsers: multiUsers,
    sendChat: sendChat,
    gallery: gallery,
    attachment: attachment,
    chatSent: chatSent,
    bin: bin,
    archivedProperty: archivedProperty,
    arrowLeft: arrowLeft,
    chatRead: chatRead,
    horizontalMenu: horizontalMenu,
};
export var EAssets;
(function (EAssets) {
    EAssets["LogoIcon"] = "logo";
    EAssets["CloseIcon"] = "close";
    EAssets["FullLogoIcon"] = "fullLogo";
    EAssets["CalendarIcon"] = "calendar";
    EAssets["ApplicationIcon"] = "application";
    EAssets["EuroIcon"] = "euro";
    EAssets["TasksIcon"] = "tasks";
    EAssets["ChatsIcon"] = "chats";
    EAssets["UserIcon"] = "user";
    EAssets["ProfileIcon"] = "profile";
    EAssets["ModeSwitchIcon"] = "modeSwitch";
    EAssets["HelpIcon"] = "help";
    EAssets["SignoutIcon"] = "signout";
    EAssets["AskIcon"] = "ask";
    EAssets["MenuIcon"] = "menu";
    EAssets["HouseIcon"] = "house";
    EAssets["ApartmentIcon"] = "apartment";
    EAssets["StudentRoomIcon"] = "studentRoom";
    EAssets["CommercialPropertyIcon"] = "commercialProperty";
    EAssets["OfficeIcon"] = "office";
    EAssets["ParkingSpotIcon"] = "parkingSpot";
    EAssets["GarageIcon"] = "garage";
    EAssets["StorageIcon"] = "storage";
    EAssets["ShedIcon"] = "shed";
    EAssets["WarehouseIcon"] = "warehouse";
    EAssets["GreenUserIcon"] = "greenUser";
    EAssets["AlertIcon"] = "alert";
    EAssets["SpinnerIcon"] = "Spinner";
    EAssets["CalendarLightIcon"] = "calendarLight";
    EAssets["SynchronizeLightIcon"] = "synchronizeLight";
    EAssets["NotesIcon"] = "notes";
    EAssets["SuitCaseIcon"] = "suitCase";
    EAssets["MenuVerticalIcon"] = "menuVertical";
    EAssets["ArrowRightIcon"] = "arrowRight";
    EAssets["SafeIcon"] = "safe";
    EAssets["InsuranceIcon"] = "insurance";
    EAssets["DocToSignIcon"] = "docToSign";
    EAssets["ContentPenWriteIcon"] = "contentPenWrite";
    EAssets["PencilIcon"] = "pencil";
    EAssets["SingleNeutralIcon"] = "singleNeutral";
    EAssets["UploadFileIcon"] = "uploadFile";
    EAssets["RingingPhoneIcon"] = "ringingPhone";
    EAssets["UserOutlinedIcon"] = "userOutlined";
    EAssets["MailIcon"] = "mail";
    EAssets["InfoIcon"] = "info";
    EAssets["SearchIcon"] = "search";
    EAssets["MultiUsersIcon"] = "multiUsers";
    EAssets["SendChatIcon"] = "sendChat";
    EAssets["GalleryIcon"] = "gallery";
    EAssets["AttachmentIcon"] = "attachment";
    EAssets["ChatSentIcon"] = "chatSent";
    EAssets["DeleteIcon"] = "bin";
    EAssets["ArchivedPropertyIcon"] = "archivedProperty";
    EAssets["ArrowLeftIcon"] = "arrowLeft";
    EAssets["ChatReadIcon"] = "chatRead";
    EAssets["HorizontalMenu"] = "horizontalMenu";
})(EAssets || (EAssets = {}));
